import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Bar, Button, ErrorMessage, Input } from '../../../../common/index';
import styles from '../styles';

const FindRoId = ({ setROId, roNotFound, defaultRoId, clearInfo, roId: roID }) => {
  const { control, watch } = useForm({
    defaultValues: {
      roId: defaultRoId
    }
  });

  const roId = watch('roId');

  const findClickHandler = () => {
    if (roID !== roId) clearInfo();
    setROId(roId);
  };

  return (
    <Bar heading="View Return Order ID">
      <Box sx={styles.viewPurchaseOrder}>
        <Box>
          <Input
            disabled={defaultRoId}
            name="roId"
            label="RO ID"
            placeholder="Enter Ro ID"
            control={control}
          />
        </Box>
        <Box sx={styles.findButton}>
          <Button
            disabled={!roId?.length || defaultRoId}
            onClick={findClickHandler}
            label="Find"
            customStyles={styles.actionButton}
          />
        </Box>
      </Box>
      {roNotFound && (
        <Box sx={{ marginBottom: '-10px' }}>
          <ErrorMessage errorMsg="Please provide a valid RO ID" />
        </Box>
      )}
    </Bar>
  );
};

export default FindRoId;
