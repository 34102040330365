import { Box } from '@mui/material';
import React from 'react';
import { Bar, Button, Input, ErrorMessage } from '../../../../common';
import { useForm } from 'react-hook-form';
import styles from '../styles';

const PutlistId = ({ setPutlistId, roNotFound, defaultRoId }) => {
  const { control, watch } = useForm({
    defaultValues: {
      putlistID: defaultRoId
    }
  });

  const putlistId = watch('putlistID');

  const findClickHandler = () => {
    setPutlistId(putlistId);
  };

  return (
    <Bar heading="View Putlist">
      <Box sx={styles.viewPurchaseOrder}>
        <Box>
          <Input
            disabled={defaultRoId}
            name="putlistID"
            label="Putlist ID"
            control={control}
            placeholder="Enter Putlist ID"
          />
        </Box>
        <Box sx={styles.findButton}>
          <Button
            label="View"
            customStyles={styles.actionButton}
            disabled={!putlistId?.length || defaultRoId}
            onClick={() => findClickHandler()}
          />
        </Box>
      </Box>
      {roNotFound && (
        <Box sx={{ marginBottom: '-10px' }}>
          <ErrorMessage errorMsg="Please provide a valid Putlist ID" />
        </Box>
      )}
    </Bar>
  );
};

export default PutlistId;
