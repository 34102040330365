import { Box } from '@mui/material';
import printJS from 'print-js';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { createTransferList } from '../../../../redux/APICaller/stockTransferAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Button, DescriptionBar, MainContent, ShowMessage } from '../../../common';
import CreateTransferFilter from './CreateTransferFilter';
import CreateTransferTable from './CreateTransferTable';
import styles from './styles';

const list = [
  {
    SNO: '01'
  },
  {
    SNO: '02'
  },
  {
    SNO: '03'
  },
  {
    SNO: '04'
  },
  {
    SNO: '05'
  }
];

const CreateTransfer = () => {
  const dispatch = useDispatch();
  const [showTransferList, setShowTransferList] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [showWidError, setShowWidError] = useState(false);
  const [TransferId, setTransfertId] = useState('');
  const [printUrl, setPrintUrl] = useState('');
  const [apiCalled, setApiCalled] = useState(false);
  const { control, trigger, getValues, setError, setValue, reset } = useForm({
    defaultValues: {
      putAwayMethod: 'Manual',
      putter: '',
      '01-wid': '',
      '01-quantity': '',
      '02-wid': '',
      '02-quantity': '',
      '03-wid': '',
      '03-quantity': '',
      '04-wid': '',
      '04-quantity': '',
      '05-wid': '',
      '05-quantity': ''
    },
    mode: 'all'
  });

  const inValidWid = (widArr) => {
    widArr?.map((wid) => {
      list?.map(({ SNO: id }) => {
        if (getValues(`${id}-wid`) === wid) {
          setError(`${id}-wid`, { type: 'custom', message: 'WID does not exist.' });
        }
      });
    });
  };

  const createTransfer = async (payload) => {
    setApiCalled(true);
    const res = await createTransferList({
      ...payload
    });
    if (res?.status === SUCCESS) {
      reset();
      setTransfertId(res?.data?.inventory_transfer_code);
      setPrintUrl(res?.data?.planned_inventory_transfer_url);
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
    } else {
      setTransfertId('');
      setPrintUrl('');
      if (res?.data?.message && res?.data?.message?.split(' ')[0] === 'WID') {
        inValidWid([res?.data?.message?.split(' ')[1]]);
      }
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setApiCalled(false);
  };

  const finishExecution = (res) => {
    const widAndQtyList = [];
    let isErrorFound = false;
    list?.map(({ SNO }) => {
      if (getValues(`${SNO}-wid`) && getValues(`${SNO}-quantity`))
        widAndQtyList.push({
          wid_code: getValues(`${SNO}-wid`),
          quantity: Number(getValues(`${SNO}-quantity`))
        });
      // Error section
      if (
        (getValues(`${SNO}-wid`) && !getValues(`${SNO}-quantity`)) ||
        (!getValues(`${SNO}-wid`) && getValues(`${SNO}-quantity`))
      ) {
        isErrorFound = true;
        if (!getValues(`${SNO}-quantity`))
          setError(`${SNO}-quantity`, { type: 'custom', message: 'This is a required field' });
        else setError(`${SNO}-wid`, { type: 'custom', message: 'This is a required field' });
      }
    });

    if (!widAndQtyList?.length) setShowWidError(true);
    else setShowWidError(false);

    if (res && !isErrorFound && widAndQtyList?.length) {
      const payload = {
        source_warehouse_id: filterData?.sourceWarehouse,
        destination_warehouse_id: filterData?.destinationWarehouse,
        wid_and_qty_list: widAndQtyList
      };
      createTransfer(payload);
    }
  };

  const triggerValidation = () => {
    trigger().then((res) => finishExecution(res));
  };

  return (
    <MainContent
      heading="Warehouse-To-Warehouse Stock Transfer"
      description="Create Transfer Request">
      <CreateTransferFilter
        setShowTransferList={setShowTransferList}
        setFilterData={setFilterData}
        filterData={filterData}
      />
      {showTransferList && (
        <>
          <DescriptionBar
            label="Add Below Items in Transfer List"
            customStyles={{ justifyContent: 'space-between' }}>
            {TransferId && (
              <Box sx={styles.messageBar}>
                <ShowMessage type="SUCCESS" message={`Transfer ID ${TransferId} Created`} />
                {printUrl && (
                  <Box
                    onClick={() => {
                      printJS(decodeURIComponent(printUrl));
                    }}
                    sx={styles.print}>
                    Print
                  </Box>
                )}
              </Box>
            )}
          </DescriptionBar>
          <CreateTransferTable
            list={list}
            control={control}
            setError={setError}
            setValue={setValue}
          />

          {showWidError && (
            <ShowMessage type="ERROR" message="You have to fill in at least one row" />
          )}
          <Box sx={{ marginTop: '1em' }}>
            <Button
              label="Create Transfer Request"
              disabled={apiCalled}
              onClick={() => triggerValidation()}
              customStyles={styles.createButton}
            />
          </Box>
        </>
      )}
    </MainContent>
  );
};

export default CreateTransfer;
