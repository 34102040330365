import Box from '@mui/material/Box';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../../constants/constants';
import { putFinalIRNReceiveDetails } from '../../../../../redux/APICaller/irnAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Pagination, Spinner, TableDataRenderer, Tooltip } from '../../../../common/index';
import styles from '../styles';

const countTotal = (list = []) => {
  if (list?.length > 1) {
    const newList = [...list];
    let totalqty = 0;
    list?.map((item) => {
      totalqty += Number(item?.qty_to_receive);
    });
    const newObj = {
      inbound_remark: 'Total Received',
      move_to_zone: '',
      qty_to_receive: totalqty
    };
    newList.push(newObj);
    return newList;
  }
  return list;
};

const ReceiveIRNTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  actionButton,
  irnId,
  InputRenderer,
  setFinalReceiveDone,
  subtractionOutstandingQty,
  qcId,
  warehouseId
}) => {
  const dispatch = useDispatch();

  const { control, getValues, trigger, reset } = useForm({
    mode: 'all'
  });

  const finalReceive = async (wid, irn_item_id, irn_item_part_id, warehouse_id) => {
    const customPayload = {
      irn_item_id,
      irn_item_part_id,
      item_quality_check_id: qcId,
      warehouse_id: warehouseId,
      quantity_to_receive: Number(getValues(wid))
    };

    const res = await putFinalIRNReceiveDetails(customPayload);
    if (res?.status === SUCCESS) {
      reset({ [wid]: '' });
      subtractionOutstandingQty(wid, customPayload?.quantity_to_receive);
      setFinalReceiveDone(countTotal([res?.data?.updated_response]));
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const finishExecution = (res, wid, irn_item_id, irn_item_part_id) => {
    if (res) {
      setFinalReceiveDone([]);
      finalReceive(wid, irn_item_id, irn_item_part_id);
    }
  };

  const receiveBtnClick = (wid, irn_item_id, irn_item_part_id) => {
    const arrId = [wid];
    trigger(arrId).then((res) => finishExecution(res, wid, irn_item_id, irn_item_part_id));
  };

  const columnDefs = [
    {
      field: 'SKUCode',
      headerName: 'SKU Code'
    },
    {
      field: 'QtytoReceive',
      headerName: 'Qty to Receive',
      cellRenderer: ({ data: cellData }) => {
        if (cellData?.OutstandingQty)
          return InputRenderer({
            name: `${cellData?.wid}`,
            onKeyPress: (event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            },
            control,
            rules: {
              required: {
                value: true,
                message: 'This is a required field'
              },
              validate: {
                isPositiveNumber: (value) => {
                  if (+value > 0 && +value <= +cellData?.OutstandingQty) return true;
                  else return 'Please provide a valid quantity';
                }
              }
            }
          });
      }
    },
    {
      field: 'ProductDescription',
      headerName: 'Product Description',
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.ProductDescription} dataLength={18} />;
      }
    },
    { field: 'POQuantity', headerName: 'PO Quantity' },
    { field: 'ReceivedQuantity', headerName: 'Received Quantity' },
    { field: 'OutstandingQty', headerName: 'Outstanding Qty' },
    {
      field: 'Action',
      headerName: 'Action',
      cellRenderer: ({ data: cellData }) => {
        if (cellData?.OutstandingQty) {
          const label = 'Receive';
          const onButtonClick = () =>
            receiveBtnClick(cellData?.wid, cellData?.irn_item_id, cellData?.irn_item_part_id);
          return actionButton(onButtonClick, label);
        }
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default ReceiveIRNTable;
