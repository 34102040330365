import { Box } from '@mui/material';
import React from 'react';
import styles from './styles';

const DescriptionBar = ({
  label,
  value,
  renderOptions,
  children,
  customStylesMulti,
  customStyles
}) => {
  return (
    <>
      {renderOptions ? (
        <Box sx={{ ...styles.multiWrapper, ...customStylesMulti }}>
          {renderOptions?.map(({ label, value }, index) => {
            return (
              <Box key={index} sx={styles.optionWrapper}>
                <Box sx={styles.label}>{label}</Box>
                <Box sx={styles.value}>{value}</Box>
              </Box>
            );
          })}
          <Box>{children}</Box>
        </Box>
      ) : (
        <Box sx={{ ...styles.wrapper, ...customStyles }}>
          <Box sx={styles.optionWrapper}>
            <Box sx={styles.label}>{label}</Box>
            <Box sx={styles.value}>{value}</Box>
          </Box>
          <Box>{children}</Box>
        </Box>
      )}
    </>
  );
};

export default DescriptionBar;
