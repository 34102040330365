export const emptyEmail = "Email can't be empty";

export const emptyPassword = "Password can't be empty";

export const inValidEmail = 'Please enter a valid email address';

export const emptyOTP = 'OTP can not be empty';

export const enterSixDigit = 'Enter the 6-digit code we sent';

export const emptyNewPassword = "New Password can't be empty";

export const emptyConfirmPassword = `Confirm Password can not be empty`;

export const matchedPassword = 'Both password must match';

export const passwordType = 'Password must be 6 characters with at least 1 upper case and 1 number';

export const required = 'This is required field';
