import { Box } from '@mui/material';
import React from 'react';
import {
  AcceptConsignment,
  AllOrders,
  AllowGateIn,
  AllowGateOut,
  CompleteLoadingUnloading,
  CompletePutlist,
  CreateIRN,
  CreateManualACN,
  CreateProduct,
  CreatePutlist,
  CreateReturnOrder,
  CreateTransfer,
  FindConsignment,
  FindOrders,
  FindProduct,
  FindPutlist,
  FindReturnOrder,
  GenerateReturnOrder,
  InboundCreateProduct,
  OrderView,
  OutboundCreateSO,
  Overview,
  Pick,
  PickTransfer,
  PickingDetails,
  ProductLookUp,
  PutawayTransfer,
  ReceiveIRN,
  ReceiveIRNComplete,
  ReceiveOnDock,
  ReleaseFromDock,
  SearchACN,
  SearchInventory,
  SearchStockTransfer,
  StartLoadingUnloading,
  ViewConsignment,
  ViewIRN,
  ViewOrders,
  ViewProduct,
  ViewPutlist,
  ViewReturnOrder,
  ViewTransfer
} from '../../Pages';
import ReceiveTrailerOnDock from '../../Pages/ReceiveOnDock/ReceiveTrailerOnDock';

const ComponentRouter = ({ type }) => {
  return (
    <Box>
      {type === 'overview' && <Overview />}
      {type === 'search-acn' && <SearchACN />}
      {type === 'acn-accept' && <AcceptConsignment />}
      {type === 'allow-get-in' && <AllowGateIn />}
      {type === 'create-manual-acn' && <CreateManualACN />}
      {type === 'acn-details' && <Box>ACN Details Page Comming soon...</Box>}
      {type === 'receive-on-dock' && <ReceiveOnDock />}
      {type === 'allow-gate-out' && <AllowGateOut />}
      {type === 'confirm-receive-on-dock' && <ReceiveTrailerOnDock />}
      {type === 'start-loading-unloading' && <StartLoadingUnloading />}
      {type === 'complete-loading-unloading' && <CompleteLoadingUnloading />}
      {type === 'release-from-dock' && <ReleaseFromDock />}
      {type === 'view-orders' && <ViewOrders />}
      {type === 'find-orders' && <FindOrders />}
      {type === 'view-consignment' && <ViewConsignment />}
      {type === 'find-consignment' && <FindConsignment />}
      {type === 'find-return-order' && <FindReturnOrder />}
      {type === 'view-return-order' && <ViewReturnOrder />}
      {type === 'create-return-order' && <CreateReturnOrder />}
      {type === 'create-irn' && <CreateIRN />}
      {type === 'receive-irn' && <ReceiveIRN />}
      {type === 'create-return-order-form' && <GenerateReturnOrder />}
      {type === 'receive-irn-product-lookUp' && <ProductLookUp />}
      {type === 'view-irn' && <ViewIRN />}
      {type === 'receive-irn-complete' && <ReceiveIRNComplete />}
      {type === 'create-product' && <CreateProduct />}
      {type === 'find-product' && <FindProduct />}
      {type === 'view-product' && <ViewProduct />}
      {type === 'search-inventory' && <SearchInventory />}
      {type === 'create-putlist' && <CreatePutlist />}
      {type === 'complete-putlist' && <CompletePutlist />}
      {type === 'find-putlist' && <FindPutlist />}
      {type === 'view-putlist' && <ViewPutlist />}
      {type === 'create-transfer' && <CreateTransfer />}
      {type === 'view-transfer' && <ViewTransfer />}
      {type === 'pick-transfer' && <PickTransfer />}
      {type === 'search-transfer' && <SearchStockTransfer />}
      {type === 'put-away-transfer' && <PutawayTransfer />}
      {type === 'create-purchase-order' && <InboundCreateProduct />}
      {type === 'all-orders' && <AllOrders />}
      {type === 'order-view' && <OrderView />}
      {type === 'create-so' && <OutboundCreateSO />}
      {type === 'pick' && <Pick />}
      {type === 'pick-details' && <PickingDetails />}
    </Box>
  );
};

export default ComponentRouter;
