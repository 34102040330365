import Box from '@mui/material/Box';
import React from 'react';
import { Pagination, Spinner, TableDataRenderer } from '../../../../common/index';
import styles from '../styles';

const ReceiveIRNTable = ({ list = [], pageData, pageClick, isLoading, page }) => {
  const columnDefs = [
    {
      field: 'SKUCode',
      headerName: 'SKU Code'
    },
    { field: 'WID', headerName: 'WID' },
    {
      field: 'ProductDescription',
      headerName: 'Product Description'
    },
    { field: 'Supplier', headerName: 'Supplier' },
    { field: 'QtyAlreadyAddedinPutlist', headerName: 'Qty Already Added in Putlist' },
    { field: 'QuantityLeftforPutAway', headerName: 'Quantity Left for Put Away' },
    { field: 'InboundStagingZone', headerName: 'Inbound Staging Zone' },
    {
      field: 'UpdatedOn',
      headerName: 'Updated On'
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default ReceiveIRNTable;
