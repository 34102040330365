import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import toastReducer from './showMessageSlice';

export const store = configureStore({
  reducer: {
    authStore: authReducer,
    showMessageStore: toastReducer
  }
});
