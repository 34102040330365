const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: '25px',
    justifyContent: 'space-between'
  },

  cardWrapper: {
    border: '1px solid',
    borderColor: 'custom.gray',
    width: {
      xs: '100%',
      md: '50%'
    },
    height: 'min-content',
    borderCollapse: 'collapse'
  },

  card: {
    display: 'flex'
  },

  contentWrapper: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    width: '50%',
    padding: '16px 8px',
    borderColor: 'custom.gray',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },

  label: {
    marginTop: '0.5em'
  },

  button: {
    width: {
      xs: '100%',
      md: '180px'
    }
  },

  receiveIRN: {
    fontSize: {
      xs: '16px',
      sm: '18px'
    },
    color: 'custom.blue',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  irnGenerated: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#04AA6D',
    fontSize: {
      xs: '16px',
      sm: '18px'
    }
  },

  verifiedIcon: {
    fontSize: '24px'
  },

  docsError: {
    fontSize: {
      xs: '10px',
      sm: '12px'
    },
    fontWeight: 500,
    color: 'error.main',
    marginTop: '0.5em',
    marginBottom: '-0.5em'
  }
};

export default styles;
