import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  CategoryListConverter,
  SupplierListConverter,
  WarehouseListConvertor
} from '../../../../../constants/apiDataChanger';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { getCategoryList, getSupplierList, getWarehouseList } from '../../../../../redux/APICaller';
import { findRoCSVDownload } from '../../../../../redux/APICaller/inboundReturnOrderAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, Dropdown, Input, NewDate } from '../../../../common';
import styles from '../styles';

const FindReturnOrderFilters = ({ data, setData, onFind, isFindDisable, csvPayload }) => {
  const dispatch = useDispatch();
  const [supplierList, setSupplierList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [warehouseList, setWarehouseListList] = useState([]);
  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      supplier: '',
      productCategory: '',
      sourceWarehouse: '',
      orderStatus: '',
      returnOrderType: ''
    }
  });

  const fromDate = watch('startDate');
  const toDate = watch('endDate');
  const roIdValue = watch('returnOrderID');
  const irnIDValue = watch('irnID');
  const poNumberValue = watch('poNumber');
  const supplierValue = watch('supplier');
  const productCategoryValue = watch('productCategory');
  const warehouseValue = watch('sourceWarehouse');
  const orderStatusValue = watch('orderStatus');
  const returnOrderTypeValue = watch('returnOrderType');

  useEffect(() => {
    if (fromDate) {
      if (fromDate) setData({ ...data, fromDate: fromDate });
    }
  }, [fromDate]);

  useEffect(() => {
    if (toDate) {
      if (toDate) setData({ ...data, toDate: toDate });
    }
  }, [toDate]);

  useEffect(() => {
    setData({ ...data, roIdValue: roIdValue });
  }, [roIdValue]);

  useEffect(() => {
    setData({ ...data, irnIDValue: irnIDValue });
  }, [irnIDValue]);

  useEffect(() => {
    setData({ ...data, poNumberValue: poNumberValue });
  }, [poNumberValue]);

  useEffect(() => {
    setData({ ...data, supplierValue: supplierValue });
  }, [supplierValue]);

  useEffect(() => {
    setData({ ...data, warehouseValue: warehouseValue });
  }, [warehouseValue]);

  useEffect(() => {
    setData({ ...data, orderStatusValue: orderStatusValue });
  }, [orderStatusValue]);

  useEffect(() => {
    setData({ ...data, returnOrderTypeValue: returnOrderTypeValue });
  }, [returnOrderTypeValue]);

  useEffect(() => {
    setData({ ...data, productCategoryValue: productCategoryValue });
  }, [productCategoryValue]);

  const getSupplierDropdownData = async () => {
    const res = await getSupplierList({});
    if (res?.status == SUCCESS) {
      setSupplierList(SupplierListConverter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const getProductCategoryDropdownData = async () => {
    const res = await getCategoryList({});
    if (res?.status == SUCCESS) {
      setProductCategoryList(CategoryListConverter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const getWarehouseDropdownData = async () => {
    const res = await getWarehouseList({});
    if (res?.status == SUCCESS) {
      setWarehouseListList(WarehouseListConvertor(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    getSupplierDropdownData();
    getProductCategoryDropdownData();
    getWarehouseDropdownData();
  }, []);

  let isDisable = false;
  const handleDownloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  };

  const csvDownload = async () => {
    const customPayload = {};

    customPayload.from_date =
      csvPayload?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    customPayload.to_date = csvPayload?.toDate || moment().format('YYYY-MM-DD');
    if (csvPayload.roIdValue) customPayload.ro_no = csvPayload.roIdValue;
    if (csvPayload.orderStatusValue) customPayload.order_status = csvPayload.orderStatusValue;
    if (csvPayload.returnOrderTypeValue) customPayload.ro_type = csvPayload.returnOrderTypeValue;
    if (csvPayload.poNumberValue) customPayload.po_no = csvPayload.poNumberValue;
    if (csvPayload.irnIDValue) customPayload.irn = csvPayload.irnIDValue;
    if (csvPayload.warehouseValue) customPayload.source_warehouse = csvPayload.warehouseValue;
    if (csvPayload.productCategoryValue) customPayload.category = csvPayload?.productCategoryValue;
    if (csvPayload.supplierValue) customPayload.supplier = csvPayload?.supplierValue;

    const res = await findRoCSVDownload({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      handleDownloadCsv(res?.data?.url);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  return (
    <Box sx={styles.dropDownWrapper}>
      <Box sx={styles.input}>
        <Input
          label="Return Order ID"
          placeholder="Return Order ID"
          name="returnOrderID"
          control={control}
        />
      </Box>
      <Box sx={styles.input}>
        <Input label="IRN ID" placeholder="IRN ID" name="irnID" control={control} />
      </Box>
      <Box sx={styles.input}>
        <Input label="PO Number" placeholder="PO Number" name="poNumber" control={control} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown label="Supplier" name="supplier" control={control} options={supplierList} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Product Category"
          name="productCategory"
          control={control}
          options={productCategoryList}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Source Warehouse"
          name="sourceWarehouse"
          control={control}
          options={warehouseList}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Order Status"
          name="orderStatus"
          control={control}
          options={[
            {
              label: 'Created',
              value: 'Created'
            },
            {
              label: 'In process',
              value: 'In process'
            },
            {
              label: 'Completed',
              value: 'Completed'
            }
          ]}
        />
      </Box>
      {/* <Box sx={styles.input}>
        <Dropdown
          label="Return Order Type"
          name="returnOrderType"
          control={control}
          options={[
            {
              label: 'dummy',
              value: 1
            }
          ]}
        />
      </Box> */}
      <Box sx={styles.input}>
        <NewDate label="Start Date" name="startDate" control={control} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="End Date" name="endDate" control={control} minDate={watch('startDate')} />
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button
          label="Find"
          customStyles={styles.actionButton}
          onClick={() => onFind()}
          disabled={isFindDisable}
        />
        <Button
          label="Reset"
          customStyles={styles.actionButton}
          disabled={isFindDisable}
          onClick={() => {
            reset({
              supplier: '',
              productCategory: '',
              sourceWarehouse: '',
              orderStatus: '',
              returnOrderType: ''
            });
            setValue('returnOrderID', '');
            setValue('irnID', '');
            setValue('poNumber', '');
            setValue('supplier', '');
            setValue('sourceWarehouse', '');
            setValue('productCategory', '');
            setValue('orderStatus', '');
            setValue('returnOrderType', '');
            onFind('reset');
          }}
        />
      </Box>
      <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon
          sx={styles.download}
          onClick={async () => {
            if (!isDisable) {
              isDisable = true;
              await csvDownload();
              isDisable = false;
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default FindReturnOrderFilters;
