import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../constants/apiStatusCodes';
import { SearchACNDescription, SearchACNHeading } from '../../../constants/common';
import { ACN_ID, INVOICE_NUMBER, PO_NO } from '../../../constants/commonLabels';
import { ERROR } from '../../../constants/constants';
import { acnList } from '../../../redux/APICaller/gateInAPIs';
import { showToastMessage } from '../../../redux/showMessageSlice';
import { capitalizeFirstLetter, getFormattedDate } from '../../../utils/index';
import Spinner from '../../common/Spinner';
import Table from '../../common/Table';
import TableNavbar from '../../common/Table/tableFilter';
import {
  ACNIdRenderer,
  AddressRenderer,
  CTARenderer,
  InvoiceNumberRenderer,
  ItemDetailsRenderer,
  MainContent,
  POIdRenderer
} from '../../common/index';

const ctaLabelBystatus = (status) => {
  if (status === 'Consignment dispatched') return 'Accept / Reject';
  else if (status === 'Consignment accepted') return 'Allow Gate In';
  else if (status === 'Gate in') return 'Allocate Bay';
};

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      acn_no: ACNId,
      planned_time_of_arrival: PlannedEDA,
      consignment_type: ConsgType,
      po_no: PONo,
      item_details: ItemDetails,
      ship_to_details: ShipToAddress,
      consignment_status: ConsignmentStatus,
      Id: acn_id,
      ...rest
    }) => ({
      ACNId,
      PlannedEDA: getFormattedDate(PlannedEDA, 'DD/MM/YY'),
      ConsgType,
      PONo,
      ItemDetails,
      ShipToAddress,
      ConsignmentStatus: capitalizeFirstLetter(ConsignmentStatus),
      Action: ctaLabelBystatus(capitalizeFirstLetter(ConsignmentStatus)),
      ...rest
    })
  );

  return newArrayOfObj;
};

// eslint-disable-next-line react/prop-types
const SearchACN = () => {
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    searchBy: '',
    searchStr: '',
    consgnType: '',
    consgnStatus: ''
  });
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 1
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1) => {
    setLoading(true);
    const customPayload = {};

    if (payload.consgnType) customPayload.consignment_type = payload.consgnType;
    if (payload.consgnStatus) customPayload.consignment_status = payload?.consgnStatus;
    if (payload.searchStr) {
      if (payload.searchBy === ACN_ID) customPayload.acn_no = payload?.searchStr;
      if (payload.searchBy === PO_NO) customPayload.po_no = payload?.searchStr;
      if (payload.searchBy === INVOICE_NUMBER) customPayload.invoice_no = payload?.searchStr;
    }
    const res = await acnList({
      page: page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page === 1) {
        const newData = dataKeyNameChange(res.data.consignmentListingResponse);
        setList([{ page: 1, array: newData }]);
      } else
        setList([
          ...list,
          { page, array: dataKeyNameChange(res?.data?.consignmentListingResponse) }
        ]);
      setPageData({ maxPage: res.data.totalPages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, [payload]);

  return (
    <>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading={SearchACNHeading} description={SearchACNDescription}>
          <TableNavbar data={payload} setData={setPayload} />
          <Table
            POIdRenderer={POIdRenderer}
            acnID={ACNIdRenderer}
            invoiceNumber={InvoiceNumberRenderer}
            actionButton={CTARenderer}
            addressRender={AddressRenderer}
            itemDetails={ItemDetailsRenderer}
            list={tableRows}
            pageData={pageData}
            pageClick={(_, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
          />
        </MainContent>
      )}
    </>
  );
};

export default SearchACN;
