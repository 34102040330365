import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Button, Dropdown, Input, NewDate } from '../../../../../common';
import styles from '../../styles';

const PendingPickListFilters = () => {
  const { control, watch, reset } = useForm({
    defaultValues: {
      picklistId: '',
      warehouse: '',
      channelCode: '',
      pickingZone: '',
      startDate: '',
      endDate: ''
    }
  });
  return (
    <Box sx={styles.filterWrapper}>
      <Box sx={styles.input}>
        <Input
          label="Picklist ID"
          name="picklistId"
          placeholder="Search by Transfer ID"
          control={control}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Warehouse"
          name="warehouse"
          control={control}
          options={[{ label: 'All', value: 'all' }]}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Channel Code"
          name="channelCode"
          control={control}
          options={[{ label: 'All', value: 'all' }]}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Picking zone"
          name="pickingZone"
          control={control}
          options={[{ label: 'All', value: 'all' }]}
        />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="Start Date" name="startDate" control={control} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="End Date" name="endDate" control={control} minDate={watch('startDate')} />
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button label="Reset" customStyles={styles.actionButton} onClick={() => reset()} />
      </Box>
      <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon sx={styles.download} />
      </Box>
    </Box>
  );
};

export default PendingPickListFilters;
