import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Button, MainContent } from '../../../common';
import OrderViewTable from './OrderViewTable';
import { selectAuthStore } from '../../../../redux/authSlice';
import { useSelector } from 'react-redux';
import { poList } from '../../../../redux/APICaller/inboundOrderAPIs';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { useDispatch } from 'react-redux';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { ERROR } from '../../../../constants/constants';
import { useParams } from 'react-router-dom';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      product_info: ProductInfo,
      ordered_quantity: OrderedQty,
      received_quantity: ReceiverQty,
      cancelled_quantity: CanceledQty,
      rejected_quantity: RejectedQty,
      outstanding_quantity: OutstandingQty,
      total_value: TotalValue,
      ...rest
    }) => ({
      ProductInfo,
      OrderedQty,
      ReceiverQty,
      CanceledQty,
      RejectedQty,
      OutstandingQty,
      TotalValue,
      ...rest
    })
  );

  return newArrayOfObj;
};

const OrderView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const authStore = useSelector(selectAuthStore);
  const [tableRows, setTableRows] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 1
  });
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderId, setOrderId] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);

  console.log(authStore?.orderType);

  const APICALL = async (page = 1) => {
    setIsLoading(true);
    const customPayload = { POid: orderId };

    const res = await poList({
      page: page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page === 1) {
        const newData = dataKeyNameChange(res?.data?.Products);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.Products) }]);
      setPageData({ maxPage: res.data.totalPages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  useEffect(() => {
    if (params?.order_id) {
      setOrderId(params?.order_id);
    }
  }, [params?.order_id]);

  useEffect(() => {
    if (orderId) APICALL();
  }, [orderId]);

  return (
    <MainContent
      heading="Orders view"
      description={
        <Box sx={styles.desccriptionViewWrapper}>
          <Box>
            <Box>Order ID: 407-1899868-7203500</Box>
            {authStore?.orderType === 'B2B' ? <Box>PO: FERYT-2322</Box> : null}
            <Box>Sales Channel: Crompton FK B2C</Box>
          </Box>
          <Box sx={styles.descriptionButton}>
            <Button label="Print picklist" />
            {authStore?.orderType === 'B2C' ? <Button label="Print label" /> : null}

            <Button label="Print invoice" />
            {authStore?.orderType === 'B2B' ? <Button label="Download LR" /> : null}
            <Button label="Download POD" />
          </Box>
        </Box>
      }>
      <Box sx={styles.createPicklistButton}>
        <Button
          disabled={!authStore?.orderType || authStore?.orderType === 'B2C'}
          label="Create Picklist"
        />
      </Box>
      <OrderViewTable
        list={tableRows}
        pageData={pageData}
        pageClick={(e, pageNumber) => {
          apiCallWithPage(pageNumber);
        }}
        isLoading={isLoading}
        page={pageData.currentPage}
      />
    </MainContent>
  );
};

export default OrderView;
