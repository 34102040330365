import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { closeToastMessage, selectToastStore } from '../../../redux/showMessageSlice';
import styles from './styles';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={5} ref={ref} variant="filled" {...props} />;
});

const vertical = 'bottom';
const horizontal = 'left';

const AlertMessage = () => {
  const dispatch = useDispatch();
  const { isOpen, messageType, message } = useSelector(selectToastStore);

  const handleClose = (reason) => {
    if (reason?.pointerType === 'mouse') {
      return;
    }
    dispatch(closeToastMessage());
  };

  if (!messageType || !message || !isOpen || (messageType !== SUCCESS && messageType !== ERROR)) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Snackbar
        open={isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        sx={styles.snackBar}>
        <Alert
          onClose={handleClose}
          severity={messageType === SUCCESS ? 'success' : 'error'}
          sx={styles.message}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default AlertMessage;
