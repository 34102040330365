import Box from '@mui/material/Box';
import React from 'react';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { Spinner, TableDataRenderer } from '../../../../common/index';
import styles from '../styles';

const CreateReturnOrderTable = ({
  list = [],
  isLoading,
  productInfoRenderer,
  setSkuIdList,
  setSelectedRows
}) => {
  const [{ width }] = useWindowDimensions();
  const columnDefs = [
    {
      field: 'ProductInfo',
      headerName: 'Product Info',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      minWidth: 300,
      flex: width > 900 ? 1 : 0,
      cellRenderer: ({ data: { ProductInfo } }) => {
        return productInfoRenderer({
          productDescription: ProductInfo?.product_description,
          skuCode: ProductInfo?.sku_code,
          brandSkuCode: ProductInfo?.brand_sku_code,
          portalSkuCode: ProductInfo?.portal_sku_code,
          asinCode: ProductInfo?.asin_code,
          fsnCode: ProductInfo?.fsn_code,
          dataLength: 13
        });
      }
    },
    {
      field: 'IRNQty',
      headerName: 'IRN Qty',
      flex: width > 900 ? 1 : 0
    },
    {
      field: 'QCStatus',
      headerName: 'QC Status',
      flex: width > 900 ? 1 : 0
    }
  ];

  const selectionChanged = (param) => {
    const selectedRows = param?.api?.getSelectedRows();
    const totalRows = param?.api?.getModel()?.getRowCount();
    if (selectedRows?.length === totalRows) setSkuIdList([]);
    else {
      const selectedRowsIdList = selectedRows?.map((row) => {
        return row?.irn_item_id;
      });
      setSkuIdList(selectedRowsIdList);
    }
    setSelectedRows(selectedRows?.length);
  };

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <TableDataRenderer
            list={list}
            rowHeight={150}
            columnDefs={columnDefs}
            onSelectionChanged={selectionChanged}
          />
        )}
      </Box>
      {/* <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box> */}
    </Box>
  );
};

export default CreateReturnOrderTable;
