import { OVERVIEW_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI } from '../../helpers/API';

const { GET_YARD_LIST, YARD_LIST_CSV_DOWNLOAD } = OVERVIEW_PATHS;

/**
 * Yard List API
 * @param {*} data
 * @returns Array of list
 */
export function yardList(data) {
  return getAPI({
    url: GET_YARD_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Yard List CSV Download
 * @param {*} data
 * Downloads the CSV file
 */
export function yardListCsvDownload(data) {
  return postAPI({
    url: YARD_LIST_CSV_DOWNLOAD,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
