const styles = {
  wrapper: {
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '300px',
    wordSpacing: 1.2
  }
};

export default styles;
