import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { findROList } from '../../../../redux/APICaller/inboundReturnOrderAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../../utils/index';
import Spinner from '../../../common/Spinner';
import { DescriptionBar, MainContent, POIdRenderer, ROIdRenderer } from '../../../common/index';
import FindReturnOrderFilters from './FindReturnOrderFilters';
import FindReturnOrderTable from './FindReturnOrderTable';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      ro_no: ReturnOrderID,
      irn: IRNId,
      po_no: PONO,
      ro_type: ROType,
      ro_date: RODate,
      supplier: Supplier,
      source_warehouse_code: SourceWarehouseCode,
      destination_warehouse_code: DestinationWarehouseCode,
      total_quantity: TotalQty,
      ...rest
    }) => ({
      ReturnOrderID,
      IRNId,
      PONO,
      ROType,
      RODate: getFormattedDate(RODate, 'DD/MM/YY'),
      Supplier,
      SourceWarehouseCode,
      DestinationWarehouseCode,
      TotalQty,
      ...rest
    })
  );

  return newArrayOfObj;
};

const initPayload = {
  warehouseValue: '',
  productCategoryValue: '',
  supplierValue: '',
  fromDate: '',
  toDate: '',
  roIdValue: '',
  irnIDValue: '',
  poNumberValue: '',
  orderStatusValue: '',
  returnOrderTypeValue: ''
};

const FindReturnOrder = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(initPayload);
  const [csvPayload, setCsvPayload] = useState(initPayload);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalItems, setTotalItems] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const isFindDisable = !(
    payload?.warehouseValue ||
    payload?.supplierValue ||
    payload?.productCategoryValue ||
    payload?.fromDate ||
    payload?.toDate ||
    payload?.roIdValue ||
    payload?.irnIDValue ||
    payload?.poNumberValue ||
    payload?.orderStatusValue ||
    payload?.returnOrderTypeValue
  );

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1, isBtnClick) => {
    setLoading(true);
    const customPayload = {};
    customPayload.from_date =
      payload?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    customPayload.to_date = payload?.toDate || moment().format('YYYY-MM-DD');

    if (page !== 'reset') {
      if (payload.roIdValue) customPayload.ro_no = payload.roIdValue;
      if (payload.orderStatusValue) customPayload.order_status = payload.orderStatusValue;
      if (payload.returnOrderTypeValue) customPayload.ro_type = payload.returnOrderTypeValue;
      if (payload.poNumberValue) customPayload.po_no = payload.poNumberValue;
      if (payload.irnIDValue) customPayload.irn = payload.irnIDValue;
      if (payload.warehouseValue) customPayload.source_warehouse = payload.warehouseValue;
      if (payload.productCategoryValue) customPayload.category = payload?.productCategoryValue;
      if (payload.supplierValue) customPayload.supplier = payload?.supplierValue;
    } else {
      customPayload.from_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
      customPayload.to_date = moment().format('YYYY-MM-DD');
      setPayload(initPayload);
    }
    const res = await findROList({
      page: page === 'reset' ? 0 : page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        setCsvPayload(payload);
        if (isBtnClick) setIsFilterApplied(true);
      } else {
        setCsvPayload(initPayload);
        setIsFilterApplied(false);
      }
      if (page === 1 || page === 'reset') {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      setTotalItems(res?.data?.total_elements);
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  return (
    <Box>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading="Purchase Return Order Listing">
          <FindReturnOrderFilters
            csvPayload={csvPayload}
            data={payload}
            setData={setPayload}
            onFind={(type) => {
              APICALL(type, 'btnClick');
            }}
            isFindDisable={isFilterApplied ? !isFilterApplied : isFindDisable}
          />
          <DescriptionBar label="Total number of Return Orders found:" value={totalItems} />
          <FindReturnOrderTable
            list={tableRows}
            pageData={pageData}
            pageClick={(e, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
            roID={ROIdRenderer}
            poID={POIdRenderer}
          />
        </MainContent>
      )}
    </Box>
  );
};

export default FindReturnOrder;
