import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DescriptionBar, MainContent } from '../../../common';
import FindProductFilters from './FindProductFilters';
import FindProductTable from './FindProductTable';
import { useDispatch } from 'react-redux';
import Spinner from '../../../common/Spinner';
import { ERROR } from '../../../../constants/constants';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { findProductList } from '../../../../redux/APICaller/browseProductAPIs';
import { SkuIdRenderer } from '../../../common/TableRenderers';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      sku_code: SKUCode,
      brand_sku_code: BrandSKUCode,
      fsn: FSN,
      asin: ASIN,
      supplier_or_brand_name: Supplier_Brand,
      ean_code: EANCode,
      tax_rate: TaxRate,
      shipment_type: ShipmentType,
      category_name: Category,
      sku_type: SKUType,
      ...rest
    }) => ({
      SKUCode,
      BrandSKUCode,
      FSN,
      ASIN,
      Supplier_Brand,
      EANCode,
      TaxRate,
      ShipmentType,
      Category,
      SKUType,
      ...rest
    })
  );

  return newArrayOfObj;
};

const initPayload = {
  productName: '',
  supplier: '',
  skuCode: '',
  productCategory: '',
  eanCode: '',
  shipmentType: '',
  productType: ''
};

const FindProduct = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(initPayload);
  const [csvPayload, setCsvPayload] = useState(initPayload);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [tableRows, setTableRows] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const isFindDisable = !(
    payload?.productName ||
    payload?.supplier ||
    payload?.skuCode ||
    payload?.productCategory ||
    payload?.eanCode ||
    payload?.shipmentType ||
    payload?.productType
  );

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1, isBtnClick) => {
    setLoading(true);
    const customPayload = {};

    if (page !== 'reset') {
      if (payload.productName) customPayload.product_name = payload.productName;
      if (payload.supplier) customPayload.supplier_id = payload.supplier;
      if (payload.skuCode) customPayload.sku_code = payload.skuCode;
      if (payload.productCategory) customPayload.category_id = payload.productCategory;
      if (payload.eanCode) customPayload.ean_code = payload.eanCode;
      if (payload.shipmentType) customPayload.shipment_type = payload.shipmentType;
      if (payload.productType) customPayload.product_type = payload?.productType;
    } else {
      setPayload(initPayload);
    }
    const res = await findProductList({
      page: page === 'reset' ? 0 : page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        setCsvPayload(payload);
        if (isBtnClick) setIsFilterApplied(true);
      } else {
        setCsvPayload(initPayload);
        setIsFilterApplied(false);
      }
      if (page === 1 || page === 'reset') {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      setTotalItems(res?.data?.total_elements);
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  return (
    <Box>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading="Product Listing" description="Search Product">
          <FindProductFilters
            csvPayload={csvPayload}
            data={payload}
            setData={setPayload}
            onFind={(type) => {
              APICALL(type, 'btnClick');
            }}
            isFindDisable={isFilterApplied ? !isFilterApplied : isFindDisable}
          />
          <DescriptionBar label="Total number of products found:" value={totalItems} />
          <FindProductTable
            SkuIdRenderer={SkuIdRenderer}
            list={tableRows}
            pageData={pageData}
            pageClick={(e, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
          />
        </MainContent>
      )}
    </Box>
  );
};

export default FindProduct;
