import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Button, DescriptionBar, MainContent, Spinner } from '../../../common';
import AllOrdersFilter from './AllOrdersFilter';
import AllOrdersTable from './AllOrdersTable';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      order_id: OrderId,
      order_status: OrderStatus,
      channel: Channel,
      pickup: Pickup,
      pick_zone: PickZone,
      ...rest
    }) => ({
      OrderId,
      OrderStatus,
      Channel,
      Pickup,
      PickZone,
      ...rest
    })
  );

  return newArrayOfObj;
};

const AllOrders = () => {
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    orderId: '',
    orderStatus: '',
    channel: '',
    startDate: '',
    endDate: '',
    pickZone: ''
  });
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 1
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1) => {
    setLoading(true);
    const customPayload = {};

    if (payload.orderId) customPayload.order_id = payload.orderId;
    if (payload.orderStatus) customPayload.order_status = payload?.orderStatus;
    if (payload.channel) customPayload.channel = payload?.channel;
    if (payload.startDate) customPayload.start_Date = payload.startDate;
    if (payload.endDate) customPayload.end_Date = payload.endDate;
    if (payload.pickZone) customPayload.pick_zone = payload.pickZone;
    const res = {};
    // const res = await acnList({
    //   page: page - 1,
    //   limit: 15,
    //   ...customPayload
    // });
    if (res.status === SUCCESS) {
      if (page === 1) {
        const newData = dataKeyNameChange(res.data.consignmentListingResponse);
        setList([{ page: 1, array: newData }]);
      } else
        setList([
          ...list,
          { page, array: dataKeyNameChange(res?.data?.consignmentListingResponse) }
        ]);
      setPageData({ maxPage: res.data.totalPages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, [payload]);

  return (
    <>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading="Orders list">
          <AllOrdersFilter data={payload} setData={setPayload} />
          <DescriptionBar
            label="Result found:"
            value="20 shipments"
            customStyles={{ justifyContent: 'space-between' }}>
            <Button label="Create SO" />
          </DescriptionBar>
          <AllOrdersTable
            list={tableRows}
            pageData={pageData}
            pageClick={(_, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
          />
        </MainContent>
      )}
    </>
  );
};

export default AllOrders;
