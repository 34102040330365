import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { getPoDetails, poList } from '../../../../redux/APICaller/inboundOrderAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../../utils/index';
import {
  DescriptionBar,
  MainContent,
  ProductInfoRenderer,
  ReadOnlyList,
  Spinner
} from '../../../common/index';
import FindPurchaseOrder from './FindPurchaseOrder';
import ViewOrderFilters from './ViewOrdersFilters';
import ViewOrdersTable from './ViewOrdersTable';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      product_info: ProductInfo,
      ordered_quantity: OrderedQty,
      received_quantity: ReceiverQty,
      cancelled_quantity: CanceledQty,
      rejected_quantity: RejectedQty,
      outstanding_quantity: OutstandingQty,
      total_value: TotalValue,
      ...rest
    }) => ({
      ProductInfo,
      OrderedQty,
      ReceiverQty,
      CanceledQty,
      RejectedQty,
      OutstandingQty,
      TotalValue,
      ...rest
    })
  );

  return newArrayOfObj;
};

const orderDetailsFormate = [
  {
    label: 'PO Type',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Transaction Type',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Order Date',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Order Status',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Category',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Expiry Date',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Consgn. Created',
    value: {
      type: 'write',
      value: ''
    }
  }
];

const supplierDetailsFormate = [
  {
    label: 'Name',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Whse Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Address',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'City',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Pin Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'State',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'GST',
    value: {
      type: 'write',
      value: ''
    }
  }
];

const shippingDetailsFormate = [
  {
    label: 'Name',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Whse Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Address',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'City',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Pin Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'GST',
    value: {
      type: 'write',
      value: ''
    }
  }
];

const keyValueMaker = (value, template, type) => {
  const tempTemplate = JSON.parse(JSON.stringify(template)); //{ ...template };
  Object.keys(value)?.forEach((key) => {
    if (type === 'order_details') {
      if (key === 'po_type') tempTemplate[0].value.value = value[key];
      else if (key === 'transaction_type') tempTemplate[1].value.value = value[key];
      else if (key === 'order_date')
        tempTemplate[2].value.value = getFormattedDate(value[key], 'DD/MM/YY');
      else if (key === 'order_status') tempTemplate[3].value.value = value[key];
      else if (key === 'category') tempTemplate[4].value.value = value[key];
      else if (key === 'expiry_date')
        tempTemplate[5].value.value = getFormattedDate(value[key], 'DD/MM/YY');
      else if (key === 'consign_created') tempTemplate[6].value.value = value[key];
    } else if (type === 'supplier_information') {
      if (key === 'supplier_name') tempTemplate[0].value.value = value[key];
      else if (key === 'warehouse') tempTemplate[1].value.value = value[key];
      else if (key === 'address') tempTemplate[2].value.value = value[key];
      else if (key === 'city') tempTemplate[3].value.value = value[key];
      else if (key === 'pin_code') tempTemplate[4].value.value = value[key];
      else if (key === 'state') tempTemplate[5].value.value = value[key];
      else if (key === 'gst') tempTemplate[6].value.value = value[key];
    } else if (type === 'shipping_information') {
      if (key === 'name') tempTemplate[0].value.value = value[key];
      else if (key === 'warehouse') tempTemplate[1].value.value = value[key];
      else if (key === 'address') tempTemplate[2].value.value = value[key];
      else if (key === 'city') tempTemplate[3].value.value = value[key];
      else if (key === 'pin_code') tempTemplate[4].value.value = value[key];
      else if (key === 'gst') tempTemplate[5].value.value = value[key];
    }
  });
  return tempTemplate;
};

const ViewOrders = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [orderIdOverview, setOrderIdOverview] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 1
  });
  const [list, setList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [poNotFound, setPoNotFound] = useState(false);

  const dataFound = Object.keys(orderIdOverview)?.length;

  const fetchPoDetails = async (POid) => {
    setLoading(true);
    const res = await getPoDetails({ POid });
    if (res?.status === SUCCESS) {
      if (poNotFound) setPoNotFound(false);
      const newRes = res?.data;
      newRes.order_details = keyValueMaker(
        res?.data?.order_details,
        orderDetailsFormate,
        'order_details'
      );
      newRes.supplier_information = keyValueMaker(
        res?.data?.supplier_information,
        supplierDetailsFormate,
        'supplier_information'
      );
      newRes.shipping_information = keyValueMaker(
        res?.data?.shipping_information,
        shippingDetailsFormate,
        'shipping_information'
      );
      setOrderIdOverview(newRes);
      setLoading(false);
    } else {
      setOrderIdOverview({});
      setPoNotFound(true);
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    if (params?.po_no) {
      setOrderId(params?.po_no);
    }
  }, [params?.po_no]);

  useEffect(() => {
    if (orderId) {
      setPoNotFound(false);
      fetchPoDetails(orderId);
    }
  }, [orderId]);

  const APICALL = async (page = 1) => {
    setIsLoading(true);
    const customPayload = { POid: orderId };

    const res = await poList({
      page: page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page === 1) {
        const newData = dataKeyNameChange(res?.data?.Products);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.Products) }]);
      setPageData({ maxPage: res.data.totalPages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    if (orderId && dataFound) APICALL();
  }, [orderId, dataFound]);

  return (
    <MainContent heading="Purchase Order Details">
      <Box sx={styles.dataWrapper}>
        <FindPurchaseOrder
          setOrderId={setOrderId}
          poNotFound={poNotFound}
          defaultPoId={params?.po_no}
        />
        {!poNotFound && loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            <DescriptionBar label="Detail for Purchase Order ID:" value={orderId} />
            <Box sx={styles.readOnlyCards}>
              {orderIdOverview?.order_details && (
                <ReadOnlyList heading="Order Details" fieldsData={orderIdOverview?.order_details} />
              )}
              {orderIdOverview?.supplier_information && (
                <ReadOnlyList
                  heading="Supplier Information"
                  fieldsData={orderIdOverview?.supplier_information}
                />
              )}
              {orderIdOverview?.shipping_information && (
                <ReadOnlyList
                  heading="Shipping Information"
                  fieldsData={orderIdOverview?.shipping_information}
                />
              )}
            </Box>
            <ViewOrderFilters
              orderId={orderId}
              orderedUnits={orderIdOverview?.ordered_units}
              outstandingUnits={orderIdOverview?.outstanding_units}
            />
            <ViewOrdersTable
              list={tableRows}
              purchaseOrder={ProductInfoRenderer}
              pageData={pageData}
              pageClick={(e, pageNumber) => {
                apiCallWithPage(pageNumber);
              }}
              isLoading={isLoading}
              page={pageData.currentPage}
            />
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default ViewOrders;
