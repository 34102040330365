import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { poList } from '../../../../redux/APICaller/inboundOrderAPIs';
import { getConsignmentDetails } from '../../../../redux/APICaller/inboundConsignment';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../../utils/index';
import {
  DescriptionBar,
  MainContent,
  ProductInfoRenderer,
  ReadOnlyList,
  Spinner
} from '../../../common/index';
import FindACNId from './FindACNId';
import ViewConsignmentFilters from './ViewConsignmentFilters';
import ViewConsignmentTable from './ViewConsignmentTable';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      product_info: ProductInfo,
      ordered_quantity: OrderedQty,
      received_quantity: ReceiverQty,
      cancelled_quantity: CanceledQty,
      rejected_quantity: RejectedQty,
      outstanding_quantity: OutstandingQty,
      total_value: TotalValue,
      ...rest
    }) => ({
      ProductInfo,
      OrderedQty,
      ReceiverQty,
      CanceledQty,
      RejectedQty,
      OutstandingQty,
      TotalValue,
      ...rest
    })
  );

  return newArrayOfObj;
};

const orderDetailsFormate = [
  {
    label: 'PO No.',
    value: {
      type: 'link',
      value: ''
    }
  },
  {
    label: 'Consgn. Creation Date',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Total Scheduled Units',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Scheduled Packages',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Category',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'IRNs Created',
    value: {
      type: 'read',
      value: ''
    }
  }
];

const supplierDetailsFormate = [
  {
    label: 'Name',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Whse Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Address',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'City',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Pin Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'State',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'GST',
    value: {
      type: 'write',
      value: ''
    }
  }
];

const shippingDetailsFormate = [
  {
    label: 'Name',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Whse Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Address',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'City',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Pin Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'GST',
    value: {
      type: 'write',
      value: ''
    }
  }
];

const trailerDetailsFormate = [
  {
    label: 'Trailer Number',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Trailer Gate In Time',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Trailer Gate Out Time',
    value: {
      type: 'read',
      value: ''
    }
  }
];

const keyValueMaker = (value, template, type) => {
  const tempTemplate = JSON.parse(JSON.stringify(template));

  Object.keys(value)?.forEach((key) => {
    if (type === 'order_details') {
      if (key === 'po_no') tempTemplate[0].value.value = value[key];
      else if (key === 'consign_creation_date')
        tempTemplate[1].value.value = getFormattedDate(value[key], 'DD/MM/YY');
      else if (key === 'total_scheduled_units') tempTemplate[2].value.value = value[key];
      else if (key === 'total_scheduled_packages') tempTemplate[3].value.value = value[key];
      else if (key === 'category_name') tempTemplate[4].value.value = value[key];
      else if (key === 'irns_created') tempTemplate[5].value.value = value[key];
    } else if (type === 'supplier_information') {
      if (key === 'supplier_name') tempTemplate[0].value.value = value[key];
      else if (key === 'warehouse') tempTemplate[1].value.value = value[key];
      else if (key === 'address') tempTemplate[2].value.value = value[key];
      else if (key === 'city') tempTemplate[3].value.value = value[key];
      else if (key === 'pin_code') tempTemplate[4].value.value = value[key];
      else if (key === 'state') tempTemplate[5].value.value = value[key];
      else if (key === 'gst') tempTemplate[6].value.value = value[key];
    } else if (type === 'shipping_information') {
      if (key === 'name') tempTemplate[0].value.value = value[key];
      else if (key === 'warehouse') tempTemplate[1].value.value = value[key];
      else if (key === 'address') tempTemplate[2].value.value = value[key];
      else if (key === 'city') tempTemplate[3].value.value = value[key];
      else if (key === 'pin_code') tempTemplate[4].value.value = value[key];
      else if (key === 'gst') tempTemplate[5].value.value = value[key];
    } else if (type === 'trailor_details') {
      if (key === 'trailer_number') tempTemplate[0].value.value = value[key];
      else if (key === 'trailer_gate_in_time')
        tempTemplate[1].value.value = value[key] && getFormattedDate(value[key], 'DD/MM/YY');
      else if (key === 'trailer_gate_out_time')
        tempTemplate[2].value.value = value[key] && getFormattedDate(value[key], 'DD/MM/YY');
    }
  });
  return tempTemplate;
};

const ViewConsignment = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [consignmentLineItems, setConsignmentLineItems] = useState(0);
  const [orderIdOverview, setOrderIdOverview] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [list, setList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [poNotFound, setPoNotFound] = useState(false);

  const dataFound = Object.keys(orderIdOverview)?.length;

  const fetchPoDetails = async (acnId) => {
    setLoading(true);
    const res = await getConsignmentDetails({ acnId });
    if (res?.status === SUCCESS) {
      if (poNotFound) setPoNotFound(false);
      const newRes = res?.data;

      newRes.order_details = keyValueMaker(
        res?.data?.order_details,
        orderDetailsFormate,
        'order_details'
      );
      newRes.supplier_information = keyValueMaker(
        res?.data?.supplier_information,
        supplierDetailsFormate,
        'supplier_information'
      );
      newRes.shipping_information = keyValueMaker(
        res?.data?.shipping_information,
        shippingDetailsFormate,
        'shipping_information'
      );
      newRes.trailor_details = keyValueMaker(
        res?.data?.trailor_details,
        trailerDetailsFormate,
        'trailor_details'
      );
      setOrderIdOverview(newRes);
      setLoading(false);
    } else {
      setOrderIdOverview({});
      setPoNotFound(true);
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    if (params?.acn_no) {
      setOrderId(params?.acn_no);
    }
  }, [params?.acn_no]);

  useEffect(() => {
    if (orderId) {
      setPoNotFound(false);
      fetchPoDetails(orderId);
    }
  }, [orderId]);

  const APICALL = async (page = 1) => {
    setIsLoading(true);
    const customPayload = { POid: orderId };

    const res = await poList({
      page: page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page === 1) {
        const newData = dataKeyNameChange(res?.data?.Products);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.Products) }]);
      setPageData({ maxPage: res.data.total_pages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
      setConsignmentLineItems(res.data.total_elements);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  // const apiCallWithPage = (page) => {
  //   if (!isDataExist(page)) APICALL(page);
  //   else setPageData({ ...pageData, currentPage: page });
  // };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  // useEffect(() => {
  //   if (orderId && dataFound) APICALL();
  // }, [orderId, dataFound]);

  return (
    <MainContent heading="Consignment Details">
      <Box sx={styles.dataWrapper}>
        <FindACNId setOrderId={setOrderId} poNotFound={poNotFound} defaultPoId={params?.acn_no} />
        {!poNotFound && loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            <DescriptionBar label="Information for Consignment ID:" value={orderId} />
            <Box sx={styles.readOnlyCards}>
              {orderIdOverview?.order_details && (
                <ReadOnlyList heading="Order Details" fieldsData={orderIdOverview?.order_details} />
              )}
              {orderIdOverview?.supplier_information && (
                <ReadOnlyList
                  heading="Supplier Information"
                  fieldsData={orderIdOverview?.supplier_information}
                />
              )}
              {orderIdOverview?.shipping_information && (
                <ReadOnlyList
                  heading="Shipping Information"
                  fieldsData={orderIdOverview?.shipping_information}
                />
              )}
              {orderIdOverview?.trailor_details && (
                <ReadOnlyList
                  heading="Trailer Details"
                  fieldsData={orderIdOverview?.trailor_details}
                />
              )}
            </Box>
            <ViewConsignmentFilters orderId={orderId} consignmentLineItems={consignmentLineItems} />
            <ViewConsignmentTable
              list={tableRows}
              purchaseOrder={ProductInfoRenderer}
              pageData={pageData}
              pageClick={(e, pageNumber) => {
                // apiCallWithPage(pageNumber);
              }}
              isLoading={isLoading}
              page={pageData.currentPage}
            />
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default ViewConsignment;
