import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { WarehouseListConvertor } from '../../../../../constants/apiDataChanger';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { getWarehouseList } from '../../../../../redux/APICaller';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, Dropdown, Input, NewDate } from '../../../../common';
import styles from '../styles';

const SearchStockFilters = ({ data, setData, onFind, isFindDisable }) => {
  const dispatch = useDispatch();
  const [warehouseList, setWarehouseList] = useState([]);

  const { control, reset, watch } = useForm({
    defaultValues: {
      transferId: '',
      sourceWarehouse: '',
      destinationWarehouse: '',
      status: '',
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    },
    mode: 'all'
  });

  const transferId = watch('transferId');
  const sourceWarehouse = watch('sourceWarehouse');
  const destinationWarehouse = watch('destinationWarehouse');
  const status = watch('status');
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  useEffect(() => {
    if (startDate) {
      if (startDate) setData({ ...data, startDate: startDate });
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      if (endDate) setData({ ...data, endDate: endDate });
    }
  }, [endDate]);

  useEffect(() => {
    setData({ ...data, transferId: transferId });
  }, [transferId]);

  useEffect(() => {
    setData({ ...data, destinationWarehouse: destinationWarehouse });
  }, [destinationWarehouse]);

  useEffect(() => {
    setData({ ...data, status: status });
  }, [status]);

  useEffect(() => {
    setData({ ...data, sourceWarehouse: sourceWarehouse });
  }, [sourceWarehouse]);

  const getWarehouseDropdownData = async () => {
    const res = await getWarehouseList({});
    if (res?.status == SUCCESS) {
      setWarehouseList(WarehouseListConvertor(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    getWarehouseDropdownData();
  }, []);

  return (
    <Box sx={styles.filterWrapper}>
      <Box sx={styles.input}>
        <Input
          label="Transfer ID"
          name="transferId"
          control={control}
          placeholder="Search by Transfer ID"
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Source Warehouse"
          name="sourceWarehouse"
          control={control}
          options={warehouseList}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Destination Warehouse"
          name="destinationWarehouse"
          control={control}
          options={warehouseList}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Status"
          name="status"
          control={control}
          options={[
            { value: 'Open', label: 'Open' },
            { value: 'Picked', label: 'Picked' },
            { value: 'Transfer complete', label: 'Transfer complete' }
          ]}
        />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="Start Date" name="startDate" control={control} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="End Date" name="endDate" control={control} minDate={watch('startDate')} />
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button
          label="Find"
          customStyles={styles.actionButton}
          onClick={() => onFind()}
          disabled={isFindDisable}
        />
        <Button
          label="Reset"
          customStyles={styles.actionButton}
          disabled={isFindDisable}
          onClick={() => {
            reset();
            onFind('reset');
          }}
        />
      </Box>
    </Box>
  );
};

export default SearchStockFilters;
