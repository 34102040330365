import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { updateProductIdsList } from '../../../../redux/authSlice';
import { roAllSkuList } from '../../../../redux/APICaller/inboundReturnOrderAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import {
  Button,
  DescriptionBar,
  MainContent,
  ProductInfoRenderer,
  Spinner
} from '../../../common/index';
import CreateReturnOrderTable from './CreateReturnOrderTable';
import FindIRNId from './FindIrnId';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ product_info: ProductInfo, irn_quantity: IRNQty, qc_status: QCStatus, ...rest }) => ({
      ProductInfo,
      IRNQty,
      QCStatus,
      ...rest
    })
  );

  return newArrayOfObj;
};

const CreateReturnOrder = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [list, setList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [poNotFound, setPoNotFound] = useState(false);
  const [dataFound, setdataFound] = useState(0);
  const [skuIdList, setSkuIdList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(0);

  // useEffect(() => {
  //   if (params?.po_no) {
  //     setOrderId(params?.po_no);
  //   }
  // }, [params?.po_no]);

  const APICALL = async (page = 1) => {
    if (page === 1) setLoading(true);

    setIsLoading(true);
    const customPayload = { irn_id: orderId };

    const res = await roAllSkuList({
      page: page - 1,
      // limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (!res?.data?.total_elements) setPoNotFound(true);
      setdataFound(res?.data?.total_elements);
      if (page === 1) {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.Products) }]);
      setPageData({ maxPage: res.data.totalPages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
      if (page === 1) setLoading(false);
    } else {
      if (page === 1) setLoading(false);
      setPoNotFound(true);
      setdataFound(0);
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    if (orderId) {
      setPoNotFound(false);
      APICALL();
    }
  }, [orderId]);

  const setSkuIds = () => {
    dispatch(updateProductIdsList(skuIdList));
    navigate(`/create-return-order/${orderId}`);
  };

  return (
    <MainContent heading="Purchase Return Order Creation">
      <Box sx={styles.dataWrapper}>
        <FindIRNId setOrderId={setOrderId} poNotFound={poNotFound} />
        {!poNotFound && loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            <DescriptionBar label=" Detail for IRN ID:" value={orderId} />
            <DescriptionBar label="Total SKUs found:" value={dataFound} />
            <CreateReturnOrderTable
              setSelectedRows={setSelectedRows}
              setSkuIdList={setSkuIdList}
              list={tableRows}
              productInfoRenderer={ProductInfoRenderer}
              pageData={pageData}
              pageClick={(e, pageNumber) => {
                apiCallWithPage(pageNumber);
              }}
              isLoading={isLoading}
              page={pageData.currentPage}
            />
            <Box>
              <Button disabled={!selectedRows} label="Generate RO" onClick={() => setSkuIds()} />
            </Box>
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default CreateReturnOrder;
