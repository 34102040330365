const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflowY: 'auto',
    padding: '2em 0',
    gap: {
      xs: '14px',
      sm: '18px'
    }
  },

  mainTab: {
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: 'white',

    '& .MuiAccordionDetails-root': {
      padding: '0 !important'
    },

    '&.MuiAccordion-root:before': {
      padding: '0 !important',
      opacity: '0 !important'
    }
  },

  headingWrapper: {
    boxSizing: 'border-box',
    flexDirection: {
      xs: 'row',
      md: 'row-reverse'
    },
    gap: '15px',
    padding: {
      xs: '0 16px',
      md: '0 20px'
    },
    '&:hover': {
      backgroundColor: '#697576'
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    }
  },

  icon: {
    fontSize: '0.9rem',
    color: 'white',
    fontWeight: 500
  },

  heading: {
    boxSizing: 'border-box',
    transition: '.5s',
    fontSize: {
      xs: '14px',
      sm: '15.5px'
    },
    fontWeight: 400
  },

  label: {
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    cursor: 'pointer',
    letterSpacing: 0.8,
    padding: {
      xs: '10px 8px 10px 3em',
      md: '12px 8px 12px 4.2em'
    },
    '&:hover': {
      backgroundColor: '#697576'
    }
  },

  leftBar: {
    position: 'absolute',
    top: 4,
    left: 2,
    borderRadius: '8px',
    backgroundColor: 'red',
    height: '83%',
    border: '2px solid #47C2EC'
  }
};

export default styles;
