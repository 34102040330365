const styles = {
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    height: '100vh'
  },

  drawerPaper: {
    '& .MuiDrawer-paper': {
      zIndex: 100,
      position: 'fixed',
      width: {
        xs: '230px',
        md: '250px'
      },
      maxWidth: {
        xs: '300px',
        md: '340px'
      },
      borderRadius: 0,
      borderTop: 'none',
      borderBottom: 'none',
      top: 62,
      backgroundColor: '#252829',
      borderRight: '1px solid rgb(105, 117, 118)',
      boxShadow: '0 2px 9px 2px rgba(125, 125, 125, 0.16)',
      height: `calc(100% - 62px)`
    }
  },

  drawerContent: {
    overflow: 'auto',
    position: 'fixed',
    top: 62,
    height: 'calc(100% - 62px)',
    backgroundColor: '#ffffff',
    transition: '300ms'
  },

  content: {
    padding: {
      xs: 2,
      sm: 3
    }
  }
};

export default styles;
