import { Box } from '@mui/material';
import React from 'react';
import { Button, POIdRenderer } from '../../common/index';
import styles from './styles';

const ReadOnlyList = ({
  heading = '',
  fieldsData = [],
  customStyles,
  showButton = false,
  buttonLabel,
  buttonAction,
  disabledAction,
  buttonStyles,
  wrapperCustomStyles
}) => {
  return (
    <Box sx={{ ...styles.wrapper, ...wrapperCustomStyles }}>
      <Box sx={styles.heading}>{heading}</Box>
      <Box sx={styles.cardWrapper}>
        {fieldsData?.map(({ label, value }, index) => {
          return (
            <Box key={`${label + '-' + index}`} sx={styles.card}>
              <Box
                sx={{
                  ...styles.label,
                  borderBottom: index !== fieldsData.length - 1 && '1px solid'
                }}>
                {label}
              </Box>
              <Box
                sx={{
                  ...styles.value,
                  ...customStyles,
                  borderBottom: index !== fieldsData.length - 1 && '1px solid'
                }}>
                {value?.type === 'link' ? POIdRenderer(value?.value) : value?.value}
              </Box>
            </Box>
          );
        })}
      </Box>
      {showButton && (
        <Button
          label={buttonLabel}
          onClick={buttonAction}
          disabled={disabledAction}
          customStyles={buttonStyles}
        />
      )}
    </Box>
  );
};

export default ReadOnlyList;
