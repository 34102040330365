/**
 * Stepper List to add or delete items in the stepper
 */

export const stepperList = [
  {
    heading: 'Overview'
  },
  {
    heading: 'Gate In',
    subHeadings: [
      {
        label: 'Search ACN',
        route: '/search-acn'
      },
      {
        label: 'Create manual ACN',
        route: '/create-manual-acn'
      }
    ]
  },
  {
    heading: 'Gate Out',
    subHeadings: [
      {
        label: 'Allow Gate Out',
        route: '/allow-gate-out'
      }
    ]
  },
  {
    heading: 'Dock',
    subHeadings: [
      {
        label: 'Receive on dock',
        route: '/receive-on-dock'
      },
      {
        label: 'Start Loading / Unloading',
        route: '/start-loading-unloading'
      },
      {
        label: 'Complete Loading/ Unloading',
        route: '/complete-loading-unloading'
      },
      {
        label: 'Release from dock',
        route: '/release-from-dock'
      }
    ]
  },
  {
    heading: 'Inbound Orders',
    subHeadings: [
      {
        label: 'Create PO',
        route: '/create-purchase-order'
      },
      {
        label: 'View Orders',
        route: '/view-orders'
      },
      {
        label: 'Find Orders',
        route: '/find-orders'
      }
    ]
  }
  // {
  //   heading: 'Inbound Consignment',
  //   subHeadings: [
  //     {
  //       label: 'View Consignment',
  //       route: '/view-consignment'
  //     },
  //     {
  //       label: 'Find Consignment',
  //       route: '/find-consignment'
  //     }
  //   ]
  // },
  // {
  //   heading: 'Inbound Return Orders',
  //   subHeadings: [
  //     {
  //       label: 'Find Return Order',
  //       route: '/find-return-order'
  //     },
  //     {
  //       label: 'View Return Order',
  //       route: '/view-return-order'
  //     },
  //     {
  //       label: 'Create Return Order',
  //       route: '/create-return-order'
  //     }
  //   ]
  // },
  // {
  //   heading: 'IRN',
  //   subHeadings: [
  //     {
  //       label: 'Create IRN',
  //       route: '/create-irn'
  //     },
  //     {
  //       label: 'Receive IRN',
  //       route: '/receive-irn'
  //     },
  //     {
  //       label: 'View IRN Stock',
  //       route: '/view-irn'
  //     }
  //   ]
  // },
  // {
  //   heading: 'Putaways',
  //   subHeadings: [
  //     {
  //       label: 'Create Putlist',
  //       route: '/create-putlist'
  //     },
  //     {
  //       label: 'Complete Putlist',
  //       route: '/complete-putlist'
  //     },
  //     {
  //       label: 'Find Putlist',
  //       route: '/find-putlist'
  //     },
  //     {
  //       label: 'View Putlist',
  //       route: '/view-putlist'
  //     }
  //     // {
  //     //   label: 'Find Putlist',
  //     //   route: '/find-putlist'
  //     // },
  //     // {
  //     //   label: 'View Putlist',
  //     //   route: '/view-putlist'
  //     // }
  //   ]
  // },
  // {
  //   heading: 'Browse Product',
  //   subHeadings: [
  //     {
  //       label: 'Create Product',
  //       route: '/create-product'
  //     },
  //     {
  //       label: 'Find Product',
  //       route: '/find-product'
  //     },
  //     {
  //       label: 'View Product',
  //       route: '/view-product'
  //     }
  //   ]
  // },
  // {
  //   heading: 'Browse Inventory',
  //   subHeadings: [
  //     {
  //       label: 'Search Inventory',
  //       route: '/search-inventory'
  //     }
  //   ]
  // },
  // {
  //   heading: 'Stock Transfer',
  //   subHeadings: [
  //     {
  //       label: 'Create Transfer',
  //       route: '/create-transfer'
  //     },
  //     {
  //       label: 'Pick Transfer',
  //       route: '/pick-transfer'
  //     },
  //     {
  //       label: 'Put away Transfer',
  //       route: '/put-away-transfer'
  //     },
  //     {
  //       label: 'Search Transfer',
  //       route: '/search-transfer'
  //     },
  //     {
  //       label: 'View Transfer',
  //       route: '/view-transfer'
  //     }
  //   ]
  // },
  // {
  //   heading: 'Outbound',
  //   subHeadings: [
  //     {
  //       label: 'Create SO',
  //       route: '/create-so'
  //     },
  //     {
  //       label: 'All Orders',
  //       route: '/all-orders'
  //     },
  //     {
  //       label: 'Pick',
  //       route: '/pick'
  //     },
  //     {
  //       label: 'Pack',
  //       route: '/pack'
  //     },
  //     {
  //       label: 'Manifest',
  //       route: '/manifest'
  //     },
  //     {
  //       label: 'Put Back',
  //       route: '/put-back'
  //     }
  //   ]
  // }
];
