import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  CategoryListConverter,
  SupplierListConverter,
  WarehouseListConvertor
} from '../../../../../constants/apiDataChanger';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { getCategoryList, getSupplierList, getWarehouseList } from '../../../../../redux/APICaller';
import { findAcnListCsvDownload } from '../../../../../redux/APICaller/inboundConsignment';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, Dropdown, Input, NewDate } from '../../../../common';
import styles from '../styles';

const FindConsignmentFilters = ({ data, setData, onFind, isFindDisable, csvPayload }) => {
  const dispatch = useDispatch();
  const [supplierList, setSupplierList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [warehouseList, setWarehouseListList] = useState([]);
  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      supplier: '',
      productCategory: '',
      warehouse: ''
    }
  });

  const fromDate = watch('startDate');
  const toDate = watch('endDate');
  const purchaseOrderIdValue = watch('purchaseOrderId');
  const supplierValue = watch('supplier');
  const acnIdValue = watch('acnId');
  const productCategoryValue = watch('productCategory');
  const warehouseValue = watch('warehouse');

  useEffect(() => {
    if (fromDate) {
      if (fromDate) setData({ ...data, fromDate: fromDate });
    }
  }, [fromDate]);

  useEffect(() => {
    if (toDate) {
      if (toDate) setData({ ...data, toDate: toDate });
    }
  }, [toDate]);

  useEffect(() => {
    setData({ ...data, purchaseOrderIdValue: purchaseOrderIdValue });
  }, [purchaseOrderIdValue]);

  useEffect(() => {
    setData({ ...data, supplierValue: supplierValue });
  }, [supplierValue]);

  useEffect(() => {
    setData({ ...data, warehouseValue: warehouseValue });
  }, [warehouseValue]);

  useEffect(() => {
    setData({ ...data, acnIdValue: acnIdValue });
  }, [acnIdValue]);

  useEffect(() => {
    setData({ ...data, productCategoryValue: productCategoryValue });
  }, [productCategoryValue]);

  const getSupplierDropdownData = async () => {
    const res = await getSupplierList({});
    if (res?.status == SUCCESS) {
      setSupplierList(SupplierListConverter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const getProductCategoryDropdownData = async () => {
    const res = await getCategoryList({});
    if (res?.status == SUCCESS) {
      setProductCategoryList(CategoryListConverter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const getWarehouseDropdownData = async () => {
    const res = await getWarehouseList({});
    if (res?.status == SUCCESS) {
      setWarehouseListList(WarehouseListConvertor(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    getSupplierDropdownData();
    getProductCategoryDropdownData();
    getWarehouseDropdownData();
  }, []);

  let isDisable = false;
  const handleDownloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  };

  const csvDownload = async () => {
    const customPayload = {};

    customPayload.date_from =
      csvPayload?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    customPayload.date_to = csvPayload?.toDate || moment().format('YYYY-MM-DD');
    if (csvPayload.purchaseOrderIdValue) customPayload.po_no = csvPayload.purchaseOrderIdValue;
    if (csvPayload.warehouseValue) customPayload.warehouse = csvPayload.warehouseValue;
    if (csvPayload.productCategoryValue)
      customPayload.category_id = csvPayload?.productCategoryValue;
    if (csvPayload.supplierValue) customPayload.supplier_id = csvPayload?.supplierValue;
    if (csvPayload.acnIdValue) customPayload.acn_no = csvPayload.acnIdValue;

    const res = await findAcnListCsvDownload({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      handleDownloadCsv(res?.data?.url);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  return (
    <Box sx={styles.dropDownWrapper}>
      <Box sx={styles.input}>
        <Input
          label="Purchase Order ID"
          placeholder="Find by PO ID"
          name="purchaseOrderId"
          control={control}
        />
      </Box>
      <Box sx={styles.input}>
        <Input label="ACN ID" placeholder="Find by Consignment" name="acnId" control={control} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown label="Supplier" name="supplier" control={control} options={supplierList} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Product Category"
          name="productCategory"
          control={control}
          options={productCategoryList}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown label="Warehouse" name="warehouse" control={control} options={warehouseList} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="Start Date" name="startDate" control={control} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="End Date" name="endDate" control={control} minDate={watch('startDate')} />
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button
          label="Find"
          customStyles={styles.actionButton}
          onClick={() => onFind()}
          disabled={isFindDisable}
        />
        <Button
          label="Reset"
          customStyles={styles.actionButton}
          disabled={isFindDisable}
          onClick={() => {
            reset({ supplier: '', productCategory: '', warehouse: '' });
            setValue('purchaseOrderId', '');
            setValue('acnId', '');
            setValue('supplier', '');
            setValue('skuCode', '');
            setValue('productCategory', '');
            setValue('warehouse', '');
            onFind('reset');
          }}
        />
      </Box>
      <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon
          sx={styles.download}
          onClick={async () => {
            if (!isDisable) {
              isDisable = true;
              await csvDownload();
              isDisable = false;
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default FindConsignmentFilters;
