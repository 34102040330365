import { GATE_OUT_PATHS } from '../../constants/apiPaths';
import { getAPI } from '../../helpers/API';

const { GET_TRUCK_LIST } = GATE_OUT_PATHS;

/**
 * Yard List
 * @param {*} data
 * @returns Array of yards
 */
export function gateOutList(data) {
  return getAPI({
    url: GET_TRUCK_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
