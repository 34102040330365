import { Box } from '@mui/material';
import React from 'react';
import { Input } from '../../../../common';
import DropDown from '../../../../common/FormComponents/Dropdown';
import styles from '../styles';

const CustomDropdownHandler = ({ defultVal, name, label, control, options, rules, inputType }) => {
  return (
    <>
      {defultVal ? (
        <Box sx={styles.input}>
          <DropDown
            defaultValue={defultVal}
            name={name}
            placeholder={label}
            control={control}
            options={options}
            rules={rules}
          />
        </Box>
      ) : (
        <Box sx={styles.input}>
          <Input
            name={name}
            disabled
            placeholder={label}
            type={inputType}
            control={control}
            rules={rules}
          />
        </Box>
      )}
    </>
  );
};

export default CustomDropdownHandler;
