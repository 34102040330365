import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SupplierListConverter } from '../../../../constants/apiDataChanger';
import { SUCCESS } from '../../../../constants/apiStatusCodes';
import {
  ACN_ID,
  INVOICE_NUMBER,
  PO_NO,
  TRAILOR_LOCATION,
  TRAILOR_NUMBER,
  TRAILOR_STATUS,
  TRUCK_NUMBER
} from '../../../../constants/commonLabels';
import { ERROR } from '../../../../constants/constants';
import { filterType, searchConsigment } from '../../../../constants/filterOptionsData';
import { getSupplierList } from '../../../../redux/APICaller';
import { yardListCsvDownload } from '../../../../redux/APICaller/overviewAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Dropdown, Input, NewDate } from '../../../common';
import styles from '../styles';

const OverviewFilters = ({ data, setData }) => {
  const dispatch = useDispatch();
  const [brandList, setBrandList] = useState([]);
  const { control, watch } = useForm({
    defaultValues: {
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    }
  });
  const [preStartDate, setPreStartDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [preendDateDate, setPreendDateDate] = useState(moment().format('YYYY-MM-DD'));

  const searchConsigmentValue = watch('searchConsigment');
  const filterTypeValue = watch('filterType');
  const searchStatusValue = watch('searchStatus');
  const searchStr = watch('acnId');
  const brandValue = watch('supplier');
  const searchByTrailerDetails = watch('searchByTrailer');
  const searchByTrailersValue = watch('searchByTrailerValue');

  const fromDate = watch('startDate');

  const toDate = watch('endDate');

  useEffect(() => {
    if (fromDate !== preStartDate) {
      setPreStartDate(fromDate);
      if (fromDate) setData({ ...data, fromDate: fromDate });
    }
  }, [fromDate]);

  useEffect(() => {
    if (toDate !== preendDateDate) {
      setPreendDateDate(toDate);
      if (toDate) setData({ ...data, toDate: toDate });
    }
  }, [toDate]);

  const [isSearchCalled, setIsSearchCalled] = useState(false);
  const [isSearchCalledByTrailer, setIsSearchCalledByTrailer] = useState(false);

  useEffect(() => {
    data.searchBy = searchConsigmentValue;
  }, [searchConsigmentValue]);

  useEffect(() => {
    if (brandValue) setData({ ...data, brandType: brandValue });
  }, [brandValue]);

  useEffect(() => {
    if (filterTypeValue) setData({ ...data, consgnType: filterTypeValue });
  }, [filterTypeValue]);

  useEffect(() => {
    data.searchByTrailer = searchByTrailerDetails;
  }, [searchByTrailerDetails]);

  useEffect(() => {
    if (searchStatusValue) setData({ ...data, consgnStatus: searchStatusValue });
  }, [searchStatusValue]);

  useEffect(() => {
    if (!searchStr && isSearchCalled) {
      setData({ ...data, searchStr: '' });
      setIsSearchCalled(false);
    }
  }, [searchStr, isSearchCalled]);

  useEffect(() => {
    if (!searchByTrailersValue && isSearchCalledByTrailer) {
      setData({ ...data, searchByTrailersValue: '' });
      setIsSearchCalledByTrailer(false);
    }
  }, [searchByTrailersValue, isSearchCalledByTrailer]);

  const handleDownloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  };

  const csvDownload = async () => {
    const customPayload = {};
    if (data.consgnType) customPayload.consignment_type = data.consgnType;
    if (data.consgnStatus) customPayload.consignment_status = data?.consgnStatus;
    if (data.brandType) customPayload.brand = data?.brandType;
    customPayload.from_date = data?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    customPayload.to_date = data?.toDate || moment().format('YYYY-MM-DD');

    if (data.searchStr) {
      if (data.searchBy === ACN_ID) customPayload.acn_no = data?.searchStr;
      if (data.searchBy === PO_NO) customPayload.po_no = data?.searchStr;
      if (data.searchBy === INVOICE_NUMBER) customPayload.invoice_no = data?.searchStr;
    }
    if (data.searchByTrailersValue) {
      if (data.searchByTrailer === TRAILOR_NUMBER)
        customPayload.truck_no = data.searchByTrailersValue;
      if (data.searchByTrailer === TRAILOR_STATUS)
        customPayload.trailer_status = data?.searchByTrailersValue;
      if (data.searchByTrailer === TRAILOR_LOCATION)
        customPayload.trailer_location = data?.searchByTrailersValue;
    }

    const res = await yardListCsvDownload({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      handleDownloadCsv(res?.data?.url);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  const getBrandDropdownData = async () => {
    const res = await getSupplierList({});
    if (res?.status == SUCCESS) {
      setBrandList(SupplierListConverter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    getBrandDropdownData();
  }, []);

  const label = searchConsigmentValue || ACN_ID;
  const label2 = searchByTrailerDetails || TRUCK_NUMBER;
  let isDisable = false;

  return (
    <Box sx={styles.dropDownWrapper}>
      <Box sx={styles.input}>
        <Dropdown
          label="Search consignment by"
          placeholder="Search consignment by"
          name="searchConsigment"
          control={control}
          options={searchConsigment}
        />
      </Box>
      <Box sx={styles.input}>
        <Input
          label={label}
          name="acnId"
          placeholder={`Enter your ${label?.toLowerCase()}`}
          control={control}
          disabled={!searchConsigmentValue}
          isSearch={searchStr && searchConsigmentValue}
          onSearch={() => {
            setData({ ...data, searchStr: watch('acnId') });
            setIsSearchCalled(true);
          }}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown label="Supplier" name="supplier" control={control} options={brandList} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Filter Cosgn. Type"
          name="filterType"
          control={control}
          options={filterType}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Search by trailer detail"
          name="searchByTrailer"
          control={control}
          options={[
            {
              label: 'Trailor number',
              value: 'Trailor number'
            },
            {
              label: 'Trailor status',
              value: 'Trailor status'
            },
            {
              label: 'Trailor location',
              value: 'Trailor location'
            }
          ]}
        />
      </Box>
      <Box sx={styles.input}>
        <Input
          label={label2}
          name="searchByTrailerValue"
          placeholder={`Enter your ${label2}`}
          disabled={!searchByTrailerDetails}
          control={control}
          isSearch={searchByTrailerDetails && searchByTrailersValue}
          onSearch={() => {
            setData({ ...data, searchByTrailersValue: watch('searchByTrailerValue') });
            setIsSearchCalledByTrailer(true);
          }}
        />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="Start Date" name="startDate" control={control} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="End Date" name="endDate" control={control} minDate={watch('startDate')} />
      </Box>
      <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon
          sx={styles.download}
          onClick={async () => {
            if (!isDisable) {
              isDisable = true;
              await csvDownload();
              isDisable = false;
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default OverviewFilters;
