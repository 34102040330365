import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import styles from './styles';

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ padding: '20px 0' }}>{children}</Box>}
    </Box>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

const TabPanel = ({ labels, tabPanels, tabChange }) => {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    tabChange(newValue);
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.tabsWrapper}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          {labels?.map((label, index) => {
            return <Tab sx={styles.tab} key={index} label={label} {...a11yProps(index)} />;
          })}
        </Tabs>
      </Box>
      {tabPanels?.map((panelContent, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {panelContent}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default TabPanel;
