const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },

  lcanId: {
    cursor: 'pointer',
    transition: 'all 200ms ease',
    color: 'custom.blue',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  toolTip: {
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '300px',
    wordSpacing: 1.2
  },

  address: {
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '&:hover': {
      color: '#0858cf'
    }
  },

  dropDownWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '15px',
    marginBottom: '15px',
    position: 'relative',
    backgroundColor: '#F0F1F2',
    padding: '8px 16px'
  },

  input: {
    width: {
      xs: '100%',
      sm: '44.6%',
      md: '17.8%'
    }
  },

  downloadWrapper: {
    position: {
      xs: 'static',
      md: 'absolute'
    },
    bottom: 8,
    right: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    padding: '4px',
    borderRadius: '5px',
    marginTop: '18px',
    height: {
      xs: '32px',
      sm: '34px'
    },
    width: {
      xs: '34px',
      sm: '38px'
    }
  },

  download: {
    transition: 'all 400ms ease',
    cursor: 'pointer',
    color: 'white'
  },

  tableWrapper: {
    width: '100%',
    height: 470,
    overflowY: 'auto',
    fontFamily: "'Poppins', sans-serif",
    '& .ag-header-cell-text': {
      fontWeight: 500,
      fontSize: '16px'
    },
    '& .ag-cell': {
      fontSize: '14.5px',
      display: 'flex',
      alignItems: 'center'
    }
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%'
  }
};

export default styles;
