import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { findAcnList } from '../../../../redux/APICaller/inboundConsignment';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import Spinner from '../../../common/Spinner';
import { ACNIdRenderer, DescriptionBar, MainContent, POIdRenderer } from '../../../common/index';
import FindConsignmentFilters from './FindConsignmentFilters';
import FindConsignmentTable from './FindConsignmentTable';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      acn_no: ACNId,
      po_number: PONO,
      scheduled_qty: SCHEDULEDQTY,
      received_qty: RECEIVEDQTY,
      dock_rejected_qty: DOCKREJECTEDQTY,
      status: CONSIGNMENTSTATUS,
      outstanding_qty: OUTSTANDINGQTY,
      source_warehouse: SOURCEWHSECODE,
      dest_warehouse: DESTINATIONWHSECODE,
      trailer_no: TRAILERNO,
      ...rest
    }) => ({
      ACNId,
      PONO,
      SCHEDULEDQTY,
      RECEIVEDQTY,
      DOCKREJECTEDQTY,
      CONSIGNMENTSTATUS,
      OUTSTANDINGQTY,
      SOURCEWHSECODE,
      DESTINATIONWHSECODE,
      TRAILERNO,
      ...rest
    })
  );

  return newArrayOfObj;
};

const initPayload = {
  warehouseValue: '',
  skuCodeValue: '',
  acnIdValue: '',
  productCategoryValue: '',
  supplierValue: '',
  purchaseOrderIdValue: '',
  fromDate: '',
  toDate: ''
};

const FindConsignment = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(initPayload);
  const [csvPayload, setCsvPayload] = useState(initPayload);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalItems, setTotalItems] = useState('');
  const [tableRows, setTableRows] = useState([]);

  const isFindDisable = !(
    payload?.warehouseValue ||
    payload?.supplierValue ||
    payload?.productCategoryValue ||
    payload?.purchaseOrderIdValue ||
    payload?.acnIdValue ||
    payload?.fromDate ||
    payload?.toDate
  );

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1) => {
    setLoading(true);
    const customPayload = {};

    customPayload.date_from =
      payload?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    customPayload.date_to = payload?.toDate || moment().format('YYYY-MM-DD');

    if (page !== 'reset') {
      if (payload.purchaseOrderIdValue) customPayload.po_no = payload.purchaseOrderIdValue;
      if (payload.warehouseValue) customPayload.warehouse = payload.warehouseValue;
      if (payload.productCategoryValue) customPayload.category_id = payload?.productCategoryValue;
      if (payload.supplierValue) customPayload.supplier_id = payload?.supplierValue;
      if (payload.acnIdValue) customPayload.acn_no = payload.acnIdValue;
    } else {
      customPayload.date_from = moment().subtract(7, 'days').format('YYYY-MM-DD');
      customPayload.date_to = moment().format('YYYY-MM-DD');
      setPayload(initPayload);
    }
    const res = await findAcnList({
      page: page === 'reset' ? 0 : page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        setCsvPayload(payload);
      } else setCsvPayload(initPayload);
      if (page === 1 || page === 'reset') {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      setTotalItems(res?.data?.total_elements);
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  return (
    <Box>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading="Consignment Listing">
          <FindConsignmentFilters
            csvPayload={csvPayload}
            data={payload}
            setData={setPayload}
            onFind={(type) => {
              APICALL(type);
            }}
            isFindDisable={isFindDisable}
          />
          <DescriptionBar label="Total number of Consignments found:" value={totalItems} />
          <FindConsignmentTable
            list={tableRows}
            pageData={pageData}
            pageClick={(e, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
            acnID={ACNIdRenderer}
            poID={POIdRenderer}
          />
        </MainContent>
      )}
    </Box>
  );
};

export default FindConsignment;
