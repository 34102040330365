import { INBOUND_RETURN_ORDER_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI, putAPI } from '../../helpers/API';

const {
  GET_IRN_LIST_DETAILS,
  GET_RO_LIST,
  GET_FIND_RO_CSV_DOWNLOAD,
  CREATE_RO,
  GET_RO_ITEM_LIST,
  GET_RO_DETAILS,
  GET_RO_ITEM_LIST_CSV_DOWNLOAD,
  VIEW_PO_DETAILS_UPLOAD
} = INBOUND_RETURN_ORDER_PATHS;

/**
 * RO all SKU List
 * @param {*} data
 * @returns
 */
export function roAllSkuList(data) {
  return getAPI({
    url: GET_IRN_LIST_DETAILS.replace(':irnId', data?.irn_id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Find RO List
 * @param {*} data
 * @returns RO List
 */
export function findROList(data) {
  return postAPI({
    url: GET_RO_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * RO Details CSV Download
 * @param {*} data
 * @returns CSV File
 */
export function findRoCSVDownload(data) {
  return postAPI({
    url: GET_FIND_RO_CSV_DOWNLOAD,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * RO selected sku List
 * @param {*} data
 * @returns
 */
export function roSelectedSkuList(data) {
  return postAPI({
    url: GET_IRN_LIST_DETAILS.replace(':irnId', data?.irn_id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Create RO
 * @param {*} data
 * @returns
 */
export function createRo(data) {
  return postAPI({
    url: CREATE_RO,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * RO Item List
 * @param {*} data
 * @returns RO Item List
 */
export function roItemsList(data) {
  return getAPI({
    url: GET_RO_ITEM_LIST.replace(':ROid', data?.ROid),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get RO Details By Id
 * @param {*} data
 * @returns RO Details By Id
 */
export function getRoDetails(data) {
  return getAPI({
    url: GET_RO_DETAILS.replace(':ROid', data?.ROid),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * RO Item CSV Download
 * @param {*} data
 * @returns CSV File
 */
export function roItemDetailsCSVDownload(data) {
  return postAPI({
    url: GET_RO_ITEM_LIST_CSV_DOWNLOAD.replace(':ROid', data?.ROid),
    data: data?.file,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true,
    isMultipart: true
  });
}

/**
 * View PO File Upload
 * @param {*} data
 * @returns
 */
export function viewPoFileUpload(data) {
  return postAPI({
    url: VIEW_PO_DETAILS_UPLOAD.replace(':ROid', data?.ROid),
    data: data?.file,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true,
    isMultipart: true
  });
}

/**
 * Put RO Details By Id
 * @param {*} data
 * @returns
 */
export function putRoDetails(data) {
  return putAPI({
    url: GET_RO_DETAILS.replace(':ROid', data?.ROid),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
