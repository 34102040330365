import { Box, FormLabel, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import MatRadio from '@mui/material/Radio';
import React from 'react';
import { Controller } from 'react-hook-form';

const Radio = ({ name, control, label, options, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '14.5px'
            }
          }}>
          {label && <FormLabel>{label}</FormLabel>}
          <RadioGroup value={field.value} onChange={field.onChange}>
            {options?.map(({ label, value }, index) => (
              <FormControlLabel
                key={`${label + '-' + index}`}
                label={label}
                control={<MatRadio value={value} />}
              />
            ))}
          </RadioGroup>
        </Box>
      )}
      {...rest}
    />
  );
};

export default Radio;
