import { Box } from '@mui/material';
import React from 'react';
import { Spinner, TableDataRenderer, Tooltip } from '../../../../common';
import styles from '../styles';

const ViewTransferTable = ({ list = [], pageData, pageClick, isLoading, page }) => {
  const columnDefs = [
    {
      field: 'SKU',
      headerName: 'SKU'
    },
    { field: 'WID', headerName: 'WID' },
    {
      field: 'ProductDescription',
      headerName: 'ProductDescription',
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.ProductDescription} dataLength={20} />;
      }
    },
    { field: 'TransferQty', headerName: 'Transfer Qty' },
    {
      field: 'SourceArea',
      headerName: 'Source Area',
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.SourceArea} dataLength={20} />;
      }
    },
    {
      field: 'SourceShelf',
      headerName: 'SourceShelf',
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.SourceShelf} dataLength={20} />;
      }
    },
    {
      field: 'DestinationArea',
      headerName: 'Destination Area',
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.DestinationArea} dataLength={20} />;
      }
    },
    {
      field: 'DestinationShelf',
      headerName: 'Destination Shelf',
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.DestinationShelf} dataLength={20} />;
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
    </Box>
  );
};

export default ViewTransferTable;
