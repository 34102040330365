import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { Pagination, Spinner, TableDataRenderer } from '../../../../common';
import styles from '../styles';

const AllOrdersTable = ({ list = [], pageData, pageClick, isLoading, page }) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const navigate = useNavigate();

  const columnDefs = [
    {
      field: 'OrderId',
      headerName: 'Order Id',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box sx={styles.orderIDWrapper}>
            <Box sx={styles.orderId} onClick={() => navigate(`${data?.OrderId?.id.substring(3)}`)}>
              {data?.OrderId?.id}
            </Box>
            <Box sx={styles.shipmentId}>{data?.OrderId?.shipmentId}</Box>
          </Box>
        );
      }
    },
    { field: 'OrderStatus', headerName: 'Order Status', flex: isWebView ? 1 : 0 },
    { field: 'Channel', headerName: 'Channel', flex: isWebView ? 1 : 0 },
    { field: 'Pickup', headerName: 'Pickup', flex: isWebView ? 1 : 0 },
    {
      field: 'PickZone',
      headerName: 'Pick Zone',
      flex: isWebView ? 1 : 0
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => pageClick(e, pageNumber)}
        />
      </Box>
    </Box>
  );
};

export default AllOrdersTable;
