import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { getIrnVerifyDetails, irnReturnItemsList } from '../../../../redux/APICaller/irnAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { CTARenderer, DescriptionBar, InputRenderer, MainContent, Spinner } from '../../../common';
import PrintWID from './PrintWID';
import ReceiveIRNFilters from './ReceiveIRNFilters';
import ReceiveIRNTable from './ReceiveIRNTable';
import VerifyIRNId from './VerifyIRNID';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      sku_code: SKUCode,
      qty_to_receive: QtytoReceive,
      product_description: ProductDescription,
      po_quantity: POQuantity,
      received_quantity: ReceivedQuantity,
      outstanding_quantity: OutstandingQty,
      ...rest
    }) => ({
      SKUCode,
      QtytoReceive,
      ProductDescription,
      OutstandingQty,
      POQuantity,
      ReceivedQuantity,
      ...rest
    })
  );

  return newArrayOfObj;
};

const ReceiveIRNComplete = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [roId, setRoId] = useState('');
  const [roIdOverview, setRoIdOverview] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [list, setList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [poNotFound, setPoNotFound] = useState(false);
  const [identifyingCode, setIdentifyingCode] = useState(params?.wid);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [finalReceiveDone, setFinalReceiveDone] = useState([]);

  const dataFound = Object.keys(roIdOverview)?.length;

  const fetchPoDetails = async (irn_id) => {
    setLoading(true);
    const res = await getIrnVerifyDetails({ irn_id });
    if (res?.status === SUCCESS) {
      if (poNotFound) setPoNotFound(false);
      setRoIdOverview(res?.data);
      setLoading(false);
    } else {
      setRoIdOverview({});
      setPoNotFound(true);
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    if (params?.irn) {
      setRoId(params?.irn);
    }
  }, [params?.irn]);

  useEffect(() => {
    if (roId) {
      setPoNotFound(false);
      fetchPoDetails(roId);
    }
  }, [roId]);

  const APICALL = async (page = 1, isBtnClick) => {
    setIsLoading(true);
    let customPayload = {
      irnId: roIdOverview?.irn_id,
      irn_item_part_id: params?.irnId,
      item_quality_check_id: params?.qcId
    };

    if (page !== 'reset') {
      if (identifyingCode) customPayload.identifying_code = identifyingCode;
    } else {
      customPayload = {
        irnId: roIdOverview?.irn_id,
        irn_item_part_id: params?.irnId,
        item_quality_check_id: params?.qcId
      };
    }
    const res = await irnReturnItemsList({
      page: page === 'reset' ? 0 : page - 1,
      // limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        if (isBtnClick) setIsFilterApplied(true);
      } else {
        setIsFilterApplied(false);
      }

      if (page === 1 || page === 'reset') {
        setList([
          {
            page: 1,
            array: dataKeyNameChange(res?.data?.list)
          }
        ]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    if (roId && dataFound) APICALL();
  }, [roId, dataFound]);

  const clearFilter = () => {
    setIdentifyingCode();
    setIsFilterApplied();
  };

  const subtractionOutstandingQty = (id, qty) => {
    const newTableRows = tableRows?.map((item) => {
      if (item?.wid === id) {
        const newItem = {
          ...item,
          OutstandingQty: item?.OutstandingQty - Number(qty),
          ReceivedQuantity: Number(item?.ReceivedQuantity) + Number(qty)
        };
        return newItem;
      } else return item;
    });
    setTableRows(newTableRows);
  };

  return (
    <MainContent heading="Receive Invoice Receiving Note">
      <Box sx={styles.dataWrapper}>
        <Box sx={styles.printLayoutWrapper}>
          <VerifyIRNId
            customStyles={styles.verifyIRN}
            clearFilter={clearFilter}
            roId={roId}
            setIrnId={setRoId}
            irnNotFound={poNotFound}
            defaultIrnId={params?.irn}
          />
          {dataFound && finalReceiveDone?.length ? (
            <PrintWID customStyles={styles.widPrint} list={finalReceiveDone} />
          ) : null}
        </Box>
        {!poNotFound && loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            <DescriptionBar
              label="Information for IRN ID:"
              value={roId}
              customStyles={{ justifyContent: 'space-between' }}>
              {dataFound && finalReceiveDone?.length ? (
                <Box sx={styles.statusVerified}>
                  <CheckCircleOutlineIcon sx={styles.verifiedIcon} />
                  Inventory Received
                </Box>
              ) : null}
            </DescriptionBar>
            <DescriptionBar
              renderOptions={[
                {
                  label: 'PO Number:',
                  value: `${roIdOverview?.po_number}`
                },
                {
                  label: 'ACN ID:',
                  value: `${roIdOverview?.acn_no}`
                },
                {
                  label: 'Supplier Invoice Number:',
                  value: `${roIdOverview?.supplier_invoice_number}`
                },
                {
                  label: 'Warehouse Code:',
                  value: `${roIdOverview?.warehouse_code}`
                }
              ]}
            />
            <ReceiveIRNFilters
              disabled={params?.wid}
              isFilterApplied={isFilterApplied}
              identifyingCode={identifyingCode}
              setIdentifyingCode={setIdentifyingCode}
              APICALL={APICALL}
            />
            <ReceiveIRNTable
              subtractionOutstandingQty={subtractionOutstandingQty}
              setFinalReceiveDone={setFinalReceiveDone}
              irnId={roIdOverview?.irn_id}
              warehouseId={roIdOverview?.warehouse_id}
              InputRenderer={InputRenderer}
              actionButton={CTARenderer}
              list={tableRows}
              pageData={pageData}
              pageClick={(e, pageNumber) => {
                apiCallWithPage(pageNumber);
              }}
              isLoading={isLoading}
              page={pageData.currentPage}
              qcId={params?.qcId}
            />
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default ReceiveIRNComplete;
