import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { getWarehouseList } from '../../../../../redux/APICaller';
import { WarehouseListConvertor } from '../../../../../constants/apiDataChanger';
import { getPutterList } from '../../../../../redux/APICaller/putawaysAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, Dropdown, Input, NewDate } from '../../../../common';
import styles from '../styles';

const dataKeyNameChangeForPutter = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(({ putter_id: value, putter_name: label, ...rest }) => ({
    value,
    label,
    ...rest
  }));
  return newArrayOfObj;
};

const FindPutlistFilters = ({ data, setData, onFind, isFindDisable, csvPayload }) => {
  const dispatch = useDispatch();
  const [warehouseList, setWarehouseList] = useState([]);
  const [PutterList, setPutterList] = useState([]);

  const { control, reset, watch } = useForm({
    defaultValues: {
      putlistID: '',
      warehouse: '',
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      putlistStatus: '',
      searchByWID: '',
      searchPutter: ''
    },
    mode: 'all'
  });

  const putlistID = watch('putlistID');
  const warehouse = watch('warehouse');
  const putlistStatus = watch('putlistStatus');
  const searchByWID = watch('searchByWID');
  const searchPutter = watch('searchPutter');
  const wid = watch('wid');
  const supplier = watch('supplier');
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  useEffect(() => {
    if (startDate) {
      if (startDate) setData({ ...data, startDate: startDate });
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      if (endDate) setData({ ...data, endDate: endDate });
    }
  }, [endDate]);

  useEffect(() => {
    setData({ ...data, putlistID: putlistID });
  }, [putlistID]);

  useEffect(() => {
    setData({ ...data, putlistStatus: putlistStatus });
  }, [putlistStatus]);

  useEffect(() => {
    setData({ ...data, searchByWID: searchByWID });
  }, [searchByWID]);

  useEffect(() => {
    setData({ ...data, supplier: supplier });
  }, [supplier]);

  useEffect(() => {
    setData({ ...data, warehouse: warehouse });
  }, [warehouse]);

  useEffect(() => {
    setData({ ...data, wid: wid });
  }, [wid]);

  useEffect(() => {
    setData({ ...data, searchPutter: searchPutter });
  }, [searchPutter]);

  const getPutterDropdownData = async () => {
    const res = await getPutterList({});
    if (res?.status == SUCCESS) {
      setPutterList(dataKeyNameChangeForPutter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const getWarehouseDropdownData = async () => {
    const res = await getWarehouseList({});
    if (res?.status == SUCCESS) {
      setWarehouseList(WarehouseListConvertor(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    getPutterDropdownData();
    getWarehouseDropdownData();
  }, []);

  // let isDisable = false;
  // const handleDownloadCsv = (url) => {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.click();
  //   link.remove();
  // };

  // const csvDownload = async () => {
  //   const customPayload = {};
  // customPayload.date_from =
  //   csvPayload?.startDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
  // customPayload.date_to = csvPayload?.endDate || moment().format('YYYY-MM-DD');
  // if (csvPayload.warehouse) customPayload.warehouse_id = csvPayload.warehouse;
  // if (csvPayload.putlistID) customPayload.putlist_code = csvPayload.putlistID;
  // if (csvPayload.startDate) customPayload.startDate = csvPayload.startDate;
  // if (csvPayload.endDate) customPayload.endDate = csvPayload.endDate;
  // if (csvPayload.putlistStatus) customPayload.putlist_status = csvPayload.putlistStatus;
  // if (csvPayload.searchByWID) customPayload.wid_or_sku = csvPayload.searchByWID;
  // if (csvPayload.searchPutter) customPayload.putter_id = csvPayload?.searchPutter;
  //   const res = await apicall({
  //     ...customPayload
  //   });
  //   if (res.status === SUCCESS) {
  //     handleDownloadCsv(res?.data?.url);
  //   } else {
  //     dispatch(showToastMessage({ messageType: ERROR }));
  //   }
  // };

  return (
    <Box sx={styles.filterWrapper}>
      <Box sx={styles.input}>
        <Input label="Putlist ID" name="putlistID" control={control} placeholder="Enter Putlist" />
      </Box>
      <Box sx={styles.input}>
        <Dropdown label="Warehouse" name="warehouse" control={control} options={warehouseList} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="Start Date" name="startDate" control={control} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="End Date" name="endDate" control={control} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Putlist Status"
          name="putlistStatus"
          control={control}
          options={[
            { label: 'Created', value: 'Created' },
            { label: 'Pending', value: 'Pending' },
            { label: 'In process', value: 'In process' },
            { label: 'Completed', value: 'Completed' }
          ]}
        />
      </Box>
      <Box sx={styles.input}>
        <Input
          label="Search By WID/SKU"
          name="searchByWID"
          control={control}
          placeholder="Enter WID/SKU"
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Search Putter"
          name="searchPutter"
          control={control}
          options={PutterList}
        />
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button
          label="Find"
          customStyles={styles.actionButton}
          onClick={() => onFind()}
          disabled={isFindDisable}
        />
        <Button
          label="Reset"
          customStyles={styles.actionButton}
          disabled={isFindDisable}
          onClick={() => {
            reset();
            onFind('reset');
          }}
        />
      </Box>
      {/* <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon sx={styles.download} />
      </Box> */}
    </Box>
  );
};

export default FindPutlistFilters;
