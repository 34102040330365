import Box from '@mui/material/Box';
import React from 'react';
import { Pagination, Spinner, TableDataRenderer } from '../../../../common';
import styles from '../styles';

const ViewConsignmentTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  purchaseOrder
}) => {
  const columnDefs = [
    {
      field: 'ProductInfo',
      headerName: 'Product Info',
      minWidth: 260,
      cellRenderer: ({ data: { ProductInfo } }) => {
        return purchaseOrder({
          productDescription: ProductInfo?.product_description,
          skuCode: ProductInfo?.sku_code,
          brandSkuCode: ProductInfo?.brand_sku_code,
          portalSkuCode: ProductInfo?.portal_sku_code,
          asinCode: ProductInfo?.asin_code,
          fsnCode: ProductInfo?.fsn_code
        });
      }
    },
    { field: 'ScheduledQty', headerName: 'Scheduled Qty' },
    {
      field: 'ReceivedQty/DockRejectedQty',
      headerName: 'ReceivedQty / DockRejectedQty',
      minWidth: 280
    },
    { field: 'ProductCategory', headerName: 'Product Category' },
    { field: 'Brand', headerName: 'Brand' },
    { field: 'IRN', headerName: 'IRN' },
    { field: 'IRNStatus', headerName: 'IRN Status' },
    { field: 'Totalvalue', headerName: 'Total value' }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <TableDataRenderer list={list} columnDefs={columnDefs} rowHeight={150} />
        )}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default ViewConsignmentTable;
