import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { WarehouseListConvertor } from '../../../../../constants/apiDataChanger';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { getWarehouseList } from '../../../../../redux/APICaller';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Dropdown } from '../../../../common';
import styles from '../styles';

const CreateTransferFilter = ({ setShowTransferList, setFilterData, filterData }) => {
  const dispatch = useDispatch();
  const [warehouseList, setWarehouseListList] = useState([]);
  const { control, watch } = useForm({
    defaultValues: {
      sourceWarehouse: '',
      destinationWarehouse: ''
    },
    mode: 'all'
  });

  const getWarehouseDropdownData = async () => {
    const res = await getWarehouseList({});
    if (res?.status == SUCCESS) {
      setWarehouseListList(WarehouseListConvertor(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    getWarehouseDropdownData();
  }, []);

  useEffect(() => {
    if (watch('sourceWarehouse') && watch('destinationWarehouse')) {
      setFilterData({
        ...filterData,
        sourceWarehouse: watch('sourceWarehouse'),
        destinationWarehouse: watch('destinationWarehouse')
      });
      setShowTransferList(true);
    }
  }, [watch('sourceWarehouse'), watch('destinationWarehouse')]);

  return (
    <Box sx={styles.filterWrapper}>
      <Box sx={styles.input}>
        <Dropdown
          label="Source Warehouse"
          name="sourceWarehouse"
          control={control}
          options={warehouseList}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Destination Warehouse"
          name="destinationWarehouse"
          control={control}
          options={warehouseList}
        />
      </Box>
      {/* <Box sx={styles.buttonWrapper}>
        <Button label="Proceed" customStyles={styles.actionButton} />
      </Box> */}
    </Box>
  );
};

export default CreateTransferFilter;
