import { Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, DescriptionBar, MainContent } from '../../../../../common';
import styles from '../../styles';
import PickingDetailsTable from './PickingDetailsTable';

const PickingDetails = () => {
  const { id } = useParams();
  return (
    <MainContent heading="Picking details" description={`Pick list ID: ${id}`}>
      <DescriptionBar
        renderOptions={[
          {
            label: 'Picking status:',
            value: 'Pending'
          },
          {
            label: 'Created on:',
            value: '25 May 2023 05:47 PM'
          },
          {
            label: 'Zone:',
            value: 'Zone 1'
          },
          {
            label: 'Total orders:',
            value: '6'
          },
          {
            label: 'Warehouse Code:',
            value: 'G5 whse 1'
          }
        ]}
      />
      <Box sx={styles.confirmPicking}>
        <Button label="Print Pick List" />
        <Button label="Confirm picking" />
      </Box>
      <PickingDetailsTable />
    </MainContent>
  );
};

export default PickingDetails;
