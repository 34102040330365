import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import React from 'react';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { roItemDetailsCSVDownload } from '../../../../../redux/APICaller/inboundReturnOrderAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import styles from '../styles';

const ViewReturnOrderFilters = ({ Outstanding, Ordered, roId }) => {
  const dispatch = useDispatch();
  let isDisable = false;

  const handleDownloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  };

  const csvDownload = async () => {
    const customPayload = { ROid: roId };

    const res = await roItemDetailsCSVDownload({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      handleDownloadCsv(res?.data?.url);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  return (
    <Box sx={styles.dropDownWrapper}>
      <Box sx={styles.filterDataWrapper}>
        <Box sx={styles.filterHeading}>Return Order Items</Box>
        <Box sx={styles.filterData}>
          <Box sx={styles.contentBox}>
            Ordered: <Box component="span">{Ordered}</Box>
          </Box>
          <Box sx={styles.contentBox}>
            Outstanding: <Box component="span">{Outstanding}</Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon
          sx={styles.download}
          onClick={async () => {
            if (!isDisable) {
              isDisable = true;
              await csvDownload();
              isDisable = false;
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ViewReturnOrderFilters;
