import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Bar, Button, ErrorMessage, Input } from '../../../../common';
import styles from '../styles';

const TransferID = ({ setTransferID, transferIDNotFound, defaultPutlistId }) => {
  const { control, watch } = useForm({
    defaultValues: {
      transferId: defaultPutlistId
    }
  });

  const transferID = watch('transferId');

  const findClickHandler = () => {
    setTransferID(transferID);
  };

  return (
    <Bar heading="Put Away Transfer">
      <Box sx={styles.viewPurchaseOrder}>
        <Box>
          <Input
            disabled={defaultPutlistId}
            name="transferId"
            label="Transfer ID"
            control={control}
            placeholder="Enter Transfer ID"
          />
        </Box>
        <Box sx={styles.findButton}>
          <Button
            label="Find"
            customStyles={styles.actionButton}
            disabled={!transferID?.length || defaultPutlistId}
            onClick={findClickHandler}
          />
        </Box>
      </Box>
      {transferIDNotFound && (
        <Box sx={{ marginBottom: '-10px' }}>
          <ErrorMessage errorMsg="Please provide a valid Transfer ID" />
        </Box>
      )}
    </Bar>
  );
};

export default TransferID;
