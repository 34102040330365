import { PUTAWAYS_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI, putAPI } from '../../helpers/API';

const {
  GET_COMPLETE_PUT_LIST,
  PUT_COMPLETE_PUTLIST,
  GET_PUT_AWAY_TRANSFER_LIST,
  GET_VERIFY_SHELF_CODE,
  GET_PUTTER_LIST,
  PUT_CREATE_PUTAWAY_LIST,
  POST_FIND_PUTAWAY_LIST,
  GET_PUTLIST_LIST,
  GET_PUTLIST_DETAILS
} = PUTAWAYS_PATHS;

/**
 * PutList List
 * @param {*} data
 * @returns
 */
export function getCompleteputList(data) {
  return getAPI({
    url: GET_COMPLETE_PUT_LIST.replace(':putlistID', data?.putlistID),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Put Complete Put List
 * @param {*} data
 * @returns
 */
export function putCompleteputList(data) {
  return putAPI({
    url: PUT_COMPLETE_PUTLIST.replace(':putlistID', data?.putlistID),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Put Away Transfer List
 * @param {*} data
 * @returns
 */
export function getPutAwayTransferList(data) {
  return getAPI({
    url: GET_PUT_AWAY_TRANSFER_LIST.replace(':transferID', data?.transferID),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Verify Shelf Code
 * @param {*} data
 * @returns
 */
export function verifyShelfCode(data) {
  return getAPI({
    url: GET_VERIFY_SHELF_CODE.replace(':shelfCode', data?.shelfCode),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get Putter List
 * @param {*} data
 * @returns
 */
export function getPutterList(data) {
  return getAPI({
    url: GET_PUTTER_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Create Put Away List
 * @param {*} data
 * @returns
 */
export function createPutAwayList(data) {
  return putAPI({
    url: PUT_CREATE_PUTAWAY_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Find PutAway List
 * @param {*} data
 * @returns
 */
export function findPutAwayList(data) {
  return postAPI({
    url: POST_FIND_PUTAWAY_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Putlist Item List
 * @param {*} data
 * @returns
 */
export function putlistItemsList(data) {
  return getAPI({
    url: GET_PUTLIST_LIST.replace(':putlistId', data?.putlist_code),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get PutList Details By Id
 * @param {*} data
 * @returns
 */
export function getPutlistDetails(data) {
  return getAPI({
    url: GET_PUTLIST_DETAILS.replace(':putlistId', data?.putlistId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
