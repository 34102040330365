const styles = {
  wrapper: {
    margin: {
      xs: '0 2%',
      sm: '0'
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    gap: '40px',
    backgroundColor: 'white'
  },

  heading: {
    fontSize: {
      xs: '28px',
      sm: '32px'
    },
    fontWeight: 400,
    userSelect: 'none',
    textAlign: 'center'
  },

  image: {
    height: {
      xs: '200px',
      sm: '300px'
    }
  },

  linkBox: {
    marginTop: '1.5em',
    textAlign: {
      xs: 'center',
      sm: 'left'
    }
  },

  linkWrapper: {
    textDecoration: 'none',
    fontWeight: 500,
    color: 'rgb(34, 34, 34,0.8)'
  },

  link: {
    fontSize: '18px',
    transition: '1s',
    border: '1px solid rgb(34, 34, 34,0.5)',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#0858cf',
      color: 'white',
      border: 'none',
      textDecoration: 'underline'
    }
  }
};

export default styles;
