const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },

  image: {
    height: {
      xs: '150px',
      sm: '200px'
    }
  },

  heading: {
    fontSize: {
      xs: '32px',
      sm: '36px'
    },
    fontWeight: 200,
    lineHeight: '34px',
    userSelect: 'none',
    color: '#272020'
  },

  description: {
    fontSize: {
      xs: '16px',
      sm: '18px'
    },
    userSelect: 'none',
    wordSpacing: '1px',
    color: '#737578',
    marginTop: '-15px'
  }
};

export default styles;
