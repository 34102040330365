import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectAuthStore } from '../../../redux/authSlice';
import DropDown from '../../common/FormComponents/Dropdown';
import CustomPopover from '../../common/PopOver';
import styles from './styles';

const activePaths = ['/create-so', '/all-orders', '/pick', '/pack', '/manifest', '/put-back'];

const isStringInArray = (targetString, stringArray) => {
  // Check if the array includes the target string
  return stringArray.includes(targetString);
};

const Navbar = ({ showStepper, setShowStepper, yms, warehouse, orderType }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authStore = useSelector(selectAuthStore);
  const navigate = useNavigate();
  const { control, watch } = useForm({
    defaultValues: {
      yms,
      warehouse,
      orderType
    },
    mode: 'all'
  });

  const warehouseValue = watch('warehouse');
  const orderTypeValue = watch('orderType');

  useEffect(() => {
    if (warehouseValue && localStorage.getItem('facility_id') !== warehouseValue) {
      // dispatch(
      //   setWarehouseLocationAndType({
      //     warehouseLocation: warehouseValue
      //   })
      // );
      localStorage.setItem('facility_id', warehouseValue);
      window?.location?.reload(false);
    }
  }, [warehouseValue]);

  useEffect(() => {
    if (orderTypeValue && localStorage.getItem('order_type') !== orderTypeValue) {
      localStorage.setItem('order_type', orderTypeValue);
      window?.location?.reload(false);
    }
  }, [orderTypeValue]);

  /**
   * Function to open link in new Tab
   * @param {*} url
   */
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Box sx={styles.navBarWrapper}>
      <Box sx={styles.navBarIcon}>
        <IconButton onClick={() => setShowStepper(!showStepper)}>
          <MenuIcon sx={styles.menuIcon} />
        </IconButton>
        <Box
          onClick={() => navigate('/')}
          component="img"
          src="/losung360New.png"
          alt="losung logo"
          sx={styles.navBarLogo}
        />
        <Box sx={styles.mobileDropDown}>
          <CustomPopover icon={<ApartmentOutlinedIcon />}>
            <Box sx={styles.mobileDropdown} component="form">
              <Box>
                <DropDown
                  name="yms"
                  label="YMS"
                  disabled={authStore?.applicationTypeList?.length === 1 ? true : false}
                  control={control}
                  options={authStore?.applicationTypeList}
                />
              </Box>
              <Box>
                <DropDown
                  name="warehouse"
                  label="Warehouse"
                  disabled={authStore?.warehouseLocationList?.length === 1 ? true : false}
                  control={control}
                  options={authStore?.warehouseLocationList}
                />
              </Box>
              {/* <Box>
                <DropDown
                  name="orderType"
                  label="Order Type"
                  disabled={
                    authStore?.orderTypeList?.length === 1 ||
                    !isStringInArray(location?.pathname, activePaths)
                      ? true
                      : false
                  }
                  control={control}
                  options={authStore?.orderTypeList}
                />
              </Box> */}
            </Box>
          </CustomPopover>
        </Box>
        <Box sx={styles.dropDownWrapper}>
          <Box sx={styles.dropDown}>
            <DropDown
              name="yms"
              disabled={authStore?.applicationTypeList?.length === 1 ? true : false}
              control={control}
              options={authStore?.applicationTypeList}
            />
          </Box>
          <Box sx={styles.dropDown}>
            <DropDown
              name="warehouse"
              disabled={authStore?.warehouseLocationList?.length === 1 ? true : false}
              control={control}
              options={authStore?.warehouseLocationList}
            />
          </Box>
          {/* <Box sx={styles.dropDown}>
            <DropDown
              name="orderType"
              disabled={
                authStore?.orderTypeList?.length === 1 ||
                !isStringInArray(location?.pathname, activePaths)
                  ? true
                  : false
              }
              control={control}
              options={authStore?.orderTypeList}
            />
          </Box> */}
        </Box>
      </Box>
      <Box sx={styles.iconWrapper}>
        <Box
          onClick={() =>
            openInNewTab(
              'https://docs.google.com/forms/d/e/1FAIpQLScTqIlzjEwAxSxPKvkcP8l9vKYuvCZibFa1P0h9v3dpKFzglA/viewform'
            )
          }
          sx={styles.feedbackButtonWeb}>
          Your Feedback
        </Box>
        <IconButton>
          <SettingsIcon sx={styles.icon} />
        </IconButton>
        <IconButton>
          <NotificationsIcon sx={styles.icon} />
        </IconButton>
        <CustomPopover
          wrapperStyles={styles.mobilePopper}
          icon={<MoreVertOutlinedIcon sx={styles.icon} />}>
          <Box
            sx={styles.logOutWrapper}
            onClick={() =>
              openInNewTab(
                'https://docs.google.com/forms/d/e/1FAIpQLScTqIlzjEwAxSxPKvkcP8l9vKYuvCZibFa1P0h9v3dpKFzglA/viewform'
              )
            }>
            Your Feedback
          </Box>
          <Box
            onClick={() => {
              window.location.reload();
              localStorage.clear();
            }}
            sx={styles.logOutWrapper}>
            Log Out
          </Box>
        </CustomPopover>
        <CustomPopover wrapperStyles={styles.webPopper} icon={<ExitToAppIcon sx={styles.icon} />}>
          <Box
            onClick={() => {
              window.location.reload();
              localStorage.clear();
            }}
            sx={styles.logOutWrapper}>
            Log Out
          </Box>
        </CustomPopover>
      </Box>
      {/* <Box sx={styles.iconWrapper}>
        <IconButton>
          <SettingsIcon sx={styles.icon} />
        </IconButton>
        <IconButton>
          <NotificationsIcon sx={styles.icon} />
        </IconButton>
        <CustomPopover icon={<ExitToAppIcon sx={styles.icon} />}>
          <Box
            onClick={() => {
              window.location.reload();
              localStorage.clear();
            }}
            sx={styles.logOutWrapper}>
            Log Out
          </Box>
        </CustomPopover>
      </Box> */}
    </Box>
  );
};

export default Navbar;
