const styles = {
  dataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },

  readOnlyCards: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: {
      xs: '20px',
      md: '35px'
    },
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    justifyContent: 'space-between'
  },

  viewPurchaseOrder: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: {
      xs: 'flex-start',
      sm: 'center'
    },
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    gap: {
      xs: '0',
      sm: '20px'
    }
  },

  buttonWrapper: {
    display: 'flex',
    gap: '15px',
    marginTop: '1.5em'
  },

  findButton: {
    marginTop: {
      xs: '1.2em',
      sm: '1.6em'
    }
  },

  actionButton: {
    height: {
      xs: '34px',
      sm: '38px'
    }
  },

  detailsContent: {
    fontWeight: 500
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },

  lcanId: {
    cursor: 'pointer',
    transition: 'all 200ms ease',
    color: 'custom.blue',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  toolTip: {
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '300px',
    wordSpacing: 1.2
  },

  address: {
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '&:hover': {
      color: '#0858cf'
    }
  },

  dropDownWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '15px',
    marginBottom: '15px',
    position: 'relative',
    backgroundColor: '#F0F1F2',
    padding: '15px 16px'
  },

  input: {
    width: {
      xs: '100%',
      sm: '44.6%',
      md: '17.8%'
    }
  },

  downloadWrapper: {
    position: {
      xs: 'static',
      md: 'absolute'
    },
    bottom: 8,
    right: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    padding: '4px',
    borderRadius: '5px',
    marginTop: '18px',
    height: {
      xs: '32px',
      sm: '34px'
    },
    width: {
      xs: '34px',
      sm: '38px'
    }
  },

  download: {
    transition: 'all 400ms ease',
    cursor: 'pointer',
    color: 'white'
  },

  tableWrapper: {
    width: '100%',
    height: 470,
    overflowY: 'auto',
    fontFamily: "'Poppins', sans-serif",
    '& .ag-header-cell-text': {
      fontWeight: 500,
      fontSize: '16px'
    },
    '& .ag-cell': {
      fontSize: '14.5px',
      display: 'flex',
      alignItems: 'center'
    }
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%'
  },

  filterDataWrapper: {
    display: 'flex',
    gap: '25px',
    alignItems: 'center'
  },

  filterHeading: {
    fontSize: '18px',
    fontWeight: 500,
    textDecoration: 'underline',
    userSelect: 'none'
  },

  filterData: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    userSelect: 'none'
  },

  contentBox: {
    transition: 'all 400ms ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },

  content: {
    padding: '0.2em 0.4em',
    border: '1px solid',
    borderColor: 'custom.labelBlack',
    borderRadius: '5px',
    cursor: 'pointer',
    color: 'custom.blue'
  },

  formsWrapper: {
    display: 'flex',
    gap: '1.5em',
    flexDirection: {
      xs: 'column',
      md: 'row'
    }
  },

  formsBox: {
    width: '100%'
  },

  forms: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    rowGap: {
      xs: '34px',
      sm: '21px'
    },
    columnGap: '6.6%',
    zIndex: 100,
    '& .input-wrapper': {
      width: '46.7%'
    }
  },

  uploadInput: {
    width: '46.7%'
  },

  button: {
    width: {
      xs: '100%',
      md: '15%'
    },
    marginLeft: {
      xs: 0,
      md: '2em'
    }
  },

  selectReasons: {
    fontSize: '16px',
    fontWeight: 500,
    textDecoration: 'underline'
  },

  shipmentType: {
    fontSize: {
      xs: '16px',
      sm: '18px'
    },
    marginBottom: '1em',
    fontWeight: 500
  },

  modalWrapper: {
    border: '1px solid black',
    marginTop: '0.5em'
  },

  modalData: {
    display: 'flex'
  },

  modalLabel: {
    width: '50%',
    padding: '16px 8px',
    borderRight: '1px solid'
  },

  modalComponent: {
    width: '50%',
    padding: '16px 8px'
  }
};

export default styles;
