import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CategoryListConverter, WarehouseListConvertor } from '../../../constants/apiDataChanger';
import { BAD_REQUEST, SUCCESS } from '../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../constants/constants';
import { createManualACN } from '../../../constants/formData';
import useWindowsDimensions from '../../../hooks/useWindowDimensions';
import { getCategoryList, getWarehouseList } from '../../../redux/APICaller';
import { createNewConsignment, getDetailsByPoNumber } from '../../../redux/APICaller/gateInAPIs';
import { showToastMessage } from '../../../redux/showMessageSlice';
import { Button, DynamicAutocomplete, MainContent } from '../../common';
import Input from '../../common/FormComponents/Input';
import ACNDataRenderer from './ACNDataRenderer';
import styles from './styles';

const CreateManualACN = () => {
  const dispatch = useDispatch();
  const [iscreated, setIscreated] = useState('');
  const [fieldsData, setFieldsData] = useState(createManualACN);
  const [invoiceNumber, setInvoiceNumber] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseListWithConsType, setWarehouseListWithConsType] = useState([]);

  const [{ width }] = useWindowsDimensions();

  const isMobile = width < 600;

  const {
    control,
    trigger,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      plannedEDA: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
      consignmentType: '',
      brand: '',
      poNumber: '',
      shipFromWarehouse: '',
      shipToWarehouse: '',
      invoiceNo: '',
      category: '',
      enterNumberOfUnit: '',
      enterNumberOfPackage: '',
      channel: '',
      locationCode: ''
    }
  });

  const getCategoryDropdownData = async () => {
    const res = await getCategoryList({});
    if (res?.status === SUCCESS) {
      const tempFields = [...fieldsData];
      tempFields[8].options = CategoryListConverter(res?.data);
      setFieldsData(tempFields);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  const consignmentType = watch('consignmentType');
  useEffect(() => {
    if (consignmentType === 'Inbound') {
      const tempFields = [...fieldsData];
      tempFields[1].isHide = false;
      tempFields[2].isHide = false;

      tempFields[3].type = 'input';
      tempFields[4].type = 'input';

      tempFields[7].disabled = true;
      tempFields[7].type = 'input';

      tempFields[8].disabled = true;
      tempFields[8].type = 'input';

      tempFields[10].isHide = true;

      tempFields[4].options = warehouseList;
      // tempFields[4].disabled = false;

      setFieldsData(tempFields);
    } else if (consignmentType === 'Outbound') {
      if (!fieldsData[8].options?.length) getCategoryDropdownData();
      if (!warehouseListWithConsType.length) getWarehouseDropdownData('outbound');

      const tempFields = [...fieldsData];
      tempFields[7].disabled = false;
      tempFields[7].type = 'dropDown';

      tempFields[8].disabled = false;
      tempFields[8].type = 'dropDown';

      tempFields[10].isHide = false;

      tempFields[1].isHide = true;
      tempFields[2].isHide = true;

      tempFields[3].options = warehouseList;
      tempFields[3].disabled = true;
      tempFields[4].disabled = true;

      setFieldsData(tempFields);
    }
    reset({
      plannedEDA: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
      consignmentType: consignmentType,
      brand: '',
      poNumber: '',
      shipFromWarehouse: '',
      shipToWarehouse: '',
      invoiceNo: '',
      category: '',
      enterNumberOfUnit: '',
      enterNumberOfPackage: '',
      channel: '',
      locationCode: ''
    });
  }, [consignmentType]);

  const getWarehouseDropdownDataWithSupplierId = async (supplier_id, warehouse_id) => {
    const res = await getWarehouseList({ supplier_id });
    if (res?.status === SUCCESS) {
      const tempFields = [...fieldsData];
      tempFields[3].options = WarehouseListConvertor(res?.data);
      // tempFields[3].disabled = false;
      setFieldsData(tempFields);
      setValue('shipFromWarehouse', warehouse_id);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  const getDetailsByPo = async (poNumber) => {
    const res = await getDetailsByPoNumber({ poNumber });
    if (res?.status === SUCCESS) {
      setValue('brand', res?.data?.supplier_name);
      setValue(
        'shipToWarehouse',
        WarehouseListConvertor([res?.data?.ship_to_warehouse_details])[0].label
      );
      setValue('toWarehouse', res?.data?.ship_to_warehouse_details?.warehouse_id);
      setValue('locationCode', res?.data?.location_code);
      setValue('channel', res?.data?.channel_name);
      setValue('category', res?.data?.category_name);
      // if (res?.data?.supplier_id)
      //   getWarehouseDropdownDataWithSupplierId(
      //     res?.data?.supplier_id,
      //     res?.data?.ship_from_warehouse_details?.warehouse_id
      //   );
      setValue(
        'shipFromWarehouse',
        WarehouseListConvertor([res?.data?.ship_from_warehouse_details])[0].label
      );
      setValue('fromWarehouse', res?.data?.ship_from_warehouse_details?.warehouse_id);
      setValue('supplier_id', res?.data?.supplier_id);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  const poNumber = watch('poNumber');
  useEffect(() => {
    if (poNumber) {
      getDetailsByPo(poNumber?.label);
    }
  }, [poNumber]);

  useEffect(() => {
    if (invoiceNumber?.length) {
      const tempFields = [...fieldsData];
      tempFields[10].isRequired = false;
      setFieldsData(tempFields);
    }
  }, [invoiceNumber]);

  const shipFromWarehouse = watch('shipFromWarehouse');
  useEffect(() => {
    if (shipFromWarehouse) {
      const tempFields = [...fieldsData];
      tempFields[4].options.forEach((item) => {
        if (item?.value === shipFromWarehouse) {
          item.disabled = true;
        } else if (item?.disabled) {
          item.disabled = false;
        }
      });
      setFieldsData(tempFields);
    }
  }, [shipFromWarehouse]);

  const clearDisableDropItem = () => {
    const tempFields = [...fieldsData];
    tempFields[4]?.options?.forEach((item) => {
      if (item?.disabled) {
        item.disabled = false;
      }
    });
    tempFields[3]?.options?.forEach((item) => {
      if (item?.disabled) {
        item.disabled = false;
      }
    });
  };

  const shipToWarehouse = watch('shipToWarehouse');
  useEffect(() => {
    if (shipToWarehouse) {
      const tempFields = [...fieldsData];
      tempFields[3].options.forEach((item) => {
        if (item?.value === shipToWarehouse) {
          item.disabled = true;
          setValue('locationCode', item?.location_code);
        } else if (item?.disabled) {
          item.disabled = false;
        }
      });
      setFieldsData(tempFields);
    }
  }, [shipToWarehouse]);

  const getWarehouseDropdownData = async (consignment_type) => {
    const payload = {};
    if (consignment_type) payload.consignment_type = consignment_type;

    const res = await getWarehouseList(payload);
    if (res?.status === SUCCESS) {
      if (consignment_type) {
        setWarehouseListWithConsType(WarehouseListConvertor(res?.data));
        const tempFields = [...fieldsData];
        tempFields[4].options = WarehouseListConvertor(res?.data);
        tempFields[4].disabled = false;
        setFieldsData(tempFields);
      } else setWarehouseList(WarehouseListConvertor(res?.data));
      // const tempFields = [...fieldsData];
      // tempFields[4].options = WarehouseListConvertor(res?.data);
      // if (consignmentType === 'Outbound')
      //   tempFields[5].options = WarehouseListConvertor(res?.data);
      // setFieldsData(tempFields);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  useEffect(() => {
    // getWarehouseDropdownData();
  }, []);

  /**
   * Functions to call API and Validate fields
   */
  const finishExecution = async (res) => {
    if (res) {
      const payload = {
        consignment_type: watch('consignmentType'),
        ship_from_warehouse: watch('shipFromWarehouse'),
        ship_to_warehouse: watch('shipToWarehouse'),
        planned_time_of_arrival: watch('plannedEDA'),
        category_id: watch('category'),
        no_of_units: watch('enterNumberOfUnit'),
        no_of_packages: watch('enterNumberOfPackage'),
        channel: watch('channel')
      };

      if (watch('consignmentType') === 'Inbound') {
        payload.supplier_id = watch('supplier_id');
        payload.po_number = watch('poNumber')?.label;
        payload.ship_from_warehouse = watch('fromWarehouse');
        payload.ship_to_warehouse = watch('toWarehouse');
      } else {
        // payload.channel = watch('channel');
        payload.invoice_no = invoiceNumber;
      }

      const res = await createNewConsignment({
        ...payload
      });
      if (res?.status === SUCCESS) {
        clearDisableDropItem();
        reset();
        setInvoiceNumber([]);
        setIscreated(res?.data?.acn_no);
        dispatch(
          showToastMessage({
            messageType: SUCCESS_MSG,
            message: res?.data?.message || `ACN ID ${res?.data?.acn_no} created successfully`
          })
        );
      } else {
        setIscreated('');
        if (res?.status === BAD_REQUEST)
          dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
        else dispatch(showToastMessage({ messageType: ERROR }));
      }
    }
  };

  const triggerValidation = () => {
    trigger().then((res) => finishExecution(res));
  };

  return (
    <MainContent
      heading="Consignment Creation"
      description="Add below details to create manual ACN ID">
      <Box component="form">
        <Box sx={styles.wrapper}>
          <Box>
            {fieldsData?.map(
              (
                {
                  disabled,
                  maxLength,
                  name,
                  label,
                  type,
                  options,
                  isRequired = false,
                  inputType = 'text',
                  isHide
                },
                index
              ) => {
                if (isHide) return null;
                return (
                  <Box key={`${name + '-' + index}`} sx={styles.tableWrapper}>
                    <Box sx={styles.label}>{label}</Box>
                    <Box sx={styles.inputWrapper}>
                      {type === 'input' ? (
                        <Box sx={styles.input}>
                          <Input
                            disabled={disabled}
                            maxLength={maxLength}
                            name={name}
                            placeholder={label}
                            type={inputType}
                            control={control}
                            rules={{
                              required: {
                                value: isRequired,
                                message: 'This is required field'
                              }
                            }}
                          />
                          {name === 'invoiceNo' && (
                            <Box
                              sx={{
                                ...styles.addMore,
                                cursor: watch('invoiceNo') === '' ? 'not-allowed' : 'pointer',
                                color: watch('invoiceNo') === '' ? 'custom.gray' : 'custom.blue'
                              }}
                              onClick={() => {
                                getValues('invoiceNo') !== '' &&
                                  setInvoiceNumber((prevv) => [...prevv, getValues('invoiceNo')]);
                                setValue('invoiceNo', '');
                              }}>
                              {isMobile ? <AddIcon /> : '+Add Invoice'}
                            </Box>
                          )}
                          {name === 'invoiceNo' && invoiceNumber.length !== 0 && (
                            <>
                              <Box sx={styles.invoiceNumberWrapper}>Invoice Numbers</Box>
                              <Box sx={styles.invoiceNumberBox}>
                                {invoiceNumber?.map((item, index) => (
                                  <Box key={`${item + '-' + index}`} sx={styles.invoiceNumber}>
                                    <Box component="span" sx={styles.number}>
                                      {index + 1}.
                                    </Box>
                                    {item}
                                  </Box>
                                ))}
                              </Box>
                            </>
                          )}
                        </Box>
                      ) : type === 'dynamicAutocomplete' ? (
                        <Box sx={styles.input}>
                          <DynamicAutocomplete
                            name={name}
                            control={control}
                            errors={errors}
                            rules={{
                              required: {
                                value: isRequired,
                                message: 'This is required field'
                              }
                            }}
                            path={'/order/purchase/numbers/:searchStr'}
                          />
                        </Box>
                      ) : (
                        <ACNDataRenderer
                          label={label}
                          options={options}
                          type={type}
                          disabled={disabled}
                          maxLength={maxLength}
                          name={name}
                          control={control}
                          inputType={inputType}
                          rules={{
                            required: {
                              value: isRequired,
                              message: 'This is required field'
                            }
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
          <Box sx={styles.buttonWrapper}>
            <Button label="Create ACN ID" onClick={triggerValidation} />
          </Box>
          {iscreated && (
            <Box sx={styles.description}>
              <CheckCircleOutlineIcon sx={styles.icon} />
              ACN ID
              <Box sx={styles.acnId}>{`${iscreated}`}</Box>
              created successfully
            </Box>
          )}
        </Box>
      </Box>
    </MainContent>
  );
};

export default CreateManualACN;
