/* eslint-disable */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SUCCESS } from '../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../constants/constants';
import { acceptConsignment } from '../../../redux/APICaller/slipCheckAPIs';
import { showToastMessage } from '../../../redux/showMessageSlice';
import Button from '../Button';
import DropDown from '../FormComponents/Dropdown';
import Input from '../FormComponents/Input';
import styles from './styles';

const SlipCheck = ({ data, setData }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isStatusUpdated, setIsStatusUpdated] = useState(false);
  const [actionType, setActionType] = useState('');

  const fieldsData = [
    {
      name: 'acnId',
      label: 'ACN ID',
      type: 'input',
      isRequired: true,
      disabled: true
    },
    {
      name: 'plannedItemDetails',
      label: 'Planned item details',
      disabled: true,
      type: 'input'
    },
    {
      name: 'actualArrivalDateNtime',
      label: 'Actual arrival date & time',
      type: 'input',
      disabled: true,
      isRequired: true
    },
    {
      name: 'consignmentStatus',
      label: 'Consignment status',
      type: 'input',
      disabled: true,
      isRequired: true
    },
    {
      name: 'consignmentType',
      label: 'Consignment type',
      type: 'input',
      disabled: true,
      isRequired: true
    },
    {
      name: 'trailerNumber',
      label: 'Trailer number',
      type: 'input',
      isRequired: true,
      maxLength: 10
    },
    {
      name: 'trailerDriverName',
      label: 'Trailer driver name',
      type: 'input',
      isRequired: true,
      maxLength: 30
    },
    {
      name: 'driverPhoneNumber',
      label: 'Driver phone number',
      type: 'input',
      isRequired: true,
      maxLength: 10,
      inputType: 'number'
    },
    {
      isRequired: true,
      name: 'isTrailerSealIntact',
      label: 'Is trailer container seal intact?',
      type: 'dropDown',
      options: [
        {
          label: 'Yes',
          value: 'true'
        },
        {
          label: 'No',
          value: 'false'
        }
      ]
    }
  ];

  const {
    control,
    setValue,
    trigger,
    watch,
    formState: { isValid }
  } = useForm({
    mode: 'all'
  });

  useEffect(() => {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (key === 'plannedItemDetails') {
            setValue(
              'plannedItemDetails',
              `Qty: ${data.no_of_units} , Packages: ${data.no_of_packages}`
            );
          }
          if (data[key]) setValue(key, data[key]);
        }
      }
    }
  }, [data]);

  const APICALL = async (payload) => {
    setLoading(true);
    setActionType(payload.gate_in_accept ? 'accept' : 'reject');

    const res = await acceptConsignment({
      ...payload
    });
    if (res?.status === SUCCESS) {
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
      setIsStatusUpdated(true);
    } else {
      setLoading(false);
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const finishExecution = (res, action) => {
    if (res) {
      APICALL({
        gate_in_accept: action === 'accept',
        acn_id: data?.id || watch('acnId'),
        trailer_number: watch('trailerNumber'),
        trailer_driver_number: Number(watch('driverPhoneNumber')),
        seal_intact: watch('isTrailerSealIntact') === 'true' ? true : false,
        driver_name: watch('trailerDriverName')
      });
    }
  };

  const triggerValidation = (action) => {
    trigger().then((res) => finishExecution(res, action));
  };

  const showPhoneError = (value) => {
    return value?.length !== 10 ? 'Phone number should be of 10 digits' : true;
  };

  return (
    <Box component="form">
      <Box sx={styles.wrapper}>
        <Box>
          {fieldsData?.map(
            (
              {
                maxLength,
                name,
                label,
                type,
                options,
                isRequired = false,
                value = '',
                disabled = false,
                inputType = 'text'
              },
              index
            ) => {
              return (
                <Box key={`${name + '-' + index}`} sx={styles.tableWrapper}>
                  <Box sx={styles.label}>{label}</Box>
                  <Box sx={styles.inputWrapper}>
                    {type === 'input' ? (
                      <Box sx={styles.input}>
                        <Input
                          maxLength={maxLength}
                          name={name}
                          placeholder={label}
                          type={inputType}
                          control={control}
                          rules={{
                            required: {
                              value: isRequired,
                              message: 'This is required field'
                            },
                            validate: {
                              isTenDigits: (value) =>
                                name !== 'driverPhoneNumber' ? true : showPhoneError(value)
                            }
                          }}
                          disabled={disabled || loading}
                        />
                      </Box>
                    ) : (
                      <Box sx={styles.input}>
                        <DropDown
                          name={name}
                          placeholder={label}
                          control={control}
                          options={options}
                          rules={{
                            required: {
                              value: isRequired,
                              message: 'This is required field'
                            }
                          }}
                          disabled={disabled || loading}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            }
          )}
        </Box>
        <Box sx={styles.buttonWrapper}>
          {!isStatusUpdated ? (
            <>
              <Button
                label="Accept"
                onClick={() => triggerValidation('accept')}
                disabled={loading}
              />
              <Button
                label="Reject"
                onClick={() => triggerValidation('reject')}
                disabled={!isValid || loading}
              />
            </>
          ) : null}
          {/* () => setOpen(true) */}
          {isStatusUpdated && actionType === 'accept' ? (
            <Button
              label="Allow Gate In"
              onClick={() => {
                navigate(`/search-acn/allow-gate-in/${params?.acnId}`);
              }}
            />
          ) : null}
        </Box>
        {isStatusUpdated && (
          <Box sx={styles.description}>
            <CheckCircleOutlineIcon sx={styles.icon} />
            Consignment status updated
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SlipCheck;
