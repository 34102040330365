import { Box } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DescriptionBar, MainContent } from '../../../common';
import TabPanel from '../../../common/Tabs';
import CreatePick from './CreatePick';
import PendingPickListFilters from './PendingPickList/Filters';
import PendingPickListTable from './PendingPickList/Table';
import { irnItemsList } from '../../../../redux/APICaller/irnAPIs';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { ERROR } from '../../../../constants/constants';
import styles from './styles';

const labels = ['Create', 'Pending pick lists', 'Completed'];

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      sku_code: SKUCode,
      qty_to_receive: QtytoReceive,
      product_description: ProductDescription,
      po_quantity: POQuantity,
      received_quantity: ReceivedQuantity,
      outstanding_quantity: OutstandingQty,
      ...rest
    }) => ({
      SKUCode,
      QtytoReceive,
      ProductDescription,
      OutstandingQty,
      POQuantity,
      ReceivedQuantity,
      ...rest
    })
  );

  return newArrayOfObj;
};

const rowData = [
  {
    id: 1,
    Priority: '123',
    Shipments: '214214',
    Units: '123',
    Pickzone: '234214',
    Pickupslot: '1242523',
    Carrier: '!@45',
    Channel: '234532'
  },
  {
    id: 2,
    Priority: '123',
    Shipments: '214214',
    Units: '123',
    Pickzone: '234214',
    Pickupslot: '1242523',
    Carrier: '!@45',
    Channel: '234532'
  }
];

const Pick = () => {
  const [currentTab, setCurrentTab] = useState(0);

  // CREATE
  const dispatch = useDispatch();

  const [unitSelectedList, setUnitSelectedList] = useState([]);
  const [totalShipments, setTotalShipments] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    let totalShipments = 0;
    let totalUnits = 0;
    unitSelectedList?.forEach((row) => {
      totalShipments += Number(row?.Shipments);
      totalUnits += Number(row?.Units);
    });
    setTotalShipments(totalShipments);
    setTotalUnits(totalUnits);
  }, [unitSelectedList?.length]);

  const APICALL = async (page = 1) => {
    setIsLoading(true);
    let customPayload = {};

    const res = await irnItemsList({
      page: page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status !== SUCCESS) {
      if (page === 1) {
        setList([
          {
            page: 1,
            array: dataKeyNameChange(rowData || res?.data?.list)
          }
        ]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page });
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  const pageClick = useCallback((e, pageNumber) => {
    apiCallWithPage(pageNumber);
  }, []);

  const createPickList = () => {
    const ids = unitSelectedList?.map((row) => {
      return row?.id;
    });
  };

  const tabChange = (newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    setUnitSelectedList([]);
  }, [currentTab]);

  return (
    <MainContent heading="Pick">
      <TabPanel
        tabChange={tabChange}
        labels={labels}
        tabPanels={[
          <Box key={0}>
            <Box sx={styles.createPickList}>
              <Box sx={styles.unitsSelected}>{unitSelectedList?.length} units selected</Box>
              <Button
                disabled={!unitSelectedList?.length}
                label="Create Picklist"
                onClick={() => createPickList()}
              />
            </Box>
            <CreatePick
              setUnitSelectedList={setUnitSelectedList}
              list={tableRows}
              pageData={pageData}
              pageClick={pageClick}
              isLoading={isLoading}
              page={pageData.currentPage}
            />
            <DescriptionBar
              customStylesMulti={{ marginTop: '20px' }}
              renderOptions={[
                { label: 'Total shipments:', value: totalShipments },
                { label: 'Total units:', value: totalUnits }
              ]}
            />
          </Box>,
          <Box key={1}>
            <PendingPickListFilters />
            <PendingPickListTable />
          </Box>
        ]}
      />
    </MainContent>
  );
};

export default Pick;
