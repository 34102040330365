const styles = {
  wrapper: {
    width: '80%',
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0px 1.5em 0',
    gap: '15px',
    backgroundColor: 'white',
    boxShadow: {
      xs: 'none',
      sm: '0 0.8px 0 rgba(0,0,0,0.15)'
    }
  },

  image: {
    width: {
      xs: '110px',
      sm: '130px'
    },
    opacity: 0.9,
    objectFit: 'cover'
  },

  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px #ddd solid',
    borderRadius: '5px',
    gap: {
      xs: '8px',
      sm: '12px'
    },
    width: {
      xs: 320,
      md: 350
    },
    height: 'min-content',
    backgroundColor: 'white',
    padding: {
      xs: '35px 24px',
      sm: '20px 26px'
    }
  },

  heading: {
    fontSize: {
      xs: '22px',
      sm: '26px'
    },
    fontWeight: 500,
    letterSpacing: 1.2,
    userSelect: 'none'
  },

  enterEmail: {
    marginBottom: '1em',
    fontSize: {
      xs: '11px',
      sm: '13px'
    }
  },

  inputWrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    gap: {
      xs: '1px',
      sm: '2px'
    }
  },

  label: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    fontWeight: 500,
    '& .asterstik': {
      fontSize: '15px',
      color: 'red',
      marginLeft: '2px'
    }
  },

  inputBase: {
    borderWidth: 1,
    height: '34px',

    '&.Mui-focused': {
      borderColor: '#F4A91A',
      boxShadow: '0px 0px 6px #F4A91A'
    },

    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    }
  },

  errorMessage: {
    fontSize: {
      xs: '10px',
      sm: '12px'
    },
    color: 'error.main'
  },

  button: {
    backgroundColor: '#F4A91A',
    height: '34px',
    color: 'white',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    opacity: 0.9,
    marginTop: {
      xs: '6px',
      sm: '10px'
    },
    '&:hover': {
      backgroundColor: '#F4A91A',
      color: 'white'
    }
  },

  forgotPasswordDescription: {
    marginTop: '-0.5em',
    border: '1px #ddd solid',
    backgroundColor: 'white',
    padding: {
      xs: '20px 16px',
      sm: '15px 20px'
    },
    width: {
      xs: 320,
      md: 350
    }
  },

  bottomHeading: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 400,
    userSelect: 'none'
  },

  bottomDescription: {
    fontSize: {
      xs: '11px',
      sm: '13px'
    },
    userSelect: 'none'
  },

  admin: {
    color: '#0066c0',
    cursor: 'pointer',
    '&:hover': {
      color: '#c45500',
      textDecoration: 'underline'
    }
  },

  backToLogin: {
    textAlign: 'center',
    marginTop: '12px',
    cursor: 'pointer',
    border: '1px solid',
    borderColor: 'custom.gray',
    padding: '2px 8px',
    '&:hover': {
      border: '1px solid',
      borderColor: 'custom.gray',
      backgroundColor: 'custom.blue',
      color: 'white'
    }
  },

  inputFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: '12px',
      sm: '16px'
    }
  },

  forgotPassword: {
    position: 'absolute',
    right: 0,
    color: '#0066c0',
    fontWeight: 400,
    cursor: 'pointer',
    fontSize: {
      xs: '11px',
      sm: '13px'
    },
    lineHeight: '19px',
    '&:hover': {
      color: '#c45500',
      textDecoration: 'underline'
    }
  },

  otpDescription: {
    fontSize: {
      xs: '11px',
      sm: '13px'
    },
    userSelect: 'none'
  },

  otpEmail: {
    fontWeight: 'bold'
  },

  description: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    color: 'rgb(34, 34, 34,0.8)'
  },

  dataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1em'
  },

  resendWrapper: {
    marginTop: '10px',
    textAlign: 'center'
  },

  resend: {
    color: '#0066c0',
    fontWeight: 500,
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '19px',
    userSelect: 'none'
  },

  timer: {
    color: 'rgb(34, 34, 34,1)',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    userSelect: 'none',
    textAlign: 'center',
    padding: '2px 8px'
  },

  time: {
    fontWeight: 500
  },

  resetLoginWrap: {
    textAlign: { xs: 'center', sm: 'left' },
    marginTop: { xs: '1em', sm: 0 }
  },

  signInDescription: {
    marginTop: '1em',
    fontSize: {
      xs: '10px',
      sm: '12px'
    },
    textAlign: 'center',
    fontWeight: 500,

    '& .links': {
      color: '#0066c0',
      fontWeight: 400,
      cursor: 'pointer',
      lineHeight: '19px',
      '&:hover': {
        color: '#c45500',
        textDecoration: 'underline'
      }
    }
  }
};

export default styles;
