export const searchConsigment = [
  {
    label: 'ACN ID',
    value: 'ACN ID'
  },
  {
    label: 'PO No.',
    value: 'PO No.'
  },
  {
    label: 'Invoice No.',
    value: 'Invoice No.'
  }
];

export const filterType = [
  {
    label: 'Inbound',
    value: 'Inbound'
  },
  {
    label: 'Outbound',
    value: 'Outbound'
  }
];

export const searchStatus = [
  {
    label: 'Trailor number',
    value: 'Trailor number'
  },
  // {
  //   label: 'Trailor status',
  //   value: 'Trailor status'
  // },
  {
    label: 'Trailor location',
    value: 'Trailor location'
  }
];
