const styles = {
  tableWrapper: {
    display: 'flex',
    border: '1px solid rgb(34, 34, 34,0.5)'
  },

  label: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 500,
    width: '50%',
    padding: {
      xs: '8px',
      sm: '10px'
    },
    borderRight: '2px solid rgb(34, 34, 34,0.5)'
  },

  inputWrapper: {
    width: '50%',
    margin: '8px 10px',
    marginBottom: '17px'
  },

  input: {
    position: 'relative'
  },

  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '1em'
  },

  icon: {
    fontSize: '25px',
    color: '#04AA6D'
  }
};

export default styles;
