export const generalDetails = [
  {
    label: 'SKU Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Brand SKU Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Product Description',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'ASIN',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'FSN',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Product Category',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Image URL',
    value: {
      type: 'link',
      value: ''
    }
  }
];

export const productAttributes = [
  {
    label: 'Color',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Modal',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'EAN',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'HSN',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Shelf Life',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Tax',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Brand',
    value: {
      type: 'read',
      value: ''
    }
  }
];

export const componentSkuDetails = [
  {
    label: 'SKU Type',
    value: {
      type: 'read',
      value: ''
    }
  }
];

export const productSpecs = [
  {
    label: 'Length',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Width',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Height',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Net Weight',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Volumetric Weight',
    value: {
      type: 'read',
      value: ''
    }
  }
];
