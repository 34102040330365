import { Box } from '@mui/material';
import printJS from 'print-js';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { printBarCode } from '../../../../../redux/APICaller/irnAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, TableDataRenderer } from '../../../../common';
import styles from '../styles';

const PrintWID = ({ customStyles, list = [] }) => {
  const dispatch = useDispatch();
  const [fileUrl, setFileUrl] = useState({});

  const printWidApi = async ({ irn_item_id, irn_item_part_id }) => {
    const res = await printBarCode({
      irn_item_id,
      irn_item_part_id
    });

    if (res?.status === SUCCESS) {
      printJS(decodeURIComponent(res?.data?.url));
      const keyName = irn_item_id || irn_item_part_id;
      setFileUrl({ ...fileUrl, [keyName]: decodeURIComponent(res?.data?.url) });
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const printWid = ({ irn_item_id, irn_item_part_id }) => {
    const keyName = irn_item_id || irn_item_part_id;

    if (fileUrl[keyName]) {
      printJS(fileUrl[keyName]);
    } else {
      printWidApi({ irn_item_id, irn_item_part_id });
    }
  };

  const columnDefs = [
    {
      field: 'inbound_remark',
      headerName: 'Inbound Remarks'
    },
    { field: 'move_to_zone', headerName: 'Move to Zone' },
    {
      field: 'qty_to_receive',
      headerName: 'Quantity'
    },
    {
      field: 'Print',
      headerName: 'Print',
      cellRenderer: ({ data }) => {
        if (data?.irn_item_id || data?.irn_item_part_id) {
          return <Button label={'Print WID'} onClick={() => printWid(data)} />;
        }
      }
    }
  ];

  return (
    <Box className="ag-theme-alpine" sx={{ ...styles.tableWrapper, ...customStyles, height: 300 }}>
      <TableDataRenderer list={list} columnDefs={columnDefs} />
    </Box>
  );
};

export default PrintWID;
