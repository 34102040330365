import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoutes = ({ children }) => {
  if (localStorage.getItem('wms_access_token') && localStorage.getItem('wms_access_roles')) {
    return children;
  } else {
    return <Navigate to="/sign-in" />;
  }
};

export default PrivateRoutes;
