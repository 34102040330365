import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../constants/apiStatusCodes';
import {
  ACN_ID,
  DOCK_RELEASE,
  INVOICE_NUMBER,
  PO_NO,
  TRAILOR_LOCATION,
  TRAILOR_NUMBER,
  TRAILOR_STATUS,
  TRUCK_NUMBER
} from '../../../../constants/commonLabels';
import { ERROR } from '../../../../constants/constants';
import {
  filterType,
  searchConsigment,
  searchStatus
} from '../../../../constants/filterOptionsData';
import { commonCsvDownload } from '../../../../redux/APICaller';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Dropdown, Input } from '../../../common';
import styles from '../styles';

const AllowGateOutFilters = ({ data, setData }) => {
  const [isSearchCalled, setIsSearchCalled] = useState(false);
  const [isSearchCalledByTrailer, setIsSearchCalledByTrailer] = useState(false);

  const dispatch = useDispatch();
  const { control, watch } = useForm();

  const searchConsigmentValue = watch('searchConsigment');
  const filterTypeValue = watch('filterType');
  const searchStatusValue = watch('searchStatus');
  const searchStr = watch('acnId');
  const brandValue = watch('filterBrand');
  const searchByTrailerDetails = watch('searchByTrailer');
  const searchByTrailersValue = watch('searchByTrailerValue');

  useEffect(() => {
    data.searchBy = searchConsigmentValue;
  }, [searchConsigmentValue]);

  useEffect(() => {
    if (brandValue) setData({ ...data, brandType: brandValue });
  }, [brandValue]);

  useEffect(() => {
    if (filterTypeValue) setData({ ...data, consgnType: filterTypeValue });
  }, [filterTypeValue]);

  useEffect(() => {
    data.searchByTrailer = searchByTrailerDetails;
  }, [searchByTrailerDetails]);

  useEffect(() => {
    if (searchStatusValue) setData({ ...data, consgnStatus: searchStatusValue });
  }, [searchStatusValue]);

  useEffect(() => {
    if (!searchStr && isSearchCalled) {
      setData({ ...data, searchStr: '' });
      setIsSearchCalled(false);
    }
  }, [searchStr, isSearchCalled]);

  useEffect(() => {
    if (!searchByTrailersValue && isSearchCalledByTrailer) {
      setData({ ...data, searchByTrailersValue: '' });
      setIsSearchCalledByTrailer(false);
    }
  }, [searchByTrailersValue, isSearchCalledByTrailer]);

  const handleDownloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  };

  const csvDownload = async () => {
    const customPayload = {
      trailer_status: DOCK_RELEASE
    };
    if (data.consgnType) customPayload.consignment_type = data.consgnType;
    if (data.consgnStatus) customPayload.consignment_status = data?.consgnStatus;
    if (data.brandType) customPayload.brand = data?.brandType;
    if (data.fromDate) customPayload.from_date = data?.fromDate;
    if (data.toDate) customPayload.to_date = data?.toDate;
    if (data.searchStr) {
      if (data.searchBy === ACN_ID) customPayload.acn_no = data?.searchStr;
      if (data.searchBy === PO_NO) customPayload.po_no = data?.searchStr;
      if (data.searchBy === INVOICE_NUMBER) customPayload.invoice_no = data?.searchStr;
    }
    if (data.searchByTrailersValue) {
      if (data.searchByTrailer === TRAILOR_NUMBER)
        customPayload.truck_no = data.searchByTrailersValue;
      if (data.searchByTrailer === TRAILOR_STATUS)
        customPayload.trailer_status = data?.searchByTrailersValue;
      if (data.searchByTrailer === TRAILOR_LOCATION)
        customPayload.trailer_location = data?.searchByTrailersValue;
    }
    const res = await commonCsvDownload({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      handleDownloadCsv(res?.data?.url);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  const label = searchConsigmentValue || ACN_ID;
  const label2 = searchByTrailerDetails || TRUCK_NUMBER;
  let isDisable = false;

  return (
    <Box sx={styles.dropDownWrapper}>
      <Box sx={styles.input}>
        <Dropdown
          label="Search consignment by"
          placeholder="Search consignment by"
          name="searchConsigment"
          control={control}
          options={searchConsigment}
        />
      </Box>
      <Box sx={styles.input}>
        <Input
          label={label}
          name="acnId"
          placeholder={`Enter your ${label?.toLowerCase()}`}
          control={control}
          disabled={!searchConsigmentValue}
          isSearch={searchStr && searchConsigmentValue}
          onSearch={() => {
            setData({ ...data, searchStr: watch('acnId') });
            setIsSearchCalled(true);
          }}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Filter Cosgn. Type"
          name="filterType"
          control={control}
          options={filterType}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Search by trailer detail"
          name="searchByTrailer"
          control={control}
          options={searchStatus}
        />
      </Box>
      <Box sx={styles.input}>
        <Input
          label={label2}
          name="searchByTrailerValue"
          placeholder={`Enter your ${label2}`}
          disabled={!searchByTrailerDetails}
          control={control}
          isSearch={searchByTrailerDetails && searchByTrailersValue}
          onSearch={() => {
            setData({ ...data, searchByTrailersValue: watch('searchByTrailerValue') });
            setIsSearchCalledByTrailer(true);
          }}
        />
      </Box>
      <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon
          sx={styles.download}
          onClick={async () => {
            if (!isDisable) {
              isDisable = true;
              await csvDownload();
              isDisable = false;
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default AllowGateOutFilters;
