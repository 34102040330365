import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Bar, Button, Input, ErrorMessage } from '../../../../common/index';
import styles from '../styles';

const VerifyConsignmentId = ({
  setIrnId,
  irnNotFound,
  defaultIrnId,
  clearFilter,
  customStyles
}) => {
  const { control, watch } = useForm({
    defaultValues: {
      verifyIrn: defaultIrnId
    }
  });

  const irnId = watch('verifyIrn');

  const findClickHandler = () => {
    clearFilter();
    setIrnId(irnId);
  };

  return (
    <Bar heading="Verify IRN for Receiving" customStyles={customStyles}>
      <Box sx={styles.viewPurchaseOrder}>
        <Box>
          <Input
            disabled={defaultIrnId}
            name="verifyIrn"
            label="Verify IRN ID"
            placeholder="Enter IRN ID"
            control={control}
          />
        </Box>
        <Box sx={styles.findButton}>
          <Button
            label="Get Details"
            customStyles={styles.actionButton}
            disabled={!irnId?.length || defaultIrnId}
            onClick={findClickHandler}
          />
        </Box>
      </Box>
      {irnNotFound && (
        <Box sx={{ marginBottom: '-10px' }}>
          <ErrorMessage errorMsg="Please provide a valid IRN ID" />
        </Box>
      )}
    </Bar>
  );
};

export default VerifyConsignmentId;
