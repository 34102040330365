import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { getIrnVerifyDetails, irnItemsList } from '../../../../redux/APICaller/irnAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { CTARenderer, DescriptionBar, MainContent, Spinner } from '../../../common';
import ReceiveIRNFilters from './ReceiveIRNFilters';
import ReceiveIRNTable from './ReceiveIRNTable';
import VerifyIRNId from './VerifyIRNID';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      sku_code: SKUCode,
      qty_to_receive: QtytoReceive,
      product_description: ProductDescription,
      po_quantity: POQuantity,
      received_quantity: ReceivedQuantity,
      outstanding_quantity: OutstandingQty,
      ...rest
    }) => ({
      SKUCode,
      QtytoReceive,
      ProductDescription,
      OutstandingQty,
      POQuantity,
      ReceivedQuantity,
      ...rest
    })
  );

  return newArrayOfObj;
};

const ReceiveIRN = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [roId, setRoId] = useState('');
  const [roIdOverview, setRoIdOverview] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [list, setList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [poNotFound, setPoNotFound] = useState(false);
  const [identifyingCode, setIdentifyingCode] = useState('');
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const dataFound = Object.keys(roIdOverview)?.length;

  const fetchPoDetails = async (irn_id) => {
    setLoading(true);
    const res = await getIrnVerifyDetails({ irn_id });
    if (res?.status === SUCCESS) {
      if (poNotFound) setPoNotFound(false);
      setRoIdOverview(res?.data);
      setLoading(false);
    } else {
      setRoIdOverview({});
      setPoNotFound(true);
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  useEffect(() => {
    if (params?.ro_no) {
      setRoId(params?.ro_no);
    }
  }, [params?.ro_no]);

  useEffect(() => {
    if (roId) {
      setPoNotFound(false);
      fetchPoDetails(roId);
    }
  }, [roId]);

  const APICALL = async (page = 1, isBtnClick) => {
    setIsLoading(true);
    let customPayload = { irnId: roIdOverview?.irn_id };

    if (page !== 'reset') {
      if (identifyingCode) customPayload.identifying_code = identifyingCode;
    } else {
      customPayload = { irnId: roIdOverview?.irn_id };
    }
    const res = await irnItemsList({
      page: page === 'reset' ? 0 : page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        if (isBtnClick) setIsFilterApplied(true);
      } else {
        setIsFilterApplied(false);
      }

      if (page === 1 || page === 'reset') {
        setList([
          {
            page: 1,
            array: dataKeyNameChange(res?.data?.list)
          }
        ]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    if (roId && roIdOverview?.irn_id) APICALL();
  }, [roIdOverview?.irn_id]);

  const clearFilter = () => {
    setIdentifyingCode();
    setIsFilterApplied();
  };

  return (
    <MainContent heading="Receive Invoice Receiving Note">
      <Box sx={styles.dataWrapper}>
        <VerifyIRNId
          clearFilter={clearFilter}
          roId={roId}
          setIrnId={setRoId}
          irnNotFound={poNotFound}
          defaultIrnId={params?.ro_no}
        />
        {!poNotFound && loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            <DescriptionBar label="Information for IRN ID:" value={roId} />
            <DescriptionBar
              renderOptions={[
                {
                  label: 'PO Number:',
                  value: `${roIdOverview?.po_number}`
                },
                {
                  label: 'ACN ID:',
                  value: `${roIdOverview?.acn_no}`
                },
                {
                  label: 'Supplier Invoice Number:',
                  value: `${roIdOverview?.supplier_invoice_number}`
                },
                {
                  label: 'Warehouse Code:',
                  value: `${roIdOverview?.warehouse_code}`
                }
              ]}
            />
            <ReceiveIRNFilters
              isFilterApplied={isFilterApplied}
              identifyingCode={identifyingCode}
              setIdentifyingCode={setIdentifyingCode}
              APICALL={APICALL}
            />
            <ReceiveIRNTable
              irnId={roIdOverview?.irn_id}
              actionButton={CTARenderer}
              list={tableRows}
              pageData={pageData}
              pageClick={(e, pageNumber) => {
                apiCallWithPage(pageNumber);
              }}
              isLoading={isLoading}
              page={pageData.currentPage}
            />
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default ReceiveIRN;
