import { amber } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

/**
 * Create a theme instance
 */

let theme = createTheme({
  palette: {
    primary: {
      main: '#055D7B'
    },
    secondary: amber,
    success: {
      main: '#77e457'
    },
    error: {
      main: '#fa5959'
    },
    custom: {
      background: '#fbfbfb',
      focusedBorder: '#0858cf',
      label: '#54575a',
      disabledLabel: '#d8d8d8',
      heading: '#231f20',
      connector: '#e8e9eb',
      disabled: '#888e8f',
      darkSilver: '#707070',
      scrollThumb: '#bbbec2',
      border: '#e6e6e6',
      orange: '#E77600',
      blue: '#077398',
      gray: '#697576',
      lightBlue: '#077398',
      darkBlue: '#055D7B',
      labelBlack: '#1F1D1F'
    }
  },

  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(', '),
    font: {
      openSans: {
        fontFamily: ['Open Sans', 'sans-serif'].join(', ')
      }
    }
  }
});

/**
 * Style Overrides
 */
theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // background: '#f0f0f0',
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '10px'
          },
          fontWeight: 400,
          color: theme.palette.custom.labelBlack,
          '&.Mui-focused': {
            fontWeight: 500,
            color: theme.palette.custom.labelBlack
          },
          transform: 'none'
        }
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          'label + &': {
            marginTop: '26px',
            [theme.breakpoints.down('sm')]: {
              marginTop: '18px'
            }
          },
          // height: '38px',
          boxSizing: 'border-box',
          borderRadius: 5,
          position: 'relative',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette.custom.gray,
          backgroundColor: 'white',
          fontSize: '16px',
          padding: '10px 12px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
            // height: '34px'
          },
          '&.Mui-error': {
            borderWidth: 2,
            borderColor: theme.palette.error.main
          },
          '&.Mui-focused': {
            borderWidth: 2,
            borderColor: theme.palette.custom.blue
          },
          '&.Mui-disabled': {
            borderColor: '#cacecf',
            cursor: 'not-allowed'
          }
        }
      }
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          color: theme.palette.error.main,
          fontWeight: 'bold',
          position: 'absolute',
          bottom: '-17px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '8px',
            bottom: '-14px'
          }
        }
      }
    }
  }
});

const customTypography = {};

theme = {
  ...theme,
  typography: {
    ...theme.typography,
    ...customTypography
  }
};

theme = responsiveFontSizes(theme);

export default theme;
