import LoadingButton from '@mui/lab/LoadingButton';
import { InputBase } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emptyEmail, inValidEmail } from '../../../../constants/errorMessage';
import { useNavigate } from 'react-router-dom';
import { selectAuthStore, setEmail, userForgotPasswordThunk } from '../../../../redux/authSlice';
import styles from '../styles';

const validateEmail = (mail) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);

const ForgotPassword = () => {
  const [error, setError] = useState({ email: '', password: '' });

  const dispatch = useDispatch();
  const authStore = useSelector(selectAuthStore);

  const navigate = useNavigate();

  const errorCheck = (formData) => {
    let isError = false;
    // Empty check
    if (!formData.get('email')) {
      setError((error) => {
        return { ...error, email: emptyEmail };
      });
      isError = true;
    } else {
      setError((error) => {
        return { ...error, email: '' };
      });
    }
    // Valid email check
    if (formData.get('email') && !validateEmail(formData.get('email'))) {
      setError((error) => {
        return { ...error, email: inValidEmail };
      });
      isError = true;
    } else {
      if (formData.get('email')) {
        setError((error) => {
          return { ...error, email: '' };
        });
        isError = false;
      }
    }
    return isError;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const isError = errorCheck(data);

    if (!isError) {
      dispatch(
        userForgotPasswordThunk({
          payload: {
            email_address: data.get('email')
          }
        })
      );
      dispatch(setEmail(data.get('email')));
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <Box component="img" src="./losung360New.png" sx={styles.image} loading="lazy" />
      </Box>
      <Box sx={styles.cardWrapper}>
        <Box sx={styles.heading}>Find your account</Box>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box sx={styles.enterEmail}>
            Enter the email address associated with your Losung account.
          </Box>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.label}>
              Email
              <Box component="span" className="asterstik">
                *
              </Box>
            </Box>
            <InputBase
              required
              fullWidth
              sx={styles.inputBase}
              id="email"
              label="Email"
              placeholder="Enter registered email"
              name="email"
              autoFocus
              error={!!error.email}
              helperText={error.email}
            />
            <Box sx={styles.errorMessage}>{error.email}</Box>
          </Box>
          <LoadingButton
            type="submit"
            fullWidth
            sx={{ ...styles.button, marginTop: '20px' }}
            loading={authStore.status === 'loading'}
            loadingPosition="start"
            variant="contained">
            <Box component="span">Continue</Box>
          </LoadingButton>
        </Box>
      </Box>
      <Box sx={styles.forgotPasswordDescription}>
        <Box sx={styles.bottomHeading}>Has your email address changed?</Box>
        <Box sx={styles.bottomDescription}>
          If you no longer use the e-mail address associated with your account, please contact{' '}
          <Box component="span" sx={styles.admin}>
            Administrator
          </Box>
        </Box>
        <Box onClick={() => navigate('/sign-in')} sx={styles.backToLogin}>
          Back to login
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
