const styles = {
  sampleDownload: {
    display: 'flex',
    alignItems: 'center',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    gap: '5px',
    marginBottom: '8px'
  },

  clickHere: {
    color: 'custom.blue',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  message: {
    marginTop: '6 px'
  },

  steps: {
    fontSize: '18px',
    fontWeight: 500
  },

  customWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  }
};

export default styles;
