import LoadingButton from '@mui/lab/LoadingButton';
import { InputBase } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { emptyOTP, enterSixDigit } from '../../../../constants/errorMessage';
import {
  selectAuthStore,
  userForgotPasswordThunk,
  userOtpVerifyThunk
} from '../../../../redux/authSlice';
import styles from '../styles';

const OtpVerification = () => {
  const [error, setError] = useState({ otp: '' });
  const [count, setCount] = useState(60);
  const [initiateCount, setInitiateCount] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const authStore = useSelector(selectAuthStore);

  useEffect(() => {
    const id = setInterval(() => {
      setCount((oldCount) => {
        if (oldCount <= 1) clearInterval(id);
        if (oldCount > 0) return oldCount - 1;
        return 0;
      });
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [initiateCount]);

  const errorCheck = (formData) => {
    let isError = false;
    // Empty check
    if (!formData.get('otp')) {
      setError((error) => {
        return { ...error, otp: emptyOTP };
      });
      isError = true;
    } else {
      setError((error) => {
        return { ...error, otp: '' };
      });
    }
    // Length Check
    if (formData.get('otp') && formData.get('otp').length !== 6) {
      setError((error) => {
        return { ...error, otp: enterSixDigit };
      });
      isError = true;
    } else {
      if (formData.get('otp')) {
        setError((error) => {
          return { ...error, otp: '' };
        });
        isError = false;
      }
    }
    return isError;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const isError = errorCheck(data);

    if (!isError) {
      dispatch(
        userOtpVerifyThunk({
          email_address: authStore?.email,
          otp: Number(data.get('otp'))
        })
      );
    }
  };

  const handleOtpResend = (count) => {
    if (count <= 0) {
      setCount(60);
      setInitiateCount(!initiateCount);
      dispatch(
        userForgotPasswordThunk({
          payload: {
            email_address: authStore?.email
          },
          isNextStep: false
        })
      );
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box component="img" src="./losung360New.png" sx={styles.image} loading="lazy" />
      <Box sx={styles.cardWrapper}>
        <Box sx={styles.heading}>Verification required</Box>
        <Box sx={styles.otpDescription}>
          To continue, complete this verification step. We have sent a One Time Password (OTP) to
          the email <Box component="span" sx={styles.otpEmail}>{` ${authStore.email}`}</Box>. Please
          enter it below.
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.label}>
              Enter OTP
              <Box component="span" className="asterstik">
                *
              </Box>
            </Box>
            <InputBase
              required
              fullWidth
              type="number"
              sx={styles.inputBase}
              disabled={!count}
              placeholder="Enter your OTP"
              name="otp"
              autoFocus
              error={!!error.otp}
              helperText={error.otp}
            />
            <Box sx={styles.errorMessage}>{error.otp}</Box>
          </Box>
          <Box sx={styles.dataWrapper}>
            {count ? (
              <Box sx={styles.timer}>
                Time left to enter OTP :{' '}
                <Box component="span" sx={styles.time}>{`00:${
                  count < 10 ? `0${count}` : count
                }`}</Box>
              </Box>
            ) : (
              <Box />
            )}
          </Box>
          <LoadingButton
            type="submit"
            fullWidth
            disabled={!count}
            sx={styles.button}
            loading={authStore.status === 'loading'}
            loadingPosition="center"
            variant="contained">
            Verify
          </LoadingButton>
          <Box sx={styles.resendWrapper}>
            <Box
              disabled={!!count}
              onClick={() => !count && handleOtpResend(count)}
              sx={{
                ...styles.resend,
                cursor: count ? 'not-allowed' : 'pointer',
                color: count ? 'custom.gray' : '#0066c0',
                '&:hover': {
                  color: count ? 'custom.gray' : '#c45500',
                  textDecoration: 'underline'
                }
              }}>
              Resend OTP
            </Box>
          </Box>
          <Box onClick={() => navigate('/sign-in')} sx={styles.backToLogin}>
            Back to login
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OtpVerification;
