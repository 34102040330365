const styles = {
  mainWrapper: {
    '& .MuiModal-backdrop': {
      backdropFilter: 'blur(3px)'
    }
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    gap: {
      xs: '14px',
      sm: '20px'
    },
    position: 'absolute',
    width: {
      xs: 350,
      sm: 550
    },
    height: 'min-content',
    top: '50%',
    left: '50%',
    padding: {
      xs: '12px 10px',
      sm: '20px 20px'
    },
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white'
  },

  heading: {
    fontSize: {
      xs: '16px',
      sm: '20px'
    },
    fontWeight: 500,
    textAlign: 'center'
  },

  iconWrapper: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: 'black'
  },

  closeIcon: {
    fontSize: {
      xs: '22px',
      sm: '25px'
    }
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },

  description: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    fontWeight: 400
  }
};

export default styles;
