import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { getIrnDetails } from '../../../../redux/APICaller/irnAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { DescriptionBar, MainContent, Spinner } from '../../../common';
import CreateIRNForm from './CreateIRNForm';
import VerifyConsignmentId from './VerifyConsignmentID';
import styles from './styles';

const CreateIRN = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [irnIdOverview, setIrnIdOverview] = useState({});
  const [poNotFound, setPoNotFound] = useState(false);
  const [consignmentVerifyed, setConsignmentVerifyed] = useState(false);
  const [ShowCreateIrn, setCreateIrnClicked] = useState(false);

  const dataFound = Object.keys(irnIdOverview)?.length;

  const fetchAcnDetails = async (acnId) => {
    setLoading(true);
    const res = await getIrnDetails({ acnId });
    if (res?.status === SUCCESS) {
      if (poNotFound) setPoNotFound(false);
      if (!consignmentVerifyed) setConsignmentVerifyed(true);
      setIrnIdOverview(res?.data);
      setLoading(false);
    } else {
      setIrnIdOverview({});
      setPoNotFound(true);
      setConsignmentVerifyed(false);
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  useEffect(() => {
    if (params?.po_no) {
      setOrderId(params?.po_no);
    }
  }, [params?.po_no]);

  useEffect(() => {
    if (orderId) {
      setPoNotFound(false);
      setConsignmentVerifyed(false);
      fetchAcnDetails(orderId);
    }
  }, [orderId]);

  return (
    <MainContent heading="Create Invoice Receiving Note">
      <Box sx={styles.dataWrapper}>
        <VerifyConsignmentId
          setOrderId={setOrderId}
          poNotFound={poNotFound}
          defaultPoId={params?.po_no}
          consignmentVerifyed={consignmentVerifyed}
          setCreateIrnClicked={setCreateIrnClicked}
        />
        {ShowCreateIrn ? (
          !poNotFound && loading ? (
            <Spinner />
          ) : dataFound ? (
            <>
              <DescriptionBar label="IRN Creation for Consignment ID:" value={orderId} />
              <CreateIRNForm irnIdOverview={irnIdOverview} />
            </>
          ) : null
        ) : null}
      </Box>
    </MainContent>
  );
};

export default CreateIRN;
