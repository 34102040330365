import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input, ErrorMessage } from '../../../../common';
import styles from '../styles';

const ReceiveIRNFilters = ({ identifyingCode, setIdentifyingCode, APICALL, isFilterApplied }) => {
  const [isInValidSKU, setIsInvalidSKU] = useState(false);

  const { control, watch, reset } = useForm({
    defaultValues: {
      identifyingCode: identifyingCode
    }
  });

  const identifyingCodeValue = watch('identifyingCode');

  useEffect(() => {
    setIdentifyingCode(identifyingCodeValue);
  }, [identifyingCodeValue]);

  return (
    <Box sx={{ ...styles.dropDownWrapper, paddingBottom: isInValidSKU ? '35px' : '15px' }}>
      <Box sx={styles.input}>
        <Input
          label="Filter by SKU/EAN/ASN/FSN"
          placeholder="Filter by SKU/EAN/ASN/FSN"
          name="identifyingCode"
          control={control}
        />
        {isInValidSKU && (
          <Box sx={{ position: 'absolute' }}>
            <ErrorMessage errorMsg="Invalid item details" />
          </Box>
        )}
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button
          disabled={!identifyingCodeValue}
          label="Apply"
          customStyles={styles.actionButton}
          onClick={() => {
            setIdentifyingCode(identifyingCodeValue);
            APICALL(1, 'btnClick');
          }}
        />
        <Button
          disabled={!isFilterApplied}
          label="Reset"
          customStyles={styles.actionButton}
          onClick={() => {
            setIdentifyingCode('');
            reset({ identifyingCode: '' });
            APICALL('reset', 'btnClick');
          }}
        />
      </Box>
      {/* <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon sx={styles.download} />
      </Box> */}
    </Box>
  );
};

export default ReceiveIRNFilters;
