import Box from '@mui/material/Box';
import React from 'react';
import './styles.css';

const Spinner = () => {
  return (
    <Box className="wrapper">
      <Box className="spinner"></Box>
    </Box>
  );
};

export default Spinner;
