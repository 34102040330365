import Box from '@mui/material/Box';
import React from 'react';
import { Pagination, Spinner, TableDataRenderer } from '../../../../common/index';
import styles from '../styles';

const FindReturnOrderTable = ({ list = [], pageData, pageClick, isLoading, page, roID, poID }) => {
  const columnDefs = [
    {
      field: 'ReturnOrderID',
      headerName: 'Return Order ID',
      cellRenderer: ({ data: cellData }) => roID(cellData.ReturnOrderID)
    },
    {
      field: 'IRNId',
      headerName: 'IRN ID'
    },
    {
      field: 'PONO',
      headerName: 'PO NO.',
      cellRenderer: ({ data: cellData }) => poID(cellData.PONO)
    },
    { field: 'ROType', headerName: 'RO Type' },
    { field: 'RODate', headerName: 'RO Date' },
    { field: 'Supplier', headerName: 'Supplier' },
    { field: 'SourceWarehouseCode', headerName: 'Source Warehouse Code' },
    { field: 'DestinationWarehouseCode', headerName: 'Destination Warehouse Code' },
    { field: 'TotalQty', headerName: 'Total Qty' }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default FindReturnOrderTable;
