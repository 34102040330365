import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { getViewProductData } from '../../../../redux/APICaller/browseProductAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { DescriptionBar, MainContent, ReadOnlyList, Spinner } from '../../../common';
import FindProductCode from './FindProductCode';
import {
  componentSkuDetails as componentSkuDetailsArr,
  generalDetails,
  productAttributes,
  productSpecs
} from './cardsData';
import styles from './styles';

const ViewProduct = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [skuID, setSkuID] = useState('');
  const [data, setData] = useState({});
  const [skuNotFound, setSkuNotFound] = useState(false);

  const dispatch = useDispatch();

  const keyValueMaker = (value, template, type) => {
    const tempTemplate = JSON.parse(JSON.stringify(template));
    Object.keys(value)?.forEach((key) => {
      if (type === 'general_details') {
        if (key === 'sku_code') tempTemplate[0].value.value = value[key];
        else if (key === 'brand_sku_code') tempTemplate[1].value.value = value[key];
        else if (key === 'product_description') tempTemplate[2].value.value = value[key];
        else if (key === 'asin') tempTemplate[3].value.value = value[key];
        else if (key === 'fsn') tempTemplate[4].value.value = value[key];
        else if (key === 'product_category') tempTemplate[5].value.value = value[key];
        else if (key === 'image_url') tempTemplate[6].value.value = value[key];
      } else if (type === 'product_attributes') {
        if (key === 'color') tempTemplate[0].value.value = value[key];
        else if (key === 'modal') tempTemplate[1].value.value = value[key];
        else if (key === 'ean') tempTemplate[2].value.value = value[key];
        else if (key === 'hsn') tempTemplate[3].value.value = value[key];
        else if (key === 'shelf_life') tempTemplate[4].value.value = value[key];
        else if (key === 'tax') tempTemplate[5].value.value = value[key];
        else if (key === 'brand') tempTemplate[6].value.value = value[key];
      } else if (type === 'product_spaces') {
        if (key === 'length') tempTemplate[0].value.value = value[key] + ' ' + value[key + '_unit'];
        else if (key === 'width')
          tempTemplate[1].value.value = value[key] + ' ' + value[key + '_unit'];
        else if (key === 'height')
          tempTemplate[2].value.value = value[key] + ' ' + value[key + '_unit'];
        else if (key === 'net_weight')
          tempTemplate[3].value.value = value[key] + ' ' + value[key + '_unit'];
        else if (key === 'volumetric_weight')
          tempTemplate[4].value.value = value[key] + ' ' + value[key + '_unit'];
      }
    });
    return tempTemplate;
  };

  const componentsKeyValueMaker = (componentSkuDetails) => {
    const tempTemplate = JSON.parse(JSON.stringify(componentSkuDetailsArr));

    if (componentSkuDetails?.sku_type) tempTemplate[0].value.value = componentSkuDetails?.sku_type;
    componentSkuDetails?.sku_type_list?.map((item, i) => {
      tempTemplate.push({
        label: `Component ${++i}`,
        value: {
          type: 'read',
          value: item?.component_name
        }
      });
      tempTemplate.push({
        label: 'Qty',
        value: {
          type: 'read',
          value: item?.quantity
        }
      });
    });

    return tempTemplate;
  };

  const dataFound = Object.keys(skuID)?.length;

  const fetchProductDetails = async (skuID) => {
    setLoading(true);
    const res = await getViewProductData({ sku_code: skuID });
    if (res?.status === SUCCESS) {
      if (skuNotFound) setSkuNotFound(false);
      const newRes = res?.data;
      newRes.general_details = keyValueMaker(
        res?.data?.general_details,
        generalDetails,
        'general_details'
      );
      newRes.product_attributes = keyValueMaker(
        res?.data?.product_attributes,
        productAttributes,
        'product_attributes'
      );
      newRes.product_spaces = keyValueMaker(
        res?.data?.product_spaces,
        productSpecs,
        'product_spaces'
      );
      newRes.component_sku_details = componentsKeyValueMaker(res?.data?.component_sku_details);
      setData(newRes);
      setLoading(false);
    } else {
      setData({});
      setSkuNotFound(true);
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  useEffect(() => {
    if (params?.sku_id) {
      setSkuID(params?.sku_id);
    }
  }, [params?.sku_id]);

  useEffect(() => {
    if (skuID) {
      setSkuNotFound(false);
      fetchProductDetails(skuID);
    }
  }, [skuID]);

  return (
    <MainContent heading="Product Listing">
      <Box sx={styles.dataWrapper}>
        <FindProductCode
          setSkuID={setSkuID}
          skuNotFound={skuNotFound}
          defaultSkuId={params?.sku_id}
        />
        {!skuNotFound && loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            {data?.general_details && (
              <DescriptionBar label="Information for Product Code:" value={skuID} />
            )}
            <Box sx={styles.readOnlyCards}>
              {data?.general_details && (
                <ReadOnlyList heading="General Details" fieldsData={data?.general_details} />
              )}
              {data?.product_attributes && (
                <ReadOnlyList heading="Product Attributes" fieldsData={data?.product_attributes} />
              )}
              {data?.component_sku_details && (
                <ReadOnlyList
                  heading="Component SKU Details"
                  fieldsData={data?.component_sku_details}
                />
              )}
              {data?.product_spaces && (
                <ReadOnlyList heading="Product Specs" fieldsData={data?.product_spaces} />
              )}
            </Box>
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default ViewProduct;
