const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    width: '100%',
    padding: '25px 20px',
    backgroundColor: 'white'
  },

  tableWrapper: {
    display: 'flex',
    border: '1px solid',
    borderCollapse: 'collapse',
    borderColor: 'custom.gray'
  },

  label: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 500,
    width: '50%',
    padding: {
      xs: '8px',
      sm: '10px'
    },
    borderRight: '1px solid',
    borderCollapse: 'collapse',
    borderColor: 'custom.gray'
  },

  inputWrapper: {
    width: '50%',
    margin: '8px 10px',
    marginBottom: '17px'
  },

  input: {
    width: {
      xs: '100%',
      md: '45%'
    }
  },

  buttonWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      sm: 'flex-start'
    }
  },

  gateIn: {
    fontSize: '16px',
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    ml: 1
  },

  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      sm: 'flex-start'
    },
    gap: '10px',
    fontSize: '14px',
    fontWeight: 400
  },

  icon: {
    fontSize: '25px',
    color: '#04AA6D'
  }
};

export default styles;
