const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    width: '100%',
    height: '100%',
    padding: '15px 10px',
    backgroundColor: 'white'
  },

  invoiceDetailsWeb: {
    position: 'absolute',
    color: '#2F759B',
    fontWeight: 500,
    display: {
      xs: 'none',
      md: 'block'
    },
    bottom: 3,
    right: -150,
    textDecoration: 'underline',
    fontSize: '14px',
    cursor: 'pointer'
  },

  invoiceDetailsMobile: {
    display: {
      xs: 'block',
      md: 'none'
    },
    textAlign: {
      xs: 'center',
      sm: 'left'
    },
    textDecoration: 'underline',
    fontSize: '14px',
    cursor: 'pointer'
  },

  tableWrapper: {
    display: 'flex',
    border: '1px solid rgb(34, 34, 34,0.5)'
  },

  label: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 500,
    width: '50%',
    padding: {
      xs: '8px',
      sm: '10px'
    },
    borderRight: '2px solid rgb(34, 34, 34,0.5)'
  },

  inputWrapper: {
    width: '50%',
    margin: '8px 10px',
    marginBottom: '17px'
  },

  input: {
    position: 'relative',
    width: {
      xs: '100%',
      md: '45%'
    }
  },

  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      sm: 'flex-start'
    }
  },

  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      sm: 'flex-start'
    },
    gap: '10px',
    fontSize: '14px',
    fontWeight: 400
  },

  invoiceDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: {
      xs: '15px',
      sm: '20px'
    },
    padding: {
      xs: '10px 18px',
      sm: '15px 22px'
    },
    width: {
      xs: '280px',
      sm: '360px'
    }
  },

  invoiceHeading: {
    textAlign: 'center',
    fontSize: {
      xs: '16px',
      sm: '18px'
    },
    textDecoration: 'underline',
    userSelect: 'none',
    fontWeight: 'bold'
  },

  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: '15px',
      sm: '20px'
    }
  },

  detailsHeading: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 500,
    textDecoration: 'underline',
    userSelect: 'none'
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '1em'
  },

  singleDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: {
      xs: '14px',
      sm: '16px'
    }
  },

  icon: {
    fontSize: '25px',
    color: '#04AA6D'
  },

  customPopoverHeight: {
    '& .MuiPopover-paper': {
      height: {
        xs: '500px',
        sm: '630px'
      }
    }
  }
};

export default styles;
