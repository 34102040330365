import { Box } from '@mui/material';
import printJS from 'print-js';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { createPutAwayList, getPutterList } from '../../../../redux/APICaller/putawaysAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Button, DescriptionBar, Dropdown, MainContent, ShowMessage } from '../../../common';
import CreatePutlistFilters from './CreatePutlistFilters';
import CreatePutlistTable from './CreatePutlistTable';
import styles from './styles';

const list = [
  {
    SNO: '01'
  },
  {
    SNO: '02'
  },
  {
    SNO: '03'
  },
  {
    SNO: '04'
  },
  {
    SNO: '05'
  }
];

const dataKeyNameChangeForPutter = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(({ putter_id: value, putter_name: label, ...rest }) => ({
    value,
    label,
    ...rest
  }));
  return newArrayOfObj;
};

const CreatePutlist = () => {
  const dispatch = useDispatch();
  const [PutterList, setPutterList] = useState([]);
  const [showPutList, setShowPutList] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [showWidError, setShowWidError] = useState(false);
  const [putListId, setPutListId] = useState('');
  const [printUrl, setPrintUrl] = useState('');
  const [apiCalled, setApiCalled] = useState(false);
  const { control, trigger, getValues, setError, setValue, reset } = useForm({
    defaultValues: {
      putAwayMethod: 'Manual',
      putter: '',
      '01-wid': '',
      '01-quantity': '',
      '02-wid': '',
      '02-quantity': '',
      '03-wid': '',
      '03-quantity': '',
      '04-wid': '',
      '04-quantity': '',
      '05-wid': '',
      '05-quantity': ''
    },
    mode: 'all'
  });

  const getPutterDropdownData = async () => {
    const res = await getPutterList({});
    if (res?.status == SUCCESS) {
      setPutterList(dataKeyNameChangeForPutter(res?.data));
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  useEffect(() => {
    getPutterDropdownData();
  }, []);

  const inValidWid = (widArr) => {
    widArr?.map((wid) => {
      list?.map(({ SNO: id }) => {
        if (getValues(`${id}-wid`) === wid) {
          setError(`${id}-wid`, { type: 'custom', message: 'WID does not exist.' });
        }
      });
    });
  };

  const createPutlist = async (payload) => {
    setApiCalled(true);
    const res = await createPutAwayList({
      ...payload
    });
    if (res?.status === SUCCESS) {
      reset();
      setPutListId(res?.data?.put_list_id);
      setPrintUrl(res?.data?.url);
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
    } else {
      setPutListId('');
      setPrintUrl('');
      if (res?.data?.message && res?.data?.message?.split(' ')[0] === 'WID') {
        inValidWid([res?.data?.message?.split(' ')[1]]);
      }
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setApiCalled(false);
  };

  const finishExecution = (res) => {
    const widAndQtyList = [];
    let isErrorFound = false;
    list?.map(({ SNO }) => {
      if (getValues(`${SNO}-wid`) && getValues(`${SNO}-quantity`))
        widAndQtyList.push({
          wid_code: getValues(`${SNO}-wid`),
          quantity: getValues(`${SNO}-quantity`)
        });
      // Error section
      if (
        (getValues(`${SNO}-wid`) && !getValues(`${SNO}-quantity`)) ||
        (!getValues(`${SNO}-wid`) && getValues(`${SNO}-quantity`))
      ) {
        isErrorFound = true;
        if (!getValues(`${SNO}-quantity`))
          setError(`${SNO}-quantity`, { type: 'custom', message: 'This is a required field' });
        else setError(`${SNO}-wid`, { type: 'custom', message: 'This is a required field' });
      }
    });

    if (!widAndQtyList?.length) setShowWidError(true);
    else setShowWidError(false);

    if (res && !isErrorFound && widAndQtyList?.length) {
      const payload = {
        supplier_id: filterData?.supplier,
        inbound_type: filterData?.inboundType,
        warehouse_id: filterData?.warehouse,
        put_away_method: getValues('putAwayMethod'),
        putter_id: getValues('putter'),
        wid_and_qty_list: widAndQtyList
      };
      createPutlist(payload);
    }
  };

  const triggerValidation = () => {
    trigger().then((res) => finishExecution(res));
  };

  return (
    <MainContent heading="Create Put Away List">
      <CreatePutlistFilters
        setShowPutList={setShowPutList}
        setFilterData={setFilterData}
        filterData={filterData}
      />
      {showPutList && (
        <>
          <DescriptionBar
            label="Add below items in Putlist"
            customStyles={{ justifyContent: 'space-between' }}>
            {putListId && (
              <Box sx={styles.printWrapper}>
                <ShowMessage type="SUCCESS" message={`Putlist ${putListId} Created`} />
                {printUrl && (
                  <Box
                    onClick={() => {
                      printJS(decodeURIComponent(printUrl));
                    }}
                    sx={styles.print}>
                    Print
                  </Box>
                )}
              </Box>
            )}
          </DescriptionBar>
          <CreatePutlistTable
            list={list}
            control={control}
            setError={setError}
            setValue={setValue}
          />
          {showWidError && (
            <ShowMessage type="ERROR" message="You have to fill in at least one row" />
          )}

          <Box sx={styles.lowerFilter}>
            <Box sx={styles.input}>
              <Dropdown
                name="putAwayMethod"
                label="Put Away Method"
                control={control}
                options={[
                  { label: 'HHD', value: 'HHD' },
                  { label: 'Manual', value: 'Manual' }
                ]}
              />
            </Box>
            <Box sx={styles.input}>
              <Dropdown
                name="putter"
                label="Putter"
                control={control}
                rules={{ required: { value: true, message: 'This is a required field' } }}
                options={PutterList}
              />
            </Box>
          </Box>
          <Button
            disabled={apiCalled}
            label="Create Putlist"
            onClick={() => triggerValidation()}
            customStyles={styles.createButton}
          />
        </>
      )}
    </MainContent>
  );
};

export default CreatePutlist;
