import Box from '@mui/material/Box';
import React from 'react';
import { Pagination, Spinner, TableDataRenderer } from '../../../../common';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import styles from '../styles';

const ViewOrdersTable = ({ list = [], pageData, pageClick, isLoading, page, purchaseOrder }) => {
  const [{ width }] = useWindowDimensions();
  const columnDefs = [
    {
      field: 'ProductInfo',
      flex: width > 900 ? 1 : 0,
      minWidth: width < 1000 ? 260 : 0,
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      headerName: 'Product Info',
      cellRenderer: ({ data: { ProductInfo } }) => {
        return purchaseOrder({
          productDescription: ProductInfo?.product_description,
          skuCode: ProductInfo?.sku_code,
          brandSkuCode: ProductInfo?.brand_sku_code,
          portalSkuCode: ProductInfo?.portal_sku_code,
          asinCode: ProductInfo?.asin_code,
          fsnCode: ProductInfo?.fsn_code
        });
      }
    },
    { field: 'OrderedQty', headerName: 'Ordered Qty', flex: width > 900 ? 1 : 0 },
    { field: 'ReceiverQty', headerName: 'Received Qty', flex: width > 900 ? 1 : 0 },
    { field: 'CanceledQty', headerName: 'Canceled Qty', flex: width > 900 ? 1 : 0 },
    { field: 'RejectedQty', headerName: 'QC Rejected Qty', flex: width > 900 ? 1 : 0 },
    { field: 'OutstandingQty', headerName: 'Outstanding Qty', flex: width > 900 ? 1 : 0 },
    { field: 'TotalValue', headerName: 'Total value', flex: width > 900 ? 1 : 0 }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <TableDataRenderer list={list} columnDefs={columnDefs} rowHeight={150} />
        )}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default ViewOrdersTable;
