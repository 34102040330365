const styles = {
  icon: {
    color: '1px solid rgb(105, 117, 118)',
    fontSize: {
      xs: '24.5px',
      sm: '28.8px'
    },
    zIndex: 5
  },

  menuIcon: {
    color: '1px solid rgb(105, 117, 118)'
  },

  navBarIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '20px'
  },

  mobileDropDown: {
    display: {
      xs: 'block',
      sm: 'none'
    }
  },

  mobileDropdown: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '15px 10%',
    width: '200px'
  },

  dropDownWrapper: {
    width: '100%',
    flexWrap: 'wrap',
    gap: '20px',
    display: {
      xs: 'none',
      sm: 'flex'
    }
  },

  dropDown: {
    width: {
      sm: '35%',
      md: '15%'
    }
  },

  drawerIcon: {
    color: 'white'
  },

  navBarWrapper: {
    width: '100%',
    padding: '8px',
    display: 'flex',
    position: 'sticky',
    top: 0,
    zIndex: 100,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(11, 12, 12, 0.16) 0px 4px 8px 0px',
    borderBottom: '1px solid rgb(187, 192, 193)',
    height: '62px'
  },

  navBarLogo: {
    width: {
      xs: '90px',
      md: '120px'
    },
    cursor: 'pointer'
  },

  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: {
      xs: '8px',
      sm: '15px'
    }
  },

  logOutWrapper: {
    padding: '8px 10px',
    width: '150px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'custom.blue',
      color: 'white'
    }
  },

  feedbackButtonWeb: {
    display: {
      xs: 'none',
      md: 'block'
    },
    backgroundColor: 'custom.blue',
    cursor: 'pointer',
    padding: '5px 15px',
    width: '150px',
    color: 'white',
    fontWeight: 500,
    textDecoration: 'none',
    fontSize: {
      xs: '14px',
      sm: '16px'
    }
  },

  mobilePopper: {
    display: {
      xs: 'block',
      md: 'none'
    }
  },

  webPopper: {
    display: {
      xs: 'none',
      md: 'block'
    }
  }
};

export default styles;
