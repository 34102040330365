import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  SupplierListConverter,
  WarehouseListConvertor
} from '../../../../../constants/apiDataChanger';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { getSupplierList, getWarehouseList } from '../../../../../redux/APICaller';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Dropdown } from '../../../../common';
import styles from '../styles';

const CreatePutlistFilters = ({ setShowPutList, setFilterData, filterData }) => {
  const dispatch = useDispatch();
  const [supplierList, setSupplierList] = useState([]);
  const [warehouseList, setWarehouseListList] = useState([]);
  const { control, watch } = useForm({
    defaultValues: {
      supplier: '',
      warehouse: '',
      inboundType: ''
    }
  });

  const getSupplierDropdownData = async () => {
    const res = await getSupplierList({});
    if (res?.status == SUCCESS) {
      setSupplierList(SupplierListConverter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const getWarehouseDropdownData = async () => {
    const res = await getWarehouseList({});
    if (res?.status == SUCCESS) {
      setWarehouseListList(WarehouseListConvertor(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    getSupplierDropdownData();
    getWarehouseDropdownData();
  }, []);

  useEffect(() => {
    if (watch('supplier') && watch('warehouse') && watch('inboundType')) {
      setFilterData({
        ...filterData,
        supplier: watch('supplier'),
        warehouse: watch('warehouse'),
        inboundType: watch('inboundType')
      });
      setShowPutList(true);
    }
  }, [watch('supplier'), watch('warehouse'), watch('inboundType')]);

  return (
    <Box sx={styles.filterWrapper}>
      <Box sx={styles.input}>
        <Dropdown name="supplier" label="Supplier" control={control} options={supplierList} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown name="warehouse" label="Warehouse" control={control} options={warehouseList} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          name="inboundType"
          label="Inbound Type"
          control={control}
          rules={{ required: { value: true, message: 'This is a required field' } }}
          options={[
            { label: 'IB QC Pass', value: 'IB QC Pass' },
            { label: 'IB QC Fail', value: 'IB QC Fail' },
            { label: 'IB LBH Capture', value: 'IB LBH Capture' },
            { label: 'IB Excess', value: 'IB Excess' }
          ]}
        />
      </Box>
    </Box>
  );
};

export default CreatePutlistFilters;
