import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputBase } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ID, GRANT_TYPE_PASSWORD } from '../../../../constants/constants';
import { emptyEmail, emptyPassword, inValidEmail } from '../../../../constants/errorMessage';
import {
  clearEmailAndForgotStep,
  selectAuthStore,
  userLoginThunk
} from '../../../../redux/authSlice';
import styles from '../styles';

const validateEmail = (mail) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);

const SignIn = () => {
  const dispatch = useDispatch();
  const authStore = useSelector(selectAuthStore);
  const navigate = useNavigate();

  const [error, setError] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (authStore.email || authStore.forgotPasswordSteps !== 1) {
      dispatch(clearEmailAndForgotStep());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorCheck = (formData) => {
    let emailError = false;
    let passwordError = false;
    let passwordError2 = false;

    // Empty check
    if (!formData.get('email')) {
      setError((error) => {
        return { ...error, email: emptyEmail };
      });
      emailError = true;
    } else {
      setError((error) => {
        return { ...error, email: '' };
      });
    }
    if (!formData.get('password')) {
      setError((error) => {
        return { ...error, password: emptyPassword };
      });
      passwordError2 = true;
    } else {
      setError((error) => {
        return { ...error, password: '' };
      });
    }
    // Valid email check
    if (formData.get('email')) {
      if (formData.get('email').includes('@')) {
        if (!validateEmail(formData.get('email'))) {
          setError((error) => {
            return { ...error, email: inValidEmail };
          });
          emailError = true;
        } else {
          setError((error) => {
            return { ...error, email: '' };
          });
          emailError = false;
        }
      } else {
        setError((error) => {
          return { ...error, email: inValidEmail };
        });
        emailError = true;
      }
    }
    // Password length check
    if (formData.get('password') && formData.get('password').length < 6) {
      setError((error) => {
        return { ...error, password: 'password cannot be less than 6 characters' };
      });
      passwordError = true;
    } else {
      if (formData.get('password')) {
        setError((error) => {
          return { ...error, password: '' };
        });
      } else {
        passwordError = true;
      }
    }

    // Final check
    return passwordError || emailError || passwordError2;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const isError = errorCheck(data);

    if (!isError) {
      dispatch(
        userLoginThunk({
          username: data.get('email'),
          password: data.get('password'),
          grant_type: GRANT_TYPE_PASSWORD,
          client_id: CLIENT_ID
        })
      );
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <Box component="img" src="./losung360New.png" sx={styles.image} loading="lazy" />
      </Box>
      <Box sx={{ ...styles.cardWrapper, minHeight: 370 }}>
        <Box sx={styles.heading}>Sign in</Box>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={styles.inputFields}>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.label}>
              Email
              <Box component="span" className="asterstik">
                *
              </Box>
            </Box>
            <InputBase
              required
              fullWidth
              id="email"
              label="Email"
              placeholder="Enter registered email"
              name="email"
              sx={styles.inputBase}
              autoFocus
              error={!!error.email}
              helperText={error.email}
            />
            <Box sx={styles.errorMessage}>{error.email}</Box>
          </Box>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.label}>
              Password
              <Box component="span" className="asterstik">
                *
              </Box>
            </Box>
            <InputBase
              required
              fullWidth
              name="password"
              label="Password"
              placeholder="Enter password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              sx={styles.inputBase}
              error={!!error.password}
              helperText={error.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box onClick={() => navigate('/forgot-password')} sx={styles.forgotPassword}>
              Forgot your password ?
            </Box>
            <Box sx={styles.errorMessage}>{error.password}</Box>
          </Box>
          <LoadingButton
            type="submit"
            sx={styles.button}
            loading={authStore.status === 'loading'}
            loadingPosition="center"
            variant="contained">
            Sign In
          </LoadingButton>
          <Box sx={styles.signInDescription}>
            By continuing, you agree to Losung{' '}
            <Box component="span" className="links">
              Conditions of Use
            </Box>{' '}
            and{' '}
            <Box component="span" className="links">
              Privacy Notice.
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
