import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { required } from '../../../../constants/errorMessage';
import { completeLoadingModal } from '../../../../constants/formData';
import { trailerStatus } from '../../../../constants/messages';
import { completeLoading } from '../../../../redux/APICaller/dockAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Input, Modal } from '../../../common';
import DropDown from '../../../common/FormComponents/Dropdown';
import styles from './styles';

const InputModal = ({ data, open, handleClose, onSuccess, buttonLabel = 'Complete Loading' }) => {
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const { control, trigger, watch, setValue } = useForm({
    mode: 'all'
  });

  useEffect(() => {
    setValue('trailerNumber', data?.TruckNo);
    setValue('consignmentType', data?.ConsgType);
    setValue('dock', data?.TrailerLOC);
    setValue('noOfInvoice', data?.no_of_invoices);
  }, []);

  const trailerUnloadingApi = async () => {
    const customPayload = {
      truck_number: watch('trailerNumber'),
      trip_id: data?.trip_id,
      no_of_packages: watch('noOfPackageLoader')
    };

    const res = await completeLoading({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      onSuccess();
      setConfirm(true);
      dispatch(
        showToastMessage({
          messageType: SUCCESS_MSG,
          message: res?.data?.message || trailerStatus
        })
      );
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res.data.message }));
    }
  };

  const finishExecution = (res) => {
    if (res) {
      trailerUnloadingApi();
    }
  };

  const triggerValidation = () => {
    trigger().then((res) => finishExecution(res));
  };

  return (
    <Modal
      heading={buttonLabel}
      open={open}
      handleClose={handleClose}
      hideAcnBtn={confirm}
      onSuccess={() => {
        triggerValidation();
      }}
      buttonLabel={buttonLabel}>
      <Box component="form">
        <Box>
          {completeLoadingModal?.map(
            (
              {
                disabled,
                maxLength,
                name,
                label,
                type,
                options,
                isRequired = false,
                inputType = 'text'
              },
              index
            ) => {
              return (
                <Box key={`${name + '-' + index}`} sx={styles.tableWrapper}>
                  <Box sx={styles.label}>{label}</Box>
                  <Box sx={styles.inputWrapper}>
                    {type === 'input' ? (
                      <Box sx={styles.input}>
                        <Input
                          disabled={disabled}
                          maxLength={maxLength}
                          name={name}
                          placeholder={label}
                          type={inputType}
                          control={control}
                          rules={{
                            required: {
                              value: isRequired,
                              message: required
                            }
                          }}
                        />
                      </Box>
                    ) : (
                      <Box sx={styles.input}>
                        <DropDown
                          name={name}
                          placeholder={label}
                          control={control}
                          options={options}
                          rules={{
                            required: {
                              value: isRequired,
                              message: required
                            }
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            }
          )}
        </Box>
        {confirm && (
          <Box sx={styles.description}>
            <CheckCircleOutlineIcon sx={styles.icon} />
            Trailer status updated
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default InputModal;
