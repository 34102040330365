const styles = {
  wrapper: {
    width: {
      xs: '100%',
      md: '31.5%'
    },
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    padding: '25px 15px',
    borderRadius: '5px',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
  },

  heading: {
    fontSize: {
      xs: '16px',
      sm: '18px'
    },
    color: 'custom.labelBlack'
  },

  cardWrapper: {
    border: '1px solid',
    borderColor: 'custom.gray'
  },

  card: {
    display: 'flex'
  },

  label: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    width: '50%',
    padding: '6px 5px',
    borderRight: '1px solid',
    borderColor: 'custom.gray',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },

  value: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    width: '50%',
    padding: '6px 5px',
    borderColor: 'custom.gray',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
};

export default styles;
