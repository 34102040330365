import { GATE_IN_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI } from '../../helpers/API';

const { GET_ACN_LIST, GET_PO_DETILA_BY_PO_FOR_ACN } = GATE_IN_PATHS;

/**
 * Get ACN List
 * @param {*} data
 * @returns Array of ACN data
 */

export function acnList(data) {
  return getAPI({
    url: GET_ACN_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Creates New Consignment
 * @param {*} data
 * @returns New Consignment
 */
export function createNewConsignment(data) {
  return postAPI({
    url: GET_ACN_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getDetailsByPoNumber(data) {
  return getAPI({
    url: GET_PO_DETILA_BY_PO_FOR_ACN.replace(':poNumber', data?.poNumber),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
