import { BROWSE_PRODUCT_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI } from '../../helpers/API';

const {
  POST_CREATE_PRODUCT_FILE_UPLOAD,
  GET_PRODUCT_LIST,
  GET_FIND_PRODUCT_LIST_CSV_DOWNLOAD,
  GET_VIEW_PRODUCT_DATA
} = BROWSE_PRODUCT_PATHS;

/**
 * Create Product File Upload
 * @param {*} data
 * @returns
 */
export function productCreatFileUpload(data) {
  return postAPI({
    url: POST_CREATE_PRODUCT_FILE_UPLOAD,
    data: data?.file,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true,
    isMultipart: true
  });
}

/**
 * Find Product List
 * @param {*} data
 * @returns
 */
export function findProductList(data) {
  return postAPI({
    url: GET_PRODUCT_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Products List CSV Download
 * @param {*} data
 * @returns
 */
export function findProductListCSVDownload(data) {
  return postAPI({
    url: GET_FIND_PRODUCT_LIST_CSV_DOWNLOAD,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get Product Details
 */
export function getViewProductData(data) {
  return getAPI({
    url: GET_VIEW_PRODUCT_DATA.replace(':sku_code', data?.sku_code),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
