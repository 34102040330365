import { Box } from '@mui/material';
import React from 'react';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { Pagination, Spinner, TableDataRenderer, Tooltip } from '../../../../common';
import styles from '../styles';

const ViewPutlistTable = ({ list = [], pageData, pageClick, isLoading, page }) => {
  const [{ width }] = useWindowDimensions();
  const columnDefs = [
    {
      field: 'SKUCode',
      headerName: 'SKU Code',
      flex: width > 1000 ? 1 : 0
    },
    {
      field: 'ProductDescription',
      headerName: 'Product Description',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.ProductDescription} dataLength={35} />;
      }
    },
    { field: 'PutawayQty', headerName: 'Putaway Qty', flex: width > 1000 ? 1 : 0 },
    { field: 'QtyAdded', headerName: 'Qty Added', flex: width > 1000 ? 1 : 0 },
    {
      field: 'ShelfCode',
      headerName: 'Shelf Code',
      flex: width > 1000 ? 1 : 0
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default ViewPutlistTable;
