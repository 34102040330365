import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { getViewTransfer } from '../../../../redux/APICaller/stockTransferAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../../utils/index';
import { DescriptionBar, MainContent, ReadOnlyList, Spinner } from '../../../common';
import TransferID from './TransferID';
import ViewTransferTable from './ViewTransferTable';
import styles from './styles';

const transferDetailsMain = [
  {
    label: 'Transfer ID',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Source Warehouse',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Destination Warehouse',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Status',
    value: {
      type: 'read',
      value: ''
    }
  }
];

const transferDetails = [
  {
    label: 'Picklist ID',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Picking Date',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Putaway ID',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Putaway Date',
    value: {
      type: 'read',
      value: ''
    }
  }
];

const keyValueMaker = (value, template, type) => {
  const tempTemplate = JSON.parse(JSON.stringify(template));
  Object.keys(value)?.forEach((key) => {
    if (type === 'transfer_details_one') {
      if (key === 'transfer_code') tempTemplate[0].value.value = value[key];
      else if (key === 'source_warehouse_code')
        tempTemplate[1].value.value = tempTemplate[1].value.value = value[key];
      else if (key === 'dest_warehouse_code') tempTemplate[2].value.value = value[key];
      else if (key === 'status') tempTemplate[3].value.value = value[key];
    }
    if (type === 'transfer_details_two') {
      if (key === 'picklist_code') tempTemplate[0].value.value = value[key];
      else if (key === 'picking_date')
        tempTemplate[1].value.value = value[key] && getFormattedDate(value[key], 'DD/MM/YY');
      else if (key === 'putaway_code') tempTemplate[2].value.value = value[key];
      else if (key === 'putaway_date')
        tempTemplate[3].value.value = value[key] && getFormattedDate(value[key], 'DD/MM/YY');
    }
  });
  return tempTemplate;
};

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      sku_code: SKU,
      wid_code: WID,
      product_description: ProductDescription,
      transfer_qty: TransferQty,
      source_area_name: SourceArea,
      source_su_code: SourceShelf,
      dest_area_name: DestinationArea,
      dest_su_code: DestinationShelf,
      ...rest
    }) => ({
      SKU,
      WID,
      ProductDescription,
      TransferQty,
      SourceArea,
      SourceShelf,
      DestinationArea,
      DestinationShelf,
      ...rest
    })
  );

  return newArrayOfObj;
};

const ViewTransfer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [transferId, setTransferId] = useState('');
  const [transferIdOverview, setTransferIdOverview] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [transferIdNotFound, setTransferIdNotFound] = useState(false);

  const pageData = {
    currentPage: 1,
    maxPage: 1
  };

  const dataFound = Object.keys(transferIdOverview)?.length;

  const fetchViewTransferDetails = async (transferId) => {
    setLoading(true);
    const res = await getViewTransfer({ transferId });
    if (res?.status === SUCCESS) {
      if (transferIdNotFound) setTransferIdNotFound(false);
      const newRes = {};
      newRes.transfer_details_response = keyValueMaker(
        res?.data?.transfer_details_response,
        transferDetailsMain,
        'transfer_details_one'
      );
      newRes.transfer_details_response2 = keyValueMaker(
        res?.data?.transfer_details_response,
        transferDetails,
        'transfer_details_two'
      );
      setTransferIdOverview(newRes);
      setTableRows(dataKeyNameChange(res?.data?.list));
    } else {
      setTransferIdOverview({});
      setTransferIdNotFound(true);
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params?.transferId) {
      setTransferId(params?.transferId);
    }
  }, [params?.transferId]);

  useEffect(() => {
    if (transferId) {
      setTransferIdNotFound(false);
      fetchViewTransferDetails(transferId);
    }
  }, [transferId]);

  return (
    <MainContent heading="Warehouse-To-Warehouse Stock Transfer">
      <Box sx={styles.dataWrapper}>
        <TransferID
          setTransferId={setTransferId}
          transferNotFound={transferIdNotFound}
          defaultTransferId={params?.transferId}
        />
        {loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            <DescriptionBar
              label="Information for Transfer ID:"
              value={transferId}
              customStyles={{ marginBottom: '-0.1em' }}
            />
            <Box sx={styles.readOnlyCards}>
              {transferIdOverview?.transfer_details_response && (
                <>
                  <ReadOnlyList
                    heading="Transfer Details"
                    fieldsData={transferIdOverview?.transfer_details_response}
                    wrapperCustomStyles={styles.cards}
                  />
                  <ReadOnlyList
                    heading="Transfer Details"
                    fieldsData={transferIdOverview?.transfer_details_response2}
                    wrapperCustomStyles={styles.cards}
                  />
                </>
              )}
            </Box>
            <ViewTransferTable
              list={tableRows}
              pageData={pageData}
              pageClick={(e, pageNumber) => {}}
              isLoading={loading}
              page={pageData.currentPage}
            />
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default ViewTransfer;
