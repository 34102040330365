import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Bar, Button, Input, ErrorMessage } from '../../../../common';
import styles from '../styles';

const PutlistID = ({ setPutlistID, putlistNotFound, defaultPutlistId }) => {
  const { control, watch } = useForm({
    defaultValues: {
      putlistID: defaultPutlistId
    }
  });

  const putlistID = watch('putlistID');

  const findClickHandler = () => {
    setPutlistID(putlistID);
  };

  return (
    <Bar heading="Putlist ID">
      <Box sx={styles.viewPurchaseOrder}>
        <Box>
          <Input
            disabled={defaultPutlistId}
            name="putlistID"
            label="Putlist ID"
            control={control}
            placeholder="Enter Putlist"
          />
        </Box>
        <Box sx={styles.findButton}>
          <Button
            disabled={!putlistID?.length || defaultPutlistId}
            onClick={findClickHandler}
            label="Find"
            customStyles={styles.actionButton}
          />
        </Box>
      </Box>
      {putlistNotFound && (
        <Box sx={{ marginBottom: '-10px' }}>
          <ErrorMessage errorMsg="Please provide a valid Putlist ID" />
        </Box>
      )}
    </Bar>
  );
};

export default PutlistID;
