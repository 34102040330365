import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Bar, Button, ErrorMessage, Input } from '../../../../common/index';
import styles from '../styles';

const FindPurchaseOrder = ({ setOrderId, poNotFound, defaultPoId }) => {
  const { control, watch } = useForm({
    defaultValues: {
      poID: defaultPoId
    }
  });

  const poId = watch('poID');

  const findClickHandler = () => {
    setOrderId(poId);
  };

  return (
    <Bar heading="View Purchase Order">
      <Box sx={styles.viewPurchaseOrder}>
        <Box>
          <Input
            disabled={defaultPoId}
            name="poID"
            label="Purchase Order ID"
            placeholder="Enter PO ID"
            control={control}
          />
        </Box>
        <Box sx={styles.findButton}>
          <Button
            disabled={!poId?.length || defaultPoId}
            onClick={findClickHandler}
            customStyles={styles.actionButton}
            label="Find"
          />
        </Box>
      </Box>
      {poNotFound && (
        <Box sx={{ marginBottom: '-10px' }}>
          <ErrorMessage errorMsg="Please provide a valid PO ID" />
        </Box>
      )}
    </Bar>
  );
};

export default FindPurchaseOrder;
