import SearchIcon from '@mui/icons-material/Search';
import { FormHelperText, IconButton, InputBase, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React from 'react';
import { Controller } from 'react-hook-form';
import { getError } from '../../../../constants/common';
import styles from './styles';

const Input = ({
  name,
  control,
  label,
  placeholder,
  type = 'text',
  fullWidth = true,
  rules,
  isSearch = false,
  onSearch,
  maxLength,
  variant = 'standard',
  className,
  ...rest
}) => {
  return (
    <Controller
      render={({ field, formState: { errors } }) => {
        const error = getError(name, errors);
        return (
          <FormControl
            sx={styles.wrapper}
            fullWidth={fullWidth}
            variant={variant}
            className={className}>
            {label && <InputLabel shrink>{label}</InputLabel>}
            <InputBase
              type={type}
              inputRef={field.ref}
              onBlur={field.onBlur}
              value={field.value}
              error={!!error}
              sx={styles.input}
              onChange={field.onChange}
              placeholder={placeholder}
              inputProps={{ maxLength: maxLength }}
              endAdornment={
                isSearch && (
                  <IconButton sx={styles.searchIconWrapper} onClick={onSearch}>
                    <SearchIcon disableTypography disablePointerEvents sx={styles.icon} />
                  </IconButton>
                )
              }
              {...rest}
            />
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};

export default Input;
