import AddBoxOutlineIcon from '@mui/icons-material/AddBoxOutlined';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { verifyShelfCode } from '../../../../../redux/APICaller/putawaysAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Input, Spinner, TableDataRenderer, Tooltip } from '../../../../common';
import styles from '../styles';

const CompletePutlistTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  setTableRows
}) => {
  const dispatch = useDispatch();
  const { control, setError, getValues, clearErrors } = useForm();

  const focusError = (name, message) => setError(name, { type: 'custom', message });

  const quantityVerify = (data, qty) => {
    let totalcount = Number(qty);
    const tempList = [...list];
    tempList?.map((item, i) => {
      if (item?.SrNo === data?.SrNo) {
        tempList[i].shelfList?.map((item) => {
          totalcount = totalcount + Number(item?.qty);
        });
      }
    });
    return totalcount <= data?.PutawayQty;
  };

  const addNewShelf = (data, shelfCode, qty) => {
    const tempList = [...list];
    tempList?.map((item, i) => {
      if (item?.SrNo === data?.SrNo) {
        tempList[i].shelfList.push({ shelfCode: '', qty: '' });
        tempList[i].shelfList[tempList[i].shelfList?.length - 2] = {
          shelfCode,
          qty
        };
      }
    });
    console.log(data);
    setTableRows(tempList);
  };

  const verifyShelfCodes = async (shelfCode) => {
    const res = await verifyShelfCode({
      shelfCode
    });

    if (res.status === SUCCESS && res?.data && typeof res?.data === 'boolean') {
      return res?.data;
    } else {
      dispatch(
        showToastMessage({
          messageType: ERROR,
          message: 'Entered shelf code do not belong to the defined Zone'
        })
      );
      return false;
    }
  };

  const addShelfHandler = async (data) => {
    const shelfCode = getValues(`${data?.SrNo}-shelfCode-${data?.shelfList?.length - 1}`);
    const qty = getValues(`${data?.SrNo}-qty-${data?.shelfList?.length - 1}`);
    if (!shelfCode)
      focusError(
        `${data?.SrNo}-shelfCode-${data?.shelfList?.length - 1}`,
        'This is required field'
      );
    else clearErrors(`${data?.SrNo}-shelfCode-${data?.shelfList?.length - 1}`);
    if (!qty)
      focusError(`${data?.SrNo}-qty-${data?.shelfList?.length - 1}`, 'This is required field');
    else clearErrors(`${data?.SrNo}-qty-${data?.shelfList?.length - 1}`);
    // Quantity check
    let isValidQty;
    if (qty) {
      isValidQty = quantityVerify(data, qty);
    }
    if (qty) {
      if (!isValidQty) {
        focusError(`${data?.SrNo}-qty-${data?.shelfList?.length - 1}`, 'Incorrect quantity');
        dispatch(
          showToastMessage({
            messageType: ERROR,
            message: 'Actual putaway qty can not be more than putaway qty'
          })
        );
      } else clearErrors(`${data?.SrNo}-qty-${data?.shelfList?.length - 1}`);
    }
    let isValidShelfCode;
    if (shelfCode && qty && isValidQty) {
      isValidShelfCode = await verifyShelfCodes(shelfCode);
    }
    if (shelfCode && qty && isValidShelfCode) addNewShelf(data, shelfCode, qty);
  };

  const columnDefs = [
    {
      field: 'SrNo',
      headerName: 'Sr. No.'
    },
    { field: 'WID', headerName: 'WID' },
    {
      field: 'ProductDescription',
      headerName: 'Product Description',
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.ProductDescription} dataLength={20} />;
      }
    },
    { field: 'ProductCategory', headerName: 'Product Category' },
    {
      field: 'WhseCode',
      headerName: 'Whse Code'
    },
    { field: 'Zone', headerName: 'Zone' },
    { field: 'Status', headerName: 'Status' },
    {
      field: 'PutawayQty',
      headerName: 'Putaway Qty'
    },
    {
      field: 'PutawayShelfCode',
      headerName: 'Putaway Shelf Code',
      cellRenderer: ({ data }) => {
        const inpitList = data?.shelfList?.map((item, i) => {
          return (
            <Box key={i} sx={{ marginTop: !i == 0 && '1px' }}>
              <Input
                disabled={item?.shelfCode && item?.qty}
                name={`${data?.SrNo}-shelfCode-${i}`}
                control={control}
              />
            </Box>
          );
        });
        return <Box sx={styles.inputWrapper}>{inpitList}</Box>;
      }
    },
    {
      field: 'QtyAddedinShelf',
      headerName: 'Qty Added in Shelf',
      cellRenderer: ({ data }) => {
        const inpitList = data?.shelfList?.map((item, i) => {
          return (
            <Box key={i} sx={{ marginTop: !i == 0 && '1px' }}>
              <Input
                disabled={item?.shelfCode && item?.qty}
                name={`${data?.SrNo}-qty-${i}`}
                control={control}
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Box>
          );
        });
        return <Box sx={styles.inputWrapper}>{inpitList}</Box>;
      }
    },
    {
      field: 'Action',
      headerName: 'Action',
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <IconButton onClick={() => addShelfHandler(data)}>
              <AddBoxOutlineIcon sx={styles.addIcon} />
            </IconButton>
          </Box>
        );
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <TableDataRenderer list={list} columnDefs={columnDefs} rowHeight={140} />
        )}
      </Box>
      {/* <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box> */}
    </Box>
  );
};

export default CompletePutlistTable;
