import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../constants/apiStatusCodes';
import { consigmentType } from '../../../../constants/commonLabels';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { required } from '../../../../constants/errorMessage';
import { releaseFromDock } from '../../../../constants/formData';
import { trailerStatus } from '../../../../constants/messages';
import { unduckTruck } from '../../../../redux/APICaller/dockAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Input, Modal } from '../../../common';
import DropDown from '../../../common/FormComponents/Dropdown';
import styles from './styles';

const InputModal = ({ open, handleClose, onSuccess, data }) => {
  const [formFields, setFormFields] = useState(releaseFromDock);
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const { control, trigger, watch, setValue } = useForm({
    mode: 'all'
  });

  useEffect(() => {
    setValue('trailerNumber', data?.TruckNo);
    setValue('consignmentType', data?.ConsgType);
    setValue('dock', data?.TrailerLOC || data?.dock_id);
  }, []);

  useEffect(() => {
    if (data?.ConsgType === consigmentType.inBound) {
      const tempFormField = [...formFields];
      tempFormField[4].hide = true;
      tempFormField[5].hide = true;
      setFormFields(tempFormField);
    } else {
      const tempFormField = [...formFields];
      tempFormField[4].hide = false;
      tempFormField[5].hide = false;
      setFormFields(tempFormField);
    }
  }, [data?.ConsgType]);

  const trailerUnloadingApi = async () => {
    const customPayload = {
      truck_number: data?.TruckNo,
      trip_id: data?.trip_id,
      no_of_invoices: watch('numberOfInvoice')
    };

    if (data?.ConsgType !== 'Inbound') {
      customPayload.no_of_packages = watch('numberOfPackage');
      customPayload.seal_intact = watch('isTrailerSealIntact') === 'yes' ? true : false;
    }

    const res = await unduckTruck({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      onSuccess();
      setConfirm(true);
      dispatch(
        showToastMessage({
          messageType: SUCCESS_MSG,
          message: res?.data?.message || trailerStatus
        })
      );
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const finishExecution = (res) => {
    if (res) {
      trailerUnloadingApi();
    }
  };

  const triggerValidation = () => {
    trigger().then((res) => finishExecution(res));
  };

  return (
    <Modal
      heading="Dock release information"
      open={open}
      handleClose={handleClose}
      hideAcnBtn={confirm}
      onSuccess={() => {
        triggerValidation();
      }}
      buttonLabel="Release from dock">
      <Box component="form" sx={{ transform: 'scale(0.95)' }}>
        <Box>
          {formFields?.map(
            (
              {
                maxLength,
                name,
                label,
                type,
                options,
                isRequired = false,
                inputType = 'text',
                disabled = false,
                hide
              },
              index
            ) => {
              if (hide) return false;
              return (
                <Box key={`${name + '-' + index}`} sx={styles.tableWrapper}>
                  <Box sx={styles.label}>{label}</Box>
                  <Box sx={styles.inputWrapper}>
                    {type === 'input' ? (
                      <Box sx={styles.input}>
                        <Input
                          maxLength={maxLength}
                          name={name}
                          placeholder={label}
                          type={inputType}
                          disabled={disabled}
                          control={control}
                          rules={{
                            required: {
                              value: isRequired,
                              message: required
                            }
                          }}
                        />
                      </Box>
                    ) : (
                      <Box sx={styles.input}>
                        <DropDown
                          name={name}
                          placeholder={label}
                          control={control}
                          options={options}
                          rules={{
                            required: {
                              value: isRequired,
                              message: required
                            }
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            }
          )}
        </Box>
        {confirm && (
          <Box sx={styles.description}>
            <CheckCircleOutlineIcon sx={styles.icon} />
            Trailer status updated
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default InputModal;
