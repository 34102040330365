const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    margin: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: {
        xs: '12px',
        sm: '14px'
      },
      color: 'custom.labelBlack'
    },
    '& .MuiCheckbox-root': {
      padding: 0
    }
  }
};

export default styles;
