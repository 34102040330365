import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainContent } from '../../../common';
import ViewIRNTable from './ViewIRNTable';
import ViewIRNFilters from './ViewIRNFilters';
import { getFormattedDate } from '../../../../utils/index';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { ERROR } from '../../../../constants/constants';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { viewIrnStock } from '../../../../redux/APICaller/irnAPIs';
import Spinner from '../../../common/Spinner';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      sku_code: SKUCode,
      wid_code: WID,
      product_description: ProductDescription,
      supplierName: Supplier,
      qty_already_added_in_put_away_list: QtyAlreadyAddedinPutlist,
      qty_left_for_put_away: QuantityLeftforPutAway,
      inbound_staging_zone: InboundStagingZone,
      updated_on: UpdatedOn,
      ...rest
    }) => ({
      SKUCode,
      WID,
      ProductDescription,
      Supplier,
      QtyAlreadyAddedinPutlist,
      QuantityLeftforPutAway,
      InboundStagingZone,
      UpdatedOn: getFormattedDate(UpdatedOn, 'DD/MM/YY'),
      ...rest
    })
  );

  return newArrayOfObj;
};

const initPayload = {
  inboundStagingZone: '',
  sku: '',
  wid: '',
  warehouse: '',
  supplier: '',
  fromDate: '',
  toDate: ''
};

const ViewIRN = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(initPayload);
  const [csvPayload, setCsvPayload] = useState(initPayload);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 1
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const isFindDisable = !(
    payload?.inboundStagingZone ||
    payload?.sku ||
    payload?.wid ||
    payload?.warehouse ||
    payload?.supplier ||
    payload?.fromDate ||
    payload?.toDate
  );

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1, isBtnClick) => {
    setLoading(true);
    const customPayload = {};

    customPayload.date_from =
      payload?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    customPayload.date_to = payload?.toDate || moment().format('YYYY-MM-DD');

    if (page !== 'reset') {
      if (payload.inboundStagingZone) customPayload.inboundStagingZone = payload.inboundStagingZone;
      if (payload.sku) customPayload.sku = payload.sku;
      if (payload.wid) customPayload.wid = payload?.wid;
      if (payload.warehouse) customPayload.warehouse = payload?.warehouse;
      if (payload.supplier) customPayload.supplier = payload.supplier;
    } else {
      customPayload.date_from = moment().subtract(7, 'days').format('YYYY-MM-DD');
      customPayload.date_to = moment().format('YYYY-MM-DD');
      setPayload(initPayload);
    }
    const res = await viewIrnStock({
      page: page === 'reset' ? 0 : page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        setCsvPayload(payload);
        if (isBtnClick) setIsFilterApplied(true);
      } else {
        setCsvPayload(initPayload);
        setIsFilterApplied(false);
      }
      if (page === 1 || page === 'reset') {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  return (
    <Box>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading="Inventory Visibility in Staging Area">
          <Box sx={styles.dataWrapper}>
            <ViewIRNFilters
              csvPayload={csvPayload}
              data={payload}
              setData={setPayload}
              onFind={(type) => {
                APICALL(type, 'btnClick');
              }}
              isFindDisable={isFilterApplied ? !isFilterApplied : isFindDisable}
            />
            <ViewIRNTable
              list={tableRows}
              pageData={pageData}
              pageClick={(e, pageNumber) => {
                apiCallWithPage(pageNumber);
              }}
              isLoading={isLoading}
              page={pageData.currentPage}
            />
          </Box>
        </MainContent>
      )}
    </Box>
  );
};

export default ViewIRN;
