import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { getSkuPartsList, patchIRNDetails } from '../../../../../redux/APICaller/irnAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import {
  Button,
  Input,
  MainContent,
  Modal,
  NewDate,
  Radio,
  Spinner,
  TableDataRenderer,
  Tooltip
} from '../../../../common/index';
import styles from '../styles';

const radioOptions = [
  {
    label: 'No Damage',
    value: '1060cd70-00bb-11ee-9d56-062d55488ff4'
  },
  {
    label: 'Product Damage',
    value: '5e81d9a6-00bb-11ee-9d56-062d55488ff4'
  },
  {
    label: 'Box Damage',
    value: '5681590b-00bb-11ee-9d56-062d55488ff4'
  },
  {
    label: 'Specification Msimatch',
    value: '6b6823e4-00bb-11ee-9d56-062d55488ff4'
  },
  {
    label: 'Excess Received',
    value: '75a809a3-00bb-11ee-9d56-062d55488ff4'
  }
];

const ProductLookUp = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [apiError, setAPIError] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [productInfo, setProductInfo] = useState({});
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [isMultiShipmentType, setIsMultiShipmentType] = useState(0);
  const { control, watch, getValues, trigger, reset } = useForm({
    defaultValues: {
      mrp: '',
      manufacturingDate: '',
      expireyDate: ''
    },
    mode: 'all'
  });

  const dispatch = useDispatch();
  const [{ width }] = useWindowDimensions();

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1) => {
    setLoading(true);
    const customPayload = { sku_id: params?.skuCode };

    const res = await getSkuPartsList({
      page: page === 'reset' ? 0 : page - 1,
      // limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page === 1) {
        setList([{ page: 1, array: res?.data }]);
      } else setList([...list, { page, array: res?.data }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
      setIsMultiShipmentType(res?.data?.length);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  const open2ndProductLookupModal = (skuId, reason, productInfo) => {
    setProductInfo({ ...productInfo, skuId, reason });
    setOpen(true);
  };

  const columnDefSingle = [
    {
      field: 'productInfo',
      headerName: 'SKU Details',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Box sx={styles.productLookUpAttributes}>
              OMS SKU Code : {data?.productInfo?.sku_code}
            </Box>
            <Box sx={styles.productLookUpAttributes}>
              Brand SKU Code : {data?.productInfo?.brand_sku_code}
            </Box>
            <Box sx={styles.productLookUpAttributes}>ASIN : {data?.productInfo?.asin_code}</Box>
            <Box sx={styles.productLookUpAttributes}>FSN : {data?.productInfo?.fsn_code}</Box>
            <Button
              disabled={!watch(data?.sku_part_id)}
              label="Select"
              onClick={() => {
                open2ndProductLookupModal(
                  data?.sku_part_id,
                  watch(data?.sku_part_id),
                  data?.productInfo
                );
              }}
            />
          </Box>
        );
      }
    },
    {
      field: 'product_description',
      headerName: 'Product Description & image',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Tooltip title={data?.product_description} dataLength={20} />
            <Box
              component="img"
              src={data?.product_image_url}
              sx={styles.lookUpTableImage}
              alt="Product Image"
            />
          </Box>
        );
      }
    },
    {
      field: 'ProductAttributes',
      headerName: 'Product Attributes',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Box>Brand : {data?.productAttributes?.brand}</Box>
            <Box sx={styles.productLookUpAttributes}>EAN : {data?.productAttributes?.ean}</Box>
            <Box sx={styles.productLookUpAttributes}>UPC : {data?.productAttributes?.upc}</Box>
            <Box sx={styles.productLookUpAttributes}>
              Category : {data?.productAttributes?.category}
            </Box>
            <Box sx={styles.productLookUpAttributes}>
              Max. shelf life : {data?.productAttributes?.shelf_life}
            </Box>
            <Box sx={styles.productLookUpAttributes}>HSN : {data?.productAttributes?.hsn}</Box>
            <Box sx={styles.productLookUpAttributes}>
              GST Applicable : {data?.productAttributes?.gst_available ? 'Yes' : 'No'}
            </Box>
            <Box sx={styles.productLookUpAttributes}>
              GST Rate : {data?.productAttributes?.gst_rate}
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'InboundRemarks',
      headerName: 'Inbound Remarks',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Box sx={styles.selectReasons}>Select Reason</Box>
            <Radio name={`${data?.sku_part_id}`} control={control} options={radioOptions} />
          </Box>
        );
      }
    }
  ];

  const columnDefsMulti = [
    {
      field: 'sku_part_no',
      headerName: 'Shipment Part',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return <Box>Part {data?.sku_part_no}</Box>;
      }
    },
    {
      field: 'productInfo',
      headerName: 'SKU Details',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Box sx={styles.productLookUpAttributes}>
              OMS SKU Code : {data?.productInfo?.sku_code}
            </Box>
            <Box sx={styles.productLookUpAttributes}>
              Brand SKU Code : {data?.productInfo?.brand_sku_code}
            </Box>
            <Box sx={styles.productLookUpAttributes}>ASIN : {data?.productInfo?.asin_code}</Box>
            <Box sx={styles.productLookUpAttributes}>FSN : {data?.productInfo?.fsn_code}</Box>
            <Button
              disabled={!watch(data?.sku_part_id)}
              label="Select"
              onClick={() =>
                open2ndProductLookupModal(
                  data?.sku_part_id,
                  watch(data?.sku_part_id),
                  data?.productInfo
                )
              }
            />
          </Box>
        );
      }
    },
    {
      field: 'product_description',
      headerName: 'Product Description',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Tooltip title={data?.product_description} dataLength={20} />
            <Box
              component="img"
              src={data?.product_image_url}
              sx={styles.lookUpTableImage}
              alt="Product Image"
            />
          </Box>
        );
      }
    },
    {
      field: 'ProductAttributes',
      headerName: 'Product Attributes',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Box sx={styles.productLookUpAttributes}>Brand : {data?.productAttributes?.brand}</Box>
            <Box sx={styles.productLookUpAttributes}>EAN : {data?.productAttributes?.ean}</Box>
            <Box sx={styles.productLookUpAttributes}>UPC : {data?.productAttributes?.upc}</Box>
            <Box sx={styles.productLookUpAttributes}>
              Category : {data?.productAttributes?.category}
            </Box>
            <Box sx={styles.productLookUpAttributes}>
              Max. shelf life : {data?.productAttributes?.shelf_life}
            </Box>
            <Box sx={styles.productLookUpAttributes}>HSN : {data?.productAttributes?.hsn}</Box>
            <Box sx={styles.productLookUpAttributes}>
              GST Applicable : {data?.productAttributes?.gst_available ? 'Yes' : 'No'}
            </Box>
            <Box sx={styles.productLookUpAttributes}>
              GST Rate : {data?.productAttributes?.gst_rate}
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'InboundRemarks',
      headerName: 'Inbound Remarks',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Box sx={styles.selectReasons}>Select Reason</Box>
            <Radio name={`${data?.sku_part_id}`} control={control} options={radioOptions} />
          </Box>
        );
      }
    }
  ];

  const modalData = [
    {
      label: 'SKU Details',
      component: (
        <Box>
          <Box sx={styles.modalDataFlow}>OMS SKU Code : {productInfo?.sku_code}</Box>
          <Box sx={styles.modalDataFlow}>Brand SKU Code : {productInfo?.brand_sku_code}</Box>
          <Box sx={styles.modalDataFlow}>ASIN : {productInfo?.asin_code}</Box>
          <Box sx={styles.modalDataFlow}>FSN : {productInfo?.fsn_code}</Box>
        </Box>
      )
    },
    {
      label: 'MRP',
      component: (
        <Input
          name="mrp"
          control={control}
          type="number"
          rules={{ required: 'This is a required field' }}
        />
      )
    },
    {
      label: 'Manufacturing Date',
      component: <NewDate name="manufacturingDate" control={control} />
    },
    {
      label: 'Expirey Date',
      component: (
        <NewDate name="expireyDate" control={control} minDate={watch('manufacturingDate')} />
      )
    }
  ];

  const getIRNDetails = async () => {
    setModalLoading(true);
    const customPayload = {
      irn_id: params?.irnId,
      qc_remarks: productInfo?.reason,
      sku_part_id: productInfo?.skuId,
      mrp: Number(getValues('mrp')),
      manufacturing_date: getValues('manufacturingDate'),
      expiry_date: getValues('expireyDate')
    };
    const res = await patchIRNDetails({
      ...customPayload
    });

    if (res.status === SUCCESS) {
      setAPIError('');
      console.log(res?.data);
      if (res?.data?.irn && res?.data?.irn_id && res?.data?.wid)
        navigate(
          `/receive-irn-complete/${res?.data?.irn}/${res?.data?.irn_item_part_id}/${res?.data?.wid}/${res?.data?.item_quality_check_id}`
        );
    } else {
      setAPIError(res?.data?.message);
      dispatch(showToastMessage({ messageType: ERROR }));
    }
    setModalLoading(false);
  };

  const finishExecution = (res) => {
    if (res) {
      getIRNDetails();
    }
  };

  const triggerValidation = () => {
    trigger().then((res) => finishExecution(res));
  };

  return (
    <MainContent
      heading="Receive Invoice Receiving Note"
      description="IRN Receiving Product look Up">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isMultiShipmentType > 0 ? (
            <Box sx={styles.shipmentType}>
              Shipment Type: {isMultiShipmentType > 1 ? 'Multi' : 'Single'} Part Shipemnt
            </Box>
          ) : null}
          <Box sx={styles.wrapper}>
            <Box className="ag-theme-alpine" sx={{ ...styles.tableWrapper, height: 580 }}>
              <TableDataRenderer
                columnDefs={isMultiShipmentType > 1 ? columnDefsMulti : columnDefSingle}
                rowHeight={280}
                list={tableRows}
                pageData={pageData}
                pageClick={(e, pageNumber) => {
                  apiCallWithPage(pageNumber);
                }}
                isLoading={isLoading}
                page={pageData.currentPage}
              />
            </Box>
          </Box>
          <Modal
            open={open}
            disabled={modalLoading}
            handleClose={() => {
              if (!modalLoading) {
                setOpen(false);
                reset();
                setAPIError('');
              }
            }}
            buttonLabel="Get Details"
            onSuccess={() => triggerValidation()}>
            <Box sx={styles.modalWrapper}>
              {modalData?.map(({ label, component }, index) => {
                return (
                  <Box key={`${label}`} sx={styles.modalData}>
                    <Box
                      sx={{
                        ...styles.modalLabel,
                        borderTop: index !== 0 && '1px solid'
                      }}>
                      {label}
                    </Box>
                    <Box
                      sx={{
                        ...styles.modalComponent,
                        borderTop: index !== 0 && '1px solid'
                      }}>
                      {component}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            {apiError && <Box sx={styles.apiError}>{apiError}</Box>}
          </Modal>
        </>
      )}
    </MainContent>
  );
};

export default ProductLookUp;
