import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { getRoDetails, roItemsList } from '../../../../redux/APICaller/inboundReturnOrderAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../../utils/index';
import {
  DescriptionBar,
  MainContent,
  ProductInfoRenderer,
  ReadOnlyList,
  Spinner
} from '../../../common/index';
import DocumentInformation from './DocumentInformation';
import FindRoId from './FindRoId';
import ViewConsignmentFilters from './ViewReturnOrderFilters';
import ViewConsignmentTable from './ViewReturnOrderTable';
import styles from './styles';

const supplierInformation = [
  {
    label: 'Name',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Whse Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Address',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'City',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Pin Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'State',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'GST',
    value: {
      type: 'read',
      value: ''
    }
  }
];

const orderDetails = [
  {
    label: 'IRN ID',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'RO Creation Date',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'RO Type',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Status',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'RO Planned Units',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'PO No.',
    value: {
      type: 'link',
      value: ''
    }
  }
];

const keyValueMaker = (value, template, type) => {
  const tempTemplate = JSON.parse(JSON.stringify(template));
  Object.keys(value)?.forEach((key) => {
    if (type === 'order_details') {
      if (key === 'irn_id') tempTemplate[0].value.value = value[key];
      else if (key === 'ro_creation_date')
        tempTemplate[1].value.value = getFormattedDate(value[key], 'DD/MM/YY');
      else if (key === 'ro_type') tempTemplate[2].value.value = value[key];
      else if (key === 'status') tempTemplate[3].value.value = value[key];
      else if (key === 'ro_planned_units') tempTemplate[4].value.value = value[key];
      else if (key === 'po_number') tempTemplate[5].value.value = value[key];
    } else if (type === 'supplier_information') {
      if (key === 'supplier_name') tempTemplate[0].value.value = value[key];
      else if (key === 'warehouse') tempTemplate[1].value.value = 'HW1' || value[key];
      else if (key === 'address') tempTemplate[2].value.value = value[key];
      else if (key === 'city') tempTemplate[3].value.value = value[key];
      else if (key === 'pin_code') tempTemplate[4].value.value = value[key];
      else if (key === 'state') tempTemplate[5].value.value = value[key];
      else if (key === 'gst') tempTemplate[6].value.value = value[key];
    }
  });
  return tempTemplate;
};

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      product_info: ProductInfo,
      planned_quantity: PlannedQty,
      returned_quantity: ReturnedQty,
      outstanding_quantity: OutstandingQty,
      debit_note_number: DebitNoteNo,
      brand_name: Brand,
      total_value: Totalvalue,
      ...rest
    }) => ({
      ProductInfo,
      PlannedQty,
      ReturnedQty,
      OutstandingQty,
      DebitNoteNo,
      Brand,
      Totalvalue,
      ...rest
    })
  );

  return newArrayOfObj;
};

const ViewReturnOrder = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [roId, setRoId] = useState('');
  const [roIdOverview, setRoIdOverview] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [returnOrderItems, setReturnOrderItems] = useState({ orderedQty: 0, outstandingQty: 0 });
  const [list, setList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [poNotFound, setPoNotFound] = useState(false);

  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const dataFound = Object.keys(roIdOverview)?.length;

  const fetchPoDetails = async (ROid) => {
    setLoading(true);
    const res = await getRoDetails({ ROid });
    if (res?.status === SUCCESS) {
      if (poNotFound) setPoNotFound(false);
      const newRes = res?.data;
      newRes.order_details = keyValueMaker(res?.data?.order_details, orderDetails, 'order_details');
      newRes.supplier_information = keyValueMaker(
        res?.data?.supplier_information,
        supplierInformation,
        'supplier_information'
      );
      setRoIdOverview(newRes);
      if (
        newRes?.document_information?.actual_file_name &&
        newRes?.document_information?.uploaded_file_url
      ) {
        setIsFileSelected(true);
      }
      setLoading(false);
    } else {
      setRoIdOverview({});
      setPoNotFound(true);
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    if (params?.ro_no) {
      setRoId(params?.ro_no);
    }
  }, [params?.ro_no]);

  useEffect(() => {
    if (roId) {
      setPoNotFound(false);
      fetchPoDetails(roId);
    }
  }, [roId]);

  const APICALL = async (page = 1) => {
    setIsLoading(true);
    const customPayload = { ROid: roId };

    const res = await roItemsList({
      page: page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page === 1) {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
        setReturnOrderItems({
          orderedQty: res?.data?.ordered_qty,
          outstandingQty: res?.data?.outstanding_qty
        });
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res.data.total_pages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    if (roId && dataFound) APICALL();
  }, [roId, dataFound]);

  const clearInfo = () => {
    setIsFileSelected(false);
    setIsFileUploaded(false);
  };

  return (
    <MainContent heading="Purchase Return Order Details">
      <Box sx={styles.dataWrapper}>
        <FindRoId
          roId={roId}
          setROId={setRoId}
          clearInfo={clearInfo}
          roNotFound={poNotFound}
          defaultRoId={params?.ro_no}
        />
        {!poNotFound && loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            <DescriptionBar label="Information for RO ID:" value={roId} />
            <Box sx={styles.readOnlyCards}>
              {roIdOverview?.order_details && (
                <ReadOnlyList heading="Order Details" fieldsData={roIdOverview?.order_details} />
              )}
              {roIdOverview?.supplier_information && (
                <ReadOnlyList
                  heading="Supplier Information"
                  fieldsData={roIdOverview?.supplier_information}
                />
              )}
              {roIdOverview?.document_information && (
                <DocumentInformation
                  roIdOverview={roIdOverview}
                  setRoIdOverview={setRoIdOverview}
                  roId={roIdOverview?.ro_id}
                  isFileSelected={isFileSelected}
                  isFileUploaded={isFileUploaded}
                  setIsFileSelected={setIsFileSelected}
                  setIsFileUploaded={setIsFileUploaded}
                />
              )}
            </Box>
            <ViewConsignmentFilters
              Ordered={returnOrderItems?.orderedQty}
              Outstanding={returnOrderItems?.outstandingQty}
              roId={roId}
            />
            <ViewConsignmentTable
              list={tableRows}
              productInfoRenderer={ProductInfoRenderer}
              pageData={pageData}
              pageClick={(e, pageNumber) => {
                apiCallWithPage(pageNumber);
              }}
              isLoading={isLoading}
              page={pageData.currentPage}
            />
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default ViewReturnOrder;
