import { DOCK_PATHS } from '../../constants/apiPaths';
import { patchAPI } from '../../helpers/API';

const { START_LOADING_UNLOADING, COMPLETE_LOADING, COMPLETE_UNLOADING, UNDOCK_TRUCK } = DOCK_PATHS;

/**
 * Complete Loading
 * @param {*} data
 * @returns completes the loading
 */
export function completeLoading(data) {
  return patchAPI({
    url: COMPLETE_LOADING.replace(':truckNumber', data?.truck_number),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Complete Unloading
 * @param {*} data
 * @returns completes the unloading
 */
export function completeUnloading(data) {
  return patchAPI({
    url: COMPLETE_UNLOADING.replace(':truckNumber', data?.truck_number),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Start Loading / Unloading
 * @param {*} data
 * @returns
 */
export function startLoadingUnloading(data) {
  return patchAPI({
    url: START_LOADING_UNLOADING.replace(':truckNumber', data?.truck_number),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Unduck Truck
 * @param {*} data
 * @returns
 */
export function unduckTruck(data) {
  return patchAPI({
    url: UNDOCK_TRUCK.replace(':truckNumber', data?.truck_number),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
