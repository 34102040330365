import { Box } from '@mui/material';
import React, { memo } from 'react';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { TableDataRenderer, Spinner, Pagination } from '../../../../common';
import styles from '../styles';

const CreatePick = ({ setUnitSelectedList, isLoading, list, pageData, pageClick, page }) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const columnDefs = [
    {
      field: 'Priority',
      headerName: 'Priority',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      flex: isWebView ? 1 : 0
    },
    { field: 'Shipments', headerName: 'Shipments', flex: isWebView ? 1 : 0 },
    { field: 'Units', headerName: 'Units', flex: isWebView ? 1 : 0 },
    { field: 'Pickzone', headerName: 'Pick zone', flex: isWebView ? 1 : 0 },
    {
      field: 'Pickupslot',
      headerName: 'Pickup slot',
      flex: isWebView ? 1 : 0
    },
    {
      field: 'Carrier',
      headerName: 'Carrier',
      flex: isWebView ? 1 : 0
    },
    {
      field: 'Channel',
      headerName: 'Channel',
      flex: isWebView ? 1 : 0
    }
  ];

  const selectionChanged = (param) => {
    const selectedRows = param?.api?.getSelectedRows();
    setUnitSelectedList(selectedRows);
  };

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <TableDataRenderer
            list={list}
            columnDefs={columnDefs}
            onSelectionChanged={selectionChanged}
          />
        )}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => pageClick(e, pageNumber)}
        />
      </Box>
    </Box>
  );
};

export default memo(CreatePick);
