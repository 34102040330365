import Box from '@mui/material/Box';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { Pagination, Spinner, TableDataRenderer, Tooltip } from '../../../../common/index';
import styles from '../styles';

const ReceiveIRNTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  actionButton,
  irnId
}) => {
  const [{ width }] = useWindowDimensions();
  const navigate = useNavigate();

  const receiveBtnClick = (sku_id) => {
    navigate(`/receive-irn/${sku_id}/${irnId}`);
  };

  const columnDefs = [
    {
      field: 'SKUCode',
      headerName: 'SKU Code'
    },
    { field: 'QtytoReceive', headerName: 'Qty to Receive' },
    {
      field: 'ProductDescription',
      headerName: 'Product Description',
      flex: width > 1000 ? 0.8 : 0,
      minWidth: 200,
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.ProductDescription} dataLength={35} />;
      }
    },
    { field: 'POQuantity', headerName: 'PO Quantity' },
    { field: 'ReceivedQuantity', headerName: 'Received Quantity' },
    { field: 'OutstandingQty', headerName: 'Outstanding Qty' },
    {
      field: 'Action',
      headerName: 'Action',
      cellRenderer: ({ data: cellData }) => {
        if (cellData?.OutstandingQty) {
          const label = 'Receive';
          const onButtonClick = () => receiveBtnClick(cellData?.sku_id);
          return actionButton(onButtonClick, label);
        }
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default ReceiveIRNTable;
