import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../constants/apiStatusCodes';
import { ERROR } from '../../../constants/constants';
import { acnListCsvDownload } from '../../../redux/APICaller';
import { showToastMessage } from '../../../redux/showMessageSlice';
import DropDown from '../FormComponents/Dropdown';
import Input from '../FormComponents/Input';
import styles from './styles';

const searchConsigment = [
  {
    label: 'ACN ID',
    value: 'ACN ID'
  },
  {
    label: 'PO No.',
    value: 'PO No.'
  },
  {
    label: 'Invoice No.',
    value: 'Invoice No.'
  }
];

const filterType = [
  {
    label: 'Inbound',
    value: 'Inbound'
  },
  {
    label: 'Outbound',
    value: 'Outbound'
  }
];

const searchStatus = [
  {
    label: 'CONSIGNMENT DISPATCHED',
    value: 'CONSIGNMENT DISPATCHED'
  },
  {
    label: 'CONSIGNMENT ACCEPTED',
    value: 'CONSIGNMENT ACCEPTED'
  },
  {
    label: 'CONSIGNMENT REJECTED',
    value: 'CONSIGNMENT REJECTED'
  },
  {
    label: 'GATE IN',
    value: 'GATE IN'
  }
];

const TableNavbar = ({ data, setData }) => {
  const dispatch = useDispatch();
  const { control, watch } = useForm();

  const searchConsigmentValue = watch('searchConsigment');
  const filterTypeValue = watch('filterType');
  const searchStatusValue = watch('searchStatus');
  const searchStr = watch('acnId');

  const [isSearchCalled, setIsSearchCalled] = useState(false);

  useEffect(() => {
    data.searchBy = searchConsigmentValue;
  }, [searchConsigmentValue]);

  useEffect(() => {
    if (filterTypeValue) setData({ ...data, consgnType: filterTypeValue });
  }, [filterTypeValue]);

  useEffect(() => {
    if (searchStatusValue) setData({ ...data, consgnStatus: searchStatusValue });
  }, [searchStatusValue]);

  useEffect(() => {
    if (!searchStr && isSearchCalled) {
      setData({ ...data, searchStr: '' });
      setIsSearchCalled(false);
    }
  }, [searchStr, isSearchCalled]);

  const handleDownloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  };

  const csvDownload = async () => {
    const customPayload = {};

    if (data.consgnType) customPayload.consignment_type = data.consgnType;
    if (data.consgnStatus) customPayload.consignment_status = data?.consgnStatus;
    if (data.searchStr) {
      if (data.searchBy === 'ACN ID') customPayload.acn_no = data?.searchStr;
      if (data.searchBy === 'PO No.') customPayload.po_no = data?.searchStr;
      if (data.searchBy === 'Invoice No.') customPayload.invoice_no = data?.searchStr;
    }

    const res = await acnListCsvDownload({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      handleDownloadCsv(res?.data?.url);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  const label = searchConsigmentValue || 'ACN ID';
  let isDisable = false;

  return (
    <Box sx={styles.dropDownWrapper}>
      <Box sx={styles.input}>
        <DropDown
          label="Search consignment by"
          placeholder="Search consignment by"
          name="searchConsigment"
          control={control}
          options={searchConsigment}
        />
      </Box>
      <Box sx={styles.input}>
        <Input
          label={label}
          name="acnId"
          placeholder={`Enter your ${label?.toLowerCase()}`}
          control={control}
          disabled={!searchConsigmentValue}
          isSearch={searchStr && searchConsigmentValue}
          onSearch={() => {
            setData({ ...data, searchStr: watch('acnId') });
            setIsSearchCalled(true);
          }}
        />
      </Box>
      <Box sx={styles.input}>
        <DropDown
          label="Filter Cosgn. Type"
          name="filterType"
          control={control}
          options={filterType}
        />
      </Box>
      <Box sx={styles.input}>
        <DropDown
          label="Search by Cosgn. Status"
          name="searchStatus"
          control={control}
          options={searchStatus}
        />
      </Box>
      <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon
          sx={styles.download}
          onClick={async () => {
            if (!isDisable) {
              isDisable = true;
              await csvDownload();
              isDisable = false;
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default TableNavbar;
