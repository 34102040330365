/**
 * Function To convert Brand List from API
 * @param {*} arrayOfObj
 * @returns
 */
export const BrandListConverter = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(({ brand_id: value, brand_name: label, ...rest }) => ({
    value,
    label,
    ...rest
  }));

  return newArrayOfObj;
};

/**
 * Function To convert Category List from API
 * @param {*} arrayOfObj
 * @returns
 */
export const CategoryListConverter = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ category_id: value, category_name: label, ...rest }) => ({
      value,
      label,
      ...rest
    })
  );
  return newArrayOfObj;
};

/**
 * Function To convert Warehouse List from API
 * @param {*} arrayOfObj
 * @returns
 */
export const WarehouseListConvertor = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ warehouse_id: value, warehouse_code: label, taluk, post, city, pincode, ...rest }) => ({
      value,
      label: `${label}, ${taluk}/${post}-${city}-${pincode}`,
      ...rest
    })
  );

  return newArrayOfObj;
};

/**
 * Function To convert Zone List from API
 * @param {*} arrayOfObj
 * @returns
 */

export const zoneListConvertor = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(({ zone_id: value, zone_name: label, ...rest }) => ({
    value,
    label,
    ...rest
  }));
  return newArrayOfObj;
};

/**
 * Function To convert Warehouse List from API
 * @param {*} arrayOfObj
 * @returns
 */
export const SupplierListConverter = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ supplier_id: value, supplier_name: label, ...rest }) => ({
      value,
      label,
      ...rest
    })
  );
  return newArrayOfObj;
};
