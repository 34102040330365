import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthStore,
  setWarehouseLocationAndType,
  setWarehouseLocationAndTypeList
} from '../src/redux/authSlice';
import AppRoutes from './routes';
import theme from './styles/theme';

const ymsOption = [
  {
    label: 'YMS',
    value: 1
  }
];

const warehouseOption = [
  {
    label: 'G5',
    value: 'a81bc81b-dead-4e5d-abff-90865d1e13c2'
  },
  {
    label: 'G7',
    value: 'c81bc81b-dead-4e5d-abff-90865d1e13c2'
  }
];

const orderTypeOption = [
  {
    label: 'B2B',
    value: 'B2B'
  },
  {
    label: 'B2C',
    value: 'B2C'
  }
];

const App = () => {
  const dispatch = useDispatch();
  const authStore = useSelector(selectAuthStore);

  useEffect(() => {
    dispatch(
      setWarehouseLocationAndTypeList({
        applicationTypeList: ymsOption,
        warehouseLocationList: warehouseOption,
        orderTypeList: orderTypeOption
      })
    );
  }, []);

  useEffect(() => {
    if (authStore?.warehouseLocationList?.length) {
      dispatch(
        setWarehouseLocationAndType({
          applicationType: 1,
          warehouseLocation: localStorage.getItem('facility_id')
            ? localStorage.getItem('facility_id')
            : authStore?.warehouseLocationList[0]?.value,
          orderType: localStorage.getItem('order_type')
            ? localStorage.getItem('order_type')
            : authStore?.orderTypeList[0]?.value
        })
      );
      if (!localStorage.getItem('facility_id'))
        localStorage.setItem('facility_id', authStore?.warehouseLocationList[0]?.value);
      if (!localStorage.getItem('order_type'))
        localStorage.setItem('order_type', authStore?.orderTypeList[0]?.value);
    }
  }, [authStore?.warehouseLocationList?.length]);

  // useEffect(() => {
  //   if (
  //     authStore?.warehouseLocation &&
  //     authStore?.warehouseLocation !== authStore?.warehouseLocationList[0]?.value
  //   ) {
  //     window.location.reload(false);
  //   }
  // }, [authStore?.warehouseLocation]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRoutes />
    </ThemeProvider>
  );
};

export default App;
