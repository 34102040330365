import { Box } from '@mui/material';
import printJS from 'print-js';
import React from 'react';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { Button, Pagination, Spinner, TableDataRenderer } from '../../../../common';
import styles from '../styles';

const SearchStockTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  TransferIdRenderer
}) => {
  const [{ width }] = useWindowDimensions();

  const WebView = width > 1000 ? 1 : 0;

  const columnDefs = [
    {
      field: 'TransferID',
      headerName: 'Transfer ID',
      flex: WebView,
      cellRenderer: ({ data }) => {
        return <TransferIdRenderer id={data?.TransferID} />;
      }
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: WebView
    },
    {
      field: 'SourceWarehouse',
      headerName: 'Source Warehouse',
      flex: WebView
    },
    {
      field: 'DestWarehouse',
      headerName: 'Dest. Warehouse',
      flex: WebView
    },
    {
      field: 'TransferQuantity',
      headerName: 'Transfer Quantity',
      flex: WebView
    },
    {
      field: 'CreationDate',
      headerName: 'Creation Date',
      flex: WebView
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: WebView,
      cellRenderer: ({ data }) => {
        return (
          <Button
            label="Print Putlist"
            onClick={() => {
              printJS(decodeURIComponent(data?.link));
            }}
          />
        );
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default SearchStockTable;
