import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { poCreatFileUpload } from '../../../../redux/APICaller/inboundOrderAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { MainContent, ShowMessage } from '../../../common';
import FileUpload from '../../../common/FileUpload';
import styles from './styles';

const CreateSO = () => {
  const dispatch = useDispatch();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isFileUploadedPartial, setIsFileUploadedPartial] = useState(false);
  const [file, setFile] = useState({ fileName: '', fileUrl: '' });
  const [uploadState, setUploadState] = useState('');

  const handleSampleCsvDownload = (uri = '') => {
    if (uri) {
      const link = document.createElement('a');
      link.href = uri;
      // link.download = 'createProductSample';
      link.target = '_self';
      link.click();
    }
  };

  const fileUploadApi = async (file, fileName) => {
    setUploadState('Upload in progress');
    const customPayload = { file };

    const res = await poCreatFileUpload({
      ...customPayload
    });

    if (res.status === SUCCESS) {
      setFile({ fileName: fileName, fileUrl: res?.data?.url });
      // if (res?.data?.url) {
      //   setIsFileUploaded(false);
      //   setIsFileUploadedPartial(true);
      //   handleSampleCsvDownload(res?.data?.url);
      // } else {
      //   setIsFileUploadedPartial(false);
      setIsFileUploaded(true);
      handleSampleCsvDownload(res?.data?.url);
      // }
      dispatch(
        showToastMessage({
          messageType: res?.data?.url ? SUCCESS_MSG : ERROR,
          message: res?.data?.message || 'File has been processed'
        })
      );
    } else {
      dispatch(
        showToastMessage({
          messageType: ERROR,
          message: res?.status === 400 ? res?.data?.message : ''
        })
      );
    }
    setUploadState('');
  };

  console.log('isFileUploaded', isFileUploaded);
  return (
    <MainContent heading="Create SO">
      <Box sx={styles.steps}>Step 1 :</Box>
      <Box sx={styles.sampleDownload}>
        <Box component="span">Download your pre-formatted csv file. Do not edit the format.</Box>
        <Box
          component="span"
          sx={styles.clickHere}
          onClick={() => {
            handleSampleCsvDownload(
              'https://wms-test-bucket-06.s3.ap-south-1.amazonaws.com/po-file.csv'
            );
          }}>
          Click here
        </Box>
      </Box>
      <Box sx={{ ...styles.steps, marginTop: '32px' }}>Step 2 :</Box>
      <Box sx={{ marginBottom: '8px' }}>Upload the file with changes.</Box>
      {!(isFileUploaded || isFileUploadedPartial) && (
        <FileUpload
          file={{
            fileName: file?.fileName,
            fileUrl: file?.fileUrl
          }}
          allowedFileTypes={['text/csv']}
          isFileUploaded={isFileUploaded}
          uploadState={uploadState}
          fileUploadApi={fileUploadApi}
        />
      )}
      {isFileUploaded && (
        <Box sx={styles.customWrap}>
          <ShowMessage
            message={
              'File has been processed, Please check downloaded csv for more details.' ||
              'File uploaded successfully'
            }
            type={SUCCESS_MSG}
            customStyles={styles.message}
          />
          <Box
            sx={{ ...styles.clickHere, mt: '6 px' }}
            onClick={() => {
              setIsFileUploaded(false);
              // setIsFileUploadedPartial(false);
            }}>
            Create again
          </Box>
        </Box>
      )}
      {/* {isFileUploadedPartial && (
        <Box sx={styles.customWrap}>
          <Box>
            <ShowMessage
              message={'The file was only partially uploaded.'}
              type={SUCCESS}
              customStyles={styles.message}
            />
          </Box>
          <Box
            sx={{ ...styles.clickHere, mt: '6 px' }}
            onClick={() => {
              setIsFileUploaded(false);
              setIsFileUploadedPartial(false);
            }}>
            Try again
          </Box>
        </Box>
      )} */}
      {/* {(isFileUploaded || isFileUploadedPartial) && (
        <>
          <Box sx={{ ...styles.steps, marginTop: '32px' }}>Step 3 :</Box>
          <Box sx={{ marginBottom: '8px' }}>Upload the file with changes.</Box>
          {!(isFileUploaded || isFileUploadedPartial) && (
            <FileUpload
              file={{
                fileName: file?.fileName,
                fileUrl: file?.fileUrl
              }}
              allowedFileTypes={['text/csv']}
              isFileUploaded={isFileUploaded}
              uploadState={uploadState}
              fileUploadApi={fileUploadApi}
            />
          )}
          {isFileUploaded && (
            <ShowMessage
              message={'File uploaded successfully'}
              type={SUCCESS}
              customStyles={styles.message}
            />
          )}
          {isFileUploadedPartial && (
            <Box sx={styles.customWrap}>
              <Box>
                <ShowMessage
                  message={'The file was only partially uploaded.'}
                  type={ERROR}
                  customStyles={styles.message}
                />
              </Box>
              <Box
                sx={{ ...styles.clickHere, mt: '12px' }}
                onClick={() => console.log('CLICKED :)')}>
                Try Again
              </Box>
            </Box>
          )}
        </>
      )} */}
    </MainContent>
  );
};

export default CreateSO;
