import { STOCK_TRANSFER_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI, putAPI } from '../../helpers/API';

const {
  POST_CREATE_TRANSFER_LIST,
  PUT_CONFIRM_PICK_TRANSFER_LIST,
  GET_PICK_TRANSFER_LIST,
  POST_SEARCH_TRANSFER_LIST,
  GET_VIEW_TRANSFER_LIST
} = STOCK_TRANSFER_PATHS;

/**
 * Create Transfer List
 * @param {*} data
 * @returns
 */
export function createTransferList(data) {
  return postAPI({
    url: POST_CREATE_TRANSFER_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Confirm Pick Transfer List
 * @param {*} data
 * @returns
 */
export function putConfirmPickTransferList(data) {
  return putAPI({
    url: PUT_CONFIRM_PICK_TRANSFER_LIST.replace(':transferID', data?.transferID),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Pick Transfer List
 * @param {*} data
 * @returns
 */
export function getPickTransferList(data) {
  return getAPI({
    url: GET_PICK_TRANSFER_LIST.replace(':transferID', data?.transferID),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Search Transfer List
 * @param {*} data
 * @returns
 */
export function searchTransferList(data) {
  return postAPI({
    url: POST_SEARCH_TRANSFER_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getViewTransfer(data) {
  return getAPI({
    url: GET_VIEW_TRANSFER_LIST.replace(':transferID', data?.transferId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
