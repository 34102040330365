import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import useWindowDimensions from '../../../../../../../hooks/useWindowDimensions';
import { Checkbox, Input, TableDataRenderer, Tooltip } from '../../../../../../common';
import styles from '../../../styles';

const PickingDetailsTable = () => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const { control } = useForm({ defaultValues: {} });

  const columnDefs = [
    {
      field: 'WID',
      headerName: 'WID',
      flex: isWebView ? 1 : 0
    },
    {
      field: 'Productdescription',
      headerName: 'Product description',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.Productdescription} />;
      }
    },
    { field: 'Units', headerName: 'Units', flex: isWebView ? 1 : 0 },
    { field: 'Shelf', headerName: 'Shelf', flex: isWebView ? 1 : 0 },
    {
      field: 'Pickedunits',
      headerName: 'Picked units',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return <Input name={`${data?.Pickedunits}-units`} control={control} />;
      }
    },
    {
      field: 'Picknotfound',
      headerName: 'Pick not found',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return <Checkbox name={`${data?.Picknotfound}-found`} control={control} />;
      }
    }
  ];

  const rowData = [
    {
      WID: 'XHHD02',
      Productdescription:
        'Blue Star 1.5 Ton 3 Star Fixed Speed Window AC (Copper, Turbo Cool, Humidity Control, Fan Models',
      Units: '4',
      Pickedunits: '234234',
      Picknotfound: '12 minutes ago'
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {/* {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />} */}
        <TableDataRenderer list={rowData} columnDefs={columnDefs} />
      </Box>
      {/* <Box sx={styles.paginationWrapper}>
      <Pagination
        count={pageData?.maxPage}
        page={page}
        onChange={(e, pageNumber) => pageClick(e, pageNumber)}
      />
    </Box> */}
    </Box>
  );
};

export default PickingDetailsTable;
