import { SLIP_CHECK_PATHS } from '../../constants/apiPaths';
import { getAPI, patchAPI, postAPI } from '../../helpers/API';

const { GET_BAY_PASS, GET_BAY_ALLOCATION_DETAILS, ACCEPT_CONSIGNMENT } = SLIP_CHECK_PATHS;

/**
 * Print Bay Pass
 * @param {*} data
 * @returns
 */
export function printBayPass(data) {
  return postAPI({
    url: GET_BAY_PASS,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

// Assign Bay For Truck
export function assignBay(data) {
  return postAPI({
    url: GET_BAY_ALLOCATION_DETAILS,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

// Allow Gate In
export function allowGateIn(data) {
  return patchAPI({
    url: ACCEPT_CONSIGNMENT,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

// Get Allocated Bay Details
export function allocatedBayDetails(data) {
  return getAPI({
    url: GET_BAY_ALLOCATION_DETAILS,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

// Accept/Reject Consignment
export function acceptConsignment(data) {
  return postAPI({
    url: ACCEPT_CONSIGNMENT,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
