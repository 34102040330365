const styles = {
  wrapper: {
    width: '100%'
  },

  tabsWrapper: {
    borderBottom: 1,
    borderColor: 'divider'
  },

  tab: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    }
  }
};

export default styles;
