import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import { ForgotPassword, OtpVerification, ResetPassword, SignIn } from '../components/Pages/index';
import { ErrorPage } from '../components/common';
import { DefaultLayout } from '../layout';
import { selectAuthStore } from '../redux/authSlice';
import PrivateRoutes from './privateRoutes';

const forgotPasswordComponentBySteps = (step) => {
  if (step === 1) return <ForgotPassword />;
  else if (step === 2) return <OtpVerification />;
  else if (step === 3) return <ResetPassword />;
  else return <ForgotPassword />;
};

const AppRoutes = () => {
  const authStore = useSelector(selectAuthStore);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route
            index
            element={
              <PrivateRoutes>
                <Dashboard type="overview" />
              </PrivateRoutes>
            }
          />
          <Route
            path="search-acn"
            element={
              <PrivateRoutes>
                <Dashboard type="search-acn" />
              </PrivateRoutes>
            }
          />
          <Route
            path="search-acn/details/:acnId"
            element={
              <PrivateRoutes>
                <Dashboard type="acn-details" />
              </PrivateRoutes>
            }
          />
          <Route
            path="search-acn/accept/:acnId"
            element={
              <PrivateRoutes>
                <Dashboard type="acn-accept" />
              </PrivateRoutes>
            }
          />
          <Route
            path="search-acn/allow-gate-in/:acnId"
            element={
              <PrivateRoutes>
                <Dashboard type="allow-get-in" />
              </PrivateRoutes>
            }
          />
          <Route
            path="create-manual-acn"
            element={
              <PrivateRoutes>
                <Dashboard type="create-manual-acn" />
              </PrivateRoutes>
            }
          />
          <Route
            path="allow-gate-out"
            element={
              <PrivateRoutes>
                <Dashboard type="allow-gate-out" />
              </PrivateRoutes>
            }
          />
          <Route
            path="receive-on-dock"
            element={
              <PrivateRoutes>
                <Dashboard type="receive-on-dock" />
              </PrivateRoutes>
            }
          />
          <Route
            path="receive-on-dock/confirm-receive-on-dock/:acnId"
            element={
              <PrivateRoutes>
                <Dashboard type="confirm-receive-on-dock" />
              </PrivateRoutes>
            }
          />
          <Route
            path="start-loading-unloading"
            element={
              <PrivateRoutes>
                <Dashboard type="start-loading-unloading" />
              </PrivateRoutes>
            }
          />
          <Route
            path="complete-loading-unloading"
            element={
              <PrivateRoutes>
                <Dashboard type="complete-loading-unloading" />
              </PrivateRoutes>
            }
          />
          <Route
            path="release-from-dock"
            element={
              <PrivateRoutes>
                <Dashboard type="release-from-dock" />
              </PrivateRoutes>
            }
          />
          <Route
            path="view-orders/:po_no?"
            element={
              <PrivateRoutes>
                <Dashboard type="view-orders" />
              </PrivateRoutes>
            }
          />
          <Route
            path="find-orders"
            element={
              <PrivateRoutes>
                <Dashboard type="find-orders" />
              </PrivateRoutes>
            }
          />
          <Route
            path="create-purchase-order"
            element={
              <PrivateRoutes>
                <Dashboard type="create-purchase-order" />
              </PrivateRoutes>
            }
          />
          <Route
            path="view-consignment/:acn_no?"
            element={
              <PrivateRoutes>
                <Dashboard type="view-consignment" />
              </PrivateRoutes>
            }
          />
          <Route
            path="find-consignment"
            element={
              <PrivateRoutes>
                <Dashboard type="find-consignment" />
              </PrivateRoutes>
            }
          />
          <Route
            path="find-return-order"
            element={
              <PrivateRoutes>
                <Dashboard type="find-return-order" />
              </PrivateRoutes>
            }
          />
          <Route
            path="view-return-order/:ro_no?"
            element={
              <PrivateRoutes>
                <Dashboard type="view-return-order" />
              </PrivateRoutes>
            }
          />
          <Route
            path="create-return-order"
            element={
              <PrivateRoutes>
                <Dashboard type="create-return-order" />
              </PrivateRoutes>
            }
          />
          <Route
            path="create-return-order/:irnId"
            element={
              <PrivateRoutes>
                <Dashboard type="create-return-order-form" />
              </PrivateRoutes>
            }
          />
          <Route
            path="create-irn"
            element={
              <PrivateRoutes>
                <Dashboard type="create-irn" />
              </PrivateRoutes>
            }
          />
          <Route
            path="receive-irn"
            element={
              <PrivateRoutes>
                <Dashboard type="receive-irn" />
              </PrivateRoutes>
            }
          />
          <Route
            path="receive-irn/:skuCode/:irnId"
            element={
              <PrivateRoutes>
                <Dashboard type="receive-irn-product-lookUp" />
              </PrivateRoutes>
            }
          />
          <Route
            path="receive-irn-complete/:irn/:irnId/:wid/:qcId"
            element={
              <PrivateRoutes>
                <Dashboard type="receive-irn-complete" />
              </PrivateRoutes>
            }
          />
          <Route
            path="view-irn"
            element={
              <PrivateRoutes>
                <Dashboard type="view-irn" />
              </PrivateRoutes>
            }
          />
          <Route
            path="create-putlist"
            element={
              <PrivateRoutes>
                <Dashboard type="create-putlist" />
              </PrivateRoutes>
            }
          />
          <Route
            path="complete-putlist"
            element={
              <PrivateRoutes>
                <Dashboard type="complete-putlist" />
              </PrivateRoutes>
            }
          />
          <Route
            path="find-putlist"
            element={
              <PrivateRoutes>
                <Dashboard type="find-putlist" />
              </PrivateRoutes>
            }
          />
          <Route
            path="view-putlist/:putlistId?"
            element={
              <PrivateRoutes>
                <Dashboard type="view-putlist" />
              </PrivateRoutes>
            }
          />
          <Route
            path="create-product"
            element={
              <PrivateRoutes>
                <Dashboard type="create-product" />
              </PrivateRoutes>
            }
          />
          <Route
            path="find-product"
            element={
              <PrivateRoutes>
                <Dashboard type="find-product" />
              </PrivateRoutes>
            }
          />
          <Route
            path="view-product/:sku_id?"
            element={
              <PrivateRoutes>
                <Dashboard type="view-product" />
              </PrivateRoutes>
            }
          />
          <Route
            path="search-inventory"
            element={
              <PrivateRoutes>
                <Dashboard type="search-inventory" />
              </PrivateRoutes>
            }
          />
          <Route
            path="create-transfer"
            element={
              <PrivateRoutes>
                <Dashboard type="create-transfer" />
              </PrivateRoutes>
            }
          />
          <Route
            path="pick-transfer"
            element={
              <PrivateRoutes>
                <Dashboard type="pick-transfer" />
              </PrivateRoutes>
            }
          />
          <Route
            path="put-away-transfer"
            element={
              <PrivateRoutes>
                <Dashboard type="put-away-transfer" />
              </PrivateRoutes>
            }
          />
          <Route
            path="search-transfer"
            element={
              <PrivateRoutes>
                <Dashboard type="search-transfer" />
              </PrivateRoutes>
            }
          />
          <Route
            path="view-transfer/:transferId?"
            element={
              <PrivateRoutes>
                <Dashboard type="view-transfer" />
              </PrivateRoutes>
            }
          />
          <Route
            path="create-so"
            element={
              <PrivateRoutes>
                <Dashboard type="create-so" />
              </PrivateRoutes>
            }
          />
          <Route
            path="all-orders"
            element={
              <PrivateRoutes>
                <Dashboard type="all-orders" />
              </PrivateRoutes>
            }
          />
          <Route
            path="all-orders/:order_id?"
            element={
              <PrivateRoutes>
                <Dashboard type="order-view" />
              </PrivateRoutes>
            }
          />
          <Route
            path="pick"
            element={
              <PrivateRoutes>
                <Dashboard type="pick" />
              </PrivateRoutes>
            }
          />
          <Route
            path="pick-details/:id"
            element={
              <PrivateRoutes>
                <Dashboard type="pick-details" />
              </PrivateRoutes>
            }
          />
          <Route
            path="pack"
            element={
              <PrivateRoutes>
                <Dashboard type="pack" />
              </PrivateRoutes>
            }
          />
          <Route
            path="manifest"
            element={
              <PrivateRoutes>
                <Dashboard type="manifest" />
              </PrivateRoutes>
            }
          />
          <Route
            path="put-back"
            element={
              <PrivateRoutes>
                <Dashboard type="put-back" />
              </PrivateRoutes>
            }
          />
          {/* Auth  */}
          <Route
            path="sign-in"
            element={
              localStorage.getItem('wms_access_token') &&
              localStorage.getItem('wms_access_roles') ? (
                <Navigate to="/" />
              ) : (
                <SignIn />
              )
            }
          />
          <Route
            path="forgot-password"
            element={
              localStorage.getItem('wms_access_token') &&
              localStorage.getItem('wms_access_roles') ? (
                <Navigate to="/" />
              ) : (
                forgotPasswordComponentBySteps(authStore.forgotPasswordSteps)
              )
            }
          />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
