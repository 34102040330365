import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { createRo } from '../../../../redux/APICaller/inboundReturnOrderAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import {
  Button,
  DescriptionBar,
  InputRenderer,
  MainContent,
  ProductInfoRenderer
} from '../../../common/index';
import FillDetails from './FillDetailsForm';
import GenerateReturnOrderTable from './GenerateReturnOrderTable';
import styles from './styles';

const GenerateReturnOrder = ({
  initSourcewarehouse,
  irnId,
  irnDetails,
  list,
  warehouseList,
  destWarehouseList,
  initDestwarehouse
}) => {
  const dispatch = useDispatch();
  const [generateRo, setGenerateRo] = useState(false);
  const [generatedRoId, setGeneratedRoId] = useState('');
  const [btnDisable, setBtnDisable] = useState(false);

  const { control, trigger, watch } = useForm({
    defaultValues: {
      sourceWhseCode: initSourcewarehouse,
      destWhseCode: initDestwarehouse,
      returnDate: moment().add(7, 'days').format('YYYY-MM-DD')
    },
    mode: 'all'
  });

  const getWarehouseDropdownData = async () => {
    setBtnDisable(true);
    const skuIdAndQty = [];
    const irnIds = list?.map((sku) => sku?.irn_item_id);
    irnIds?.forEach((element) => {
      const obj = {
        irn_item_id: element,
        ro_quantity: Number(watch(element))
      };
      skuIdAndQty.push(obj);
    });

    const customPayload = {
      irn_id: irnId,
      source_warehouse_id: watch('sourceWhseCode'),
      dest_warehouse_id: watch('destWhseCode'),
      return_date: watch('returnDate'),
      ro_selected_products_list: skuIdAndQty
    };

    const res = await createRo(customPayload);
    if (res?.status == SUCCESS) {
      setGenerateRo(true);
      setGeneratedRoId(res?.data?.ro_number);
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
    } else {
      setBtnDisable(false);
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const finishExecution = (res) => {
    if (res) {
      getWarehouseDropdownData();
    }
  };

  const triggerValidation = () => {
    const irnIds = list?.map((sku) => sku?.irn_item_id);
    const formInputNames = ['destWhseCode', 'returnDate', 'sourceWhseCode'];
    trigger([...formInputNames, ...irnIds]).then((res) => finishExecution(res));
  };

  return (
    <>
      <MainContent heading="Purchase Return Order Creation">
        <Box sx={styles.dataWrapper}>
          <Box sx={{ fontSize: '24px' }}>Fill Details to Generate RO</Box>
          <FillDetails
            generateRo={generateRo}
            control={control}
            watch={watch}
            irnId={irnId}
            supplier_name={irnDetails?.supplier_name}
            category_name={irnDetails?.category_name}
            po_number={irnDetails?.po_number}
            warehouseList={warehouseList}
            destWarehouseList={destWarehouseList}
          />
          <DescriptionBar label="Return Order Items Selected" />
          <GenerateReturnOrderTable
            generateRo={generateRo}
            control={control}
            list={list}
            inputRenderer={InputRenderer}
            purchaseOrder={ProductInfoRenderer}
            // isLoading={isLoading}
          />
          <Box>
            <Button
              label="Generate RO"
              disabled={btnDisable}
              onClick={() => triggerValidation()}
              customStyles={{ display: generateRo ? 'none' : 'block' }}
            />
            {generateRo && (
              <Box sx={styles.iconWrapper}>
                <CheckCircleOutlineIcon sx={styles.icon} />
                <Box
                  component="a"
                  href={`/view-return-order/${generatedRoId}`}
                  target="_blank"
                  sx={styles.lcanId}>
                  {generatedRoId}
                </Box>
                Generated
              </Box>
            )}
          </Box>
        </Box>
      </MainContent>
    </>
  );
};

export default GenerateReturnOrder;
