const styles = {
  wrapper: {
    width: '100%'
  },

  label: {
    height: {
      xs: '17px',
      sm: '20px'
    },
    marginBottom: {
      xs: '8px',
      sm: '12px'
    },
    color: 'custom.labelBlack'
  },

  select: {
    padding: 0,

    height: {
      xs: '34px',
      sm: '38px'
    },

    '& .MuiSelect-select': {
      padding: {
        xs: '15px 5px 13px',
        sm: '17px 6px 16px'
      },
      boxSizing: 'border-box'
    },

    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    },
    '& .MuiSelect-icon': {
      display: 'none'
    },
    backgroundPosition: 'calc(100% - 0.7em) center',
    backgroundRepeat: 'no-repeat'
  },

  menuItem: {
    height: {
      xs: '34px',
      sm: '42px'
    },
    color: 'black',
    p: '0 2px',
    borderBottom: '2px solid custom.connector',
    '&.Mui-selected': {
      backgroundColor: 'custom.lightBlue',
      color: 'white',

      '&:hover': {
        backgroundColor: 'custom.lightBlue',
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: 'custom.darkBlue',
      color: 'white'
    }
  },

  text: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: '10px',
    margin: 'auto 0',
    padding: {
      xs: '0 6px',
      sm: '0 8px'
    }
  },

  paper: {
    boxShadow: '0px 0px 12px custom.gray',
    border: '1px solid',
    borderColor: 'custom.gray',
    marginTop: '5px',
    maxHeight: {
      xs: '200px',
      sm: '350px'
    }
  },

  menu: {
    padding: '0'
  },

  errorMessage: {
    marginLeft: '0 !important'
  }
};

export default styles;
