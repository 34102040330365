const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    width: '100%',
    padding: '15px 10px',
    backgroundColor: 'white'
  },

  tableWrapper: {
    display: 'flex',
    border: '1px solid',
    borderCollapse: 'collapse',
    borderColor: 'custom.gray'
  },

  label: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 500,
    width: '50%',
    padding: {
      xs: '8px',
      sm: '10px'
    },
    borderRight: '2px solid',
    borderCollapse: 'collapse',
    borderColor: 'custom.gray'
  },

  inputWrapper: {
    width: '50%',
    margin: '8px 10px',
    marginBottom: '17px'
  },

  input: {
    position: 'relative',
    width: {
      xs: '100%',
      md: '45%'
    }
  },

  twoInput: {
    display: 'flex',
    flexDirection: 'column',
    width: {
      xs: '100%',
      md: '45%'
    },
    gap: '15px'
  },

  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      sm: 'flex-start'
    }
  },

  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      sm: 'flex-start'
    },
    gap: '10px',
    fontSize: '14px',
    fontWeight: 400
  },

  icon: {
    fontSize: '25px',
    color: '#04AA6D'
  },

  acnId: {
    fontWeight: 'bold',
    textDecoration: 'underline'
  },

  addMore: {
    userSelect: 'none',
    position: 'absolute',
    top: {
      xs: 5,
      sm: 8
    },
    right: {
      xs: 5,
      sm: 8
    },
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    color: 'custom.blue',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  invoiceNumberWrapper: {
    marginTop: '0.5em',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    color: 'custom.labelBlack'
  },

  invoiceNumberBox: {
    display: 'flex',
    border: '1px solid',
    borderColor: 'custom.gray',
    borderRadius: '5px',
    flexWrap: 'wrap',
    rowGap: {
      xs: '10px',
      sm: '15px'
    },
    padding: '0.5em 1em',
    userSelect: 'none'
  },

  invoiceNumber: {
    display: 'flex',
    gap: '8px',
    width: {
      xs: '100%',
      sm: '45%'
    },
    wordBreak: 'break-all',
    color: 'custom.blue'
  },

  number: {
    color: 'custom.labelBlack',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 500,
    width: {
      xs: '22px',
      sm: '25px'
    },
    textAlign: 'center',
    height: {
      xs: '22px',
      sm: '25px'
    },
    borderRadius: '50%',
    border: '1px solid',
    borderColor: 'custom.gray'
  }
};

export default styles;
