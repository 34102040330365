import { Box } from '@mui/material';
import React from 'react';
import { Input, NewDate } from '../../../common';
import DropDown from '../../../common/FormComponents/Dropdown';
import styles from '../styles';

const ACNDataRenderer = ({
  label,
  options,
  type,
  disabled,
  maxLength,
  name,
  control,
  inputType,
  rules
}) => {
  if (type === 'twoInput') {
    return (
      <Box sx={styles.twoInput}>
        <Box>
          <Input
            disabled={disabled}
            maxLength={maxLength}
            name={name.nameOne}
            placeholder="Enter No Of Units"
            type={inputType}
            control={control}
            rules={rules}
          />
        </Box>
        <Box>
          <Input
            disabled={disabled}
            maxLength={maxLength}
            name={name.nameTwo}
            placeholder="Enter No Of Packages"
            type={inputType}
            control={control}
            rules={rules}
          />
        </Box>
      </Box>
    );
  } else if (type === 'datetime-local') {
    return (
      <Box sx={styles.input}>
        <NewDate type="datetime-local" name={name} control={control} rules={rules} />
      </Box>
    );
  } else {
    return (
      <Box sx={styles.input}>
        <DropDown
          disabled={disabled}
          name={name}
          placeholder={label}
          control={control}
          options={options}
          rules={rules}
        />
      </Box>
    );
  }
};

export default ACNDataRenderer;
