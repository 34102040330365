import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Bar, Button, ErrorMessage, Input } from '../../../../common/index';
import styles from '../styles';

const VerifyConsignmentId = ({
  setOrderId,
  poNotFound,
  consignmentVerifyed,
  defaultPoId,
  setCreateIrnClicked
}) => {
  const { control, watch } = useForm({
    defaultValues: {
      verifyConsignment: defaultPoId
    }
  });

  const consignmentId = watch('verifyConsignment');

  const findClickHandler = () => {
    setOrderId(consignmentId);
  };

  return (
    <Bar heading="Verify Consignment for IRN">
      <Box sx={styles.viewPurchaseOrder}>
        <Box>
          <Input
            disabled={defaultPoId}
            name="verifyConsignment"
            label="Verify Consignment ID"
            placeholder="Enter ACN ID"
            control={control}
          />
        </Box>
        <Box sx={styles.findButton}>
          <Button
            label="Verify"
            customStyles={styles.actionButton}
            disabled={!consignmentId?.length || defaultPoId}
            onClick={findClickHandler}
          />
        </Box>
        {consignmentVerifyed && (
          <Box
            onClick={() => setCreateIrnClicked(true)}
            sx={{
              marginTop: '1.5em',
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              cursor: 'pointer',
              color: 'custom.blue',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}>
            Create IRN
          </Box>
        )}
      </Box>
      {poNotFound && (
        <Box sx={{ marginBottom: '-10px' }}>
          <ErrorMessage errorMsg="Please provide a valid Consignment ID" />
        </Box>
      )}
      {consignmentVerifyed && (
        <Box sx={styles.statusVerified}>
          <CheckCircleOutlineIcon sx={styles.verifiedIcon} />
          Status Verified
        </Box>
      )}
    </Bar>
  );
};

export default VerifyConsignmentId;
