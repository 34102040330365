import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BAD_REQUEST, SUCCESS } from '../../../constants/apiStatusCodes';
import { SearchACNDescription, SearchACNHeading } from '../../../constants/common';
import { ERROR } from '../../../constants/constants';
import { getAcceptConsignmentDetails } from '../../../redux/APICaller';
import { showToastMessage } from '../../../redux/showMessageSlice';
import SlipCheck from '../../common/SlipCheck/index';
import Spinner from '../../common/Spinner';
import { MainContent } from '../../common/index';

// eslint-disable-next-line react/prop-types
const SearchACN = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const getPayload = { acn_no: params?.acnId };

  const [payload, setPayload] = useState({
    acnId: '',
    plannedItemDetails: '',
    actualArrivalDateNtime: '',
    consignmentStatus: '',
    consignmentType: '',
    trailerNumber: '',
    trailerDriverName: '',
    driverPhoneNumber: '',
    isTrailerSealIntact: ''
  });

  const [initialLoad, setInitialLoad] = useState(true);

  const APICALL = async () => {
    const res = await getAcceptConsignmentDetails({
      ...getPayload
    });
    if (res.status === SUCCESS) {
      setPayload({
        ...payload,
        id: res?.data?.acn_id,
        acnId: res?.data?.acn_no,
        plannedItemDetails: res?.data?.item_details,
        actualArrivalDateNtime: res?.data?.planned_time_of_arrival,
        consignmentStatus: res?.data?.consignment_status,
        consignmentType: res?.data?.consignment_type,
        no_of_units: res?.data?.no_of_units,
        no_of_packages: res?.data?.no_of_packages,
        trailerNumber: '',
        trailerDriverName: '',
        driverPhoneNumber: '',
        isTrailerSealIntact: ''
      });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  useEffect(() => {
    APICALL();
  }, []);

  return (
    <>
      {initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading={SearchACNHeading} description={SearchACNDescription}>
          <SlipCheck data={payload} setData={setPayload} />
        </MainContent>
      )}
    </>
  );
};

export default SearchACN;
