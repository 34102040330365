import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { WarehouseListConvertor } from '../../../../constants/apiDataChanger';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { getWarehouseList } from '../../../../redux/APICaller';
import { roSelectedSkuList } from '../../../../redux/APICaller/inboundReturnOrderAPIs';
import { selectAuthStore } from '../../../../redux/authSlice';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import Spinner from '../../../common/Spinner';
import GenerateReturnOrderTable from './main';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ product_info: ProductInfo, irn_quantity: IRNQty, qc_status: QCStatus, ...rest }) => ({
      ProductInfo,
      IRNQty,
      QCStatus,
      ...rest
    })
  );

  return newArrayOfObj;
};

const GenerateReturnOrder = () => {
  const params = useParams();
  const store = useSelector(selectAuthStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [irnId, setIrnId] = useState('');
  const [irnDetails, setIrnDetails] = useState({});
  const [list, setList] = useState([]);
  const [warehouseList, setWarehouseList] = useState(null);
  const [destWarehouseList, setDestWarehouseList] = useState(null);

  const [initSourcewarehouse, setInitSourcewarehouse] = useState(null);
  const [initDestwarehouse, setInitDestwarehouse] = useState(null);

  const getDestWarehouseDropdownData = async (supplier_Id) => {
    const res = await getWarehouseList({ supplier_Id });
    if (res?.status == SUCCESS) {
      const destWarehouseList = WarehouseListConvertor(res?.data);
      setDestWarehouseList(destWarehouseList);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  const fetchRoDetails = async (irnId) => {
    const customPayload = {
      irn_id: irnId,
      irn_item_ids: store?.productsIds
    };

    const res = await roSelectedSkuList({
      page: 0,
      ...customPayload
    });
    if (res?.status === SUCCESS) {
      setIrnDetails(res?.data);
      if (res?.data?.supplier_Id) getDestWarehouseDropdownData(res?.data?.supplier_Id);
      const newData = dataKeyNameChange(res?.data?.list);
      setList(newData);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  const getWarehouseDropdownData = async () => {
    const res = await getWarehouseList({});
    if (res?.status == SUCCESS) {
      const warehouseList = WarehouseListConvertor(res?.data);
      setWarehouseList(warehouseList);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  useEffect(() => {
    if (!store?.productsIds) navigate('/create-return-order');
  }, [store?.productsIds]);

  useEffect(() => {
    if (
      (irnDetails?.source_warehouse?.warehouse_id || irnDetails?.dest_warehouse?.warehouse_id) &&
      warehouseList &&
      destWarehouseList
    ) {
      setInitSourcewarehouse(irnDetails?.source_warehouse?.warehouse_id || '');
      setInitDestwarehouse(irnDetails?.dest_warehouse?.warehouse_id || '');
    }
  }, [
    irnDetails?.source_warehouse?.warehouse_id,
    irnDetails?.dest_warehouse?.warehouse_id,
    warehouseList,
    destWarehouseList
  ]);

  useEffect(() => {
    if (initSourcewarehouse !== null && initDestwarehouse !== null) {
      setLoading(false);
    }
  }, [initDestwarehouse, initSourcewarehouse]);

  useEffect(() => {
    if (params?.irnId) {
      setIrnId(params?.irnId);
    }
  }, [params?.irnId]);

  useEffect(() => {
    if (irnId && store?.productsIds) {
      getWarehouseDropdownData();
      fetchRoDetails(irnId);
    }
  }, [irnId]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <GenerateReturnOrderTable
          initSourcewarehouse={initSourcewarehouse}
          initDestwarehouse={initDestwarehouse}
          irnId={irnId}
          irnDetails={irnDetails}
          list={list}
          warehouseList={warehouseList}
          destWarehouseList={destWarehouseList}
        />
      )}
    </>
  );
};

export default GenerateReturnOrder;
