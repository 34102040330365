import Box from '@mui/material/Box';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '../Pagination';
import Spinner from '../Spinner';
import TableDataRenderer from '../TableDataRenderer';
import styles from './styles';

const Table = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  acnID,
  addressRender,
  actionButton,
  itemDetails,
  POIdRenderer
}) => {
  const navigate = useNavigate();

  const acnIdRowClick = (acnId, status) => {
    if (status === 'Consignment dispatched') navigate(`accept/${acnId}`);
    if (status === 'Consignment accepted' || status === 'Gate in')
      navigate(`allow-gate-in/${acnId}`);
  };

  const columnDefs = [
    {
      field: 'ACNId',
      headerName: 'ACN ID',
      cellRenderer: ({ data: cellData }) => acnID(cellData)
    },
    { field: 'PlannedEDA', headerName: 'Planned EDA' },
    { field: 'ConsgType', headerName: 'Consg Type' },
    {
      field: 'PONo',
      headerName: 'PO No',
      cellRenderer: ({ data: cellData }) => POIdRenderer(cellData?.PONo)
    },
    {
      field: 'ItemDetails',
      headerName: 'Item Details',
      cellRenderer: ({ data }) => itemDetails(data)
    },
    {
      field: 'ShipToAddress',
      headerName: 'Ship To Address',
      cellRenderer: ({ data: cellData }) => addressRender(cellData)
    },
    { field: 'ConsignmentStatus', headerName: 'Consignment Status' },
    {
      field: 'Action',
      headerName: 'Action',
      cellRenderer: ({ data: cellData }) => {
        if (
          cellData?.ConsignmentStatus === 'Consignment rejected' ||
          cellData?.ConsignmentStatus === 'Gate in rejected' ||
          cellData?.ConsignmentStatus === 'In bay' ||
          cellData?.ConsignmentStatus === 'Gate out'
        )
          return null;
        const label = cellData.Action;
        const onButtonClick = () => acnIdRowClick(cellData?.ACNId, cellData?.ConsignmentStatus);
        return actionButton(onButtonClick, label);
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => pageClick(e, pageNumber)}
        />
      </Box>
    </Box>
  );
};

export default memo(Table);
