import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { stepperList } from '../Constants';
import styles from './styles';

const StepperList = ({ setShowStepper }) => {
  const [expanded, setExpanded] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Creating a variable to check if the device is mobile or desktop
   */
  const [{ width }] = useWindowDimensions();
  const isMobile = width < 900;

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  /**
   * @param {*} route
   * @returns Redirect to the defined route
   */
  const router = (route) => {
    if (isMobile) {
      setShowStepper(false);
    }
    return navigate(route);
  };

  return (
    <Box sx={styles.wrapper}>
      {stepperList?.map(({ heading, subHeadings }, index) => {
        return (
          <Accordion
            key={`${heading + '-' + index}`}
            disableGutters
            elevation={0}
            square
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={styles.mainTab}>
            <AccordionSummary
              onClick={() => index === 0 && router('/')}
              sx={{
                ...styles.headingWrapper,
                backgroundColor:
                  index === 0 && location.pathname === '/' ? '#485050' : 'transparent'
              }}
              expandIcon={index !== 0 && <ArrowForwardIosSharpIcon sx={styles.icon} />}>
              <Box sx={{ ...styles.heading, paddingLeft: index === 0 && { xs: '0', md: '1.8em' } }}>
                {heading}
              </Box>
              {index === 0 && location.pathname === '/' && <Box sx={styles.leftBar} />}
            </AccordionSummary>
            {index !== 0 && (
              <AccordionDetails>
                <Box>
                  {subHeadings?.map(({ label, route }, idx) => {
                    const checkActivePath = location.pathname.includes(route);
                    return (
                      <Box
                        key={`${label + '-' + idx}`}
                        onClick={() => route && router(route)}
                        sx={{
                          ...styles.label,
                          backgroundColor: checkActivePath ? '#485050' : 'transparent'
                        }}>
                        {label}
                        {checkActivePath && <Box sx={styles.leftBar} />}
                      </Box>
                    );
                  })}
                </Box>
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
    </Box>
  );
};

export default StepperList;
