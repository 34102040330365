import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/Button';
import styles from './styles';

const ErrorPage = () => {
  const navigate = useNavigate();

  /**
   * Automaticallly navigates to home page after 5 seconds
   */
  useEffect(() => {
    const redirect = setTimeout(() => navigate('/'), 5000);
    return () => clearTimeout(redirect);
  }, [navigate]);

  return (
    <Box sx={styles.wrapper}>
      <Box component="img" src="/404-error.png" alt="error" loading="lazy" sx={styles.image} />
      <Box sx={styles.heading}>The page you’re looking for doesn’t exist.</Box>
      <Button onClick={() => navigate('/')} label="Go back to home" />
    </Box>
  );
};

export default ErrorPage;
