import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Bar, Button, ErrorMessage, Input } from '../../../../common';
import styles from '../styles';

const TransferID = ({ setTransferId, transferNotFound, defaultTransferId }) => {
  const { control, watch } = useForm({
    defaultValues: {
      transferID: defaultTransferId
    }
  });

  const transferId = watch('transferID');

  const findClickHandler = () => {
    setTransferId(transferId);
  };

  return (
    <Bar heading="View Transfer Request">
      <Box sx={styles.viewPurchaseOrder}>
        <Box>
          <Input
            disabled={defaultTransferId}
            name="transferID"
            label="Transfer ID"
            control={control}
            placeholder="Enter Transfer ID"
          />
        </Box>
        <Box sx={styles.findButton}>
          <Button
            label="View"
            customStyles={styles.actionButton}
            disabled={!transferId?.length || defaultTransferId}
            onClick={() => findClickHandler()}
          />
        </Box>
      </Box>
      {transferNotFound && (
        <Box sx={{ marginBottom: '-10px' }}>
          <ErrorMessage errorMsg="Please provide a valid Transfer ID" />
        </Box>
      )}
    </Bar>
  );
};

export default TransferID;
