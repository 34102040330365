import { Box } from '@mui/material';
import React from 'react';
import { Pagination, Spinner, TableDataRenderer } from '../../../../common';
import styles from '../styles';

const FindProductTable = ({ list = [], pageData, pageClick, isLoading, page, SkuIdRenderer }) => {
  const columnDefs = [
    {
      field: 'SKUCode',
      headerName: 'SKU Code',
      cellRenderer: ({ data }) => {
        return <SkuIdRenderer id={data?.SKUCode} />;
      }
    },
    { field: 'BrandSKUCode', headerName: 'Brand SKU Code' },
    { field: 'FSN', headerName: 'FSN' },
    { field: 'ASIN', headerName: 'ASIN' },
    {
      field: 'Supplier_Brand',
      headerName: 'Brand'
    },
    { field: 'EANCode', headerName: 'EAN Code' },
    { field: 'TaxRate', headerName: 'Tax Rate' },
    {
      field: 'ShipmentType',
      headerName: 'Shipment Type'
    },
    {
      field: 'Category',
      headerName: 'Category'
    },
    {
      field: 'SKUType',
      headerName: 'SKU Type'
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default FindProductTable;
