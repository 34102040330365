import React from 'react';
import { Dropdown, NewDate, ReadOnlyList } from '../../../../common';

const FillDetails = ({
  control,
  generateRo,
  irnId,
  supplier_name,
  category_name,
  po_number,
  warehouseList = [],
  destWarehouseList = []
}) => {
  const returnOrderDetails = [
    {
      label: 'IRN ID',
      value: {
        type: 'write',
        value: irnId
      }
    },
    {
      label: 'Source Warehouse Code',
      value: {
        type: 'write',
        value: (
          <Dropdown
            name="sourceWhseCode"
            disabled={generateRo}
            control={control}
            options={warehouseList}
            rules={{
              required: {
                value: true,
                message: 'This is a required field'
              }
            }}
          />
        )
      }
    },
    {
      label: 'Dest. Warehouse Code',
      value: {
        type: 'write',
        value: (
          <Dropdown
            name="destWhseCode"
            disabled={generateRo}
            control={control}
            options={destWarehouseList}
            rules={{
              required: {
                value: true,
                message: 'This is a required field'
              }
            }}
          />
        )
      }
    },
    {
      label: 'Return Date',
      value: {
        type: 'write',
        value: (
          <NewDate
            name="returnDate"
            disabled={generateRo}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'This is a required field'
              }
            }}
          />
        )
      }
    },
    {
      label: 'Supplier',
      value: {
        type: 'write',
        value: supplier_name
      }
    },
    {
      label: 'Category',
      value: {
        type: 'write',
        value: category_name
      }
    },
    {
      label: 'PO No.',
      value: {
        type: 'link',
        value: po_number
      }
    }
  ];

  return (
    <ReadOnlyList
      heading="Return Order Details"
      fieldsData={returnOrderDetails}
      customStyles={{ padding: '16px 5px' }}
    />
  );
};

export default FillDetails;
