const styles = {
  dataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },

  readOnlyCards: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: {
      xs: '20px',
      md: '35px'
    },
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    justifyContent: 'space-between'
  },

  uploadDoc: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },

  viewPurchaseOrder: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  },

  findButton: {
    marginTop: {
      xs: '1.2em',
      sm: '1.6em'
    }
  },

  actionButton: {
    height: {
      xs: '34px',
      sm: '38px'
    }
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },

  lcanId: {
    cursor: 'pointer',
    transition: 'all 200ms ease',
    color: 'custom.blue',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  toolTip: {
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '300px',
    wordSpacing: 1.2
  },

  address: {
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '&:hover': {
      color: '#0858cf'
    }
  },

  dropDownWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '15px',
    marginBottom: '15px',
    position: 'relative',
    backgroundColor: '#F0F1F2',
    padding: '15px 16px'
  },

  input: {
    width: {
      xs: '100%',
      sm: '44.6%',
      md: '17.8%'
    }
  },

  downloadWrapper: {
    position: {
      xs: 'absolute',
      sm: 'static',
      md: 'absolute'
    },
    bottom: 8,
    right: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    padding: '4px',
    borderRadius: '5px',
    height: {
      xs: '32px',
      sm: '34px'
    },
    width: {
      xs: '34px',
      sm: '38px'
    }
  },

  download: {
    transition: 'all 400ms ease',
    cursor: 'pointer',
    color: 'white'
  },

  tableWrapper: {
    width: '100%',
    height: 470,
    overflowY: 'auto',
    fontFamily: "'Poppins', sans-serif",
    '& .ag-header-cell-text': {
      fontWeight: 500,
      fontSize: '16px'
    },
    '& .ag-cell': {
      fontSize: '14.5px',
      display: 'flex',
      paddingTop: '15px',
      paddingBottom: '15px',
      overflowY: 'auto'
    }
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%'
  },

  filterDataWrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    gap: '25px',
    alignItems: {
      xs: 'flex-start',
      sm: 'center'
    }
  },

  filterHeading: {
    fontSize: {
      xs: '16px',
      sm: '18px'
    },
    fontWeight: 500,
    userSelect: 'none'
  },

  filterData: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    userSelect: 'none',
    fontSize: {
      xs: '14px',
      sm: '16px'
    }
  },

  contentBox: {
    cursor: 'pointer'
  },

  content: {
    padding: '0.2em 0.4em',
    border: '1px solid',
    borderColor: 'custom.labelBlack',
    borderRadius: '5px',
    cursor: 'pointer',
    color: 'custom.blue'
  },

  uppercaseText: {
    textTransform: 'uppercase'
  },

  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },

  button: {
    height: '38px'
  },

  addIcon: {
    fontSize: '25px',
    color: 'custom.blue'
  }
};

export default styles;
