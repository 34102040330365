import { Box } from '@mui/material';
import printJS from 'print-js';
import React from 'react';
import { Button, Pagination, Spinner, TableDataRenderer } from '../../../../common';
import styles from '../styles';

const FindPutlistTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  PutlistIdRenderer
}) => {
  const columnDefs = [
    {
      field: 'PutlistID',
      headerName: 'Putlist ID',
      cellRenderer: ({ data }) => {
        return <PutlistIdRenderer id={data?.PutlistID} />;
      }
    },
    { field: 'Status', headerName: 'Status' },
    { field: 'Quantity', headerName: 'Quantity' },
    { field: 'WhseCode', headerName: 'Whse Code' },
    {
      field: 'CreationDate',
      headerName: 'Creation Date'
    },
    { field: 'CompletionDate', headerName: 'Completion Date' },
    { field: 'Putter', headerName: 'Putter' },
    {
      field: 'Action',
      headerName: 'Action',
      cellRenderer: ({ data }) => {
        return (
          <Button
            label="Print Putlist"
            onClick={() => {
              printJS(decodeURIComponent(data?.print_putlist_url));
            }}
          />
        );
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default FindPutlistTable;
