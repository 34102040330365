import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const MainContent = ({ heading, description, children }) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.heading}>{heading}</Box>
      <Box sx={styles.description}>{description}</Box>
      <Box sx={styles.childrenWrapper}>{children}</Box>
    </Box>
  );
};

export default MainContent;

MainContent.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node
};
