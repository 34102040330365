import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { getPutlistDetails, putlistItemsList } from '../../../../redux/APICaller/putawaysAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../../utils/index';
import { DescriptionBar, MainContent, ReadOnlyList, Spinner } from '../../../common';
import PutlistId from './PutlistId';
import ViewPutlistTable from './ViewPutlistTable';
import styles from './styles';

const putlistDetails = [
  {
    label: 'Putlist ID',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Warehouse Code',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Zone',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Status',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Created On',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Completed On',
    value: {
      type: 'read',
      value: ''
    }
  },
  {
    label: 'Putter Name',
    value: {
      type: 'read',
      value: ''
    }
  }
];

const keyValueMaker = (value, template, type) => {
  const tempTemplate = JSON.parse(JSON.stringify(template));
  Object.keys(value)?.forEach((key) => {
    if (type === 'putlist_details') {
      if (key === 'putlist_code') tempTemplate[0].value.value = value[key];
      else if (key === 'warehouse_code')
        tempTemplate[1].value.value = tempTemplate[1].value.value = value[key];
      else if (key === 'zone') tempTemplate[2].value.value = value[key];
      else if (key === 'status') tempTemplate[3].value.value = value[key];
      else if (key === 'created_at')
        tempTemplate[4].value.value = value[key] && getFormattedDate(value[key], 'DD/MM/YY');
      else if (key === 'completed_at')
        tempTemplate[5].value.value = value[key] && getFormattedDate(value[key], 'DD/MM/YY');
      else if (key === 'putter') tempTemplate[6].value.value = value[key];
    }
  });
  return tempTemplate;
};

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      oms_sku_code: SKUCode,
      description: ProductDescription,
      putaway_qty: PutawayQty,
      quantity_added: QtyAdded,
      storage_unit_code: ShelfCode,
      ...rest
    }) => ({
      SKUCode,
      ProductDescription,
      PutawayQty,
      QtyAdded,
      ShelfCode,
      ...rest
    })
  );

  return newArrayOfObj;
};

const ViewPutlist = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [putlistId, setPutlistId] = useState('');
  const [putlistIdOverview, setPutlistIdOverview] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [list, setList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [poNotFound, setPoNotFound] = useState(false);

  const dataFound = Object.keys(putlistIdOverview)?.length;

  const fetchPutlistDetails = async (putlistId) => {
    setLoading(true);
    const res = await getPutlistDetails({ putlistId });
    if (res?.status === SUCCESS) {
      if (poNotFound) setPoNotFound(false);
      const newRes = {};
      newRes.putlist_details = keyValueMaker(res?.data, putlistDetails, 'putlist_details');
      setPutlistIdOverview(newRes);
      setLoading(false);
    } else {
      setPutlistIdOverview({});
      setPoNotFound(true);
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  useEffect(() => {
    if (params?.putlistId) {
      setPutlistId(params?.putlistId);
    }
  }, [params?.putlistId]);

  useEffect(() => {
    if (putlistId) {
      setPoNotFound(false);
      fetchPutlistDetails(putlistId);
    }
  }, [putlistId]);

  const APICALL = async (page = 1) => {
    setIsLoading(true);
    const customPayload = { putlist_code: putlistId };

    const res = await putlistItemsList({
      page: page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page === 1) {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res.data.total_pages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    if (putlistId && dataFound) APICALL();
  }, [putlistId, dataFound]);

  return (
    <MainContent heading="Putlist Details">
      <Box sx={styles.dataWrapper}>
        <PutlistId
          setPutlistId={setPutlistId}
          roNotFound={poNotFound}
          defaultRoId={params?.putlistId}
        />
        {!poNotFound && loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            <DescriptionBar label="Information for Putlist ID:" value={putlistId} />
            <Box sx={styles.readOnlyCards}>
              {putlistIdOverview?.putlist_details && (
                <ReadOnlyList
                  heading="Putlist Details"
                  fieldsData={putlistIdOverview?.putlist_details}
                />
              )}
            </Box>
            <DescriptionBar label="Putlist Item Details" />
            <ViewPutlistTable
              list={tableRows}
              pageData={pageData}
              pageClick={(e, pageNumber) => {
                apiCallWithPage(pageNumber);
              }}
              isLoading={isLoading}
              page={pageData.currentPage}
            />
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default ViewPutlist;
