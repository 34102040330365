import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { consigmentType, dispatchStatus } from '../../../../constants/commonLabels';
import { Pagination, Spinner, TableDataRenderer } from '../../../common';
import InputModal from '../InputModal';
import styles from '../styles';

const StartLoadingUnloadingTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  onSuccess,
  acnID,
  invoiceNumber,
  actionButton,
  POIdRenderer
}) => {
  const [open, setOpen] = useState(false);
  const [currentCellData, setCurrentCellData] = useState();

  const allowGateOutClick = async (cellData) => {
    setCurrentCellData(cellData);
    setOpen(true);
  };

  const columnDefs = [
    {
      field: 'ACNId',
      headerName: 'ACN ID',
      cellRenderer: ({ data: cellData }) => acnID(cellData)
    },
    { field: 'TruckNo', headerName: 'Truck No.' },
    { field: 'ConsgType', headerName: 'Consg Type' },
    {
      field: 'PONo',
      headerName: 'PO No',
      cellRenderer: ({ data: cellData }) => POIdRenderer(cellData?.PONo)
    },
    {
      field: 'InvoNo',
      headerName: 'Invoice No.',
      cellRenderer: ({ data }) => invoiceNumber(data)
    },
    { field: 'TrailerLOC', headerName: 'Trailer LOC' },
    { field: 'TrailerStatus', headerName: 'Trailer Status' },
    {
      field: 'Action',
      headerName: 'Action',
      cellRenderer: ({ data: cellData }) => {
        const label =
          cellData?.ConsgType === consigmentType.inBound
            ? dispatchStatus.startUnloading
            : dispatchStatus.startLoading;
        const onButtonClick = () => allowGateOutClick(cellData);
        return actionButton(onButtonClick, label);
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
      {/* Modal */}
      {currentCellData && (
        <InputModal
          open={open}
          data={currentCellData}
          handleClose={() => {
            setOpen(false);
            setCurrentCellData(null);
          }}
          onSuccess={() => onSuccess(currentCellData)}
        />
      )}
    </Box>
  );
};

export default StartLoadingUnloadingTable;
