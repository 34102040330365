import { postAPI } from '../../helpers/API';
import { AUTH_PATHS } from '../../constants/apiPaths';

const { SIGN_IN, FIND_YOUR_ACCOUNT, OTP_VERIFY, RESET_PASSWORD, ACN_LIST_CSV_DOWNLOAD } =
  AUTH_PATHS;

/**
 * User Login API
 * @param {*} data
 */
export function userLogin(data) {
  return postAPI({ url: SIGN_IN, data, isPrivate: false, isCustomDomain: false });
}

/**
 * User forgot password
 * @param {*} data
 */
export function userForgotPassword(data) {
  return postAPI({ url: FIND_YOUR_ACCOUNT, data, isPrivate: false });
}

/**
 * User OTP Verification
 * @param {*} data
 */
export function userOtpVerify(data) {
  return postAPI({ url: OTP_VERIFY, data, isPrivate: false });
}

/**
 * User Reset Password
 * @param {*} data
 */
export function userResetPassword(data) {
  return postAPI({ url: RESET_PASSWORD, data, isPrivate: false });
}
