import { IRN_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI, putAPI, patchAPI } from '../../helpers/API';

const {
  GET_IRN_DETAILS,
  IRN_DETAILS_UPLOAD,
  PUT_IRN_DETAILS,
  GET_IRN_ITEM_LIST,
  PATCH_IRN_DETAILS,
  GET_SKU_PARTS_LIST,
  GET_RETURN_IRN_ITEM_LIST,
  POST_GENERATE_BARCODE,
  PUT_FINAL_RETURN_IRN_ITEM_DETAILS,
  POST_VIEW_IRN_STOCK
} = IRN_PATHS;

/**
 * Get IRN Details By Id
 * @param {*} data
 * @returns
 */
export function getIrnDetails(data) {
  return postAPI({
    url: GET_IRN_DETAILS.replace(':acnId', data?.acnId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Create IRN File Upload
 * @param {*} data
 * @returns
 */
export function irnDetailsFileUpload(data) {
  return postAPI({
    url: IRN_DETAILS_UPLOAD.replace(':acnId', data?.acnId).replace(':typeOfFile', data?.typeOfFile),
    data: data?.file,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true,
    isMultipart: true
  });
}

/**
 * Put IRN Details By Id
 * @param {*} data
 * @returns
 */
export function putIRNDetails(data) {
  return putAPI({
    url: PUT_IRN_DETAILS.replace(':irnId', data?.irn_id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * IRN Item List
 * @param {*} data
 * @returns
 */
export function irnItemsList(data) {
  return getAPI({
    url: GET_IRN_ITEM_LIST.replace(':irnId', data?.irnId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get IRN Details By Id
 * @param {*} data
 * @returns
 */
export function getIrnVerifyDetails(data) {
  return getAPI({
    url: PUT_IRN_DETAILS.replace(':irnId', data?.irn_id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Patch IRN Details
 * @param {*} data
 * @returns
 */
export function patchIRNDetails(data) {
  return patchAPI({
    url: PATCH_IRN_DETAILS.replace(':irn_id', data?.irn_id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get Sku Parts List
 * @param {*} data
 * @returns
 */
export function getSkuPartsList(data) {
  return getAPI({
    url: GET_SKU_PARTS_LIST.replace(':sku_id', data?.sku_id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * IRN Return Item List
 * @param {*} data
 * @returns
 */
export function irnReturnItemsList(data) {
  return getAPI({
    url: GET_RETURN_IRN_ITEM_LIST.replace(':irnId', data?.irnId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Print Bar code
 * @param {*} data
 * @returns
 */
export function printBarCode(data) {
  return postAPI({
    url: POST_GENERATE_BARCODE,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Put Final IRN Receive
 * @param {*} data
 * @returns
 */
export function putFinalIRNReceiveDetails(data) {
  return putAPI({
    url: PUT_FINAL_RETURN_IRN_ITEM_DETAILS,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * View Irn Stock List
 * @param {*} data
 * @returns
 */
export function viewIrnStock(data) {
  return postAPI({
    url: POST_VIEW_IRN_STOCK,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
