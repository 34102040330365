import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { BrandListConverter, CategoryListConverter } from '../../../../../constants/apiDataChanger';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { getBrandList, getCategoryList } from '../../../../../redux/APICaller';
import { findProductListCSVDownload } from '../../../../../redux/APICaller/browseProductAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, Dropdown, Input } from '../../../../common';
import styles from '../styles';

const FindProductFilters = ({ data, setData, onFind, isFindDisable, csvPayload }) => {
  const dispatch = useDispatch();
  const [supplierList, setSupplierList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const { control, watch, reset } = useForm({
    defaultValues: {
      productName: '',
      supplier: '',
      skuCode: '',
      productCategory: '',
      eanCode: '',
      shipmentType: '',
      productType: ''
    }
  });

  const productName = watch('productName');
  const supplier = watch('supplier');
  const skuCode = watch('skuCode');
  const eanCode = watch('eanCode');
  const shipmentType = watch('shipmentType');
  const productCategory = watch('productCategory');
  const productType = watch('productType');

  useEffect(() => {
    setData({ ...data, productName: productName });
  }, [productName]);

  useEffect(() => {
    if (supplier) setData({ ...data, supplier: supplier });
  }, [supplier]);

  useEffect(() => {
    setData({ ...data, skuCode: skuCode });
  }, [skuCode]);

  useEffect(() => {
    setData({ ...data, eanCode: eanCode });
  }, [eanCode]);

  useEffect(() => {
    setData({ ...data, shipmentType: shipmentType });
  }, [shipmentType]);

  useEffect(() => {
    setData({ ...data, productType: productType });
  }, [productType]);

  useEffect(() => {
    setData({ ...data, productCategory: productCategory });
  }, [productCategory]);

  const getSupplierDropdownData = async () => {
    const res = await getBrandList({});
    if (res?.status == SUCCESS) {
      setSupplierList(BrandListConverter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const getProductCategoryDropdownData = async () => {
    const res = await getCategoryList({});
    if (res?.status == SUCCESS) {
      setProductCategoryList(CategoryListConverter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    getSupplierDropdownData();
    getProductCategoryDropdownData();
  }, []);

  let isDisable = false;
  const handleDownloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  };

  const csvDownload = async () => {
    const customPayload = {};
    if (csvPayload.productName) customPayload.product_name = csvPayload.productName;
    if (csvPayload.shipmentType) customPayload.shipment_type = csvPayload.shipmentType;
    if (csvPayload.eanCode) customPayload.ean_code = csvPayload.eanCode;
    if (csvPayload.skuCode) customPayload.sku_code = csvPayload.skuCode;
    if (csvPayload.productType) customPayload.product_type = csvPayload.productType;
    if (csvPayload.productCategory) customPayload.category_id = csvPayload?.productCategory;
    if (csvPayload.supplier) customPayload.supplier_id = csvPayload?.supplier;

    const res = await findProductListCSVDownload({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      handleDownloadCsv(res?.data?.url);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  return (
    <Box sx={styles.filterWrapper}>
      <Box sx={styles.input}>
        <Input
          label="Product Name"
          name="productName"
          control={control}
          placeholder="Find by product name"
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown label="Brand" name="supplier" control={control} options={supplierList} />
      </Box>
      <Box sx={styles.input}>
        <Input
          label="SKU/Portal/Brand Code"
          name="skuCode"
          control={control}
          placeholder="Find by product code"
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Product Category"
          name="productCategory"
          control={control}
          options={productCategoryList}
        />
      </Box>
      <Box sx={styles.input}>
        <Input label="EAN Code" name="eanCode" control={control} placeholder="Find by EAN code" />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Shipment Type"
          name="shipmentType"
          control={control}
          options={[
            { label: 'Single', value: 'Single' },
            { label: 'Multi', value: 'Multi' }
          ]}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Product Type"
          name="productType"
          control={control}
          options={[
            { label: 'Simple', value: 'Simple' },
            { label: 'Bundle', value: 'Bundle' }
          ]}
        />
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button
          label="Find"
          disabled={isFindDisable}
          customStyles={styles.actionButton}
          onClick={() => onFind()}
        />
        <Button
          label="Reset"
          disabled={isFindDisable}
          customStyles={styles.actionButton}
          onClick={() => {
            reset();
            onFind('reset');
          }}
        />
      </Box>
      <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon
          sx={styles.download}
          onClick={async () => {
            if (!isDisable) {
              isDisable = true;
              await csvDownload();
              isDisable = false;
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default FindProductFilters;
