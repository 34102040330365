import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../constants/apiStatusCodes';
import { consigmentType, dispatchStatus } from '../../../../constants/commonLabels';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { required } from '../../../../constants/errorMessage';
import { startLoadingUnloading as fieldsData } from '../../../../constants/formData';
import { trailerStatus } from '../../../../constants/messages';
import { startLoadingUnloading } from '../../../../redux/APICaller/dockAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Input, Modal } from '../../../common';
import DropDown from '../../../common/FormComponents/Dropdown';
import styles from './styles';

const InputModal = ({ data, open, handleClose, onSuccess }) => {
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();
  const { control, trigger, watch, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      delayReason: 'none'
    }
  });

  useEffect(() => {
    setValue('trailerNumber', data?.TruckNo);
    setValue('consignmentType', data?.ConsgType);
    setValue('dock', data?.TrailerLOC || data?.dock_id);
  }, []);

  const payload = {
    truck_number: watch('trailerNumber'),
    delay_reasons: watch('delayReason'),
    trip_id: data?.trip_id,
    manpower: watch('numberOfLoaderUnloader')
  };

  const startLoadingUnloadingApi = async () => {
    const res = await startLoadingUnloading(payload);
    if (watch('delayReason') !== 'none') {
      payload.delay_reasons = watch('delayReason');
    }
    if (res.status === SUCCESS) {
      onSuccess();
      setConfirm(true);
      dispatch(
        showToastMessage({
          messageType: SUCCESS_MSG,
          message: res?.data?.message || trailerStatus
        })
      );
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res.data.message }));
    }
  };

  const finishExecution = (res) => {
    if (res) {
      startLoadingUnloadingApi();
    }
  };

  const triggerValidation = () => {
    trigger().then((res) => finishExecution(res));
  };

  return (
    <Modal
      heading={
        data?.ConsgType === consigmentType.inBound
          ? dispatchStatus.startUnloading
          : dispatchStatus.startLoading
      }
      open={open}
      handleClose={handleClose}
      hideAcnBtn={confirm}
      onSuccess={() => {
        triggerValidation();
      }}
      buttonLabel={
        data?.ConsgType === consigmentType.inBound
          ? dispatchStatus.startUnloading
          : dispatchStatus.startLoading
      }>
      <Box component="form" sx={{ transform: 'scale(0.95)' }}>
        <Box>
          {fieldsData?.map(
            (
              {
                disabled,
                maxLength,
                name,
                label,
                type,
                options,
                isRequired = false,
                inputType = 'text'
              },
              index
            ) => {
              return (
                <Box key={`${name + '-' + index}`} sx={styles.tableWrapper}>
                  <Box sx={styles.label}>{label}</Box>
                  <Box sx={styles.inputWrapper}>
                    {type === 'input' ? (
                      <Box sx={styles.input}>
                        <Input
                          disabled={disabled}
                          maxLength={maxLength}
                          name={name}
                          placeholder={label}
                          type={inputType}
                          control={control}
                          rules={{
                            required: {
                              value: isRequired,
                              message: required
                            }
                          }}
                        />
                      </Box>
                    ) : (
                      <Box sx={styles.input}>
                        <DropDown
                          name={name}
                          placeholder={label}
                          control={control}
                          options={options}
                          rules={{
                            required: {
                              value: isRequired,
                              message: required
                            }
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            }
          )}
        </Box>
        {confirm && (
          <Box sx={styles.description}>
            <CheckCircleOutlineIcon sx={styles.icon} />
            Trailer status updated
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default InputModal;
