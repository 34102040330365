import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { searchTransferList } from '../../../../redux/APICaller/stockTransferAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../../utils/index';
import { DescriptionBar, MainContent } from '../../../common';
import Spinner from '../../../common/Spinner';
import { TransferIdRenderer } from '../../../common/TableRenderers';
import SearchStockFilters from './SearchStockFilters';
import SearchStockTable from './SearchStockTable';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      transfer_code: TransferID,
      status: Status,
      sku: SKU,
      wid_code: WID,
      source_warehouse: SourceWarehouse,
      destination_warehouse: DestWarehouse,
      transfer_quantity: TransferQuantity,
      creation_date: CreationDate,
      ...rest
    }) => ({
      TransferID,
      Status,
      SKU,
      WID,
      SourceWarehouse,
      DestWarehouse,
      TransferQuantity,
      CreationDate: getFormattedDate(CreationDate, 'DD/MM/YY'),
      ...rest
    })
  );

  return newArrayOfObj;
};

const initPayload = {
  transferId: '',
  sourceWarehouse: '',
  destinationWarehouse: '',
  status: '',
  startDate: '',
  endDate: ''
};

const SearchStockTransfer = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(initPayload);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [tableRows, setTableRows] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const isFindDisable = !(
    payload?.transferId ||
    payload?.sourceWarehouse ||
    payload?.startDate ||
    payload?.endDate ||
    payload?.destinationWarehouse ||
    payload?.status
  );

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1, isBtnClick) => {
    setLoading(true);
    const customPayload = {};
    customPayload.from_date =
      payload?.startDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    customPayload.to_date = payload?.endDate || moment().format('YYYY-MM-DD');

    if (page !== 'reset') {
      if (payload.transferId) customPayload.transfer_code = payload.transferId;
      if (payload.sourceWarehouse) customPayload.source_warehouse_id = payload.sourceWarehouse;
      if (payload.destinationWarehouse)
        customPayload.destination_warehouse_id = payload.destinationWarehouse;
      if (payload.status) customPayload.status = payload.status;
    } else {
      customPayload.from_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
      customPayload.to_date = moment().format('YYYY-MM-DD');
      setPayload(initPayload);
    }
    const res = await searchTransferList({
      page: page === 'reset' ? 0 : page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        if (isBtnClick) setIsFilterApplied(true);
      } else {
        setIsFilterApplied(false);
      }
      if (page === 1 || page === 'reset') {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      setTotalItems(res?.data?.total_elements);
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  return (
    <Box>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent
          heading="Warehouse-To-Warehouse Stock Transfer"
          description="Search Transfer Request">
          <SearchStockFilters
            data={payload}
            setData={setPayload}
            onFind={(type) => {
              APICALL(type, 'btnClick');
            }}
            isFindDisable={isFilterApplied ? !isFilterApplied : isFindDisable}
          />
          <DescriptionBar label="Total number of Transfer Requet Found:" value={totalItems} />
          <SearchStockTable
            TransferIdRenderer={TransferIdRenderer}
            list={tableRows}
            pageData={pageData}
            pageClick={(e, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
          />
        </MainContent>
      )}
    </Box>
  );
};

export default SearchStockTransfer;
