/* eslint-disable */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../constants/constants';
import { bayAllocated, bayPassGenerated } from '../../../constants/messages';
import { getDropdownBayIdList } from '../../../redux/APICaller';
import {
  allocatedBayDetails,
  allowGateIn,
  assignBay,
  printBayPass
} from '../../../redux/APICaller/slipCheckAPIs';
import { showToastMessage } from '../../../redux/showMessageSlice';
import Button from '../Button';
import DropDown from '../FormComponents/Dropdown';
import Input from '../FormComponents/Input';
import Modal from '../Modal';
import styles from './styles';

const SlipCheck = ({ data, setData }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isStatusUpdated, setIsStatusUpdated] = useState(false);

  const { control, setValue, trigger, watch } = useForm({
    mode: 'all'
  });

  const formFieldsData = [
    {
      name: 'acnId',
      label: 'ACN ID',
      type: 'input',
      isRequired: true,
      disabled: true
    },
    {
      isHide: true,
      isRequired: true,
      name: 'invoiceNumber',
      label: 'Number of invoices',
      type: 'dropDown',
      options: [
        {
          label: '1',
          value: 1
        },
        {
          label: '2',
          value: 2
        },
        {
          label: '3',
          value: 3
        },
        {
          label: '4',
          value: 4
        },
        {
          label: '5',
          value: 5
        },
        {
          label: '6',
          value: 6
        },
        {
          label: '7',
          value: 7
        },
        {
          label: '8',
          value: 8
        },
        {
          label: '9',
          value: 9
        },
        {
          label: '10',
          value: 10
        }
      ]
    },
    {
      isHide: true,
      name: 'totalInvoiceQuantity',
      label: 'Total invoice quantity',
      type: 'input',
      isRequired: true,
      maxLength: 10,
      inputType: 'number'
    },
    {
      name: 'consignmentType',
      label: 'Consignment type',
      type: 'input',
      disabled: true,
      isRequired: true
    },
    {
      name: 'trailerNumber',
      label: 'Trailer number',
      type: 'input',
      disabled: true,
      isRequired: true
    },
    {
      isRequired: true,
      name: 'trailerType',
      label: 'Trailer type',
      type: 'dropDown',
      options: [
        {
          label: 'Single Axle',
          value: 'singleAxle'
        },
        {
          label: 'Multi Axle',
          value: 'multiAxle'
        }
      ]
    },
    {
      isRequired: true,
      name: 'trailerSize',
      label: 'Trailer size',
      type: 'dropDown',
      options: [
        {
          label: '8 feet',
          value: '8 feet'
        },
        {
          label: '10 feet',
          value: '10 feet'
        },
        {
          label: '14 feet',
          value: '14 feet'
        },
        {
          label: '17 feet',
          value: '17 feet'
        },
        {
          label: '20 feet',
          value: '20 feet'
        },
        {
          label: '22 feet',
          value: '22 feet'
        },
        {
          label: '24 feet',
          value: '24 feet'
        },
        {
          label: '32 feet',
          value: '32 feet'
        }
      ]
    }
  ];

  const [fieldsData, setFieldsData] = useState(formFieldsData);

  const initModalData = [
    {
      name: 'proceed',
      label: 'Proceed to Bay Area',
      type: 'input',
      isRequired: true
    },
    {
      name: 'proceedId',
      label: 'Proceed to Bay ID',
      type: 'dropdown',
      isRequired: true
    }
  ];

  const {
    control: modalControl,
    setValue: modalSetValue,
    trigger: modalTrigger,
    watch: modalWatch
  } = useForm({
    defaultValues: {
      customDropdown: ''
    },
    mode: 'all'
  });

  useEffect(() => {
    if (data?.consignmentType === 'Inbound') {
      const tempFields = [...fieldsData];
      tempFields[1].isHide = false;
      tempFields[2].isHide = false;
      setFieldsData(tempFields);
    }
  }, [data?.consignmentType]);

  useEffect(() => {
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (data[key]) setValue(key, data[key]);
        }
      }
    }
  }, [data]);

  const [modalDetails, setModalDetails] = useState({});
  const [isBayAllocated, setIsBayAllocated] = useState(false);
  /**
   * Modal State
   */
  const [bayOptions, setBayOptions] = useState([]);
  const [isBayOptionsSet, setIsBayOptionsSet] = useState(false);

  const getModalDetails = async () => {
    const res = await allocatedBayDetails({
      acn_no: data?.acnId,
      truck_number: data?.trailerNumber
    });

    if (res?.status == SUCCESS) {
      modalSetValue('proceed', res?.data?.bay_area);
      modalSetValue('proceedId', res?.data?.bay_code);
      setModalDetails(res?.data);
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  // Modal data fetching
  useEffect(() => {
    if (data?.consignmentStatus === 'GATE IN') {
      setLoading(true);
      setIsStatusUpdated(true);
      setOpen(true);
    }
  }, [data?.consignmentStatus]);

  /**
   * Fetching Modal data once screen is loaded
   */
  useEffect(() => {
    getModalDetails();
  }, []);

  const APICALL = async (payload) => {
    setLoading(true);

    const res = await allowGateIn({
      ...payload
    });
    if (res?.status == SUCCESS) {
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
      setIsStatusUpdated(true);
    } else {
      setLoading(false);
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const finishExecution = (res) => {
    if (res) {
      APICALL({
        acn_id: data?.id,
        no_of_invoices: watch('invoiceNumber'),
        invoice_quantity: watch('totalInvoiceQuantity') && Number(watch('totalInvoiceQuantity')),
        trailer_type: watch('trailerType'),
        trailer_size: watch('trailerSize')
      });
    }
  };

  const triggerValidation = () => {
    trigger().then((res) => finishExecution(res));
  };

  const finishModalExecution = async (res) => {
    if (res) {
      const res = await assignBay({
        truck_number: data?.trailerNumber,
        trip_id: modalDetails?.trip_id,
        bay_id: modalWatch('customDropdown') || modalDetails?.bay_id
      });

      if (res?.status == SUCCESS) {
        setIsBayAllocated(true);

        dispatch(
          showToastMessage({
            messageType: SUCCESS_MSG,
            message: res?.data?.message || bayAllocated
          })
        );
      } else {
        if (res?.status === 500) setIsBayAllocated(true);
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      }
    }
  };

  const handleBayPassDownload = (uri) => {
    const link = document.createElement('a');
    link.href = uri;
    link.target = '_blank';
    link.download = 'SamplePDF.pdf';
    link.click();
  };

  const finishBayPass = async () => {
    const res = await printBayPass({
      truck_number: data?.trailerNumber,
      acn_no: data?.acnId
    });

    if (res?.status == SUCCESS) {
      handleBayPassDownload(res?.data?.url);
      dispatch(
        showToastMessage({
          messageType: SUCCESS_MSG,
          message: res?.data?.message || bayPassGenerated
        })
      );
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const triggerModalValidation = (e) => {
    modalTrigger().then((res) => {
      if (e?.target.innerText === 'Confirm Bay') finishModalExecution(res);
      else finishBayPass();
    });
  };

  /**
   * Custom Dropdown List
   */

  const dataKeyNameChange = (arrayOfObj) => {
    const newArrayOfObj = arrayOfObj?.map(({ bay_code: label, bay_id: value, ...rest }) => ({
      label,
      value,
      ...rest
    }));

    return newArrayOfObj;
  };

  const fetchDropList = async () => {
    const res = await getDropdownBayIdList({
      bay_area: data?.consignmentType,
      acn_no: data?.acnId
    });
    if (res?.status == SUCCESS) {
      setBayOptions(dataKeyNameChange(res?.data));
      setIsBayOptionsSet(true);
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  /**
   * Fetching Custom Dropdown List
   */
  useEffect(() => {
    fetchDropList();
  }, []);

  useEffect(() => {
    modalSetValue('customDropdown', modalDetails?.bay_id);
  }, [modalDetails?.bay_id, modalSetValue]);

  return (
    <Box component="form">
      <Box sx={styles.wrapper}>
        <Box>
          {fieldsData?.map(
            (
              {
                name,
                label,
                type,
                options,
                isRequired = false,
                disabled = false,
                inputType = 'text',
                maxLength,
                isHide
              },
              index
            ) => {
              if (isHide) return null;
              return (
                <Box key={`${name + '-' + index}`} sx={styles.tableWrapper}>
                  <Box sx={styles.label}>{label}</Box>
                  <Box sx={styles.inputWrapper}>
                    {type === 'input' ? (
                      <Box sx={styles.input}>
                        <Input
                          maxLength={maxLength}
                          name={name}
                          type={inputType}
                          placeholder={label}
                          control={control}
                          rules={{
                            required: {
                              value: isRequired,
                              message: 'This is required field'
                            },
                            validate: {
                              isXDigits: (value) => {
                                if (value?.length > 6 && name === 'totalInvoiceQuantity') {
                                  return 'Total invoice quantity should be less than 999999';
                                }
                              }
                            }
                          }}
                          disabled={disabled || loading}
                        />
                      </Box>
                    ) : (
                      <Box sx={styles.input}>
                        <DropDown
                          name={name}
                          placeholder={label}
                          control={control}
                          options={options}
                          rules={{
                            required: {
                              value: isRequired,
                              message: 'This is required field'
                            }
                          }}
                          disabled={disabled || loading}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            }
          )}
        </Box>
        <Box sx={styles.buttonWrapper}>
          {!isStatusUpdated ? (
            <Button label="Allow Gate In" onClick={() => triggerValidation()} disabled={loading} />
          ) : null}
          {/* () => setOpen(true) */}
          {isStatusUpdated ? (
            <Box sx={styles.gateIn} onClick={() => setOpen(true)}>
              Allocate bay
            </Box>
          ) : null}
        </Box>
        {isStatusUpdated && (
          <Box sx={styles.description}>
            <CheckCircleOutlineIcon sx={styles.icon} />
            Consignment status updated
          </Box>
        )}
      </Box>
      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        heading="Bay Information"
        buttonLabel={isBayAllocated ? 'Print bay pass' : 'Confirm Bay'}
        showDescription="Bay Confirmed"
        // hideAcnBtn={isBayAllocated}
        onSuccess={(e) => triggerModalValidation(e)}>
        <Box>
          {initModalData?.map(
            ({ name, label, type, options, isRequired = false, value = '' }, index) => {
              return (
                <Box key={`${name + '-' + index}`} sx={styles.tableWrapper}>
                  <Box sx={styles.label}>{label}</Box>
                  <Box sx={styles.inputWrapper}>
                    {type === 'input' ? (
                      <Box>
                        <Input
                          name={name}
                          placeholder={label}
                          control={modalControl}
                          rules={{
                            required: {
                              value: isRequired,
                              message: 'This is required field'
                            }
                          }}
                          disabled
                        />
                      </Box>
                    ) : (
                      <Box>
                        {bayOptions.length !== 0 ? (
                          <DropDown
                            name="customDropdown"
                            control={modalControl}
                            disabled={isBayAllocated}
                            options={bayOptions}
                          />
                        ) : (
                          isBayOptionsSet && (
                            <Box sx={{ color: 'error.main', fontSize: '16px' }}>
                              All Bays Occupied
                            </Box>
                          )
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            }
          )}
          {isBayAllocated && (
            <Box sx={{ ...styles.buttonWrapper, justifyContent: 'center', marginTop: '0.5em' }}>
              <Box sx={styles.description}>
                <CheckCircleOutlineIcon sx={styles.icon} />
                Bay allocated
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default SlipCheck;
