import { INBOUND_ORDER_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI } from '../../helpers/API';

const {
  POST_CREATE_PO_FILE_UPLOAD,
  GET_PO_LIST_LIST,
  GET_PO_LIST_LIST_CSV_DOWNLOAD,
  GET_PO_DETAILS,
  GET_PO_LIST_DETAILS,
  GET_PO_DETAILS_CSV
} = INBOUND_ORDER_PATHS;

/**
 * PO File Upload
 * @param {*} data
 * @returns
 */
export function poCreatFileUpload(data) {
  return postAPI({
    url: POST_CREATE_PO_FILE_UPLOAD,
    data: data?.file,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true,
    isMultipart: true
  });
}

/**
 * Find PO List
 * @param {*} data
 * @returns
 */
export function findPoList(data) {
  return postAPI({
    url: GET_PO_LIST_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Find PO List CSV Download
 * @param {*} data
 * @returns
 */
export function findPoListCsvDownload(data) {
  return postAPI({
    url: GET_PO_LIST_LIST_CSV_DOWNLOAD,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get PO Details By Id
 * @param {*} data
 * @returns
 */
export function getPoDetails(data) {
  return getAPI({
    url: GET_PO_DETAILS.replace(':POid', data?.POid),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * PO List
 * @param {*} data
 * @returns
 */
export function poList(data) {
  return getAPI({
    url: GET_PO_LIST_DETAILS.replace(':POid', data?.POid),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * PO Details Download
 * @param {*} data
 * @returns
 */
export function poDetailsCSVDownload(data) {
  return postAPI({
    url: GET_PO_DETAILS_CSV.replace(':po_id', data?.po_id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
