const styles = {
  snackBar: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    zIndex: 1000
  },

  message: {
    color: 'white',
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    maxWidth: {
      xs: '300px',
      sm: '450px'
    }
  }
};

export default styles;
