import Box from '@mui/material/Box';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  SupplierListConverter,
  WarehouseListConvertor,
  zoneListConvertor
} from '../../../../../constants/apiDataChanger';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { getSupplierList, getWarehouseList, getZoneList } from '../../../../../redux/APICaller';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, Dropdown, Input, NewDate } from '../../../../common';
import styles from '../styles';

const ViewIRNFilters = ({ data, setData, onFind, isFindDisable, csvPayload }) => {
  const dispatch = useDispatch();
  const [supplierList, setSupplierList] = useState([]);
  const [warehouseList, setWarehouseListList] = useState([]);
  const [zoneList, setZoneListList] = useState([]);
  const {
    control,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      inboundStagingZone: '',
      sku: '',
      wid: '',
      warehouse: '',
      supplier: '',
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    }
  });

  console.log(watch('warehouse'), '->>>>>>>>>', 'Kartik Warehouse');

  const inboundStagingZone = watch('inboundStagingZone');
  const sku = watch('sku');
  const fromDate = watch('startDate');
  const toDate = watch('endDate');
  const wid = watch('wid');
  const warehouse = watch('warehouse');
  const supplier = watch('supplier');

  useEffect(() => {
    if (fromDate) {
      if (fromDate) setData({ ...data, fromDate: fromDate });
    }
  }, [fromDate]);

  useEffect(() => {
    if (toDate) {
      if (toDate) setData({ ...data, toDate: toDate });
    }
  }, [toDate]);

  useEffect(() => {
    setData({ ...data, wid: wid });
  }, [wid]);

  useEffect(() => {
    setData({ ...data, supplier: supplier });
  }, [supplier]);

  useEffect(() => {
    setData({ ...data, warehouse: warehouse });
  }, [warehouse]);

  useEffect(() => {
    setData({ ...data, sku: sku });
  }, [sku]);

  useEffect(() => {
    setData({ ...data, inboundStagingZone: inboundStagingZone });
  }, [inboundStagingZone]);

  const getSupplierDropdownData = async () => {
    const res = await getSupplierList({});
    if (res?.status == SUCCESS) {
      setSupplierList(SupplierListConverter(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const getWarehouseDropdownData = async () => {
    const res = await getWarehouseList({});
    if (res?.status == SUCCESS) {
      setWarehouseListList(WarehouseListConvertor(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const getZoneDropdownData = async () => {
    const res = await getZoneList({ staging_zone_identifier: 'StagingZones' });
    if (res?.status == SUCCESS) {
      setZoneListList(zoneListConvertor(res?.data));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  useEffect(() => {
    getSupplierDropdownData();
    getWarehouseDropdownData();
    getZoneDropdownData();
  }, []);

  let isDisable = false;
  const handleDownloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  };

  // const csvDownload = async () => {
  //   const customPayload = {};

  //   customPayload.date_from =
  //     csvPayload?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
  //   customPayload.date_to = csvPayload?.toDate || moment().format('YYYY-MM-DD');
  //   if (csvPayload.wid) customPayload.wid = csvPayload.wid;
  //   if (csvPayload.warehouse) customPayload.warehouse = csvPayload.warehouse;
  //   if (csvPayload.inboundStagingZone)
  //     customPayload.inboundStagingZone = csvPayload?.inboundStagingZone;
  //   if (csvPayload.supplier) customPayload.supplier_id = csvPayload?.supplier;
  //   if (csvPayload.sku) customPayload.sku_code = csvPayload.sku;

  //   if (res.status === SUCCESS) {
  //     handleDownloadCsv(res?.data?.url);
  //   } else {
  //     dispatch(showToastMessage({ messageType: ERROR }));
  //   }
  // };

  return (
    <Box sx={styles.dropDownWrapper}>
      <Box sx={styles.input}>
        <Dropdown
          label="Inbound Staging Zones"
          name="inboundStagingZone"
          control={control}
          options={zoneList}
        />
      </Box>
      <Box sx={styles.input}>
        <Input label="SKU" placeholder="Find by SKU" name="sku" control={control} />
      </Box>
      <Box sx={styles.input}>
        <Input label="WID" placeholder="Find by WID" name="wid" control={control} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown label="Warehouse" name="warehouse" control={control} options={warehouseList} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown label="Supplier" name="supplier" control={control} options={supplierList} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="Start Date" name="startDate" control={control} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="Search by date" name="endDate" control={control} />
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button
          label="View Inventory Status"
          customStyles={styles.actionButton}
          onClick={() => onFind()}
          disabled={isFindDisable}
        />
        <Button
          label="Reset"
          customStyles={styles.actionButton}
          disabled={isFindDisable}
          onClick={() => {
            reset();
            onFind('reset');
          }}
        />
      </Box>
      {/* <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon
          sx={styles.download}
          onClick={async () => {
            if (!isDisable) {
              isDisable = true;
              await csvDownload();
              isDisable = false;
            }
          }}
        />
      </Box> */}
    </Box>
  );
};

export default ViewIRNFilters;
