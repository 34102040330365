import Box from '@mui/material/Box';
import React from 'react';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { Spinner, TableDataRenderer } from '../../../../common/index';
import styles from '../styles';

const GenerateReturnOrderTable = ({
  list = [],
  isLoading,
  purchaseOrder,
  inputRenderer,
  control,
  generateRo
}) => {
  const [{ width }] = useWindowDimensions();
  const columnDefs = [
    {
      field: 'ProductInfo',
      headerName: 'Product Info',
      minWidth: 260,
      flex: width > 900 ? 1 : 0,
      cellRenderer: ({ data: { ProductInfo } }) => {
        return purchaseOrder({
          productDescription: ProductInfo?.product_description,
          skuCode: ProductInfo?.sku_code,
          brandSkuCode: ProductInfo?.brand_sku_code,
          portalSkuCode: ProductInfo?.portal_sku_code,
          asinCode: ProductInfo?.asin_code,
          fsnCode: ProductInfo?.fsn_code
        });
      }
    },
    {
      field: 'IRNQty',
      headerName: 'IRN Qty',
      flex: width > 900 ? 1 : 0
    },
    {
      field: 'QCStatus',
      headerName: 'QC Status',
      flex: width > 900 ? 1 : 0
    },
    {
      field: 'ROQty',
      headerName: 'RO Qty',
      flex: width > 900 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return inputRenderer({
          name: `${data?.irn_item_id}`,
          disabled: generateRo,
          onKeyPress: (event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          },
          control,
          rules: {
            required: {
              value: true,
              message: 'This is a required field'
            },
            validate: {
              isPositiveNumber: (value) => {
                if (value > 0 && value <= data?.IRNQty) return true;
                else return 'Please provide a valid RO Qty';
              }
            }
          }
        });
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <TableDataRenderer list={list} rowHeight={150} columnDefs={columnDefs} />
        )}
      </Box>
    </Box>
  );
};

export default GenerateReturnOrderTable;
