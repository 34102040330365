import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Bar, Button, Input, ErrorMessage } from '../../../../common';
import styles from '../styles';

const FindProductCode = ({ setSkuID, skuNotFound, defaultSkuId }) => {
  const { control, watch, getValues } = useForm({
    defaultValues: {
      productCode: defaultSkuId
    }
  });

  const findClickHandler = () => {
    setSkuID(getValues('productCode'));
  };

  return (
    <Bar heading="View Product">
      <Box sx={styles.viewPurchaseOrder}>
        <Box>
          <Input
            disabled={defaultSkuId}
            name="productCode"
            label="Product Code"
            control={control}
            placeholder="SKU Code/WID/Brand SKU Code"
          />
        </Box>
        <Box sx={styles.findButton}>
          <Button
            label="Find"
            onClick={findClickHandler}
            customStyles={styles.actionButton}
            disabled={!watch('productCode') || defaultSkuId}
          />
        </Box>
      </Box>
      {skuNotFound && (
        <Box sx={{ marginBottom: '-10px' }}>
          <ErrorMessage errorMsg="Please provide a valid SKU ID" />
        </Box>
      )}
    </Bar>
  );
};

export default FindProductCode;
