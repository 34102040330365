import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
import { Button, TableDataRenderer } from '../../../../../common';
import styles from '../../styles';

const PendingPickListTable = () => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const navigate = useNavigate();

  const columnDefs = [
    {
      field: 'PicklistID',
      headerName: 'Pick list ID',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      flex: isWebView ? 1 : 0
    },
    { field: 'Priority', headerName: 'Priority', flex: isWebView ? 1 : 0 },
    { field: 'PickUnits', headerName: 'Pick Units', flex: isWebView ? 1 : 0 },
    { field: 'Pickzone', headerName: 'Pick zone', flex: isWebView ? 1 : 0 },
    {
      field: 'Created',
      headerName: 'Created',
      flex: isWebView ? 1 : 0
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Button
            label="Print Pick List"
            onClick={() => navigate(`/pick-details/${data?.PicklistID}`)}
          />
        );
      }
    }
  ];

  const rowData = [
    {
      PicklistID: '123123',
      Priority: '21312',
      PickUnits: '214231',
      Pickzone: '234234',
      Created: '12 minutes ago'
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {/* {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />} */}
        <TableDataRenderer list={rowData} columnDefs={columnDefs} />
      </Box>
      {/* <Box sx={styles.paginationWrapper}>
      <Pagination
        count={pageData?.maxPage}
        page={page}
        onChange={(e, pageNumber) => pageClick(e, pageNumber)}
      />
    </Box> */}
    </Box>
  );
};

export default PendingPickListTable;
