import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MatTooltip from '@mui/material/Tooltip';
import { stringTrimmer } from '../../../utils';
import styles from './styles';

const theme = createTheme({
  components: { MuiTooltip: { styleOverrides: { tooltip: styles.wrapper } } }
});

const Tooltip = ({ title, dataLength, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <MatTooltip title={title} arrow placement="top">
        {children ? children : <Box>{stringTrimmer(title, dataLength)}</Box>}
      </MatTooltip>
    </ThemeProvider>
  );
};

export default Tooltip;
