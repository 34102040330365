import { Box } from '@mui/material';
import React from 'react';
import { Pagination, Spinner, TableDataRenderer, Tooltip } from '../../../../common';
import styles from '../styles';

const SearchInventoryTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  SkuIdRenderer
}) => {
  const columnDefs = [
    {
      field: 'SKUCode',
      headerName: 'SKU Code',
      cellRenderer: ({ data }) => {
        return <SkuIdRenderer id={data?.SKUCode} />;
      }
    },
    { field: 'WID', headerName: 'WID' },
    {
      field: 'ProductDescription',
      headerName: 'Product Description',
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.ProductDescription} />;
      }
    },
    { field: 'Area', headerName: 'Area' },
    {
      field: 'Zone',
      headerName: 'Zone'
    },
    { field: 'Shelf', headerName: 'Shelf' },
    { field: 'QIHQty', headerName: 'QIH Qty' },
    {
      field: 'CommittedQty',
      headerName: 'Committed Qty'
    },
    {
      field: 'ATPQty',
      headerName: 'ATP Qty'
    },
    {
      field: 'BatchCode',
      headerName: 'Batch Code'
    },
    {
      field: 'Aging',
      headerName: 'Aging'
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default SearchInventoryTable;
