import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../../constants/constants';
import { acnListCsvDownload } from '../../../../../redux/APICaller';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, Dropdown, Input, NewDate } from '../../../../common';
import styles from '../styles';

const AllOrdersFilter = ({ data, setData }) => {
  const [isSearchCalled, setIsSearchCalled] = useState(false);
  const { watch, control, reset } = useForm({
    defaultValues: {
      orderID: '',
      orderStatus: '',
      channel: '',
      startDate: '',
      endDate: '',
      pickZone: ''
    }
  });

  const dispatch = useDispatch();

  const orderID = watch('orderID');
  const orderStatus = watch('orderStatus');
  const channel = watch('channel');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const pickZone = watch('pickZone');

  useEffect(() => {
    if (orderStatus) setData({ ...data, orderStatus });
  }, [orderStatus]);

  useEffect(() => {
    if (channel) setData({ ...data, channel });
  }, [channel]);

  useEffect(() => {
    if (pickZone) setData({ ...data, pickZone });
  }, [pickZone]);

  useEffect(() => {
    if (!orderID && isSearchCalled) {
      setData({ ...data, orderID: '' });
      setIsSearchCalled(false);
    }
  }, [orderID, isSearchCalled]);

  const handleDownloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  };

  const csvDownload = async () => {
    const customPayload = {};
    if (data.orderId) customPayload.order_id = data?.orderId;
    if (data.orderStatus) customPayload.order_status = data?.orderStatus;
    if (data.channel) customPayload.channel = data?.channel;
    if (data.startDate) customPayload.start_Date = data.startDate;
    if (data.endDate) customPayload.end_Date = data.endDate;
    if (data.pickZone) customPayload.pick_zone = data.pickZone;

    const res = await acnListCsvDownload({
      ...customPayload
    });

    if (res.status === SUCCESS) {
      handleDownloadCsv(res?.data?.url);
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  let isDisable = false;

  return (
    <Box sx={styles.filterWrapper}>
      <Box sx={styles.input}>
        <Input
          label="Order ID"
          name="orderID"
          placeholder="Search for Order ID/Shipmen ID/Tracking ID"
          control={control}
          isSearch={orderID}
          onSearch={() => {
            setData({ ...data, orderID });
            setIsSearchCalled(true);
          }}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Order Status"
          name="orderStatus"
          control={control}
          options={[{ label: 'All', value: 'all' }]}
        />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Channel"
          name="channel"
          control={control}
          options={[{ label: 'All', value: 'all' }]}
        />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="Start Date" name="startDate" control={control} />
      </Box>
      <Box sx={styles.input}>
        <NewDate label="End Date" name="endDate" control={control} minDate={watch('startDate')} />
      </Box>
      <Box sx={styles.input}>
        <Dropdown
          label="Pick Zone"
          name="pickZone"
          control={control}
          options={[{ label: 'All', value: 'all' }]}
        />
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button label="Reset" customStyles={styles.actionButton} onClick={() => reset()} />
      </Box>
      <Box sx={styles.downloadWrapper}>
        <FileDownloadOutlinedIcon
          sx={styles.download}
          onClick={async () => {
            if (!isDisable) {
              isDisable = true;
              await csvDownload();
              isDisable = false;
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default AllOrdersFilter;
