import Box from '@mui/material/Box';
import React from 'react';
import { Pagination, Spinner, TableDataRenderer } from '../../../common';
import styles from '../styles';

const OverviewTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  itemDetails,
  invoiceNumber,
  POIdRenderer,
  acnID
}) => {
  const columnDefs = [
    {
      field: 'ACNId',
      headerName: 'ACN ID',
      cellRenderer: ({ data: cellData }) => acnID(cellData)
    },
    { field: 'PlannedEDA', headerName: 'Planned EDA' },
    { field: 'ActualEDA', headerName: 'Actual EDA' },
    { field: 'TruckNo', headerName: 'Truck No.' },
    { field: 'ConsgType', headerName: 'Consg Type' },
    {
      field: 'PONo',
      headerName: 'PO No',
      cellRenderer: ({ data: cellData }) => POIdRenderer(cellData?.PONo)
    },
    {
      field: 'InvoNo',
      headerName: 'Invoice No.',
      cellRenderer: ({ data }) => invoiceNumber(data)
    },
    { field: 'Channel', headerName: 'Channel' },
    { field: 'Category', headerName: 'Category' },
    { field: 'Brand', headerName: 'Supplier' },
    {
      field: 'ItemDetails',
      headerName: 'Item Details',
      cellRenderer: ({ data }) => itemDetails(data)
    },
    { field: 'TrailerLOC', headerName: 'Trailer LOC' },
    { field: 'TrailerStatus', headerName: 'Trailer Status' }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default OverviewTable;
