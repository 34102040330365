import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DescriptionBar, MainContent } from '../../../common';
import FindPutlistFilters from './FindPutlistFilters';
import FindPutlistTable from './FindPutlistTable';
import { useDispatch } from 'react-redux';
import { findPutAwayList } from '../../../../redux/APICaller/putawaysAPIs';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import Spinner from '../../../common/Spinner';
import { PutlistIdRenderer } from '../../../common/TableRenderers';
import moment from 'moment';
import { getFormattedDate } from '../../../../utils/index';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      putlist_code: PutlistID,
      putlist_status: Status,
      quantity: Quantity,
      warehouse_code: WhseCode,
      creation_date: CreationDate,
      completion_date: CompletionDate,
      putter_name: Putter,
      ...rest
    }) => ({
      PutlistID,
      Status,
      Quantity,
      WhseCode,
      CreationDate: getFormattedDate(CreationDate, 'DD/MM/YY'),
      CompletionDate: getFormattedDate(CompletionDate, 'DD/MM/YY'),
      Putter,
      ...rest
    })
  );

  return newArrayOfObj;
};

const initPayload = {
  putlistID: '',
  warehouse: '',
  startDate: '',
  endDate: '',
  putlistStatus: '',
  searchByWID: '',
  searchPutter: ''
};

const FindPutlist = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(initPayload);
  // const [csvPayload, setCsvPayload] = useState(initPayload);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [tableRows, setTableRows] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const isFindDisable = !(
    payload?.putlistID ||
    payload?.warehouse ||
    payload?.startDate ||
    payload?.endDate ||
    payload?.putlistStatus ||
    payload?.searchByWID ||
    payload?.searchPutter
  );

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1, isBtnClick) => {
    setLoading(true);
    const customPayload = {};
    customPayload.date_from =
      payload?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    customPayload.date_to = payload?.toDate || moment().format('YYYY-MM-DD');

    if (page !== 'reset') {
      if (payload.warehouse) customPayload.warehouse_id = payload.warehouse;
      if (payload.putlistID) customPayload.putlist_code = payload.putlistID;
      if (payload.startDate) customPayload.startDate = payload.startDate;
      if (payload.endDate) customPayload.endDate = payload.endDate;
      if (payload.putlistStatus) customPayload.putlist_status = payload.putlistStatus;
      if (payload.searchByWID) customPayload.wid_or_sku = payload.searchByWID;
      if (payload.searchPutter) customPayload.putter_id = payload?.searchPutter;
    } else {
      customPayload.date_from = moment().subtract(7, 'days').format('YYYY-MM-DD');
      customPayload.date_to = moment().format('YYYY-MM-DD');
      setPayload(initPayload);
    }
    const res = await findPutAwayList({
      page: page === 'reset' ? 0 : page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        // setCsvPayload(payload);
        if (isBtnClick) setIsFilterApplied(true);
      } else {
        // setCsvPayload(initPayload);
        setIsFilterApplied(false);
      }
      if (page === 1 || page === 'reset') {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      setTotalItems(res?.data?.total_elements);
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  return (
    <Box>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading="Find Put Away List">
          <FindPutlistFilters
            // csvPayload={csvPayload}
            data={payload}
            setData={setPayload}
            onFind={(type) => {
              APICALL(type, 'btnClick');
            }}
            isFindDisable={isFilterApplied ? !isFilterApplied : isFindDisable}
          />
          <DescriptionBar label="Information for Putlist ID:" value={totalItems} />
          <FindPutlistTable
            PutlistIdRenderer={PutlistIdRenderer}
            list={tableRows}
            pageData={pageData}
            pageClick={(e, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
          />
        </MainContent>
      )}
    </Box>
  );
};

export default FindPutlist;
