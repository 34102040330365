import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { findPoList } from '../../../../redux/APICaller/inboundOrderAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../../utils/index';
import Spinner from '../../../common/Spinner';
import { DescriptionBar, MainContent, POIdRenderer } from '../../../common/index';
import FindOrderTable from './FindOrderTable';
import FindOrdersFilters from './FindOrdersFilters';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      po_no: PONO,
      po_type: POType,
      ordered_quantity: OrderedQty,
      received_quantity: ReceivedQty,
      cancelled_quantity: CanceledQty,
      dock_rejected_quantity: DockRejectedQty,
      outstanding_quantity: OutstandingQty,
      source_warehouse: SourceWhseCode,
      destination_warehouse: DestinationWhseCode,
      po_expiry_date: POExpiryDate,
      ...rest
    }) => ({
      PONO,
      POType,
      OrderedQty,
      ReceivedQty,
      CanceledQty,
      DockRejectedQty,
      OutstandingQty,
      SourceWhseCode,
      DestinationWhseCode,
      POExpiryDate: getFormattedDate(POExpiryDate, 'DD/MM/YY'),
      ...rest
    })
  );

  return newArrayOfObj;
};

const initPayload = {
  warehouseValue: '',
  skuCodeValue: '',
  productCategoryValue: '',
  supplierValue: '',
  purchaseOrderIdValue: '',
  fromDate: '',
  toDate: ''
};

const FindOrders = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(initPayload);
  const [csvPayload, setCsvPayload] = useState(initPayload);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 1
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [tableRows, setTableRows] = useState([]);

  const isFindDisable = !(
    payload?.warehouseValue ||
    payload?.supplierValue ||
    payload?.productCategoryValue ||
    payload?.purchaseOrderIdValue ||
    payload?.skuCodeValue ||
    payload?.fromDate ||
    payload?.toDate
  );

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1) => {
    setLoading(true);
    const customPayload = {};

    customPayload.date_from =
      payload?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    customPayload.date_to = payload?.toDate || moment().format('YYYY-MM-DD');

    if (page !== 'reset') {
      if (payload.purchaseOrderIdValue) customPayload.po_no = payload.purchaseOrderIdValue;
      if (payload.warehouseValue) customPayload.warehouse = payload.warehouseValue;
      if (payload.productCategoryValue) customPayload.category_id = payload?.productCategoryValue;
      if (payload.supplierValue) customPayload.supplier_id = payload?.supplierValue;
      if (payload.skuCodeValue) customPayload.sku_code = payload.skuCodeValue;
    } else {
      customPayload.date_from = moment().subtract(7, 'days').format('YYYY-MM-DD');
      customPayload.date_to = moment().format('YYYY-MM-DD');
      setPayload(initPayload);
    }
    const res = await findPoList({
      page: page === 'reset' ? 0 : page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        setCsvPayload(payload);
      } else setCsvPayload(initPayload);
      if (page === 1 || page === 'reset') {
        const newData = dataKeyNameChange(res?.data?.purchase_orders);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.purchase_orders) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      setTotalItems(res?.data?.total_elements);
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  return (
    <Box>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading="Purchase Orders Listing">
          <FindOrdersFilters
            csvPayload={csvPayload}
            data={payload}
            setData={setPayload}
            onFind={(type) => {
              APICALL(type);
            }}
            isFindDisable={isFindDisable}
          />
          <DescriptionBar label="Total number of purchase orders found:" value={totalItems} />
          <FindOrderTable
            POIdRenderer={POIdRenderer}
            list={tableRows}
            pageData={pageData}
            pageClick={(e, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
          />
        </MainContent>
      )}
    </Box>
  );
};

export default FindOrders;
