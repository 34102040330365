import { INBOUND_CONSIGNMENT_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI } from '../../helpers/API';

const {
  GET_CONSIGNMENT_LIST_LIST,
  GET_CONSIGNMENT_CSV_DOWNLOAD,
  GET_CONSIGNMENT_DETAILS,
  GET_CONSIGNMENT_DETAILS_CSV
} = INBOUND_CONSIGNMENT_PATHS;

/**
 * Find Acn List
 * @param {*} data
 * @returns ACN List
 */
export function findAcnList(data) {
  return postAPI({
    url: GET_CONSIGNMENT_LIST_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Find Acn List CSV Download
 * @param {*} data
 * @returns CSV File
 */
export function findAcnListCsvDownload(data) {
  return postAPI({
    url: GET_CONSIGNMENT_CSV_DOWNLOAD,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get Consignment Details By Id
 * @param {*} data
 * @returns Consignment Details
 */
export function getConsignmentDetails(data) {
  return getAPI({
    url: GET_CONSIGNMENT_DETAILS.replace(':acnId', data?.acnId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * ACN Details Download
 * @param {*} data
 * @returns ACN Details
 */
export function acnDetailsCSVDownload(data) {
  return postAPI({
    url: GET_CONSIGNMENT_DETAILS_CSV.replace(':acnId', data?.acn_id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
