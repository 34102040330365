import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR } from '../../../../constants/constants';
import { inventoryList } from '../../../../redux/APICaller/browseInventoryAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { DescriptionBar, MainContent } from '../../../common';
import Spinner from '../../../common/Spinner';
import { SkuIdRenderer } from '../../../common/TableRenderers';
import SearchInventoryFilters from './SearchInventoryFilters';
import SearchInventoryTable from './SearchInventoryTable';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      sku: SKUCode,
      wid: WID,
      product_description: ProductDescription,
      area: Area,
      zone: Zone,
      shelf: Shelf,
      quantity_in_hand: QIHQty,
      committed_quantity: CommittedQty,
      atp_quantity: ATPQty,
      batch_code: BatchCode,
      aging: Aging,
      ...rest
    }) => ({
      SKUCode,
      WID,
      ProductDescription,
      Area,
      Zone,
      Shelf,
      QIHQty,
      CommittedQty,
      ATPQty,
      BatchCode,
      Aging,
      ...rest
    })
  );

  return newArrayOfObj;
};

const initPayload = {
  warehouse: '',
  area: '',
  zone: '',
  shelf: '',
  sku: '',
  wid: '',
  supplier: ''
};

const SearchInventory = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(initPayload);
  const [csvPayload, setCsvPayload] = useState(initPayload);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [tableRows, setTableRows] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const isFindDisable = !(
    payload?.warehouse ||
    payload?.area ||
    payload?.zone ||
    payload?.shelf ||
    payload?.sku ||
    payload?.wid ||
    payload?.supplier
  );

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1, isBtnClick) => {
    setLoading(true);
    const customPayload = {};
    if (page !== 'reset') {
      if (payload.warehouse) customPayload.warehouse_id = payload.warehouse;
      if (payload.area) customPayload.area_id = payload.area;
      if (payload.zone) customPayload.zone_id = payload.zone;
      if (payload.shelf) customPayload.shelf = payload.shelf;
      if (payload.sku) customPayload.sku = payload.sku;
      if (payload.wid) customPayload.wid = payload.wid;
      if (payload.supplier) customPayload.suppplier_id = payload?.supplier;
    } else {
      setPayload(initPayload);
    }
    const res = await inventoryList({
      page: page === 'reset' ? 0 : page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page !== 'reset') {
        setCsvPayload(payload);
        if (isBtnClick) setIsFilterApplied(true);
      } else {
        setCsvPayload(initPayload);
        setIsFilterApplied(false);
      }
      if (page === 1 || page === 'reset') {
        const newData = dataKeyNameChange(res?.data?.list);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      setTotalItems(res?.data?.total_elements);
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  return (
    <Box>
      {isLoading && initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading="Inventory Visibility" description="Search Inventory">
          <SearchInventoryFilters
            csvPayload={csvPayload}
            data={payload}
            setData={setPayload}
            onFind={(type) => {
              APICALL(type, 'btnClick');
            }}
            isFindDisable={isFilterApplied ? !isFilterApplied : isFindDisable}
          />
          <DescriptionBar label="Total inventory found:" value={totalItems} />
          <SearchInventoryTable
            list={tableRows}
            pageData={pageData}
            pageClick={(e, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
            SkuIdRenderer={SkuIdRenderer}
          />
        </MainContent>
      )}
    </Box>
  );
};

export default SearchInventory;
