import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputBase } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  emptyConfirmPassword,
  emptyNewPassword,
  matchedPassword,
  passwordType
} from '../../../../constants/errorMessage';
import { selectAuthStore, userResetPasswordThunk } from '../../../../redux/authSlice';
import styles from '../styles';

function containsUppercase(str) {
  return /[A-Z]/.test(str);
}

function containsNumbers(str) {
  return /\d/.test(str);
}

const ResetPassword = () => {
  const [error, setError] = useState({ newPassword: '', rePassword: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const navigate = useNavigate();
  const authStore = useSelector(selectAuthStore);
  const dispatch = useDispatch();

  const handleClickShowPassword = (type) => {
    if (type === 'newPassword') setShowPassword(!showPassword);
    else setShowRePassword(!showRePassword);
  };

  const errorCheck = (formData) => {
    let newPasswordError = false;
    let rePasswordError = false;

    // Empty check
    if (!formData.get('newPassword')) {
      setError((error) => {
        return { ...error, newPassword: emptyNewPassword };
      });
      newPasswordError = true;
    } else {
      setError((error) => {
        return { ...error, newPassword: '' };
      });
    }
    if (!formData.get('rePassword')) {
      setError((error) => {
        return {
          ...error,
          rePassword: formData.get('newPassword') ? matchedPassword : emptyConfirmPassword
        };
      });
      rePasswordError = true;
    } else {
      setError((error) => {
        return { ...error, rePassword: '' };
      });
    }
    // Password must be
    if (formData.get('newPassword')) {
      if (
        !containsUppercase(formData.get('newPassword')) ||
        !containsNumbers(formData.get('newPassword')) ||
        formData.get('newPassword').length < 6
      ) {
        setError((error) => {
          return {
            ...error,
            newPassword: passwordType
          };
        });
        newPasswordError = true;
      } else {
        setError((error) => {
          return { ...error, newPassword: '' };
        });
      }
    }
    if (formData.get('rePassword') && formData.get('newPassword')) {
      if (formData.get('rePassword') !== formData.get('newPassword')) {
        setError((error) => {
          return { ...error, rePassword: matchedPassword };
        });
        rePasswordError = true;
      } else {
        if (
          containsUppercase(formData.get('newPassword')) &&
          containsNumbers(formData.get('newPassword')) &&
          formData.get('newPassword').length >= 6
        ) {
          setError((error) => {
            return { ...error, rePassword: '' };
          });
        }
      }
    }

    return newPasswordError || rePasswordError;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const isError = errorCheck(data);

    if (!isError) {
      dispatch(
        userResetPasswordThunk({
          payload: {
            email_address: authStore.email,
            reset_token: authStore.reset_token,
            new_password: data.get('newPassword'),
            reset_new_password: data.get('rePassword')
          },
          navigate
        })
      );
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <Box component="img" src="./losung360New.png" sx={styles.image} loading="lazy" />
      </Box>
      <Box sx={styles.cardWrapper}>
        <Box sx={styles.heading}>Reset Password</Box>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={styles.inputFields}>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.label}>
              New Password
              <Box component="span" className="asterstik">
                *
              </Box>
            </Box>
            <InputBase
              required
              fullWidth
              sx={styles.inputBase}
              name="newPassword"
              placeholder="Enter new password"
              type={showPassword ? 'text' : 'password'}
              id="newPassword"
              error={!!error.newPassword}
              helperText={error.newPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword('newPassword')}
                    onMouseDown={() => handleClickShowPassword('newPassword')}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box sx={{ ...styles.description, color: 'error.main' }}>{error.newPassword}</Box>
            <Box sx={styles.description}>{!error.newPassword && passwordType}</Box>
          </Box>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.label}>
              Confirm Password
              <Box component="span" className="asterstik">
                *
              </Box>
            </Box>
            <InputBase
              type={showRePassword ? 'text' : 'password'}
              required
              fullWidth
              sx={styles.inputBase}
              id="rePassword"
              placeholder="Re-enter Password"
              name="rePassword"
              error={!!error.rePassword}
              helperText={error.rePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword()}
                    onMouseDown={() => handleClickShowPassword()}>
                    {showRePassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box sx={{ ...styles.description, color: 'error.main' }}>{error.rePassword}</Box>
            <Box sx={styles.description}>{!error.rePassword && matchedPassword}</Box>
          </Box>
          <LoadingButton
            type="submit"
            fullWidth
            loading={authStore.status === 'loading'}
            loadingPosition="start"
            sx={styles.button}
            variant="contained">
            Reset Password
          </LoadingButton>
          <Box onClick={() => navigate('/sign-in')} sx={styles.backToLogin}>
            Back to login
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
