import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { TableDataRenderer, Tooltip, Pagination, Spinner } from '../../../../common';
import styles from '../styles';

const OrderViewTable = ({ list = [], pageData, pageClick, isLoading, page }) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const columnDefs = [
    {
      field: 'LSIN',
      headerName: 'LSIN',
      flex: isWebView ? 1 : 0
    },
    {
      field: 'ProductDescription',
      headerName: 'Product Description',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return <Tooltip title={data?.ProductDescription} dataLength={40} />;
      }
    },
    { field: 'Units', headerName: 'Units', flex: isWebView ? 1 : 0 },
    { field: 'ShipmentID', headerName: 'Shipment ID', flex: isWebView ? 1 : 0 }
  ];

  const rowData = [
    {
      LSIN: 'LSIN: BS-022',
      ProductDescription:
        'Blue Star 1.5 Ton 3 Star Fixed Speed Window AC (Copper, Turbo Cool, Humidity Control, Fan Modes-Auto/High/Medium/Low,',
      Units: '1',
      ShipmentID: 'UJD7833'
    }
  ];
  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <TableDataRenderer list={list} columnDefs={columnDefs} rowHeight={150} />
        )}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default OrderViewTable;
