import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BAD_REQUEST, SUCCESS } from '../../../constants/apiStatusCodes';
import {
  ACN_ID,
  INVOICE_NUMBER,
  PO_NO,
  TRAILOR_LOCATION,
  TRAILOR_NUMBER,
  TRAILOR_STATUS
} from '../../../constants/commonLabels';
import { ERROR } from '../../../constants/constants';
import { yardList } from '../../../redux/APICaller/overviewAPIs';
import { showToastMessage } from '../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../utils/index';
import Spinner from '../../common/Spinner';
import {
  ACNIdRenderer,
  InvoiceNumberRenderer,
  ItemDetailsRenderer,
  MainContent,
  POIdRenderer
} from '../../common/index';
import OverviewFilters from './OverviewFilters';
import OverviewTable from './OverviewTable';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({
      acn_no: ACNId,
      planned_time_of_arrival: PlannedEDA,
      actual_time_of_arrival: ActualEDA,
      truck_no: TruckNo,
      consignment_type: ConsgType,
      po_no: PONo,
      invoice_no: InvoNo,
      channel: Channel,
      category: Category,
      brand: Brand,
      supplier: Supplier,
      item_details: ItemDetails,
      trailer_location: TrailerLOC,
      trailer_status: TrailerStatus,
      ...rest
    }) => ({
      ACNId,
      PlannedEDA: getFormattedDate(PlannedEDA, 'DD/MM/YY'),
      ActualEDA: getFormattedDate(ActualEDA, 'DD/MM/YY'),
      TruckNo,
      ConsgType,
      PONo,
      InvoNo,
      Channel,
      Category,
      Brand: Brand || Supplier,
      ItemDetails,
      TrailerLOC,
      TrailerStatus,
      ...rest
    })
  );

  return newArrayOfObj;
};

const Overview = () => {
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    searchBy: '',
    searchStr: '',
    consgnType: '',
    consgnStatus: '',
    brandType: '',
    searchByTrailer: '',
    searchByTrailersValue: '',
    fromDate: '',
    toDate: ''
  });
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 1
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = async (page = 1) => {
    setLoading(true);
    const customPayload = {};
    if (payload.consgnType) customPayload.consignment_type = payload.consgnType;
    if (payload.consgnStatus) customPayload.consignment_status = payload?.consgnStatus;
    if (payload.brandType) customPayload.brand = payload?.brandType;

    customPayload.from_date =
      payload?.fromDate || moment().subtract(7, 'days').format('YYYY-MM-DD');

    customPayload.to_date = payload?.toDate || moment().format('YYYY-MM-DD');

    if (payload.searchStr) {
      if (payload.searchBy === ACN_ID) customPayload.acn_no = payload?.searchStr;
      if (payload.searchBy === PO_NO) customPayload.po_no = payload?.searchStr;
      if (payload.searchBy === INVOICE_NUMBER) customPayload.invoice_no = payload?.searchStr;
    }
    if (payload.searchByTrailersValue) {
      if (payload.searchByTrailer === TRAILOR_NUMBER)
        customPayload.truck_no = payload.searchByTrailersValue;
      if (payload.searchByTrailer === TRAILOR_STATUS)
        customPayload.trailer_status = payload?.searchByTrailersValue;
      if (payload.searchByTrailer === TRAILOR_LOCATION)
        customPayload.trailer_location = payload?.searchByTrailersValue;
    }
    const res = await yardList({
      page: page - 1,
      limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (page === 1) {
        const newData = dataKeyNameChange(res.data.yardListingResponse);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.yardListingResponse) }]);
      setPageData({ maxPage: res.data.totalPages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setInitialLoad(false);
    setLoading(false);
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, [payload]);

  return (
    <Box>
      {initialLoad ? (
        <Spinner />
      ) : (
        <MainContent heading="Yard Status">
          <OverviewFilters data={payload} setData={setPayload} />
          <OverviewTable
            POIdRenderer={POIdRenderer}
            itemDetails={ItemDetailsRenderer}
            invoiceNumber={InvoiceNumberRenderer}
            acnID={ACNIdRenderer}
            list={tableRows}
            pageData={pageData}
            pageClick={(e, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
            isLoading={isLoading}
            page={pageData.currentPage}
          />
        </MainContent>
      )}
    </Box>
  );
};

export default Overview;
