import { Box } from '@mui/material';
import React from 'react';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { Button, Input, Pagination, Spinner, TableDataRenderer } from '../../../../common';
import styles from '../styles';

const CreateTransferTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  control,
  setError,
  setValue
}) => {
  const [{ width }] = useWindowDimensions();

  const resetSelection = (id) => {
    setValue(`${id}-wid`, '');
    setValue(`${id}-quantity`, '');
  };

  const columnDefs = [
    {
      field: 'SNO',
      headerName: 'S.No.',
      maxWidth: 150,
      flex: width > 1000 ? 1 : 0
    },
    {
      field: 'wid',
      headerName: 'WID',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return <Input name={`${data?.SNO}-wid`} control={control} />;
      }
    },
    {
      field: 'TransferQuantity',
      headerName: 'Transfer Quantity',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Input
            name={`${data?.SNO}-quantity`}
            type="number"
            control={control}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        );
      }
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: width > 1000 ? 1 : 0,
      cellRenderer: ({ data }) => {
        return <Button label="Reset Selection" onClick={() => resetSelection(data?.SNO)} />;
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      {/* <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box> */}
    </Box>
  );
};

export default CreateTransferTable;
