import Box from '@mui/material/Box';
import React from 'react';
import { Pagination, Spinner, TableDataRenderer } from '../../../../common';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import styles from '../styles';

const ViewReturnOrderTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  productInfoRenderer
}) => {
  const [{ width }] = useWindowDimensions();

  const columnDefs = [
    {
      field: 'ProductInfo',
      headerName: 'Product Info',
      minWidth: 300,
      flex: width > 900 ? 1 : 0,
      cellRenderer: ({ data: { ProductInfo } }) => {
        return productInfoRenderer({
          productDescription: ProductInfo?.product_description,
          skuCode: ProductInfo?.sku_code,
          brandSkuCode: ProductInfo?.brand_sku_code,
          portalSkuCode: ProductInfo?.portal_sku_code,
          asinCode: ProductInfo?.asin_code,
          fsnCode: ProductInfo?.fsn_code
        });
      }
    },
    { field: 'PlannedQty', headerName: 'Planned Qty', flex: width > 900 ? 1 : 0 },
    { field: 'ReturnedQty', headerName: 'Returned Qty', flex: width > 900 ? 1 : 0 },
    { field: 'OutstandingQty', headerName: 'Outstanding Qty', flex: width > 900 ? 1 : 0 },
    { field: 'DebitNoteNo', headerName: 'Debit Note No.', flex: width > 900 ? 1 : 0 },
    { field: 'Brand', headerName: 'Brand', flex: width > 900 ? 1 : 0 },
    { field: 'Totalvalue', headerName: 'Total value', flex: width > 900 ? 1 : 0 }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <TableDataRenderer list={list} columnDefs={columnDefs} rowHeight={150} />
        )}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default ViewReturnOrderTable;
