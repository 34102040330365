import Box from '@mui/material/Box';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { NoData, Pagination, Spinner } from '../../../../common/index';
import styles from '../styles';

const FindOrderTable = ({ list = [], pageData, pageClick, isLoading, page, POIdRenderer }) => {
  const columnDefs = [
    {
      field: 'PONO',
      headerName: 'PO NO.',
      cellRenderer: ({ data: cellData }) => POIdRenderer(cellData?.PONO)
    },
    { field: 'POType', headerName: 'PO Type' },
    { field: 'OrderedQty', headerName: 'Ordered Qty' },
    { field: 'ReceivedQty', headerName: 'Received Qty' },
    { field: 'CanceledQty', headerName: 'Canceled Qty' },
    { field: 'DockRejectedQty', headerName: 'Packages Rejected' },
    { field: 'OutstandingQty', headerName: 'Outstanding Qty' },
    { field: 'SourceWhseCode', headerName: 'Source Whse Code' },
    { field: 'DestinationWhseCode', headerName: 'Destination Whse Code' },
    { field: 'POExpiryDate', headerName: 'POExpiryDate' }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : list.length ? (
          <AgGridReact rowData={list || []} columnDefs={columnDefs} wrapText rowHeight={65} />
        ) : (
          <NoData />
        )}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default FindOrderTable;
