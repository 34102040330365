import { COMMON_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI } from '../../helpers/API';

const {
  GET_BRAND_LIST,
  GET_CATEGORY_LIST,
  GET_WAREHOUSE_LIST,
  COMMON_LIST_CSV_DOWNLOAD,
  GET_SUPPLIER_LIST,
  GET_BAYLIST,
  ACN_LIST_CSV_DOWNLOAD,
  GET_ACCEPT_CONSIGNMENT_DETAILS,
  GET_ZONE_LIST
} = COMMON_PATHS;

/**
 * Brand List
 * @param {*} data
 * @returns Brand List
 */
export function getBrandList(data) {
  return getAPI({
    url: GET_BRAND_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Category List
 * @param {*} data
 * @returns Category List
 */
export function getCategoryList(data) {
  return getAPI({
    url: GET_CATEGORY_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get Zone List
 * @param {*} data
 * @returns
 */
export function getZoneList(data) {
  return getAPI({
    url: GET_ZONE_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Warehouse List
 * @param {*} data
 * @returns Warehouse List
 */
export function getWarehouseList(data) {
  if (!('supplier_id' in data)) data.supplier_id = '';
  if (!('consignment_type' in data)) data.consignment_type = '';
  return postAPI({
    url: GET_WAREHOUSE_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Common List CSV Download
 * @param {*} data
 * @returns CSV File
 */
export function commonCsvDownload(data) {
  return postAPI({
    url: COMMON_LIST_CSV_DOWNLOAD,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get Supplier List
 * @param {*} data
 * @returns
 */
export function getSupplierList(data) {
  return getAPI({
    url: GET_SUPPLIER_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

// Get Dropdown bayId list
export function getDropdownBayIdList(data) {
  return getAPI({
    url: GET_BAYLIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

// ACN List CSV Download
export function acnListCsvDownload(data) {
  return postAPI({
    url: ACN_LIST_CSV_DOWNLOAD,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

// Get Accept/Reject Consignment Details
export function getAcceptConsignmentDetails(data) {
  return postAPI({
    url: GET_ACCEPT_CONSIGNMENT_DETAILS.replace(':acnId', data?.acn_no),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get PO List
 * @param {*} data
 * @returns []
 */
export function getAutocompleteList(data, URL) {
  return getAPI({
    url: URL.replace(':searchStr', data?.searchStr),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
