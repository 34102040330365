import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SUCCESS, BAD_REQUEST } from '../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { getCompleteputList, putCompleteputList } from '../../../../redux/APICaller/putawaysAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Button, DescriptionBar, MainContent, ShowMessage, Spinner } from '../../../common';
import CompletePutlistTable from './CompletePutlistTable';
import PutlistID from './PutlistID';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    (
      {
        wid_code: WID,
        product_description: ProductDescription,
        product_category: ProductCategory,
        warehouse_code: WhseCode,
        zone_name: Zone,
        status: Status,
        put_away_quantity: PutawayQty,
        ...rest
      },
      i
    ) => ({
      SrNo: `0${++i}`,
      WID,
      ProductDescription,
      ProductCategory,
      WhseCode,
      Zone,
      Status,
      PutawayQty,
      shelfList: [{ shelfCode: '', qty: '' }],
      ...rest
    })
  );

  return newArrayOfObj;
};

const dataKeyNameChangeForPutlist = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ shelfCode: put_away_su_code, qty: quantity_added_in_su_code, ...rest }) =>
      put_away_su_code &&
      quantity_added_in_su_code && {
        put_away_su_code,
        quantity_added_in_su_code: Number(quantity_added_in_su_code),
        ...rest
      }
  );

  const resArr = newArrayOfObj.filter(function (el) {
    return el != '';
  });
  return resArr;
};

const CompletePutlist = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [putlistID, setPutlistID] = useState('');
  const [putlistOverview, setPutlistOverview] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [tempTableRow, setTempTableRow] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 1
  });
  const [list, setList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [putlistNotFound, setPutlistNotFound] = useState(false);
  const [putlistCreated, setPutlistCreated] = useState(false);
  const [putlistCreateApiStatus, setPutlistCreateApiStatus] = useState(false);

  const dataFound = Object.keys(putlistOverview)?.length;

  const fetchPutlistDetails = () => {
    setLoading(true);
    setPutlistOverview({ found: true });
  };

  useEffect(() => {
    if (params?.putlist_id) {
      setPutlistID(params?.putlist_id);
    }
  }, [params?.putlist_id]);

  useEffect(() => {
    if (putlistID) {
      setPutlistNotFound(false);
      fetchPutlistDetails(putlistID);
    }
  }, [putlistID]);

  const APICALL = async (page = 1) => {
    setIsLoading(true);
    const customPayload = { putlistID };
    const res = await getCompleteputList({
      page: page - 1,
      // limit: 15,
      ...customPayload
    });
    if (res.status === SUCCESS) {
      if (putlistNotFound) setPutlistNotFound(false);
      if (page === 1) {
        const newData = dataKeyNameChange(res?.data);
        setTempTableRow(dataKeyNameChange(res?.data));
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data) }]);
      setPageData({ maxPage: res.data.totalPages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
    } else {
      setPutlistOverview({});
      setPutlistNotFound(true);
      // dispatch(showToastMessage({ messageType: ERROR }));
    }
    setLoading(false);
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    if (putlistID && dataFound) APICALL();
  }, [putlistID, dataFound]);

  const completePutlist = async () => {
    setPutlistCreateApiStatus(true);
    const customPayload = [];

    const tempTableRows = [...tableRows];
    tempTableRows?.map((item) => {
      const obj = {};
      obj.putlist_item_id = item?.putlist_item_id;
      obj.putlist_item_list = dataKeyNameChangeForPutlist(item?.shelfList);
      if (obj?.putlist_item_list?.length) customPayload.push(obj);
    });
    const res = await putCompleteputList({
      putlistID,
      putlist_items_request: customPayload
    });
    if (res.status === SUCCESS) {
      setPutlistCreated(true);
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
      setTableRows(tempTableRow);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setPutlistCreateApiStatus(false);
  };

  return (
    <MainContent heading="Complete Put Away List">
      <Box sx={styles.dataWrapper}>
        <PutlistID
          setPutlistID={setPutlistID}
          putlistNotFound={putlistNotFound}
          defaultPutlistId={params?.putlist_id}
        />
        {!putlistNotFound && loading ? (
          <Spinner />
        ) : dataFound ? (
          <>
            <DescriptionBar
              label="Information for Putlist ID:"
              value={putlistID}
              customStyles={{ justifyContent: 'space-between' }}>
              {putlistCreated && (
                <ShowMessage type="SUCCESS" message="Putlist ID successfully completed" />
              )}
            </DescriptionBar>
            <CompletePutlistTable
              setTableRows={setTableRows}
              list={tableRows}
              pageData={pageData}
              pageClick={(e, pageNumber) => {
                apiCallWithPage(pageNumber);
              }}
              isLoading={isLoading}
              page={pageData.currentPage}
            />
            <Button
              disabled={putlistCreateApiStatus}
              label="Complete Putlist"
              customStyles={{ maxWidth: { xs: '100%', md: '15%' } }}
              onClick={() => completePutlist()}
            />
          </>
        ) : null}
      </Box>
    </MainContent>
  );
};

export default CompletePutlist;
