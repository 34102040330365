import moment from 'moment';

/**
 * param (dateString,dateFormat)
 * The function takes a date as a string and format and return a desired date
 */

export const getFormattedDate = (dateString, dateFormat = 'dddd,MMMM Do YYYY,h:mm:ss a') => {
  let momentDate;
  if (Array.isArray(dateString) && dateString?.length > 6)
    momentDate = moment(dateString?.slice(0, 6));
  else momentDate = moment(dateString);

  if (!momentDate?.isValid()) {
    return 'Invalid Date';
  }

  const formattedDate = momentDate.format(dateFormat);
  return formattedDate;
};

export const capitalizeFirstLetter = (str) => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  else return '';
};

/**
 * String Trimmer
 */

export const stringTrimmer = (data, dataLength = 25) => {
  if (data?.length > dataLength + 1) {
    return data.substring(0, dataLength) + '...';
  } else {
    return data;
  }
};
