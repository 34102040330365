const styles = {
  wrapper: {
    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    }
  },

  input: {
    height: {
      xs: '34px',
      sm: '38px'
    }
  },

  searchIconWrapper: {
    padding: '0 8px !important',
    marginRight: '-11px',
    borderRadius: '0 !important',
    height: {
      xs: '34px',
      sm: '38px'
    },
    borderLeft: '1px solid',
    borderColor: 'custom.gray'
  },

  icon: {
    color: '#232F3E'
  }
};

export default styles;
