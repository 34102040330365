import { ALLOW_GATE_OUT_PATHS } from '../../constants/apiPaths';
import { patchAPI } from '../../helpers/API';

const { TRUCK_GATE_OUT } = ALLOW_GATE_OUT_PATHS;

// Allow get Out
export function allowGateOut(data) {
  return patchAPI({
    url: TRUCK_GATE_OUT.replace(':truckNumber', data?.truck_number),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
