/**
 * Authentication Paths
 */
export const AUTH_PATHS = {
  SIGN_IN: `/realms/wms/protocol/openid-connect/token`,
  FIND_YOUR_ACCOUNT: `/reset-password`,
  OTP_VERIFY: `/verify-otp`,
  RESET_PASSWORD: `/password-reset`,
  REFRESH_TOKEN: `/realms/wms/protocol/openid-connect/token`
};

/**
 * Allow Gate Out Paths
 */
export const ALLOW_GATE_OUT_PATHS = {
  TRUCK_GATE_OUT: `/truck/:truckNumber/release`
};

/**
 * Browse Inventory Paths
 */
export const BROWSE_INVENTORY_PATHS = {
  POST_INVENTORY_LIST: '/inventory',
  POST_INVENTORY_LIST_CSV_DOWNLOAD: '/inventory/export',
  GET_AREA_LIST: '/facility/area',
  GET_ZONE_LIST: '/facility/zone'
};

/**
 * Browse Product Paths
 */
export const BROWSE_PRODUCT_PATHS = {
  POST_CREATE_PRODUCT_FILE_UPLOAD: '/sku/import',
  GET_PRODUCT_LIST: '/sku/product/details',
  GET_FIND_PRODUCT_LIST_CSV_DOWNLOAD: '/sku/product/details/export',
  GET_VIEW_PRODUCT_DATA: `/sku/:sku_code/details`
};

/**
 * Dock Paths
 */
export const DOCK_PATHS = {
  START_LOADING_UNLOADING: `/dock/truck/:truckNumber/process`,
  COMPLETE_UNLOADING: `/dock/truck/:truckNumber/unload`,
  COMPLETE_LOADING: `/dock/truck/:truckNumber/load`,
  UNDOCK_TRUCK: `/dock/truck/:truckNumber/undock`
};

/**
 * Gate In Paths
 */
export const GATE_IN_PATHS = {
  GET_ACN_LIST: `/consignment`,
  GET_PO_DETILA_BY_PO_FOR_ACN: '/order/purchase/:poNumber'
};

/**
 * Gate Out Paths
 */
export const GATE_OUT_PATHS = {
  GET_TRUCK_LIST: '/truck/listing'
};

/**
 * Inbound Consignment Paths
 */
export const INBOUND_CONSIGNMENT_PATHS = {
  GET_CONSIGNMENT_LIST_LIST: '/consignment/filter',
  GET_CONSIGNMENT_DETAILS: `/consignment/:acnId/details`,
  GET_CONSIGNMENT_DETAILS_CSV: '/consignment/:acnId/products/export',
  GET_CONSIGNMENT_CSV_DOWNLOAD: '/consignment/filter/export'
};

/**
 * Inbound Orders Paths
 */
export const INBOUND_ORDER_PATHS = {
  POST_CREATE_PO_FILE_UPLOAD: '/order/import',
  GET_PO_LIST_LIST: '/order/details',
  GET_PO_LIST_LIST_CSV_DOWNLOAD: '/order/details/export',
  GET_PO_DETAILS: `/order/:POid`,
  GET_PO_LIST_DETAILS: `/order/:POid/products`,
  GET_PO_DETAILS_CSV: '/order/:po_id/products/export'
};

/**
 * Inbound Return Order Paths
 */
export const INBOUND_RETURN_ORDER_PATHS = {
  GET_IRN_LIST_DETAILS: `/order/return/:irnId/products`,
  GET_RO_LIST: '/order/return/filter',
  GET_FIND_RO_CSV_DOWNLOAD: '/order/return/filter/export',
  CREATE_RO: `/order/return/create`,
  GET_RO_DETAILS: `/order/return/:ROid`,
  GET_RO_ITEM_LIST: `/order/return/:ROid/products_list`,
  VIEW_PO_DETAILS_UPLOAD: '/order/return/upload/:ROid',
  GET_RO_ITEM_LIST_CSV_DOWNLOAD: `/order/return/:ROid/products_list/export`
};

/**
 * IRN Paths
 */
export const IRN_PATHS = {
  GET_IRN_DETAILS: `/consignment/:acnId/irn`,
  IRN_DETAILS_UPLOAD: '/irn/:acnId/:typeOfFile',
  PUT_IRN_DETAILS: '/irn/:irnId',
  GET_IRN_ITEM_LIST: '/irn/:irnId/items',
  GET_SKU_PARTS_LIST: '/sku/:sku_id',
  PATCH_IRN_DETAILS: '/irn/:irn_id/item/details',
  GET_RETURN_IRN_ITEM_LIST: '/irn/:irnId/item/details',
  PUT_FINAL_RETURN_IRN_ITEM_DETAILS: '/irn/receive/quantity',
  POST_GENERATE_BARCODE: '/irn/generate/barcode',
  POST_VIEW_IRN_STOCK: '/inventory/details'
};

/**
 * Overview Paths
 */
export const OVERVIEW_PATHS = { GET_YARD_LIST: '/yard', YARD_LIST_CSV_DOWNLOAD: '/yard/export' };

/**
 * Putaways Paths
 */
export const PUTAWAYS_PATHS = {
  GET_PUTTER_LIST: '/facility/putters',
  PUT_CREATE_PUTAWAY_LIST: '/inventory/items/confirm/putaway',
  GET_COMPLETE_PUT_LIST: `/inventory/items/:putlistID`,
  GET_VERIFY_SHELF_CODE: `/inventory/available/:shelfCode`,
  PUT_COMPLETE_PUTLIST: `/inventory/items/complete/putaway/:putlistID`,
  GET_PUTLIST_DETAILS: `/inventory/putlist/:putlistId`,
  GET_PUTLIST_LIST: `/inventory/putlist/:putlistId/details`,
  POST_FIND_PUTAWAY_LIST: '/inventory/putaway/details',
  GET_PUT_AWAY_TRANSFER_LIST: '/inventory/transfer/picked/items/:transferID'
};

/**
 * Receive on Dock
 */
export const RECEIVE_ON_DOCK_PATHS = {
  GET_DOCK_LIST: '/dock',
  GET_INVOICE_DETAILS: `/invoice/trip/:tripId`,
  ASSIGN_DOCK: `/dock/truck/:truckNumber/dock`
};

/**
 * Slip Check Paths
 */
export const SLIP_CHECK_PATHS = {
  GET_BAY_PASS: '/bay/pass',
  GET_BAY_ALLOCATION_DETAILS: `/truck/bay`,
  ACCEPT_CONSIGNMENT: `/consignment/truck`
};

/**
 * Stock Transfer Paths
 */
export const STOCK_TRANSFER_PATHS = {
  POST_CREATE_TRANSFER_LIST: '/inventory/transfer',
  GET_PICK_TRANSFER_LIST: '/inventory/transfer/:transferID/process',
  PUT_CONFIRM_PICK_TRANSFER_LIST: '/inventory/transfer/:transferID/pick',
  GET_VIEW_TRANSFER_LIST: '/inventory/transfer/:transferID',
  POST_SEARCH_TRANSFER_LIST: '/inventory/transfer/filter'
};

/**
 * Common Paths
 */
export const COMMON_PATHS = {
  GET_ACCEPT_CONSIGNMENT_DETAILS: `/consignment/:acnId`,
  ACN_LIST_CSV_DOWNLOAD: '/consignment/export',
  GET_BAYLIST: '/bay',
  GET_BRAND_LIST: '/brand',
  GET_WAREHOUSE_LIST: '/warehouse',
  COMMON_LIST_CSV_DOWNLOAD: 'truck/listing/export',
  GET_CATEGORY_LIST: '/category',
  GET_SUPPLIER_LIST: '/supplier',
  GET_RO_LIST_DETAILS: `/order/return/:ROid/products`,
  POST_CREATE_PO_FILE_UPLOAD: '/order/import',
  GET_ZONE_LIST: '/facility/zone'
};
