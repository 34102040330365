import Box from '@mui/material/Box';
import React from 'react';
import styles from './styles';

const NoData = ({ heading = 'No Data Found', description }) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.heading}>{heading}</Box>
      <Box sx={styles.description}>{description}</Box>
    </Box>
  );
};

export default NoData;
