import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../../constants/constants';
import { irnDetailsFileUpload, putIRNDetails } from '../../../../../redux/APICaller/irnAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, Input, NewDate, NewFileUpload } from '../../../../common';
import styles from './styles';

const formMaker = (warehouse_code, supplier_name, po_no) => [
  {
    name: 'warehouseCode',
    label: 'Warehouse Code',
    value: warehouse_code,
    isRequired: true,
    disabled: false
  },
  {
    name: 'supplierName',
    label: 'Supplier Name',
    value: supplier_name,
    disabled: false,
    isRequired: true
  },
  {
    name: 'supplierInvoiceNumber',
    label: 'Supplier Invoice Number',
    type: 'input',
    disabled: false,
    isRequired: true
  },
  {
    name: 'invoiceLineItems',
    label: 'Invoice Line Items',
    type: 'input',
    inputType: 'number',
    disabled: false,
    isRequired: true
  },
  {
    name: 'totalInvoiceQuantity',
    label: 'Total Invoice Quantity',
    type: 'input',
    inputType: 'number',
    disabled: false,
    isRequired: true
  },
  {
    name: 'totalInvoiceValue',
    label: 'Total Invoice Value',
    type: 'input',
    inputType: 'number',
    isRequired: true
  },
  {
    name: 'invoiceDate',
    label: 'Invoice Date',
    disabled: false,
    type: 'date',
    isRequired: true
  },
  {
    name: 'poNumber',
    label: 'PO Number',
    value: po_no,
    isRequired: true,
    disabled: false
  },
  {
    name: 'totalInvoicePackages',
    label: 'Total Invoice Packages',
    type: 'input',
    inputType: 'number',
    isRequired: true,
    disabled: false
  },
  {
    name: 'supplierGSTNumber',
    label: 'Supplier GST Number',
    type: 'input',
    inputType: 'input',
    isRequired: true
  }
];

const fileUploadForm = (isFileUploadedStatus, fileUploadApi) => [
  {
    label: 'Upload Supplier Invoice',
    component: (
      <NewFileUpload
        file={{
          fileName: isFileUploadedStatus?.supplierInvoiceName,
          fileUrl: isFileUploadedStatus?.supplierInvoiceUrl
        }}
        isFileSelected={
          isFileUploadedStatus?.supplierInvoiceName && isFileUploadedStatus?.supplierInvoiceUrl
        }
        fileUploadApi={(file, fileName) => {
          fileUploadApi(file, fileName, 'supplierInvoice');
        }}
        uploadState={isFileUploadedStatus?.supplierInvoiceUploadState}
      />
    ),
    requiredError: isFileUploadedStatus.supplierInvoice && 'File upload is required'
  },
  {
    label: 'Upload Transporter Bill of Lading',
    component: (
      <NewFileUpload
        file={{
          fileName: isFileUploadedStatus?.transporterBillOfLoadingName,
          fileUrl: isFileUploadedStatus?.transporterBillOfLoadingUrl
        }}
        isFileSelected={
          isFileUploadedStatus?.transporterBillOfLoadingName &&
          isFileUploadedStatus?.transporterBillOfLoadingUrl
        }
        fileUploadApi={(file, fileName) => {
          fileUploadApi(file, fileName, 'transporterBillOfLoading');
        }}
        uploadState={isFileUploadedStatus?.transporterBillOfLoadingUploadState}
      />
    ),
    requiredError: isFileUploadedStatus.transporterBillOfLoading && 'File upload is required'
  },
  {
    label: 'Upload POD',
    component: (
      <NewFileUpload
        file={{
          fileName: isFileUploadedStatus?.podName,
          fileUrl: isFileUploadedStatus?.podUrl
        }}
        isFileSelected={isFileUploadedStatus?.podName && isFileUploadedStatus?.podUrl}
        fileUploadApi={(file, fileName) => {
          fileUploadApi(file, fileName, 'pod');
        }}
        uploadState={isFileUploadedStatus?.podUploadState}
      />
    ),
    requiredError: isFileUploadedStatus.pod && 'File upload is required'
  }
];
const CreateIRNForm = ({ irnIdOverview }) => {
  const dispatch = useDispatch();
  const [isFileUploadedStatus, setIsFileUploadedStatus] = useState({
    supplierInvoice: false,
    supplierInvoiceName: '',
    supplierInvoiceUrl: '',
    supplierInvoiceUploadState: '',
    transporterBillOfLoading: false,
    transporterBillOfLoadingName: '',
    transporterBillOfLoadingUrl: '',
    transporterBillOfLoadingUploadState: '',
    pod: false,
    podName: '',
    podUrl: '',
    podUploadState: ''
  });

  const [isIrnGenerated, setIsIrnGenerated] = useState(false);
  const [createIRN, setCreateIRN] = useState('');

  const { control, trigger, watch } = useForm({
    mode: 'all',
    defaultValues: {
      supplierInvoiceNumber: '',
      invoiceLineItems: '',
      totalInvoiceQuantity: '',
      totalInvoiceValue: '',
      invoiceDate: '',
      totalInvoicePackages: '',
      supplierGSTNumber: irnIdOverview?.supplier_gst
    }
  });

  const fileUploadApi = async (file, fileName, type) => {
    const obj = { ...isFileUploadedStatus };
    let fileUploadingFor = '';
    if (type === 'supplierInvoice') {
      obj.supplierInvoiceUploadState = 'Upload in progress';
      fileUploadingFor = 'supplierinvoice';
    } else if (type === 'transporterBillOfLoading') {
      obj.transporterBillOfLoadingUploadState = 'Upload in progress';
      fileUploadingFor = 'billoflading';
    } else {
      obj.podUploadState = 'Upload in progress';
      fileUploadingFor = 'proofofdelivery';
    }

    setIsFileUploadedStatus(obj);

    const obj2 = { ...isFileUploadedStatus };

    const customPayload = { file, acnId: irnIdOverview?.irn_id, typeOfFile: fileUploadingFor };

    const res = await irnDetailsFileUpload({
      ...customPayload
    });

    if (res.status === SUCCESS) {
      if (type === 'supplierInvoice') {
        obj2.supplierInvoiceName = fileName;
        (obj2.supplierInvoiceUrl = res?.data?.url), (obj2.supplierInvoice = false);
      } else if (type === 'transporterBillOfLoading') {
        obj2.transporterBillOfLoadingName = fileName;
        (obj2.transporterBillOfLoadingUrl = res?.data?.url),
          (obj2.transporterBillOfLoading = false);
      } else {
        obj2.podName = fileName;
        (obj2.podUrl = res?.data?.url), (obj2.pod = false);
      }

      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }

    obj2.podUploadState = '';
    obj2.transporterBillOfLoadingUploadState = '';
    obj2.supplierInvoiceUploadState = '';

    setIsFileUploadedStatus(obj2);
  };

  const [fileUploadFormMaker, setfileUploadFormMaker] = useState(
    fileUploadForm(isFileUploadedStatus, fileUploadApi)
  );

  useEffect(() => {
    setfileUploadFormMaker(fileUploadForm(isFileUploadedStatus, fileUploadApi));
  }, [
    isFileUploadedStatus?.pod,
    isFileUploadedStatus?.supplierInvoice,
    isFileUploadedStatus?.transporterBillOfLoading,
    isFileUploadedStatus?.supplierInvoiceUploadState,
    isFileUploadedStatus?.transporterBillOfLoadingUploadState,
    isFileUploadedStatus?.podUploadState
  ]);

  const generateIrn = async (customPayload) => {
    const res = await putIRNDetails({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      setIsIrnGenerated(true);
      setCreateIRN(res?.data?.irn_no);
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
    } else {
      dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
    }
  };

  const finishExecution = (res) => {
    let allFileUploaded = true;
    let obj = {};
    if (!isFileUploadedStatus?.supplierInvoiceUrl) {
      obj.supplierInvoice = true;
      allFileUploaded = false;
    }
    if (!isFileUploadedStatus?.transporterBillOfLoadingUrl) {
      obj.transporterBillOfLoading = true;
      allFileUploaded = false;
    }
    if (!isFileUploadedStatus?.podUrl) {
      obj.pod = true;
      allFileUploaded = false;
    }

    if (!allFileUploaded)
      setIsFileUploadedStatus({
        ...isFileUploadedStatus,
        ...obj
      });

    if (res && allFileUploaded) {
      const customPayload = {
        irn_id: irnIdOverview?.irn_id,
        supplier_invoice_number: watch('supplierInvoiceNumber'),
        invoice_line_items: Number(watch('invoiceLineItems')),
        total_invoice_quantity: Number(watch('totalInvoiceQuantity')),
        total_invoice_value: Number(watch('totalInvoiceValue')),
        invoice_date: watch('invoiceDate'),
        total_invoice_packages: Number(watch('totalInvoicePackages')),
        supplier_gst_number: watch('supplierGSTNumber')
      };

      generateIrn(customPayload);
    }
  };

  const triggerValidation = () => {
    trigger().then((res) => finishExecution(res));
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.cardWrapper}>
          {formMaker(
            irnIdOverview?.warehouse_code,
            irnIdOverview?.supplier_name,
            irnIdOverview?.po_no
          )?.map(({ name, label, type, isRequired, disabled, value, inputType }, index) => {
            return (
              <Box key={`${label + '-' + index}`} sx={styles.card}>
                <Box
                  sx={{
                    ...styles.contentWrapper,
                    borderRight: '1px solid',
                    borderTop: index !== 0 && '1px solid'
                  }}>
                  <Box sx={styles.label}>{label}</Box>
                </Box>
                <Box
                  sx={{
                    ...styles.contentWrapper,
                    borderTop: index !== 0 && '1px solid'
                  }}>
                  {type === 'input' ? (
                    <Input
                      name={name}
                      type={inputType}
                      control={control}
                      disabled={disabled}
                      rules={{
                        required: {
                          value: isRequired,
                          message: 'This is a required field'
                        }
                      }}
                    />
                  ) : type === 'date' ? (
                    <NewDate
                      name={name}
                      control={control}
                      disabled={disabled}
                      rules={{
                        required: {
                          value: isRequired,
                          message: 'This is a required field'
                        }
                      }}
                    />
                  ) : (
                    <Box sx={styles.label}>{value}</Box>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box sx={styles.cardWrapper}>
          {fileUploadFormMaker?.map(({ label, component, requiredError }, index) => {
            return (
              <Box key={`${label + '-' + index}`} sx={styles.card}>
                <Box
                  sx={{
                    ...styles.contentWrapper,
                    borderRight: '1px solid',
                    borderBottom: index !== fileUploadFormMaker.length - 1 && '1px solid'
                  }}>
                  <Box sx={styles.label}>{label}</Box>
                </Box>
                <Box
                  sx={{
                    ...styles.contentWrapper,
                    borderBottom: index !== fileUploadFormMaker.length - 1 && '1px solid'
                  }}>
                  {component}
                  <Box sx={styles.docsError}>{requiredError}</Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      {!isIrnGenerated ? (
        <Button
          label="Generate IRN"
          onClick={() => triggerValidation()}
          customStyles={styles.button}
        />
      ) : (
        <Box sx={styles.receiveIRN} onClick={() => console.log('IRN Received')}>
          Receive IRN
        </Box>
      )}
      {isIrnGenerated && (
        <Box sx={styles.irnGenerated}>
          <CheckCircleOutlineIcon sx={styles.verifiedIcon} />
          {createIRN} Generated
        </Box>
      )}
    </>
  );
};

export default CreateIRNForm;
