import { FormControl, InputBase, InputLabel, FormHelperText } from '@mui/material';
import { getError } from '../../../../constants/common';
import { Controller } from 'react-hook-form';

const NewDate = ({
  name,
  type = 'date',
  control,
  label,
  fullWidth = true,
  rules,
  minDate = '',
  maxDate = '',
  variant = 'standard',
  className,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState: { errors } }) => {
        const error = getError(name, errors);
        return (
          <FormControl
            fullWidth={fullWidth}
            variant={variant}
            className={className}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                cursor: 'not-allowed'
              }
            }}>
            {label && <InputLabel>{label}</InputLabel>}
            <InputBase
              type={type}
              fullWidth={fullWidth}
              value={field.value}
              error={!!error}
              onChange={field.onChange}
              inputRef={field.ref}
              sx={{
                height: {
                  xs: '34px',
                  sm: '38px'
                }
              }}
              onBlur={field.onBlur}
              inputProps={{
                min: minDate,
                max: maxDate
              }}
              {...rest}
            />
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
      {...rest}
    />
  );
};

export default NewDate;
