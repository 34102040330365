import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../../constants/constants';
import {
  putRoDetails,
  viewPoFileUpload
} from '../../../../../redux/APICaller/inboundReturnOrderAPIs';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Dropdown, NewFileUpload, ReadOnlyList } from '../../../../common';
import styles from '../styles';

const DocumentInformation = ({
  roIdOverview,
  isFileSelected,
  isFileUploaded,
  setIsFileSelected,
  setIsFileUploaded,
  roId,
  setRoIdOverview
}) => {
  const dispatch = useDispatch();
  const { control, watch } = useForm({
    defaultValues: {
      docType: roIdOverview?.document_information?.dock_type,
      pickupType: roIdOverview?.document_information?.return_pickup_type
    }
  });
  const [uploadState, setUploadState] = useState('');

  const fileUploadApi = async (file, fileName) => {
    setUploadState('Upload in progress');
    const customPayload = { file, ROid: roId };

    const res = await viewPoFileUpload({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      setRoIdOverview({
        ...roIdOverview,
        document_information: {
          ...roIdOverview?.document_information,
          actual_file_name: fileName,
          uploaded_file_url: res?.data?.url,
          uploaded_file_key: res?.data?.uploaded_file_key
        }
      });
      setIsFileUploaded(true);
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
    setUploadState('');
  };

  const saveDocumentInfo = async () => {
    const customPayload = {
      ROid: roId,
      dock_type: watch('docType'),
      return_pickup_type: watch('pickupType'),
      actual_file_name: roIdOverview?.document_information?.actual_file_name,
      uploaded_file_key: roIdOverview?.document_information?.uploaded_file_key
    };
    const res = await putRoDetails({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      setIsFileSelected(true);
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
    } else {
      dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  const documentInformationFormate = [
    {
      label: 'Upload Doc',
      value: {
        type: 'write',
        value: (
          <Box sx={styles.uploadDoc}>
            <Dropdown
              name="docType"
              label="DOC Type"
              disabled={isFileSelected || isFileUploaded}
              control={control}
              options={[{ label: 'Debit Note', value: 'debitNote' }]}
            />
            <NewFileUpload
              file={{
                fileName: roIdOverview?.document_information?.actual_file_name,
                fileUrl: roIdOverview?.document_information?.uploaded_file_url
              }}
              isDisabled={!watch('docType')}
              isFileSelected={isFileSelected}
              isFileUploaded={isFileUploaded}
              fileUploadApi={fileUploadApi}
              uploadState={uploadState}
            />
          </Box>
        )
      }
    },
    {
      label: 'Return Pickup type',
      value: {
        type: 'write',
        value: (
          <Dropdown
            name="pickupType"
            disabled={isFileSelected}
            control={control}
            options={[
              { label: 'Supplier Pickup', value: 'supplierPickup' },
              { label: 'Portal Pickup', value: 'portalPickup' }
            ]}
          />
        )
      }
    }
  ];

  return (
    <ReadOnlyList
      heading="Document Information"
      fieldsData={documentInformationFormate}
      isFileSelected={isFileSelected}
      disabledAction={!(watch('pickupType') && isFileUploaded)}
      showButton={!isFileSelected}
      buttonLabel="Save"
      buttonAction={() => saveDocumentInfo()}
      buttonStyles={{ width: { xs: '100%', md: '100px' } }}
    />
  );
};

export default DocumentInformation;
