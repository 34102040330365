import { Box, Drawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { selectAuthStore } from '../../redux/authSlice';
import ComponentRouter from './ComponentRouter';
import Navbar from './Navbar';
import StepperList from './StepperList';
import styles from './styles';

const Dashboard = ({ type }) => {
  const authStore = useSelector(selectAuthStore);
  const [{ width }] = useWindowDimensions();
  const isMobile = width < 900;
  const [showStepper, setShowStepper] = useState(false);

  useEffect(() => {
    setShowStepper(!isMobile);
  }, [isMobile]);

  const drawer = (
    <Drawer
      variant="persistent"
      anchor="left"
      open={showStepper}
      elevation={5}
      PaperProps={{ variant: 'outlined' }}
      sx={styles.drawerPaper}>
      <StepperList setShowStepper={setShowStepper} />
    </Drawer>
  );

  return (
    <Box sx={styles.appFrame}>
      {drawer}
      {authStore?.applicationTypeList?.length &&
      authStore?.warehouseLocationList?.length &&
      authStore?.orderTypeList?.length &&
      authStore?.warehouseLocation &&
      authStore?.orderType &&
      authStore?.applicationType ? (
        <Navbar
          showStepper={showStepper}
          setShowStepper={setShowStepper}
          yms={authStore?.applicationType}
          warehouse={authStore?.warehouseLocation}
          orderType={authStore?.orderType}
        />
      ) : null}
      <Box
        sx={{
          ...styles.drawerContent,
          opacity: showStepper && isMobile ? 0.3 : 1,
          width: {
            xs: '100%',
            md: showStepper ? `calc(100% - 250px)` : '100%'
          },
          marginLeft: {
            xs: '0',
            md: showStepper ? '250px' : 0
          }
        }}>
        <Box sx={styles.content} onClick={() => isMobile && setShowStepper(false)}>
          <ComponentRouter type={type} />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
