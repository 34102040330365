import Box from '@mui/material/Box';
import React from 'react';
import { Pagination, Spinner, TableDataRenderer } from '../../../../common/index';

import styles from '../styles';

const FindConsignmentTable = ({ list = [], pageData, pageClick, isLoading, page, acnID, poID }) => {
  const columnDefs = [
    {
      field: 'ACNId',
      headerName: 'ACNId',
      cellRenderer: ({ data: cellData }) => acnID(cellData.ACNId || '123455')
    },
    {
      field: 'PONO',
      headerName: 'PO No.',
      cellRenderer: ({ data: cellData }) => poID(cellData.PONO)
    },
    { field: 'SCHEDULEDQTY', headerName: 'Scheduled Qty' },
    { field: 'RECEIVEDQTY', headerName: 'Received Qty' },
    { field: 'DOCKREJECTEDQTY', headerName: 'Dock Rejected Qty' },
    { field: 'CONSIGNMENTSTATUS', headerName: 'Consignment Status' },
    { field: 'OUTSTANDINGQTY', headerName: 'Outstanding Qty' },
    { field: 'SOURCEWHSECODE', headerName: 'Source Whse Code' },
    { field: 'DESTINATIONWHSECODE', headerName: 'Destination Whse Code' },
    { field: 'TRAILERNO', headerName: 'Trailer No.' }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default FindConsignmentTable;
